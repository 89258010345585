.bgTop {
    background-color: #d82124de;
    font-size: 14px;
    color: white;
    padding: 0 !important;
    display: flex !important;
    justify-content: space-around !important;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;

    .spanTop {
        padding-top: 3px;
    }
}
.icoTop {
    margin-bottom: 3px;
    margin-right: 3px;
}
.navLink:hover {
    color: yellow !important;
}
.nav-link {
    color: white !important;
}

.telTop {
    font-size: 14px;
    color: white;
}
.bgbodytertiary {
    background-color: #d82124de;
    position: sticky !important;
    top: 0;
    z-index: 15;
    font-family: "Montserrat", sans-serif;
    margin-top: 1px;
    .navImg {
        width: 110px;
    }
    .brand {
        color: white;
        font-size: 14px;
    }
    .navLink {
        color: white;
    }
    .navDropdown {
        color: yellow;
    }
    .collapse {
        flex-grow: 0;
    }
}

.dropdown-toggle {
    color: white !important; /* Заголовок меню будет белого цвета */
}

@media (max-width: 760px) {
    // .bgbodytertiary {
    //     position: sticky !important;
    //     top: 0;
    //     z-index: 15;
    // }
}
// .navbar-collapse .header {
//     display: flex;
//     justify-content: space-around;
//     background-color: #d82124de;
//     width: 100%;
//     padding: 5px 0 5px 0;

//     .logoImg {
//         max-width: 120px;
//     }

//     .haederNav {
//         width: 77%;
//         align-self: center;

//         .nav_menuTop {
//             display: flex;
//             color: white;
//             a {
//                 margin-left: 10px;
//                 font-family: "Montserrat", sans-serif;
//                 font-size: 14px;
//             }
//         }

//         .sp_column {
//             display: grid;
//             grid-template-columns: 50% 50%;
//             padding: 5px 0;
//         }

//         .nav_menu {
//             display: flex;

//             .itemNav2 {
//                 margin-left: 10px;
//                 color: white;
//                 a {
//                     font-family: "Montserrat", sans-serif;
//                     font-size: 14px;
//                 }
//             }
//         }
//     }
// }
