
h
.app {
  font-family: 'Montserrat', sans-serif;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  /* overflow-x: hidden; */
 
}
.accordion {
  font-family: 'Montserrat', sans-serif;
  margin: 20px;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
}

.search_form {
  width: 35%;
  /* margin: 0 auto; */
  position: relative;
  margin-right: 22px;
  border: 1px solid rgb(223, 223, 223);
  display: flex;
  border-radius: 5px;
  box-sizing: content-box !important;
}

.search_input {
  height: 15px;
  /* outline: none; */
  margin-top: 4px;
  border: none;
}

.autocomplete {
  list-style: none;
  position: absolute;
  z-index: 10;
  background-color: white;
  top: 110%;
  /* margin-right: 22px; */
  box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.15);
  max-height: 240px;
  height: auto;
  overflow: auto;
}

.autocomplete_item {
  padding: 4px;
}

.autocomplete_item:hover {
  background-color: rgb(217, 216, 216);
  cursor: pointer;
  transition: cubic-bezier(1, 0, 0, 1) .3s all;
}


.search_img {

  width: 17px;
  height: 15px;
  padding: 4px;
  margin-top: 7px;
  margin-bottom: 4px;
  margin-left: 5px;
  box-sizing: content-box;
}

.search_form {}

.title {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: 10px;
  
}

.discrtitle {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 10px;
  margin-top: 26px;
}

.expand-all-button {
  display: none;
}

a {
  text-decoration: none;
  box-sizing: border-box;
}

.tours {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
  padding: 20px;

}

.tour {
  width: 100%;
  position: relative;
}

.tour .image {
  width: 100%;
  height: 280px;
  overflow: hidden;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  border-radius: 5px;

}

.duration {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 7px;
  right: 10px;
  top: 10px;
  font-size: 10pt;
}

.price {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  z-index: 9;
  background: #e7505b;
  color: white;
  width: 209px;
  text-align: center;
  font-weight: 400;
  font-size: 15pt;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
}

.name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 17px;
  margin-top: 10px;
  color: black;
  text-align: center;
}

@media (max-width: 760px) {
  /* body {
    overflow-x: hidden;
  } */
  html, body {
    /* overflow-x: hidden; */
}
  .tours {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .search_form {
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
    border: 1px solid rgb(223, 223, 223);
    display: flex;
    border-radius: 5px
  }


}

   /* ___________________________________________________ css React */
/* ____________________________________________________ далі  css vidviday */

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=cyrillic-ext);

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  /* overflow: hidden; */
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

body * {
  -webkit-text-size-adjust: none;
  outline: none;
}

.accordion-all-expand {
  width: 60%;
  padding: 30px;
}

.clear {
  clear: both;
  display: block;
  font-size: 0;
  height: 0;
  overflow: hidden
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none
}

input,
select,
textarea {
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin: 0
}

textarea {
  display: block
}

select::-ms-expand {
  display: none
}

a,
a:active,
a:hover,
a:link,
a:visited {
  cursor: pointer;
  outline: none;
  text-decoration: none
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px
}

::-webkit-scrollbar-track {
  background-color: #929292
}

::-webkit-scrollbar-thumb {
  background-color: #2ab0cd;
  border-radius: 8px
}

.banner-img,
.banner-map,
.contact,
.img,
.relative,
.video,
.zoom,
main {
  position: relative
}

body,
html {
  height: 100%
}

body,
main {
  overflow: hidden
}

main {
  margin-top: 110px;
  padding-top: 20px
}

.overflow-hidden,
html.overflow-hidden body {
  overflow: hidden
}

.swiper-slide:not(:first-child) {
  display: none
}

.spacer-lg,
.spacer-md,
.spacer-sm,
.spacer-xl,
.spacer-xs {
  clear: both
}

.spacer-xl {
  height: 130px
}

.spacer-lg {
  height: 100px
}

.spacer-md {
  height: 60px
}

.spacer-sm {
  height: 40px
}

.spacer-xs {
  height: 30px
}

.spacer-xxs {
  height: 15px
}

.container {
  max-width: 1300px;
  width: 100%
}

.row.short-distance {
  margin: 0 -10px
}

.row.short-distance>div {
  padding: 0 10px
}

.full-size {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

hr {
  border: 0;
  border-top: 1px solid #e9e9e9;
  height: 0;
  margin: 0
}

.seo-text {
  word-break: break-word
}

.seo-text .more-info {
  display: none
}

.seo-text .more-info .active {
  display: block
}

.full-info {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: height .44s linear
}

.full-info.active {
  height: auto
}

.filter-result-bar,
.filter-result-bar label {
  align-items: center;
  display: flex
}

.filter-result-bar>span {
  margin-right: auto
}

.filter-result-bar>label {
  font-size: 0;
  margin-bottom: 0;
  margin-right: 35px
}

.filter-result-bar>label:last-child {
  margin-right: 0
}

.filter-result-bar>label select,
.filter-result-bar>label span {
  display: inline-block;
  min-width: 95px;
  vertical-align: middle
}

.filter-result-bar>label>span {
  color: #323232;
  font-weight: 500;
  margin-right: 15px
}

.filter-result-bar>label .SumoSelect {
  margin-bottom: 0
}

.img img {
  display: block;
  max-width: 100%;
  width: 100%
}

.img.img-border {
  border-radius: 5px;
  margin-bottom: 30px;
  overflow: hidden
}

.img.img-border.img-caption {
  margin-bottom: 20px;
  padding-bottom: 60px
}

.img.img-border.img-caption:before {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.img.img-border.img-caption .top-part {
  background-color: #ffb947;
  border-radius: 5px 5px 0 0;
  padding: 15px 20px
}

.img.img-border.img-caption .top-part+.zoom+.img-caption-info .guide-name {
  height: auto
}

.img.img-border.img-caption .zoom .full-size {
  cursor: pointer
}

.img.img-border.img-caption .contact {
  font-size: 14px;
  line-height: 20px;
  padding-left: 0
}

.img.img-border.img-caption .contact .img {
  display: inline-block;
  left: auto;
  margin-right: 5px;
  position: relative;
  top: auto;
  vertical-align: middle
}

.img.img-border.img-caption hr {
  margin-bottom: 15px
}

.img.img-border.img-caption .btn {
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 20px
}

.img.img-border.img-caption .btn.btn-block {
  width: auto
}

.img-caption-info,
.img-caption-title {
  margin-bottom: -25px;
  padding: 16px 20px
}

.img-caption-info .h3 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: 700;
  overflow: hidden
}

.guide-name {
  height: 77px;
  margin-bottom: 10px
}

.guide-name .h3 {
  margin-bottom: 5px
}

.guide-name+.text {
  display: block;
  margin-bottom: 15px
}

.banner-img,
.banner-map {
  border-radius: 18px;
  overflow: hidden
}

.banner-img img {
  display: block;
  width: 100%
}

.banner-map {
  padding-top: 55.027%
}

.bordered-box {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 20px
}

.bordered-box hr {
  margin: 20px 0
}

.bordered-box .thumb-price {
  border-top: none;
  margin: 0;
  padding: 0
}

.bordered-box .thumb-price .text span {
  margin: 0 6px
}

.bordered-box .thumb-price sup {
  font-size: 12px;
  line-height: 18px;
  position: relative;
  top: -10px
}

.bordered-box.vacancy {
  margin-bottom: 20px;
  padding: 20px 30px
}

.bordered-box.vacancy:last-child {
  margin-bottom: 0
}

.bordered-box.vacancy .h3,
.bordered-box.vacancy .text p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.bordered-box.vacancy .h3 {
  -webkit-line-clamp: 2;
  font-weight: 700;
  margin-bottom: 5px
}

.bordered-box.vacancy .text-sm {
  display: block;
  margin-bottom: 10px
}

.bordered-box.vacancy .text {
  margin-bottom: 15px
}

.bordered-box.vacancy .text p {
  -webkit-line-clamp: 3
}

.bordered-box.doc .img {
  background-color: #c4c4c4;
  margin-bottom: 12px;
  padding-top: 107.365%;
  position: relative
}

.bordered-box.doc .img img {
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

.bordered-box.doc .text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #323232;
  display: -webkit-box;
  height: 44px;
  overflow: hidden
}

.bg-box {
  background-color: #fafafa;
  padding: 36px 40px
}

.contact {
  margin-bottom: 12px;
  padding-left: 30px
}

.contact.no-padding {
  padding-left: 0
}

.contact .img {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 1px;
  width: 18px
}

.contact .img img {
  max-height: 100%
}

.contact .img svg {
  height: 18px
}

.contact a,
.contact span {
  display: inline-block;
  vertical-align: middle
}

.done {
  margin: 0 auto;
  width: 60px
}

.bread-crumbs {
  margin-bottom: 15px;
  margin-top: -10px
}

.bread-crumbs li {
  display: inline-block
}

.bread-crumbs li a,
.bread-crumbs li span {
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  position: relative;
  vertical-align: middle
}

.bread-crumbs li:first-child span {
  margin: 0 10px 0 0
}

.bread-crumbs li span {
  color: #323232;
  margin-right: 10px;
  transition: all .44s ease-in-out
}

.bread-crumbs li a {
  color: #323232
}

.bread-crumbs li:last-child a,
.bread-crumbs li:last-child span {
  color: #626262;
  margin: 0
}

.bread-crumbs a,
.bread-crumbs span {
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  position: relative;
  vertical-align: middle
}

.bread-crumbs a {
  color: #323232
}

.video {
  display: block;
  padding-top: 48.168%
}

.video iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.video .ytp-title {
  display: none !important
}

.zoom {
  overflow: hidden;
  z-index: 1
}

.zoom img {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform
}

.zoom .full-size {
  cursor: zoom-in
}

.zoom.centered {
  padding-top: 67%
}

.zoom.centered img {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.align-items-center {
  align-items: center
}

.justify-content-center {
  justify-content: center
}

.justify-content-between {
  justify-content: space-between
}

.inline-block {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle
}

.map-route-wrap form input::-moz-placeholder {
  color: #929292
}

.map-route-wrap form input,
.map-route-wrap form input::placeholder {
  color: #929292
}

.d-none {
  display: none !important
}

.d-block {
  display: block !important
}

.d-flex {
  display: flex !important
}

.justify-content-end {
  justify-content: flex-end
}

.ms-30 {
  margin-left: 30px
}

@media (min-width: 1200px) {
  .contact a:hover {
    color: #2ab0cd
  }

  .bread-crumbs a:hover,
  .bread-crumbs a:hover span {
    color: #7ebd3e
  }

  .zoom:hover img {
    transform: scale(1.06)
  }

  .bordered-box.vacancy .h3 a:hover,
  .img-caption-info .h3:hover {
    color: #7ebd3e
  }
}

#tour-selection-btn {
  height: 58px
}

.mce-content-body {
  padding: 10px
}

@media (min-width: 1200px) {

  .only-pad,
  .only-pad-mobile,
  .section.only-mobile {
    display: none !important
  }
}

@media (max-width: 1199px) {
  main {
    margin-top: 72px;
    padding-top: 0
  }

  .spacer-lg,
  .spacer-xl {
    height: 60px
  }

  .mobile-reverse-content>div:first-child {
    order: 2
  }

  .mobile-reverse-content>div:nth-child(2) {
    order: 3
  }

  .mobile-reverse-content>div:last-child {
    order: 1
  }

  .section.only-mobile {
    display: block !important
  }

  .bread-crumbs,
  .only-desktop.only,
  .section.only-desktop {
    display: none
  }

  #tour-selection-btn,
  .justify-content-center-md {
    justify-content: center !important
  }

  .bordered-box>.thumb-price>.text {
    width: 100%
  }
}

@media (max-width: 991px) {
  .only-desktop-pad {
    display: none !important
  }

  .spacer-lg,
  .spacer-md {
    height: 50px
  }

  .spacer-xs {
    height: 15px
  }

  .filter-result-bar>label.only-desktop {
    display: none
  }
}

/* @media (min-width: 768px) {
  .only-mobile {
    display: none !important
  }
}

@media (max-width: 767px) {
  main {
    margin-top: 56px
  }

  .only-desktop,
  .only-pad {
    display: none !important
  }

  .spacer-xl {
    height: 40px
  }

  .spacer-lg,
  .spacer-md {
    height: 30px
  }

  .spacer-sm {
    height: 20px
  }

  .filter-result-bar {
    justify-content: center
  }

  .banner-map {
    border-radius: 0;
    margin: 0 -15px
  }

  .contact .text-md {
    font-size: 14px;
    line-height: 22px
  }
} */

.sign-up-form label .tooltip-wrap .text span {
  color: #323232
}

.sign-up-form label .tooltip {
  background: #fff;
  width: 350px;
  z-index: 10
}

.sign-up-form .single-datepicker {
  position: relative;
  z-index: 5
}

.order-page .bordered-box.suc .col-12:last-child .text b {
  white-space: normal !important
}

.cabinet-page .tooltip-wrap .tooltip[data-v-6eea6b4a],
.cabinet-page .tooltip-wrap .tooltip[data-v-f8dc3816] {
  color: #323232 !important;
  text-align: center
}

.cabinet-page .tooltip {
  background: #fff;
  width: 350px;
  z-index: 10
}

.order-page .tooltip-wrap .text span {
  color: #323232
}

.order-page .tooltip {
  background: #fff;
  width: 300px;
  z-index: 10
}

.order-page .tooltip-auto .tooltip {
  width: auto
}

.order-page .tooltip-auto .tooltip-wrap .text span {
  white-space: nowrap
}

.discount .tooltip-wrap .tooltip {
  background: #323232 !important;
  color: hsla(0, 0%, 100%, .7) !important;
  text-align: center;
  white-space: nowrap !important
}

.discount .tooltip-wrap .tooltip:before {
  background: #323232 !important
}

.thumbs-carousel {
  margin-top: -65px
}

@media (max-width: 1200px) {
  .thumbs-carousel.vac .swiper-wrapper {
    padding-bottom: 0
  }
}

@media (max-width: 992px) {

  .sign-up-form .tooltip-wrap .tooltip.placement-center[data-v-6eea6b4a],
  .sign-up-form .tooltip-wrap .tooltip.placement-center[data-v-f8dc3816] {
    left: auto;
    right: -15px;
    transform: translate(0) !important;
    -webkit-transform: translate(0) !important;
    -moz-transform: translate(0) !important;
    -ms-transform: translate(0) !important;
    -o-transform: translate(0) !important
  }

  .sign-up-form label .tooltip:before {
    left: auto;
    right: 18px
  }

  .d-b {
    display: block !important
  }

  .d-b .col-8 {
    flex: 0 0 100%;
    justify-content: start;
    margin: 15px 0 0;
    max-width: 100%
  }

  .order-page .add-participant-btn {
    margin-bottom: 30px
  }

  .participant.phone-number {
    border-bottom: 0 solid #e9e9e9 !important
  }

  .order-page .tooltip-wrap .tooltip.placement-center[data-v-6eea6b4a],
  .order-page .tooltip-wrap .tooltip.placement-center[data-v-f8dc3816] {
    left: auto;
    right: -15px;
    transform: translate(0) !important;
    -webkit-transform: translate(0) !important;
    -moz-transform: translate(0) !important;
    -ms-transform: translate(0) !important;
    -o-transform: translate(0) !important
  }

  .order-page .tooltip:before {
    left: auto;
    right: 18px
  }

  .cabinet-page .tooltip-wrap .tooltip.placement-center[data-v-6eea6b4a],
  .cabinet-page .tooltip-wrap .tooltip.placement-center[data-v-f8dc3816] {
    left: auto;
    right: -15px;
    transform: translate(0) !important;
    -webkit-transform: translate(0) !important;
    -moz-transform: translate(0) !important;
    -ms-transform: translate(0) !important;
    -o-transform: translate(0) !important
  }

  .cabinet-page .tooltip:before {
    left: auto;
    right: 18px
  }
}

@media (max-width: 767px) {
  .d-b .col-8 {
    display: block !important
  }

  .d-b .col-8 .btn {
    display: block;
    margin: 20px 0 0 !important
  }

  .order-page .bordered-box.b-pad {
    padding: 20px
  }

  .participant.phone-number .form>.text {
    width: 100% !important
  }

  .order-page .slide-up-down__container.more-info {
    margin: 0 0 10px
  }

  .thumbs-carousel .swiper-container {
    top: 0 !important
  }

  .thumbs-carousel .swiper-wrapper {
    padding-bottom: 0 !important
  }

  .thumbs-carousel {
    margin-top: 0
  }
}

@media (max-width: 575px) {
  .discount .tooltip-wrap .tooltip:before {
    left: auto;
    right: 18px
  }

  .discount .tooltip-wrap .tooltip.placement-center[data-v-6eea6b4a],
  .discount .tooltip-wrap .tooltip.placement-center[data-v-f8dc3816] {
    left: auto;
    right: -15px;
    transform: translate(0) !important;
    -webkit-transform: translate(0) !important;
    -moz-transform: translate(0) !important;
    -ms-transform: translate(0) !important;
    -o-transform: translate(0) !important
  }
}

@media (max-width: 374px) {
  .sign-up-form label .tooltip {
    width: 300px
  }

  .sign-up-form label .tooltip br {
    display: none
  }

  .order-page .tooltip {
    width: 300px
  }

  .order-page .tooltip br {
    display: none
  }

  .cabinet-page .tooltip {
    width: 300px
  }

  .cabinet-page .tooltip br {
    display: none
  }
}

.text,
body {
  color: #626262;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 22px
}

b,
strong {
  font-weight: 700
}

a {
  color: inherit
}

a,
a:active,
a:hover,
a:link,
a:visited {
  cursor: pointer;
  outline: none;
  text-decoration: none
}

iframe {
  border: none;
  outline: none
}

ol,
ul {
  /* list-style: none */
}

.simple-item {
  position: relative;
  z-index: 1
}

.text-lg {
  font-size: 18px;
  line-height: 26px
}

.text-md {
  font-size: 16px;
  line-height: 26px
}

.text-normal {
  font-size: 14px;
  line-height: 22px
}

.text-sm {
  font-size: 12px;
  line-height: 16px
}

.text-nowrap {
  white-space: nowrap
}

.text-md.light,
.text-sm.light,
.text.light {
  color: hsla(0, 0%, 100%, .7)
}

.text-md.light ul li,
.text-sm.light ul li,
.text.light ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative
}

.text-md.light ul li:before,
.text-sm.light ul li:before,
.text.light ul li:before {
  background-color: #e9e9e9
}

.text {
  color: #626262;
  font-weight: 400
}

.text h1 b,
.text h2 b,
.text h3 b,
.text h4 b,
.text h5 b,
.text h6 b {
  font-weight: 700
}

.text>* {
  margin-bottom: 10px
}

.text>:first-child {
  margin-top: 0
}

.text>:last-child {
  margin-bottom: 0
}

.text img {
  height: auto;
  max-width: 100%
}

.text ol li,
.text ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative
}

.text ol li:last-child,
.text ul li:last-child {
  margin-bottom: 0
}

.text ol li span,
.text ul li span {
  color: #7ebd3e;
  font-weight: 500
}

.text ul li:before {
  background: #2ab0cd;
  border-radius: 50%;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 6px
}

.text ol {
  counter-reset: number
}

.text ol li:before {
  color: #2ab0cd;
  content: counter(number) ".";
  counter-increment: number;
  font-size: inherit;
  font-weight: 700;
  left: 0;
  line-height: 20px;
  position: absolute;
  top: 0
}

.text a,
.text span.open-popup {
  color: #7ebd3e;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap
}

.text .btn.arrow-right {
  line-height: 20px
}

.text i {
  font-weight: 300
}

.text i,
.text span {
  color: #7ebd3e
}

.text b {
  color: #323232;
  font-weight: 500
}

.text.text-bold b {
  color: #7ebd3e;
  font-weight: 500
}

.text blockquote,
.text img,
.text>div:not(.loaded-img) {
  margin: 30px 0
}

.text>div:not(.loaded-img) {
  border-radius: 10px;
  overflow: hidden;
  position: relative
}

.text>div:not(.loaded-img) iframe {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.text.text-sm ul li:before {
  top: 6px
}

.text.text-sm ol li:before {
  top: -1px
}

.text.text-md ul li:before {
  top: 10px
}

.text.text-md ol li:before {
  top: 4px
}

.text .label {
  background-color: #ffb947;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  left: auto;
  line-height: 16px;
  margin-bottom: 0;
  padding: 2px 10px 0;
  text-transform: none;
  top: auto;
  vertical-align: middle
}

.text .label,
blockquote {
  font-weight: 500;
  position: relative
}

blockquote {
  border-left: 4px solid #2ab0cd;
  color: #323232;
  font-size: 22px;
  line-height: 32px;
  padding-left: 30px;
  text-align: left
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #323232;
  font-weight: 500
}

.h1.light,
.h2.light,
.h3.light,
.h4.light,
.h5.light,
.h6.light,
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
  color: #fff
}

.h1,
h1 {
  font-size: 30px
}

.h1,
.h2,
h1,
h2 {
  line-height: 1.33em
}

.h2,
h2 {
  font-size: 24px
}

.h3,
h3 {
  font-size: 18px;
  line-height: 1.33em
}

.h4,
h4 {
  font-size: 16px;
  line-height: 1.25em
}

.h5,
.h6,
h5,
h6 {
  font-size: 14px;
  line-height: 1.43em
}

.error {
  color: #323232;
  font-size: 96px;
  font-weight: 700;
  line-height: 92px;
  margin-bottom: 12px
}

.section-title {
  margin-bottom: 25px
}

.title {
  color: #323232;
  font-weight: 700
}

.title.light {
  color: #fff
}

.title-decor {
  display: inline-block;
  font-weight: 700;
  padding-right: 50px;
  position: relative
}

.title-decor:before {
  background: currentColor;
  content: "";
  height: 5px;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 30px
}

.title-icon>* {
  vertical-align: middle
}

.title-icon img {
  display: inline-block;
  height: auto;
  margin-right: 15px;
  width: 26px
}

.title-icon span {
  font-weight: 500
}

.sub-title {
  color: #000;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  position: relative
}

.sub-title.style-2 {
  color: #1f1f1f;
  font-size: 26px;
  line-height: 37px
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-medium {
  font-weight: 500
}

.text-bold {
  font-weight: 700
}

.text-uppercase {
  text-transform: uppercase
}

.full-link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

ol {
  counter-reset: number
}

ol li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative
}

ol li:before {
  color: #2ab0cd;
  content: counter(number) ".";
  counter-increment: number;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  line-height: 20px;
  position: absolute;
  top: 0
}

@media (min-width: 1200px) {

  .text-md.open-popup:hover,
  .text-sm.open-popup:hover,
  .text.open-popup:hover {
    color: #7ebd3e
  }

  .text a:hover,
  .text span.open-popup:hover {
    color: #2ab0cd
  }

  .text-xl-left {
    text-align: left
  }

  .text-xl-center {
    text-align: center
  }

  .text-xl-right {
    text-align: right
  }
}

@media (max-width: 1199px) {
  .text-center-sm {
    text-align: center
  }
}

@media (max-width: 991px) {
  .text>* {
    margin-bottom: 20px
  }
}

@media (max-width: 767px) {
  .text-left-xs {
    text-align: left
  }

  .text-center-xs {
    text-align: center
  }

  .h1,
  h1 {
    font-size: 22px;
    line-height: 1.2em
  }

  .h2,
  h2 {
    font-size: 20px
  }

  blockquote {
    font-size: 18px;
    line-height: 30px;
    padding-left: 15px
  }

  .title br,
  .xs-hide {
    display: none
  }

  .text,
  .text-sm {
    font-size: 14px;
    line-height: 22px
  }

  .text ul li:before {
    top: 8px
  }

  .sub-title {
    font-size: 20px;
    line-height: 30px
  }

  .text>div:not(.loaded-img) {
    border-radius: 0;
    margin: 30px -15px;
    width: calc(100% + 30px)
  }
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 20px;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  vertical-align: bottom
}

.btn.btn-danger,
.btn.type-1,
.btn.type-2,
.btn.type-4,
.btn.type-5 {
  border-radius: 5px;
  padding: 7px 20px 5px
}

.btn.btn-danger img,
.btn.btn-danger span,
.btn.btn-danger svg,
.btn.type-1 img,
.btn.type-1 span,
.btn.type-1 svg,
.btn.type-2 img,
.btn.type-2 span,
.btn.type-2 svg,
.btn.type-4 img,
.btn.type-4 span,
.btn.type-4 svg,
.btn.type-5 img,
.btn.type-5 span,
.btn.type-5 svg {
  display: inline-block;
  vertical-align: middle
}

.btn.btn-danger img,
.btn.btn-danger svg,
.btn.type-1 img,
.btn.type-1 svg,
.btn.type-2 img,
.btn.type-2 svg,
.btn.type-4 img,
.btn.type-4 svg,
.btn.type-5 img,
.btn.type-5 svg {
  margin-right: 15px;
  max-width: 30px
}

.btn.btn-danger path:not(.only-stroke),
.btn.type-1 path:not(.only-stroke),
.btn.type-2 path:not(.only-stroke),
.btn.type-4 path:not(.only-stroke),
.btn.type-5 path:not(.only-stroke) {
  fill: #fff
}

.btn.btn-danger path.only-stroke,
.btn.type-1 path.only-stroke,
.btn.type-2 path.only-stroke,
.btn.type-4 path.only-stroke,
.btn.type-5 path.only-stroke {
  stroke: #fff
}

.btn.type-1,
.btn.type-2,
.btn.type-5 {
  border: 2px solid #7ebd3e
}

.btn.type-1,
.btn.type-4 {
  color: #fff
}

.btn.type-1.arrow-right:after,
.btn.type-4.arrow-right:after {
  border-right: 1px solid #fff;
  border-top: 1px solid #fff
}

.btn.type-2,
.btn.type-3,
.btn.type-5 {
  background: transparent
}

.btn.type-1 {
  background: #7ebd3e
}

.btn.type-1.btn-fb svg,
.btn.type-1.btn-google svg {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: 16px
}

.btn.type-1.btn-fb path,
.btn.type-1.btn-google path {
  fill: #fff
}

.btn.type-1.btn-fb {
  background-color: #4064ad;
  border-color: #4064ad
}

.btn.type-1.btn-google {
  background-color: #e44134;
  border-color: #e44134
}

.btn.type-1.disabled-btn,
.btn.type-1.secondary-btn {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  color: #626262
}

.btn.btn-danger {
  background-color: #e44134;
  border: 2px solid #e44134;
  color: #fff
}

.btn.btn-danger:hover:not(.disabled),
.btn.btn-danger:hover:not(:disabled) {
  background-color: #fff;
  border-color: #e44134;
  color: #e44134
}

.btn.type-2 {
  color: #7ebd3e
}

.btn.type-3 {
  color: #323232;
  line-height: 16px;
  padding: 6px 0 4px
}

.btn.type-3.btn-more {
  padding: 0
}

.btn.type-3.btn-more:after {
  border-right: 2px solid #323232;
  border-top: 2px solid #323232;
  content: "";
  display: inline-block;
  height: 5px;
  margin-bottom: 1px;
  margin-left: 6px;
  position: relative;
  transform: rotate(45deg);
  transition: all .3s ease-in-out;
  vertical-align: middle;
  width: 5px
}

.btn.type-3.light {
  color: #fff
}

.btn.type-3.light.btn-more:after {
  border-color: #fff;
  vertical-align: baseline
}

.btn.type-4 {
  background: #ffb947;
  border: 2px solid #ffb947
}

.btn.type-5 {
  color: #323232
}

.btn.type-5.arrow-right:after {
  border-right: 1px solid #323232;
  border-top: 1px solid #323232
}

.btn.type-5.arrow-right path:not(.only-stroke) {
  fill: #7ebd3e
}

.btn.type-5.arrow-right path.only-stroke {
  stroke: #7ebd3e
}

.btn.disabled-btn {
  cursor: inherit;
  pointer-events: none
}

.btn.arrow-right:after,
.btn.btn-read-more:after {
  content: "";
  height: 8px;
  transform: rotate(45deg);
  width: 8px
}

.btn.arrow-right:after {
  margin-top: -4px;
  position: absolute;
  right: 20px;
  top: 50%
}

.btn.btn-read-more {
  color: #323232;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none !important
}

.btn.btn-read-more:after {
  border-right: 1.4px solid #323232;
  margin-left: 6px;
  transform: rotate(45deg)
}

.btn.btn-read-more.left-arrow:before,
.btn.btn-read-more:after {
  border-top: 1.4px solid #323232;
  content: "";
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px
}

.btn.btn-read-more.left-arrow:before {
  border-left: 1.4px solid #323232;
  margin-right: 6px;
  transform: rotate(-45deg)
}

.btn.btn-read-more.left-arrow:after {
  display: none
}

.btn.text-left {
  text-align: left
}

.btn.flex {
  align-items: center;
  display: flex;
  height: 100%
}

.btn.font-lg {
  font-size: 14px
}

.btn.btn-big {
  padding: 12px 20px
}

.btn:disabled {
  cursor: default !important;
  opacity: .7
}

.btn-lg,
.btn-md {
  font-size: 12px;
  line-height: 24px
}

.btn-block {
  display: block;
  width: 100%
}

.btn-close {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 24px;
  z-index: 1
}

.btn-close:before {
  background: #7ebd3e;
  border-radius: 50%;
  content: "";
  height: 24px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  transform: scale(0);
  visibility: hidden;
  width: 24px;
  z-index: 0
}

.btn-close span {
  display: block;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px
}

.btn-close span:after,
.btn-close span:before {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: rgba(0, 0, 0, .6);
  content: "";
  display: block;
  height: 1px;
  left: -1px;
  position: absolute;
  top: 5px;
  width: 12px
}

.btn-close span:before {
  transform: rotate(-45deg)
}

.btn-close span:after {
  transform: rotate(45deg)
}

.btn-close.light {
  border: none
}

.btn-close.light span:after,
.btn-close.light span:before {
  background-color: #fff
}

.btn-link {
  color: #7ebd3e;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s ease-in-out
}

.btn-link,
.expand-all,
.show-more,
.show-more-btn {
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  position: relative
}

.expand-all,
.show-more,
.show-more-btn {
  color: #323232;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .1em;
  padding-right: 20px;
  text-transform: uppercase
}

.expand-all:before,
.show-more-btn:before,
.show-more:before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-bottom: 1px solid #323232;
  border-left: 1px solid #323232;
  content: "";
  height: 6px;
  position: absolute;
  right: 2px;
  top: 7px;
  transform: rotate(-45deg);
  width: 6px
}

.expand-all.up:before,
.show-more-btn.active:before,
.show-more.active:before {
  top: 10px;
  transform: rotate(-225deg)
}

.show-more span:first-child {
  display: inline-block
}

.show-more span:last-child {
  display: none
}

.show-more span.h3 {
  text-transform: none
}

.show-more.active span:first-child {
  display: none
}

.show-more.active span:last-child {
  display: inline-block
}

.show-more-btn span.h3 {
  text-transform: none
}

.btn-to-top {
  background-color: #7ebd3e;
  border-radius: 5px;
  bottom: 120px;
  cursor: pointer;
  height: 40px;
  opacity: 0;
  position: fixed;
  transform: scale(0);
  width: 40px;
  z-index: 72
}

.btn-to-top:after,
.btn-to-top:before {
  content: "";
  position: absolute
}

.btn-to-top:before {
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  height: 12px;
  left: 14px;
  top: 13px;
  transform: rotate(-45deg);
  width: 12px
}

.btn-to-top:after {
  background-color: #fff;
  height: 16px;
  left: 19px;
  top: 13px;
  width: 2px
}

.btn-to-top.active {
  opacity: 1;
  transform: none
}

.btn-delete {
  cursor: pointer;
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px
}

.btn-delete:after,
.btn-delete:before {
  background-color: #323232;
  content: "";
  height: 2px;
  left: 4px;
  position: absolute;
  top: 7px;
  width: 8px
}

.btn-delete:before {
  transform: rotate(45deg)
}

.btn-delete:after {
  transform: rotate(-45deg)
}

.staff-label {
  cursor: default !important
}

@media (min-width: 1200px) {

  .btn:not(:disabled).type-1:hover,
  .btn:not(:disabled).type-3:hover {
    color: #7ebd3e
  }

  .btn:not(:disabled).type-1:not(.staff-label):hover,
  .btn:not(:disabled).type-4:not(.staff-label):hover {
    background: transparent
  }

  .btn:not(:disabled).type-2:hover,
  .btn:not(:disabled).type-5:hover {
    background-color: #7ebd3e;
    color: #fff
  }

  .btn:not(:disabled).type-1.btn-fb:hover {
    color: #4064ad !important
  }

  .btn:not(:disabled).type-1.btn-fb:hover path {
    fill: #4064ad
  }

  .btn:not(:disabled).type-1.btn-google:hover {
    color: #e44134 !important
  }

  .btn:not(:disabled).type-1.btn-google:hover path {
    fill: #e44134
  }

  .btn:not(:disabled).type-3.btn-more:hover:after {
    border-color: #7ebd3e
  }

  .btn:not(:disabled).type-4:not(.staff-label):hover {
    color: #323232
  }

  .btn:not(:disabled).type-4:not(.staff-label):hover path:not(.only-stroke) {
    fill: #ffb947
  }

  .btn:not(:disabled).type-4:not(.staff-label):hover path.only-stroke {
    stroke: #ffb947
  }

  .btn:not(:disabled).type-4:not(.staff-label):hover.arrow-right:after {
    border-color: #323232
  }

  .btn:not(:disabled).type-5:hover path:not(.only-stroke) {
    fill: #fff
  }

  .btn:not(:disabled).type-5:hover path.only-stroke {
    stroke: #fff
  }

  .btn:not(:disabled).type-5:hover.arrow-right:after {
    border-color: #fff
  }

  .btn:not(:disabled).btn-read-more:hover {
    color: #7ebd3e
  }

  .btn:not(:disabled).btn-read-more.left-arrow:hover:before,
  .btn:not(:disabled).btn-read-more:hover:after {
    border-color: #7ebd3e
  }

  .btn-link:hover {
    color: #323232
  }

  .btn-close:hover:before {
    opacity: 1;
    transform: scale(1);
    visibility: visible
  }

  .btn-close:hover span:after,
  .btn-close:hover span:before {
    background: #fff
  }

  .expand-all:hover,
  .show-more:hover {
    /* color:#7ebd3e; */
  }

  .expand-all:hover:before,
  .show-more:hover:before {
    border-color: #7ebd3e
  }

  .btn-to-top:hover {
    background-color: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .08)
  }

  .btn-to-top:hover:before {
    border-color: #7ebd3e
  }

  .btn-to-top:hover:after {
    background-color: #7ebd3e
  }

  .btn-delete:hover:after,
  .btn-delete:hover:before {
    background-color: #cf2020
  }
}

@media (min-width: 1600px) {
  .btn-to-top {
    right: 1.8%
  }
}

@media (max-width: 1599px) {
  .btn-to-top {
    right: 20px
  }
}

@media (max-width: 1199px) {
  .btn-book-size {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 18px;
    min-height: 48px;
    padding: 7px 20px 5px
  }

  .btn-block-sm {
    display: block;
    width: 100%
  }
}

@media (max-width: 767px) {
  .btn {
    white-space: nowrap
  }

  .btn-book-size {
    padding: 7px 20px 5px
  }
}

@media (max-width: 575px) {
  .btn-block-xs {
    display: block;
    margin-bottom: 15px;
    width: 100%
  }
}
/* 
#header-layer-close {
  background-color: rgba(0, 0, 0, .3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 72px;
  transform: translate3d(150%, 0, 0);
  will-change: transform;
  z-index: 99
}

#header-layer-close:before {
  background: linear-gradient(90deg, transparent 0, rgba(0, 0, 0, .3));
  bottom: 0;
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 50%
}

#header-layer-close.active {
  transform: none
} */

/* header {
  background-color: #fff;
  height: 110px;
  left: 0;
  line-height: 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100
} */

/* header .container {
  height: 100%;
  padding: 10px 15px 10px 17.5%;
  position: relative
}

header.scrolled {
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08)
} */

.vertical-separator {
  background-color: #e9e9e9;
  display: inline-block;
  height: 18px;
  width: 1px
}

.dropdown,
.exchange,
.header-search,
.lang,
.log-in,
.tel {
  /* position: relative */
}

.exchange,
.lang,
.log-in,
.tel,
nav>ul>li {
  display: inline-block;
  vertical-align: middle
}

.header-search button,
.log-in:before,
.tel:before {
  height: 18px;
  position: absolute;
  width: 18px
}

.log-in:before,
.tel:before {
  content: ""
}

#logo {
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 225px
}

#logo img {
  display: block;
  width: 100%
}

.header-search button,
.log-in {
  cursor: pointer
}

.header-search button:disabled,
.log-in:disabled {
  cursor: default
}

.header-search button:disabled:hover path,
.log-in:disabled:hover path {
  fill: #2ab0cd
}

.header-search {
  display: block;
  position: relative;
  z-index: 1
}

.header-search:before {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
  content: "";
  display: none;
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: -1
}

.header-search button {
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 2px;
  right: 12px;
  top: 12px
}

.header-search button svg {
  height: 15px;
  width: 15px
}

.header-search button path {
  fill: #2ab0cd
}

.header-search .input-search {
  background-color: #fcfcfc;
  border-width: 2px;
  height: 42px;
  margin: 0
}

.header-search .btn-close,
.header-search .vertical-separator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

.header-search .vertical-separator {
  right: 45px
}

.header-search .btn-close {
  border: none;
  height: 10px;
  right: 90px;
  transition: opacity .3s ease-in-out, visibility 0s ease-in-out;
  width: 10px
}

.header-search .btn-close:before {
  display: none
}

.header-search .btn-close:after {
  background-color: #e9e9e9;
  content: "";
  height: 18px;
  margin-top: -9px;
  position: absolute;
  right: -10px;
  top: 50%;
  width: 1px
}

.header-search .btn-close span {
  left: 0;
  top: 0;
  transform: none
}

.header-search.active .btn-close {
  opacity: 1;
  visibility: visible
}

.header-search.search-dropdown-form:before {
  box-shadow: none
}

.voice-search-btn {
  cursor: pointer;
  height: 18px;
  margin-top: -9px;
  position: absolute;
  right: 50px;
  top: 50%;
  width: 18px
}

.voice-search-btn svg {
  stroke: #2ab0cd
}



.exchange>span,
.lang>span,
.log-in {
  color: #323232
}

.dropdown-btn {
  border-bottom: 1px solid #323232;
  border-right: 1px solid #323232;
  content: "";
  cursor: pointer;
  height: 6px;
  margin-top: -5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(45deg);
  width: 6px
}

/* .dropdown-btn:before {
  bottom: -5px;
  content: "";
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px
}

.dropdown {
  padding-right: 15px
} */

/* .dropdown.active .dropdown-btn {
  border-color: #2ab0cd;
  transform: rotate(225deg) translate3d(-4px, -2px, 0)
} */

.log-in,
.tel {
  padding-left: 28px
}

.log-in:before,
.tel:before {
  left: 0;
  top: calc(50% - 9px)
}






.log-in.log-inned {
  font-weight: 400;
  padding-left: 0
}

.log-in.log-inned:before {
  display: none
}

.log-in.log-inned .img {
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 40px
}

.log-in.log-inned .img img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}



.log-in.log-inned li:last-child {
  border-top: 1px solid #e9e9e9;
  margin-top: 15px;
  padding-top: 15px
}

.log-in.log-inned li a span {
  background-color: #2ab0cd;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  width: 18px
}

.log-inned-icon {

  display: inline-block;
  height: 30px;
  margin-right: 15px;
  position: relative;
  vertical-align: middle;
  width: 30px;
  z-index: 1
}

.log-inned-icon span {
  background-color: #2ab0cd;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
  position: absolute;
  right: -6px;
  text-align: center;
  top: -6px;
  width: 18px
}

.exchange>span>span {
  color: #2ab0cd
}

/* nav li.dropdown:after {
  display: none
} */

nav a {
  font-size: 16px
}











@media (min-width: 1200px) {



  

  .header-search button:hover path {
    fill: #7ebd3e
  }

  .header-search .btn-close:hover span:after,
  .header-search .btn-close:hover span:before {
    background-color: #cf2020
  }

  .voice-search-btn:hover svg {
    stroke: #7ebd3e
  }
}

@media (max-width: 1399px) {
  #logo {
    width: 210px
  }

  header .container {
    padding: 10px 15px 10px 240px
  }
}

@media (min-width: 1200px) {
  header .container .row {
    align-items: center;
    height: 100%
  }

  header .container .row>div:first-child {
    flex: 0 0 48%;
    max-width: 48%
  }

  header .container .row>div:nth-child(2) {
    flex: 0 0 52%;
    max-width: 52%
  }

  header .container .row>div:nth-child(2),
  nav>ul {
    align-items: center;
    display: flex;
    justify-content: space-between
  }

 

  .exchange .dropdown-toggle,
  .lang .dropdown-toggle,
  .log-in .dropdown-toggle,
  .tel .dropdown-toggle {
    /* display: block !important */
  }

  



  .header-search .btn-close {
    opacity: 0;
    visibility: hidden
  }
}

/* @media (max-width: 1199px) {
  header {
    height: 72px
  }

  header .container {
    padding: 10px 15px 10px 190px
  }

  header .container>.row {
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-end
  }

  header .container>.row>div {
    display: inline-block;
    flex-basis: 0;
    flex-grow: 0
  }

  header .container>.row>div:first-child {
    margin-left: auto
  }

  header .container>.row>div:last-child {
    padding: 0;
    width: 0
  }

  header .container>.row>div:nth-child(2) {
    align-items: center;
    display: flex
  }

  header .container>.row>div:last-child,
  header .container>.row>div:nth-child(2) {
    flex-basis: 0;
    flex-grow: 0
  }

  header .header-search .btn-close,
  header .header-search .input-search,
  header .header-search .search-toggle,
  header .header-search .vertical-separator {
    display: none
  }

  #menu-btn,
  .exchange,
  .lang {
    margin-left: 20px
  }

  .log-in {
    margin-left: 15px
  }

  #logo {
    width: 160px
  }

  .header-search {
    display: inline-block;
    height: 20px;
    vertical-align: middle;
    width: 20px
  }

  .header-search button {
    right: 0;
    top: 0
  }

  .header-search button path {
    fill: #2ab0cd
  }

  .header-search .input-search {
    bottom: 0;
    height: auto;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: -1
  }

  .header-search .btn-close:before {
    bottom: -10px;
    display: block;
    left: -10px;
    right: -10px;
    top: -10px
  }

  .dropdown.active:after {
    border-color: #2ab0cd;
    transform: rotate(225deg) translate3d(-4px, -2px, 0)
  }

  .dropdown.active .dropdown-toggle {
    opacity: 1;
    transform: none
  }

  #menu-btn {
    display: inline-block;
    height: 24px;
    position: relative;
    vertical-align: middle;
    width: 24px
  }

  #menu-btn span,
  #menu-btn:after,
  #menu-btn:before {
    background-color: #2ab0cd;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    will-change: transform, opacity
  }

  #menu-btn span:nth-child(2),
  #menu-btn:after,
  #menu-btn:before {
    top: 11px
  }

  #menu-btn:after,
  #menu-btn:before {
    content: ""
  }

  #menu-btn:before {
    transform: rotate(45deg) scale(0)
  }

  #menu-btn:after {
    transform: rotate(-45deg) scale(0)
  }

  #menu-btn span:first-child {
    top: 4px;
    transform-origin: center bottom
  }

  #menu-btn span:last-child {
    bottom: 4px;
    transform-origin: center top
  }

  #menu-btn.active:before {
    transform: rotate(45deg)
  }

  #menu-btn.active:after {
    transform: rotate(-45deg)
  }

  #menu-btn.active span {
    transform: scale(0)
  }

  nav {
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    bottom: 0;
    max-height: calc(100vh - 72px);
    overflow: auto;
    padding: 30px;
    position: fixed;
    right: 0;
    top: 72px;
    transform: translate3d(100%, 0, 0);
    width: 48.699%;
    will-change: transform
  }

  nav::-webkit-scrollbar {
    height: 4px;
    width: 4px
  }

  nav::-webkit-scrollbar-track {
    background-color: transparent
  }

  nav::-webkit-scrollbar-thumb {
    background-color: #2ab0cd;
    border-radius: 8px
  }

  nav>ul>li {
    display: block;
    position: relative
  }

  nav>ul>li>a:before {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
  }

  nav li {
    margin-bottom: 30px
  }

  nav li:last-child {
    margin-bottom: 0
  }

  nav li.dropdown>span {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    right: auto;
    top: auto;
    vertical-align: middle
  }

  nav li.dropdown>span:before {
    border-radius: 50%;
    bottom: -10px;
    content: "";
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px
  }

  nav li.dropdown .dropdown-toggle {
    box-shadow: none;
    display: none;
    left: auto;
    opacity: 1;
    padding: 15px 0 0;
    position: relative;
    top: auto;
    transform: none;
    transition: none
  }

  nav li.dropdown .dropdown-toggle:before {
    display: none
  }

  nav li.dropdown .dropdown-toggle ul {
    border: none;
    min-width: auto;
    padding: 0;
    width: 100%
  }

  nav li.dropdown .dropdown-toggle ul:last-child {
    margin-bottom: 0
  }

  nav li.dropdown .dropdown-toggle li,
  nav li.dropdown .dropdown-toggle ul {
    margin-bottom: 10px
  }

  nav li.dropdown .dropdown-toggle li a,
  nav li.dropdown .dropdown-toggle ul a {
    padding: 5px 0
  }

  nav li.dropdown .dropdown-toggle li:last-child,
  nav li.dropdown .dropdown-toggle ul:last-child {
    margin-bottom: 0
  }

  nav li.dropdown .dropdown-toggle a {
    font-size: 14px
  }

  nav li.dropdown.active>a {
    color: #323232
  }

  nav li.dropdown.active>span {
    border-color: #323232;
    transform: rotate(225deg) translate3d(-4px, -2px, 0)
  }

  nav.active {
    transform: none
  }

  #search-dropdown {
    background-color: rgba(0, 0, 0, .9);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 20px 18.554%;
    position: fixed;
    right: 0;
    top: 0;
    transform: scale(1.2);
    visibility: hidden;
    z-index: 101
  }

  #search-dropdown .header-search {
    display: block;
    height: auto;
    position: relative;
    width: 100%
  }

  #search-dropdown .header-search:after {
    background-color: #e9e9e9;
    bottom: 8px;
    content: "";
    position: absolute;
    right: 55px;
    top: 8px;
    width: 1px
  }

  #search-dropdown .header-search .input-search {
    bottom: auto;
    height: 42px;
    left: auto;
    opacity: 1;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    z-index: 0
  }

  #search-dropdown .header-search .input-search:focus+button+.voice-search-btn+.btn-close {
    opacity: 1;
    pointer-events: auto;
    z-index: 3
  }

  #search-dropdown .header-search button {
    right: 20px;
    top: 12px;
    z-index: 1
  }

  #search-dropdown .header-search .btn-close {
    border: none;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 110px;
    z-index: 2
  }

  #search-dropdown .header-search .btn-close:before {
    bottom: -5px;
    height: auto;
    left: -5px;
    opacity: 0;
    right: -5px;
    top: -5px;
    transform: none;
    width: auto
  }

  #search-dropdown .header-search .btn-close:after {
    background-color: #e9e9e9;
    content: "";
    height: 26px;
    margin-top: -13px;
    position: absolute;
    right: -15px;
    top: 50%;
    width: 1px
  }

  #search-dropdown .header-search.active .btn-close {
    opacity: 1;
    pointer-events: auto
  }

  #search-dropdown.active {
    opacity: 1;
    transform: none;
    visibility: visible
  }

  .exchange>.full-size,
  .lang>.full-size,
  .log-inned>.full-size,
  .tel>.full-size {
    z-index: 1
  }

  .voice-search-btn.only-desktop,
  header .container .row>div>.vertical-separator {
    display: none
  }

  .voice-search-btn {
    right: 62px
  }
}

@media (max-width: 991px) {
  header .container {
    padding: 10px 15px 10px 170px
  }

  #logo {
    width: 140px
  }
}

@media (max-width: 767px) {
  header {
    height: 56px
  }

  header .container {
    padding: 10px 15px 10px 130px
  }

  header .container .row>div:first-child {
    margin-right: 30px;
    padding-right: 0
  }

  header .container .row>div:nth-child(2) {
    justify-content: flex-end;
    padding-left: 0
  }

  header.login-header .container .row>div>.header-search {
    margin-right: -5px
  }

  header.login-header .container .row>div:first-child {
    margin-right: 30px
  }

  header.login-header #menu-btn,
  header.login-header .exchange,
  header.login-header .lang,
  header.login-header .log-in {
    margin-left: 15px
  }

  #logo {
    width: 100px
  }

  .log-in:not(.log-inned),
  .tel.dropdown {
    height: 20px;
    min-width: 20px;
    padding: 0
  }

  .log-in:not(.log-inned):before,
  .tel.dropdown:before {
    left: 1px;
    top: 1px
  }

  .tel {
    margin-left: -10px
  }

  .tel.dropdown .full-size {
    left: 0;
    right: 0;
    width: auto
  }

  .tel.dropdown.active .dropdown-toggle {
    transform: scaleY(1) translate3d(50%, 0, 0)
  }

  .tel .dropdown-btn,
  .tel.dropdown:after {
    display: none
  }

  .tel .dropdown-toggle {
    right: 50%;
    transform: scaleY(0) translate3d(50%, 0, 0)
  }

  .log-in:not(.log-inned) {
    color: transparent;
    font-size: 0
  }

  #search-dropdown {
    padding: 15px
  }

  #header-layer-close,
  nav {
    top: 56px
  }

  nav {
    max-height: calc(100vh - 56px);
    padding: 20px;
    width: 83.335%
  }

  nav .vertical-separator {
    margin: 0 30px
  }

  nav .exchange,
  nav .lang {
    margin: 0
  }

  nav .exchange .dropdown-toggle,
  nav .lang .dropdown-toggle {
    display: block;
    left: -13px;
    padding: 12px;
    right: -13px;
    width: auto
  }

  .lang {
    display: none
  }
} */

@media (max-width: 575px) {
  .log-in:before {
    height: 20px;
    width: 20px
  }

  .log-in.log-inned .img {
    height: 32px;
    width: 32px
  }

  .log-inned-icon {
    height: 20px;
    margin-bottom: -4px;
    margin-right: 30px;
    width: 20px
  }

  .log-inned-icon span {
    font-size: 8px;
    height: 12px;
    line-height: 12px;
    right: -4px;
    top: -4px;
    width: 12px
  }

  .lang {
    display: none
  }
}

@media (max-width: 325px) {
  header .container .row>div>.header-search {
    margin-right: -10px
  }

  .lang {
    display: none
  }
}

.banner-tabs {
  background-color: #929292;
  border-radius: 10px;
  overflow: hidden;
  position: relative
}

.banner-tabs .tabs-nav {
  left: 20px;
  position: absolute;
  top: 20px;
  z-index: 2
}

.banner-tabs .tab-toggle {
  border-bottom: none
}

.banner-tabs .tab-caption {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 24px;
  margin-bottom: 0;
  margin-right: 5px;
  padding: 2px 20px;
  text-transform: uppercase
}

.banner-tabs .tab-caption:before {
  display: none
}

.banner-tabs .tab-caption img {
  display: inline-block;
  margin-right: 5px;
  max-width: 14px;
  position: relative;
  vertical-align: middle
}

.banner-tabs .tab-caption.active {
  display: none
}

.banner-tabs .banner-carousel .swiper-button-next,
.banner-tabs .banner-carousel .swiper-button-prev,
.banner-tabs .banner-carousel .swiper-pagination {
  top: auto
}

.banner-tabs .banner-carousel .swiper-button-next,
.banner-tabs .banner-carousel .swiper-button-prev {
  bottom: 20px
}

.banner-tabs .banner-carousel .swiper-pagination {
  bottom: 31px
}

.banner-tabs .banner-carousel .swiper-slide {
  overflow: hidden
}

.banner-tabs .banner-carousel .swiper-slide img {
  display: block
}

.banner-tabs .banner-carousel .swiper-slide .full-size {
  align-items: flex-end;
  background: linear-gradient(1turn, rgba(0, 0, 0, .8), transparent 19%);
  display: flex;
  letter-spacing: .2px;
  padding: 15px 20px
}

.banner-tabs .banner-carousel .swiper-slide .full-size span {
  color: #fff;
  font-weight: 500;
  line-height: 24px
}

.banner-tabs .video {
  padding-top: 54.5%
}

.banner-tab-map {
  padding-top: 66.142%;
  position: relative
}

@media (min-width: 1200px) {
  .banner-tabs .tab-caption:hover {
    background: #7ebd3e
  }
}

@media (max-width: 1199px) {
  .banner-tabs .tab-caption {
    color: transparent;
    font-size: 0
  }

  .banner-tabs .tab-caption img {
    margin-right: 0
  }
}

@media (max-width: 767px) {

  .banner-img,
  .banner-tabs {
    border-radius: 0;
    margin: 0 -15px
  }

  .banner-tabs .tabs-nav {
    left: 10px;
    top: 10px
  }

  .banner-tabs .banner-carousel .swiper-button-next,
  .banner-tabs .banner-carousel .swiper-button-prev {
    bottom: auto;
    top: 10px
  }

  .banner-tabs .banner-carousel .swiper-pagination {
    bottom: 15px;
    right: 30px
  }

  .banner-tabs .banner-carousel .swiper-slide .full-size {
    padding: 15px 30px 5px
  }

  .banner-tabs .banner-carousel .swiper-slide .full-size span {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    font-size: 12px;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis
  }
}

.m-0 {
  margin: 0 !important
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-0 {
  margin-bottom: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.ms-0 {
  margin-left: 0 !important
}

.me-0 {
  margin-right: 0 !important
}

.p-0 {
  padding: 0 !important
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.py-0 {
  padding-bottom: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.ps-0 {
  padding-left: 0 !important
}

.pe-0 {
  padding-right: 0 !important
}

.m-5 {
  margin: 5px !important
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important
}

.my-5 {
  margin-top: 5px !important
}

.mb-5,
.my-5 {
  margin-bottom: 5px !important
}

.mt-5 {
  margin-top: 5px !important
}

.ms-5 {
  margin-left: 5px !important
}

.me-5 {
  margin-right: 5px !important
}

.p-5 {
  padding: 5px !important
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important
}

.py-5 {
  padding-top: 5px !important
}

.pb-5,
.py-5 {
  padding-bottom: 5px !important
}

.pt-5 {
  padding-top: 5px !important
}

.ps-5 {
  padding-left: 5px !important
}

.pe-5 {
  padding-right: 5px !important
}

.m-6 {
  margin: 6px !important
}

.mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important
}

.my-6 {
  margin-top: 6px !important
}

.mb-6,
.my-6 {
  margin-bottom: 6px !important
}

.mt-6 {
  margin-top: 6px !important
}

.ms-6 {
  margin-left: 6px !important
}

.me-6 {
  margin-right: 6px !important
}

.p-6 {
  padding: 6px !important
}

.px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important
}

.py-6 {
  padding-top: 6px !important
}

.pb-6,
.py-6 {
  padding-bottom: 6px !important
}

.pt-6 {
  padding-top: 6px !important
}

.ps-6 {
  padding-left: 6px !important
}

.pe-6 {
  padding-right: 6px !important
}

.m-7 {
  margin: 7px !important
}

.mx-7 {
  margin-left: 7px !important;
  margin-right: 7px !important
}

.my-7 {
  margin-top: 7px !important
}

.mb-7,
.my-7 {
  margin-bottom: 7px !important
}

.mt-7 {
  margin-top: 7px !important
}

.ms-7 {
  margin-left: 7px !important
}

.me-7 {
  margin-right: 7px !important
}

.p-7 {
  padding: 7px !important
}

.px-7 {
  padding-left: 7px !important;
  padding-right: 7px !important
}

.py-7 {
  padding-top: 7px !important
}

.pb-7,
.py-7 {
  padding-bottom: 7px !important
}

.pt-7 {
  padding-top: 7px !important
}

.ps-7 {
  padding-left: 7px !important
}

.pe-7 {
  padding-right: 7px !important
}

.m-8 {
  margin: 8px !important
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important
}

.my-8 {
  margin-top: 8px !important
}

.mb-8,
.my-8 {
  margin-bottom: 8px !important
}

.mt-8 {
  margin-top: 8px !important
}

.ms-8 {
  margin-left: 8px !important
}

.me-8 {
  margin-right: 8px !important
}

.p-8 {
  padding: 8px !important
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important
}

.py-8 {
  padding-top: 8px !important
}

.pb-8,
.py-8 {
  padding-bottom: 8px !important
}

.pt-8 {
  padding-top: 8px !important
}

.ps-8 {
  padding-left: 8px !important
}

.pe-8 {
  padding-right: 8px !important
}

.m-9 {
  margin: 9px !important
}

.mx-9 {
  margin-left: 9px !important;
  margin-right: 9px !important
}

.my-9 {
  margin-top: 9px !important
}

.mb-9,
.my-9 {
  margin-bottom: 9px !important
}

.mt-9 {
  margin-top: 9px !important
}

.ms-9 {
  margin-left: 9px !important
}

.me-9 {
  margin-right: 9px !important
}

.p-9 {
  padding: 9px !important
}

.px-9 {
  padding-left: 9px !important;
  padding-right: 9px !important
}

.py-9 {
  padding-top: 9px !important
}

.pb-9,
.py-9 {
  padding-bottom: 9px !important
}

.pt-9 {
  padding-top: 9px !important
}

.ps-9 {
  padding-left: 9px !important
}

.pe-9 {
  padding-right: 9px !important
}

.m-10 {
  margin: 10px !important
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important
}

.my-10 {
  margin-top: 10px !important
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important
}

.mt-10 {
  margin-top: 10px !important
}

.ms-10 {
  margin-left: 10px !important
}

.me-10 {
  margin-right: 10px !important
}

.p-10 {
  padding: 10px !important
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important
}

.py-10 {
  padding-top: 10px !important
}

.pb-10,
.py-10 {
  padding-bottom: 10px !important
}

.pt-10 {
  padding-top: 10px !important
}

.ps-10 {
  padding-left: 10px !important
}

.pe-10 {
  padding-right: 10px !important
}

.m-11 {
  margin: 11px !important
}

.mx-11 {
  margin-left: 11px !important;
  margin-right: 11px !important
}

.my-11 {
  margin-top: 11px !important
}

.mb-11,
.my-11 {
  margin-bottom: 11px !important
}

.mt-11 {
  margin-top: 11px !important
}

.ms-11 {
  margin-left: 11px !important
}

.me-11 {
  margin-right: 11px !important
}

.p-11 {
  padding: 11px !important
}

.px-11 {
  padding-left: 11px !important;
  padding-right: 11px !important
}

.py-11 {
  padding-top: 11px !important
}

.pb-11,
.py-11 {
  padding-bottom: 11px !important
}

.pt-11 {
  padding-top: 11px !important
}

.ps-11 {
  padding-left: 11px !important
}

.pe-11 {
  padding-right: 11px !important
}

.m-12 {
  margin: 12px !important
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important
}

.my-12 {
  margin-top: 12px !important
}

.mb-12,
.my-12 {
  margin-bottom: 12px !important
}

.mt-12 {
  margin-top: 12px !important
}

.ms-12 {
  margin-left: 12px !important
}

.me-12 {
  margin-right: 12px !important
}

.p-12 {
  padding: 12px !important
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important
}

.py-12 {
  padding-top: 12px !important
}

.pb-12,
.py-12 {
  padding-bottom: 12px !important
}

.pt-12 {
  padding-top: 12px !important
}

.ps-12 {
  padding-left: 12px !important
}

.pe-12 {
  padding-right: 12px !important
}

.m-13 {
  margin: 13px !important
}

.mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important
}

.my-13 {
  margin-top: 13px !important
}

.mb-13,
.my-13 {
  margin-bottom: 13px !important
}

.mt-13 {
  margin-top: 13px !important
}

.ms-13 {
  margin-left: 13px !important
}

.me-13 {
  margin-right: 13px !important
}

.p-13 {
  padding: 13px !important
}

.px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important
}

.py-13 {
  padding-top: 13px !important
}

.pb-13,
.py-13 {
  padding-bottom: 13px !important
}

.pt-13 {
  padding-top: 13px !important
}

.ps-13 {
  padding-left: 13px !important
}

.pe-13 {
  padding-right: 13px !important
}

.m-14 {
  margin: 14px !important
}

.mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important
}

.my-14 {
  margin-top: 14px !important
}

.mb-14,
.my-14 {
  margin-bottom: 14px !important
}

.mt-14 {
  margin-top: 14px !important
}

.ms-14 {
  margin-left: 14px !important
}

.me-14 {
  margin-right: 14px !important
}

.p-14 {
  padding: 14px !important
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important
}

.py-14 {
  padding-top: 14px !important
}

.pb-14,
.py-14 {
  padding-bottom: 14px !important
}

.pt-14 {
  padding-top: 14px !important
}

.ps-14 {
  padding-left: 14px !important
}

.pe-14 {
  padding-right: 14px !important
}

.m-15 {
  margin: 15px !important
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important
}

.my-15 {
  margin-top: 15px !important
}

.mb-15,
.my-15 {
  margin-bottom: 15px !important
}

.mt-15 {
  margin-top: 15px !important
}

.ms-15 {
  margin-left: 15px !important
}

.me-15 {
  margin-right: 15px !important
}

.p-15 {
  padding: 15px !important
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important
}

.py-15 {
  padding-top: 15px !important
}

.pb-15,
.py-15 {
  padding-bottom: 15px !important
}

.pt-15 {
  padding-top: 15px !important
}

.ps-15 {
  padding-left: 15px !important
}

.pe-15 {
  padding-right: 15px !important
}

.m-16 {
  margin: 16px !important
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important
}

.my-16 {
  margin-top: 16px !important
}

.mb-16,
.my-16 {
  margin-bottom: 16px !important
}

.mt-16 {
  margin-top: 16px !important
}

.ms-16 {
  margin-left: 16px !important
}

.me-16 {
  margin-right: 16px !important
}

.p-16 {
  padding: 16px !important
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important
}

.py-16 {
  padding-top: 16px !important
}

.pb-16,
.py-16 {
  padding-bottom: 16px !important
}

.pt-16 {
  padding-top: 16px !important
}

.ps-16 {
  padding-left: 16px !important
}

.pe-16 {
  padding-right: 16px !important
}

.m-17 {
  margin: 17px !important
}

.mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important
}

.my-17 {
  margin-top: 17px !important
}

.mb-17,
.my-17 {
  margin-bottom: 17px !important
}

.mt-17 {
  margin-top: 17px !important
}

.ms-17 {
  margin-left: 17px !important
}

.me-17 {
  margin-right: 17px !important
}

.p-17 {
  padding: 17px !important
}

.px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important
}

.py-17 {
  padding-top: 17px !important
}

.pb-17,
.py-17 {
  padding-bottom: 17px !important
}

.pt-17 {
  padding-top: 17px !important
}

.ps-17 {
  padding-left: 17px !important
}

.pe-17 {
  padding-right: 17px !important
}

.m-18 {
  margin: 18px !important
}

.mx-18 {
  margin-left: 18px !important;
  margin-right: 18px !important
}

.my-18 {
  margin-top: 18px !important
}

.mb-18,
.my-18 {
  margin-bottom: 18px !important
}

.mt-18 {
  margin-top: 18px !important
}

.ms-18 {
  margin-left: 18px !important
}

.me-18 {
  margin-right: 18px !important
}

.p-18 {
  padding: 18px !important
}

.px-18 {
  padding-left: 18px !important;
  padding-right: 18px !important
}

.py-18 {
  padding-top: 18px !important
}

.pb-18,
.py-18 {
  padding-bottom: 18px !important
}

.pt-18 {
  padding-top: 18px !important
}

.ps-18 {
  padding-left: 18px !important
}

.pe-18 {
  padding-right: 18px !important
}

.m-19 {
  margin: 19px !important
}

.mx-19 {
  margin-left: 19px !important;
  margin-right: 19px !important
}

.my-19 {
  margin-top: 19px !important
}

.mb-19,
.my-19 {
  margin-bottom: 19px !important
}

.mt-19 {
  margin-top: 19px !important
}

.ms-19 {
  margin-left: 19px !important
}

.me-19 {
  margin-right: 19px !important
}

.p-19 {
  padding: 19px !important
}

.px-19 {
  padding-left: 19px !important;
  padding-right: 19px !important
}

.py-19 {
  padding-top: 19px !important
}

.pb-19,
.py-19 {
  padding-bottom: 19px !important
}

.pt-19 {
  padding-top: 19px !important
}

.ps-19 {
  padding-left: 19px !important
}

.pe-19 {
  padding-right: 19px !important
}

.m-20 {
  margin: 20px !important
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important
}

.my-20 {
  margin-top: 20px !important
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important
}

.mt-20 {
  margin-top: 20px !important
}

.ms-20 {
  margin-left: 20px !important
}

.me-20 {
  margin-right: 20px !important
}

.p-20 {
  padding: 20px !important
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.py-20 {
  padding-top: 20px !important
}

.pb-20,
.py-20 {
  padding-bottom: 20px !important
}

.pt-20 {
  padding-top: 20px !important
}

.ps-20 {
  padding-left: 20px !important
}

.pe-20 {
  padding-right: 20px !important
}

.m-25 {
  margin: 25px !important
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important
}

.my-25 {
  margin-top: 25px !important
}

.mb-25,
.my-25 {
  margin-bottom: 25px !important
}

.mt-25 {
  margin-top: 25px !important
}

.ms-25 {
  margin-left: 25px !important
}

.me-25 {
  margin-right: 25px !important
}

.p-25 {
  padding: 25px !important
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important
}

.py-25 {
  padding-top: 25px !important
}

.pb-25,
.py-25 {
  padding-bottom: 25px !important
}

.pt-25 {
  padding-top: 25px !important
}

.ps-25 {
  padding-left: 25px !important
}

.pe-25 {
  padding-right: 25px !important
}

.m-30 {
  margin: 30px !important
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important
}

.my-30 {
  margin-top: 30px !important
}

.mb-30,
.my-30 {
  margin-bottom: 30px !important
}

.mt-30 {
  margin-top: 30px !important
}

.ms-30 {
  margin-left: 30px !important
}

.me-30 {
  margin-right: 30px !important
}

.p-30 {
  padding: 30px !important
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important
}

.py-30 {
  padding-top: 30px !important
}

.pb-30,
.py-30 {
  padding-bottom: 30px !important
}

.pt-30 {
  padding-top: 30px !important
}

.ps-30 {
  padding-left: 30px !important
}

.pe-30 {
  padding-right: 30px !important
}

.m-35 {
  margin: 35px !important
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important
}

.my-35 {
  margin-top: 35px !important
}

.mb-35,
.my-35 {
  margin-bottom: 35px !important
}

.mt-35 {
  margin-top: 35px !important
}

.ms-35 {
  margin-left: 35px !important
}

.me-35 {
  margin-right: 35px !important
}

.p-35 {
  padding: 35px !important
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important
}

.py-35 {
  padding-top: 35px !important
}

.pb-35,
.py-35 {
  padding-bottom: 35px !important
}

.pt-35 {
  padding-top: 35px !important
}

.ps-35 {
  padding-left: 35px !important
}

.pe-35 {
  padding-right: 35px !important
}

.m-40 {
  margin: 40px !important
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important
}

.my-40 {
  margin-top: 40px !important
}

.mb-40,
.my-40 {
  margin-bottom: 40px !important
}

.mt-40 {
  margin-top: 40px !important
}

.ms-40 {
  margin-left: 40px !important
}

.me-40 {
  margin-right: 40px !important
}

.p-40 {
  padding: 40px !important
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important
}

.py-40 {
  padding-top: 40px !important
}

.pb-40,
.py-40 {
  padding-bottom: 40px !important
}

.pt-40 {
  padding-top: 40px !important
}

.ps-40 {
  padding-left: 40px !important
}

.pe-40 {
  padding-right: 40px !important
}

.m-45 {
  margin: 45px !important
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important
}

.my-45 {
  margin-top: 45px !important
}

.mb-45,
.my-45 {
  margin-bottom: 45px !important
}

.mt-45 {
  margin-top: 45px !important
}

.ms-45 {
  margin-left: 45px !important
}

.me-45 {
  margin-right: 45px !important
}

.p-45 {
  padding: 45px !important
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important
}

.py-45 {
  padding-top: 45px !important
}

.pb-45,
.py-45 {
  padding-bottom: 45px !important
}

.pt-45 {
  padding-top: 45px !important
}

.ps-45 {
  padding-left: 45px !important
}

.pe-45 {
  padding-right: 45px !important
}

.m-50 {
  margin: 50px !important
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important
}

.my-50 {
  margin-top: 50px !important
}

.mb-50,
.my-50 {
  margin-bottom: 50px !important
}

.mt-50 {
  margin-top: 50px !important
}

.ms-50 {
  margin-left: 50px !important
}

.me-50 {
  margin-right: 50px !important
}

.p-50 {
  padding: 50px !important
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important
}

.py-50 {
  padding-top: 50px !important
}

.pb-50,
.py-50 {
  padding-bottom: 50px !important
}

.pt-50 {
  padding-top: 50px !important
}

.ps-50 {
  padding-left: 50px !important
}

.pe-50 {
  padding-right: 50px !important
}

.m-55 {
  margin: 55px !important
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important
}

.my-55 {
  margin-top: 55px !important
}

.mb-55,
.my-55 {
  margin-bottom: 55px !important
}

.mt-55 {
  margin-top: 55px !important
}

.ms-55 {
  margin-left: 55px !important
}

.me-55 {
  margin-right: 55px !important
}

.p-55 {
  padding: 55px !important
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important
}

.py-55 {
  padding-top: 55px !important
}

.pb-55,
.py-55 {
  padding-bottom: 55px !important
}

.pt-55 {
  padding-top: 55px !important
}

.ps-55 {
  padding-left: 55px !important
}

.pe-55 {
  padding-right: 55px !important
}

.m-60 {
  margin: 60px !important
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important
}

.my-60 {
  margin-top: 60px !important
}

.mb-60,
.my-60 {
  margin-bottom: 60px !important
}

.mt-60 {
  margin-top: 60px !important
}

.ms-60 {
  margin-left: 60px !important
}

.me-60 {
  margin-right: 60px !important
}

.p-60 {
  padding: 60px !important
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important
}

.py-60 {
  padding-top: 60px !important
}

.pb-60,
.py-60 {
  padding-bottom: 60px !important
}

.pt-60 {
  padding-top: 60px !important
}

.ps-60 {
  padding-left: 60px !important
}

.pe-60 {
  padding-right: 60px !important
}

.m-65 {
  margin: 65px !important
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important
}

.my-65 {
  margin-top: 65px !important
}

.mb-65,
.my-65 {
  margin-bottom: 65px !important
}

.mt-65 {
  margin-top: 65px !important
}

.ms-65 {
  margin-left: 65px !important
}

.me-65 {
  margin-right: 65px !important
}

.p-65 {
  padding: 65px !important
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important
}

.py-65 {
  padding-top: 65px !important
}

.pb-65,
.py-65 {
  padding-bottom: 65px !important
}

.pt-65 {
  padding-top: 65px !important
}

.ps-65 {
  padding-left: 65px !important
}

.pe-65 {
  padding-right: 65px !important
}

.m-70 {
  margin: 70px !important
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important
}

.my-70 {
  margin-top: 70px !important
}

.mb-70,
.my-70 {
  margin-bottom: 70px !important
}

.mt-70 {
  margin-top: 70px !important
}

.ms-70 {
  margin-left: 70px !important
}

.me-70 {
  margin-right: 70px !important
}

.p-70 {
  padding: 70px !important
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important
}

.py-70 {
  padding-top: 70px !important
}

.pb-70,
.py-70 {
  padding-bottom: 70px !important
}

.pt-70 {
  padding-top: 70px !important
}

.ps-70 {
  padding-left: 70px !important
}

.pe-70 {
  padding-right: 70px !important
}

.m-75 {
  margin: 75px !important
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important
}

.my-75 {
  margin-top: 75px !important
}

.mb-75,
.my-75 {
  margin-bottom: 75px !important
}

.mt-75 {
  margin-top: 75px !important
}

.ms-75 {
  margin-left: 75px !important
}

.me-75 {
  margin-right: 75px !important
}

.p-75 {
  padding: 75px !important
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important
}

.py-75 {
  padding-top: 75px !important
}

.pb-75,
.py-75 {
  padding-bottom: 75px !important
}

.pt-75 {
  padding-top: 75px !important
}

.ps-75 {
  padding-left: 75px !important
}

.pe-75 {
  padding-right: 75px !important
}

.m-80 {
  margin: 80px !important
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important
}

.my-80 {
  margin-top: 80px !important
}

.mb-80,
.my-80 {
  margin-bottom: 80px !important
}

.mt-80 {
  margin-top: 80px !important
}

.ms-80 {
  margin-left: 80px !important
}

.me-80 {
  margin-right: 80px !important
}

.p-80 {
  padding: 80px !important
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important
}

.py-80 {
  padding-top: 80px !important
}

.pb-80,
.py-80 {
  padding-bottom: 80px !important
}

.pt-80 {
  padding-top: 80px !important
}

.ps-80 {
  padding-left: 80px !important
}

.pe-80 {
  padding-right: 80px !important
}

.m-85 {
  margin: 85px !important
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important
}

.my-85 {
  margin-top: 85px !important
}

.mb-85,
.my-85 {
  margin-bottom: 85px !important
}

.mt-85 {
  margin-top: 85px !important
}

.ms-85 {
  margin-left: 85px !important
}

.me-85 {
  margin-right: 85px !important
}

.p-85 {
  padding: 85px !important
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important
}

.py-85 {
  padding-top: 85px !important
}

.pb-85,
.py-85 {
  padding-bottom: 85px !important
}

.pt-85 {
  padding-top: 85px !important
}

.ps-85 {
  padding-left: 85px !important
}

.pe-85 {
  padding-right: 85px !important
}

.m-90 {
  margin: 90px !important
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important
}

.my-90 {
  margin-top: 90px !important
}

.mb-90,
.my-90 {
  margin-bottom: 90px !important
}

.mt-90 {
  margin-top: 90px !important
}

.ms-90 {
  margin-left: 90px !important
}

.me-90 {
  margin-right: 90px !important
}

.p-90 {
  padding: 90px !important
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important
}

.py-90 {
  padding-top: 90px !important
}

.pb-90,
.py-90 {
  padding-bottom: 90px !important
}

.pt-90 {
  padding-top: 90px !important
}

.ps-90 {
  padding-left: 90px !important
}

.pe-90 {
  padding-right: 90px !important
}

.m-95 {
  margin: 95px !important
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important
}

.my-95 {
  margin-top: 95px !important
}

.mb-95,
.my-95 {
  margin-bottom: 95px !important
}

.mt-95 {
  margin-top: 95px !important
}

.ms-95 {
  margin-left: 95px !important
}

.me-95 {
  margin-right: 95px !important
}

.p-95 {
  padding: 95px !important
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important
}

.py-95 {
  padding-top: 95px !important
}

.pb-95,
.py-95 {
  padding-bottom: 95px !important
}

.pt-95 {
  padding-top: 95px !important
}

.ps-95 {
  padding-left: 95px !important
}

.pe-95 {
  padding-right: 95px !important
}

.m-100 {
  margin: 100px !important
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important
}

.my-100 {
  margin-top: 100px !important
}

.mb-100,
.my-100 {
  margin-bottom: 100px !important
}

.mt-100 {
  margin-top: 100px !important
}

.ms-100 {
  margin-left: 100px !important
}

.me-100 {
  margin-right: 100px !important
}

.p-100 {
  padding: 100px !important
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important
}

.py-100 {
  padding-top: 100px !important
}

.pb-100,
.py-100 {
  padding-bottom: 100px !important
}

.pt-100 {
  padding-top: 100px !important
}

.ps-100 {
  padding-left: 100px !important
}

.pe-100 {
  padding-right: 100px !important
}

.m-xs-0 {
  margin: 0 !important
}

.mx-xs-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-xs-0 {
  margin-bottom: 0 !important
}

.mt-xs-0,
.my-xs-0 {
  margin-top: 0 !important
}

.mb-xs-0 {
  margin-bottom: 0 !important
}

.ms-xs-0 {
  margin-left: 0 !important
}

.me-xs-0 {
  margin-right: 0 !important
}

.p-xs-0 {
  padding: 0 !important
}

.px-xs-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.py-xs-0 {
  padding-bottom: 0 !important
}

.pt-xs-0,
.py-xs-0 {
  padding-top: 0 !important
}

.pb-xs-0 {
  padding-bottom: 0 !important
}

.ps-xs-0 {
  padding-left: 0 !important
}

.pe-xs-0 {
  padding-right: 0 !important
}

.m-xs-5 {
  margin: 5px !important
}

.mx-xs-5 {
  margin-left: 5px !important;
  margin-right: 5px !important
}

.my-xs-5 {
  margin-top: 5px !important
}

.mb-xs-5,
.my-xs-5 {
  margin-bottom: 5px !important
}

.mt-xs-5 {
  margin-top: 5px !important
}

.ms-xs-5 {
  margin-left: 5px !important
}

.me-xs-5 {
  margin-right: 5px !important
}

.p-xs-5 {
  padding: 5px !important
}

.px-xs-5 {
  padding-left: 5px !important;
  padding-right: 5px !important
}

.py-xs-5 {
  padding-top: 5px !important
}

.pb-xs-5,
.py-xs-5 {
  padding-bottom: 5px !important
}

.pt-xs-5 {
  padding-top: 5px !important
}

.ps-xs-5 {
  padding-left: 5px !important
}

.pe-xs-5 {
  padding-right: 5px !important
}

.m-xs-6 {
  margin: 6px !important
}

.mx-xs-6 {
  margin-left: 6px !important;
  margin-right: 6px !important
}

.my-xs-6 {
  margin-top: 6px !important
}

.mb-xs-6,
.my-xs-6 {
  margin-bottom: 6px !important
}

.mt-xs-6 {
  margin-top: 6px !important
}

.ms-xs-6 {
  margin-left: 6px !important
}

.me-xs-6 {
  margin-right: 6px !important
}

.p-xs-6 {
  padding: 6px !important
}

.px-xs-6 {
  padding-left: 6px !important;
  padding-right: 6px !important
}

.py-xs-6 {
  padding-top: 6px !important
}

.pb-xs-6,
.py-xs-6 {
  padding-bottom: 6px !important
}

.pt-xs-6 {
  padding-top: 6px !important
}

.ps-xs-6 {
  padding-left: 6px !important
}

.pe-xs-6 {
  padding-right: 6px !important
}

.m-xs-7 {
  margin: 7px !important
}

.mx-xs-7 {
  margin-left: 7px !important;
  margin-right: 7px !important
}

.my-xs-7 {
  margin-top: 7px !important
}

.mb-xs-7,
.my-xs-7 {
  margin-bottom: 7px !important
}

.mt-xs-7 {
  margin-top: 7px !important
}

.ms-xs-7 {
  margin-left: 7px !important
}

.me-xs-7 {
  margin-right: 7px !important
}

.p-xs-7 {
  padding: 7px !important
}

.px-xs-7 {
  padding-left: 7px !important;
  padding-right: 7px !important
}

.py-xs-7 {
  padding-top: 7px !important
}

.pb-xs-7,
.py-xs-7 {
  padding-bottom: 7px !important
}

.pt-xs-7 {
  padding-top: 7px !important
}

.ps-xs-7 {
  padding-left: 7px !important
}

.pe-xs-7 {
  padding-right: 7px !important
}

.m-xs-8 {
  margin: 8px !important
}

.mx-xs-8 {
  margin-left: 8px !important;
  margin-right: 8px !important
}

.my-xs-8 {
  margin-top: 8px !important
}

.mb-xs-8,
.my-xs-8 {
  margin-bottom: 8px !important
}

.mt-xs-8 {
  margin-top: 8px !important
}

.ms-xs-8 {
  margin-left: 8px !important
}

.me-xs-8 {
  margin-right: 8px !important
}

.p-xs-8 {
  padding: 8px !important
}

.px-xs-8 {
  padding-left: 8px !important;
  padding-right: 8px !important
}

.py-xs-8 {
  padding-top: 8px !important
}

.pb-xs-8,
.py-xs-8 {
  padding-bottom: 8px !important
}

.pt-xs-8 {
  padding-top: 8px !important
}

.ps-xs-8 {
  padding-left: 8px !important
}

.pe-xs-8 {
  padding-right: 8px !important
}

.m-xs-9 {
  margin: 9px !important
}

.mx-xs-9 {
  margin-left: 9px !important;
  margin-right: 9px !important
}

.my-xs-9 {
  margin-top: 9px !important
}

.mb-xs-9,
.my-xs-9 {
  margin-bottom: 9px !important
}

.mt-xs-9 {
  margin-top: 9px !important
}

.ms-xs-9 {
  margin-left: 9px !important
}

.me-xs-9 {
  margin-right: 9px !important
}

.p-xs-9 {
  padding: 9px !important
}

.px-xs-9 {
  padding-left: 9px !important;
  padding-right: 9px !important
}

.py-xs-9 {
  padding-top: 9px !important
}

.pb-xs-9,
.py-xs-9 {
  padding-bottom: 9px !important
}

.pt-xs-9 {
  padding-top: 9px !important
}

.ps-xs-9 {
  padding-left: 9px !important
}

.pe-xs-9 {
  padding-right: 9px !important
}

.m-xs-10 {
  margin: 10px !important
}

.mx-xs-10 {
  margin-left: 10px !important;
  margin-right: 10px !important
}

.my-xs-10 {
  margin-top: 10px !important
}

.mb-xs-10,
.my-xs-10 {
  margin-bottom: 10px !important
}

.mt-xs-10 {
  margin-top: 10px !important
}

.ms-xs-10 {
  margin-left: 10px !important
}

.me-xs-10 {
  margin-right: 10px !important
}

.p-xs-10 {
  padding: 10px !important
}

.px-xs-10 {
  padding-left: 10px !important;
  padding-right: 10px !important
}

.py-xs-10 {
  padding-top: 10px !important
}

.pb-xs-10,
.py-xs-10 {
  padding-bottom: 10px !important
}

.pt-xs-10 {
  padding-top: 10px !important
}

.ps-xs-10 {
  padding-left: 10px !important
}

.pe-xs-10 {
  padding-right: 10px !important
}

.m-xs-11 {
  margin: 11px !important
}

.mx-xs-11 {
  margin-left: 11px !important;
  margin-right: 11px !important
}

.my-xs-11 {
  margin-top: 11px !important
}

.mb-xs-11,
.my-xs-11 {
  margin-bottom: 11px !important
}

.mt-xs-11 {
  margin-top: 11px !important
}

.ms-xs-11 {
  margin-left: 11px !important
}

.me-xs-11 {
  margin-right: 11px !important
}

.p-xs-11 {
  padding: 11px !important
}

.px-xs-11 {
  padding-left: 11px !important;
  padding-right: 11px !important
}

.py-xs-11 {
  padding-top: 11px !important
}

.pb-xs-11,
.py-xs-11 {
  padding-bottom: 11px !important
}

.pt-xs-11 {
  padding-top: 11px !important
}

.ps-xs-11 {
  padding-left: 11px !important
}

.pe-xs-11 {
  padding-right: 11px !important
}

.m-xs-12 {
  margin: 12px !important
}

.mx-xs-12 {
  margin-left: 12px !important;
  margin-right: 12px !important
}

.my-xs-12 {
  margin-top: 12px !important
}

.mb-xs-12,
.my-xs-12 {
  margin-bottom: 12px !important
}

.mt-xs-12 {
  margin-top: 12px !important
}

.ms-xs-12 {
  margin-left: 12px !important
}

.me-xs-12 {
  margin-right: 12px !important
}

.p-xs-12 {
  padding: 12px !important
}

.px-xs-12 {
  padding-left: 12px !important;
  padding-right: 12px !important
}

.py-xs-12 {
  padding-top: 12px !important
}

.pb-xs-12,
.py-xs-12 {
  padding-bottom: 12px !important
}

.pt-xs-12 {
  padding-top: 12px !important
}

.ps-xs-12 {
  padding-left: 12px !important
}

.pe-xs-12 {
  padding-right: 12px !important
}

.m-xs-13 {
  margin: 13px !important
}

.mx-xs-13 {
  margin-left: 13px !important;
  margin-right: 13px !important
}

.my-xs-13 {
  margin-top: 13px !important
}

.mb-xs-13,
.my-xs-13 {
  margin-bottom: 13px !important
}

.mt-xs-13 {
  margin-top: 13px !important
}

.ms-xs-13 {
  margin-left: 13px !important
}

.me-xs-13 {
  margin-right: 13px !important
}

.p-xs-13 {
  padding: 13px !important
}

.px-xs-13 {
  padding-left: 13px !important;
  padding-right: 13px !important
}

.py-xs-13 {
  padding-top: 13px !important
}

.pb-xs-13,
.py-xs-13 {
  padding-bottom: 13px !important
}

.pt-xs-13 {
  padding-top: 13px !important
}

.ps-xs-13 {
  padding-left: 13px !important
}

.pe-xs-13 {
  padding-right: 13px !important
}

.m-xs-14 {
  margin: 14px !important
}

.mx-xs-14 {
  margin-left: 14px !important;
  margin-right: 14px !important
}

.my-xs-14 {
  margin-top: 14px !important
}

.mb-xs-14,
.my-xs-14 {
  margin-bottom: 14px !important
}

.mt-xs-14 {
  margin-top: 14px !important
}

.ms-xs-14 {
  margin-left: 14px !important
}

.me-xs-14 {
  margin-right: 14px !important
}

.p-xs-14 {
  padding: 14px !important
}

.px-xs-14 {
  padding-left: 14px !important;
  padding-right: 14px !important
}

.py-xs-14 {
  padding-top: 14px !important
}

.pb-xs-14,
.py-xs-14 {
  padding-bottom: 14px !important
}

.pt-xs-14 {
  padding-top: 14px !important
}

.ps-xs-14 {
  padding-left: 14px !important
}

.pe-xs-14 {
  padding-right: 14px !important
}

.m-xs-15 {
  margin: 15px !important
}

.mx-xs-15 {
  margin-left: 15px !important;
  margin-right: 15px !important
}

.my-xs-15 {
  margin-top: 15px !important
}

.mb-xs-15,
.my-xs-15 {
  margin-bottom: 15px !important
}

.mt-xs-15 {
  margin-top: 15px !important
}

.ms-xs-15 {
  margin-left: 15px !important
}

.me-xs-15 {
  margin-right: 15px !important
}

.p-xs-15 {
  padding: 15px !important
}

.px-xs-15 {
  padding-left: 15px !important;
  padding-right: 15px !important
}

.py-xs-15 {
  padding-top: 15px !important
}

.pb-xs-15,
.py-xs-15 {
  padding-bottom: 15px !important
}

.pt-xs-15 {
  padding-top: 15px !important
}

.ps-xs-15 {
  padding-left: 15px !important
}

.pe-xs-15 {
  padding-right: 15px !important
}

.m-xs-16 {
  margin: 16px !important
}

.mx-xs-16 {
  margin-left: 16px !important;
  margin-right: 16px !important
}

.my-xs-16 {
  margin-top: 16px !important
}

.mb-xs-16,
.my-xs-16 {
  margin-bottom: 16px !important
}

.mt-xs-16 {
  margin-top: 16px !important
}

.ms-xs-16 {
  margin-left: 16px !important
}

.me-xs-16 {
  margin-right: 16px !important
}

.p-xs-16 {
  padding: 16px !important
}

.px-xs-16 {
  padding-left: 16px !important;
  padding-right: 16px !important
}

.py-xs-16 {
  padding-top: 16px !important
}

.pb-xs-16,
.py-xs-16 {
  padding-bottom: 16px !important
}

.pt-xs-16 {
  padding-top: 16px !important
}

.ps-xs-16 {
  padding-left: 16px !important
}

.pe-xs-16 {
  padding-right: 16px !important
}

.m-xs-17 {
  margin: 17px !important
}

.mx-xs-17 {
  margin-left: 17px !important;
  margin-right: 17px !important
}

.my-xs-17 {
  margin-top: 17px !important
}

.mb-xs-17,
.my-xs-17 {
  margin-bottom: 17px !important
}

.mt-xs-17 {
  margin-top: 17px !important
}

.ms-xs-17 {
  margin-left: 17px !important
}

.me-xs-17 {
  margin-right: 17px !important
}

.p-xs-17 {
  padding: 17px !important
}

.px-xs-17 {
  padding-left: 17px !important;
  padding-right: 17px !important
}

.py-xs-17 {
  padding-top: 17px !important
}

.pb-xs-17,
.py-xs-17 {
  padding-bottom: 17px !important
}

.pt-xs-17 {
  padding-top: 17px !important
}

.ps-xs-17 {
  padding-left: 17px !important
}

.pe-xs-17 {
  padding-right: 17px !important
}

.m-xs-18 {
  margin: 18px !important
}

.mx-xs-18 {
  margin-left: 18px !important;
  margin-right: 18px !important
}

.my-xs-18 {
  margin-top: 18px !important
}

.mb-xs-18,
.my-xs-18 {
  margin-bottom: 18px !important
}

.mt-xs-18 {
  margin-top: 18px !important
}

.ms-xs-18 {
  margin-left: 18px !important
}

.me-xs-18 {
  margin-right: 18px !important
}

.p-xs-18 {
  padding: 18px !important
}

.px-xs-18 {
  padding-left: 18px !important;
  padding-right: 18px !important
}

.py-xs-18 {
  padding-top: 18px !important
}

.pb-xs-18,
.py-xs-18 {
  padding-bottom: 18px !important
}

.pt-xs-18 {
  padding-top: 18px !important
}

.ps-xs-18 {
  padding-left: 18px !important
}

.pe-xs-18 {
  padding-right: 18px !important
}

.m-xs-19 {
  margin: 19px !important
}

.mx-xs-19 {
  margin-left: 19px !important;
  margin-right: 19px !important
}

.my-xs-19 {
  margin-top: 19px !important
}

.mb-xs-19,
.my-xs-19 {
  margin-bottom: 19px !important
}

.mt-xs-19 {
  margin-top: 19px !important
}

.ms-xs-19 {
  margin-left: 19px !important
}

.me-xs-19 {
  margin-right: 19px !important
}

.p-xs-19 {
  padding: 19px !important
}

.px-xs-19 {
  padding-left: 19px !important;
  padding-right: 19px !important
}

.py-xs-19 {
  padding-top: 19px !important
}

.pb-xs-19,
.py-xs-19 {
  padding-bottom: 19px !important
}

.pt-xs-19 {
  padding-top: 19px !important
}

.ps-xs-19 {
  padding-left: 19px !important
}

.pe-xs-19 {
  padding-right: 19px !important
}

.m-xs-20 {
  margin: 20px !important
}

.mx-xs-20 {
  margin-left: 20px !important;
  margin-right: 20px !important
}

.my-xs-20 {
  margin-top: 20px !important
}

.mb-xs-20,
.my-xs-20 {
  margin-bottom: 20px !important
}

.mt-xs-20 {
  margin-top: 20px !important
}

.ms-xs-20 {
  margin-left: 20px !important
}

.me-xs-20 {
  margin-right: 20px !important
}

.p-xs-20 {
  padding: 20px !important
}

.px-xs-20 {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.py-xs-20 {
  padding-top: 20px !important
}

.pb-xs-20,
.py-xs-20 {
  padding-bottom: 20px !important
}

.pt-xs-20 {
  padding-top: 20px !important
}

.ps-xs-20 {
  padding-left: 20px !important
}

.pe-xs-20 {
  padding-right: 20px !important
}

.m-xs-25 {
  margin: 25px !important
}

.mx-xs-25 {
  margin-left: 25px !important;
  margin-right: 25px !important
}

.my-xs-25 {
  margin-top: 25px !important
}

.mb-xs-25,
.my-xs-25 {
  margin-bottom: 25px !important
}

.mt-xs-25 {
  margin-top: 25px !important
}

.ms-xs-25 {
  margin-left: 25px !important
}

.me-xs-25 {
  margin-right: 25px !important
}

.p-xs-25 {
  padding: 25px !important
}

.px-xs-25 {
  padding-left: 25px !important;
  padding-right: 25px !important
}

.py-xs-25 {
  padding-top: 25px !important
}

.pb-xs-25,
.py-xs-25 {
  padding-bottom: 25px !important
}

.pt-xs-25 {
  padding-top: 25px !important
}

.ps-xs-25 {
  padding-left: 25px !important
}

.pe-xs-25 {
  padding-right: 25px !important
}

.m-xs-30 {
  margin: 30px !important
}

.mx-xs-30 {
  margin-left: 30px !important;
  margin-right: 30px !important
}

.my-xs-30 {
  margin-top: 30px !important
}

.mb-xs-30,
.my-xs-30 {
  margin-bottom: 30px !important
}

.mt-xs-30 {
  margin-top: 30px !important
}

.ms-xs-30 {
  margin-left: 30px !important
}

.me-xs-30 {
  margin-right: 30px !important
}

.p-xs-30 {
  padding: 30px !important
}

.px-xs-30 {
  padding-left: 30px !important;
  padding-right: 30px !important
}

.py-xs-30 {
  padding-top: 30px !important
}

.pb-xs-30,
.py-xs-30 {
  padding-bottom: 30px !important
}

.pt-xs-30 {
  padding-top: 30px !important
}

.ps-xs-30 {
  padding-left: 30px !important
}

.pe-xs-30 {
  padding-right: 30px !important
}

.m-xs-35 {
  margin: 35px !important
}

.mx-xs-35 {
  margin-left: 35px !important;
  margin-right: 35px !important
}

.my-xs-35 {
  margin-top: 35px !important
}

.mb-xs-35,
.my-xs-35 {
  margin-bottom: 35px !important
}

.mt-xs-35 {
  margin-top: 35px !important
}

.ms-xs-35 {
  margin-left: 35px !important
}

.me-xs-35 {
  margin-right: 35px !important
}

.p-xs-35 {
  padding: 35px !important
}

.px-xs-35 {
  padding-left: 35px !important;
  padding-right: 35px !important
}

.py-xs-35 {
  padding-top: 35px !important
}

.pb-xs-35,
.py-xs-35 {
  padding-bottom: 35px !important
}

.pt-xs-35 {
  padding-top: 35px !important
}

.ps-xs-35 {
  padding-left: 35px !important
}

.pe-xs-35 {
  padding-right: 35px !important
}

.m-xs-40 {
  margin: 40px !important
}

.mx-xs-40 {
  margin-left: 40px !important;
  margin-right: 40px !important
}

.my-xs-40 {
  margin-top: 40px !important
}

.mb-xs-40,
.my-xs-40 {
  margin-bottom: 40px !important
}

.mt-xs-40 {
  margin-top: 40px !important
}

.ms-xs-40 {
  margin-left: 40px !important
}

.me-xs-40 {
  margin-right: 40px !important
}

.p-xs-40 {
  padding: 40px !important
}

.px-xs-40 {
  padding-left: 40px !important;
  padding-right: 40px !important
}

.py-xs-40 {
  padding-top: 40px !important
}

.pb-xs-40,
.py-xs-40 {
  padding-bottom: 40px !important
}

.pt-xs-40 {
  padding-top: 40px !important
}

.ps-xs-40 {
  padding-left: 40px !important
}

.pe-xs-40 {
  padding-right: 40px !important
}

.m-xs-45 {
  margin: 45px !important
}

.mx-xs-45 {
  margin-left: 45px !important;
  margin-right: 45px !important
}

.my-xs-45 {
  margin-top: 45px !important
}

.mb-xs-45,
.my-xs-45 {
  margin-bottom: 45px !important
}

.mt-xs-45 {
  margin-top: 45px !important
}

.ms-xs-45 {
  margin-left: 45px !important
}

.me-xs-45 {
  margin-right: 45px !important
}

.p-xs-45 {
  padding: 45px !important
}

.px-xs-45 {
  padding-left: 45px !important;
  padding-right: 45px !important
}

.py-xs-45 {
  padding-top: 45px !important
}

.pb-xs-45,
.py-xs-45 {
  padding-bottom: 45px !important
}

.pt-xs-45 {
  padding-top: 45px !important
}

.ps-xs-45 {
  padding-left: 45px !important
}

.pe-xs-45 {
  padding-right: 45px !important
}

.m-xs-50 {
  margin: 50px !important
}

.mx-xs-50 {
  margin-left: 50px !important;
  margin-right: 50px !important
}

.my-xs-50 {
  margin-top: 50px !important
}

.mb-xs-50,
.my-xs-50 {
  margin-bottom: 50px !important
}

.mt-xs-50 {
  margin-top: 50px !important
}

.ms-xs-50 {
  margin-left: 50px !important
}

.me-xs-50 {
  margin-right: 50px !important
}

.p-xs-50 {
  padding: 50px !important
}

.px-xs-50 {
  padding-left: 50px !important;
  padding-right: 50px !important
}

.py-xs-50 {
  padding-top: 50px !important
}

.pb-xs-50,
.py-xs-50 {
  padding-bottom: 50px !important
}

.pt-xs-50 {
  padding-top: 50px !important
}

.ps-xs-50 {
  padding-left: 50px !important
}

.pe-xs-50 {
  padding-right: 50px !important
}

.m-xs-55 {
  margin: 55px !important
}

.mx-xs-55 {
  margin-left: 55px !important;
  margin-right: 55px !important
}

.my-xs-55 {
  margin-top: 55px !important
}

.mb-xs-55,
.my-xs-55 {
  margin-bottom: 55px !important
}

.mt-xs-55 {
  margin-top: 55px !important
}

.ms-xs-55 {
  margin-left: 55px !important
}

.me-xs-55 {
  margin-right: 55px !important
}

.p-xs-55 {
  padding: 55px !important
}

.px-xs-55 {
  padding-left: 55px !important;
  padding-right: 55px !important
}

.py-xs-55 {
  padding-top: 55px !important
}

.pb-xs-55,
.py-xs-55 {
  padding-bottom: 55px !important
}

.pt-xs-55 {
  padding-top: 55px !important
}

.ps-xs-55 {
  padding-left: 55px !important
}

.pe-xs-55 {
  padding-right: 55px !important
}

.m-xs-60 {
  margin: 60px !important
}

.mx-xs-60 {
  margin-left: 60px !important;
  margin-right: 60px !important
}

.my-xs-60 {
  margin-top: 60px !important
}

.mb-xs-60,
.my-xs-60 {
  margin-bottom: 60px !important
}

.mt-xs-60 {
  margin-top: 60px !important
}

.ms-xs-60 {
  margin-left: 60px !important
}

.me-xs-60 {
  margin-right: 60px !important
}

.p-xs-60 {
  padding: 60px !important
}

.px-xs-60 {
  padding-left: 60px !important;
  padding-right: 60px !important
}

.py-xs-60 {
  padding-top: 60px !important
}

.pb-xs-60,
.py-xs-60 {
  padding-bottom: 60px !important
}

.pt-xs-60 {
  padding-top: 60px !important
}

.ps-xs-60 {
  padding-left: 60px !important
}

.pe-xs-60 {
  padding-right: 60px !important
}

.m-xs-65 {
  margin: 65px !important
}

.mx-xs-65 {
  margin-left: 65px !important;
  margin-right: 65px !important
}

.my-xs-65 {
  margin-top: 65px !important
}

.mb-xs-65,
.my-xs-65 {
  margin-bottom: 65px !important
}

.mt-xs-65 {
  margin-top: 65px !important
}

.ms-xs-65 {
  margin-left: 65px !important
}

.me-xs-65 {
  margin-right: 65px !important
}

.p-xs-65 {
  padding: 65px !important
}

.px-xs-65 {
  padding-left: 65px !important;
  padding-right: 65px !important
}

.py-xs-65 {
  padding-top: 65px !important
}

.pb-xs-65,
.py-xs-65 {
  padding-bottom: 65px !important
}

.pt-xs-65 {
  padding-top: 65px !important
}

.ps-xs-65 {
  padding-left: 65px !important
}

.pe-xs-65 {
  padding-right: 65px !important
}

.m-xs-70 {
  margin: 70px !important
}

.mx-xs-70 {
  margin-left: 70px !important;
  margin-right: 70px !important
}

.my-xs-70 {
  margin-top: 70px !important
}

.mb-xs-70,
.my-xs-70 {
  margin-bottom: 70px !important
}

.mt-xs-70 {
  margin-top: 70px !important
}

.ms-xs-70 {
  margin-left: 70px !important
}

.me-xs-70 {
  margin-right: 70px !important
}

.p-xs-70 {
  padding: 70px !important
}

.px-xs-70 {
  padding-left: 70px !important;
  padding-right: 70px !important
}

.py-xs-70 {
  padding-top: 70px !important
}

.pb-xs-70,
.py-xs-70 {
  padding-bottom: 70px !important
}

.pt-xs-70 {
  padding-top: 70px !important
}

.ps-xs-70 {
  padding-left: 70px !important
}

.pe-xs-70 {
  padding-right: 70px !important
}

.m-xs-75 {
  margin: 75px !important
}

.mx-xs-75 {
  margin-left: 75px !important;
  margin-right: 75px !important
}

.my-xs-75 {
  margin-top: 75px !important
}

.mb-xs-75,
.my-xs-75 {
  margin-bottom: 75px !important
}

.mt-xs-75 {
  margin-top: 75px !important
}

.ms-xs-75 {
  margin-left: 75px !important
}

.me-xs-75 {
  margin-right: 75px !important
}

.p-xs-75 {
  padding: 75px !important
}

.px-xs-75 {
  padding-left: 75px !important;
  padding-right: 75px !important
}

.py-xs-75 {
  padding-top: 75px !important
}

.pb-xs-75,
.py-xs-75 {
  padding-bottom: 75px !important
}

.pt-xs-75 {
  padding-top: 75px !important
}

.ps-xs-75 {
  padding-left: 75px !important
}

.pe-xs-75 {
  padding-right: 75px !important
}

.m-xs-80 {
  margin: 80px !important
}

.mx-xs-80 {
  margin-left: 80px !important;
  margin-right: 80px !important
}

.my-xs-80 {
  margin-top: 80px !important
}

.mb-xs-80,
.my-xs-80 {
  margin-bottom: 80px !important
}

.mt-xs-80 {
  margin-top: 80px !important
}

.ms-xs-80 {
  margin-left: 80px !important
}

.me-xs-80 {
  margin-right: 80px !important
}

.p-xs-80 {
  padding: 80px !important
}

.px-xs-80 {
  padding-left: 80px !important;
  padding-right: 80px !important
}

.py-xs-80 {
  padding-top: 80px !important
}

.pb-xs-80,
.py-xs-80 {
  padding-bottom: 80px !important
}

.pt-xs-80 {
  padding-top: 80px !important
}

.ps-xs-80 {
  padding-left: 80px !important
}

.pe-xs-80 {
  padding-right: 80px !important
}

.m-xs-85 {
  margin: 85px !important
}

.mx-xs-85 {
  margin-left: 85px !important;
  margin-right: 85px !important
}

.my-xs-85 {
  margin-top: 85px !important
}

.mb-xs-85,
.my-xs-85 {
  margin-bottom: 85px !important
}

.mt-xs-85 {
  margin-top: 85px !important
}

.ms-xs-85 {
  margin-left: 85px !important
}

.me-xs-85 {
  margin-right: 85px !important
}

.p-xs-85 {
  padding: 85px !important
}

.px-xs-85 {
  padding-left: 85px !important;
  padding-right: 85px !important
}

.py-xs-85 {
  padding-top: 85px !important
}

.pb-xs-85,
.py-xs-85 {
  padding-bottom: 85px !important
}

.pt-xs-85 {
  padding-top: 85px !important
}

.ps-xs-85 {
  padding-left: 85px !important
}

.pe-xs-85 {
  padding-right: 85px !important
}

.m-xs-90 {
  margin: 90px !important
}

.mx-xs-90 {
  margin-left: 90px !important;
  margin-right: 90px !important
}

.my-xs-90 {
  margin-top: 90px !important
}

.mb-xs-90,
.my-xs-90 {
  margin-bottom: 90px !important
}

.mt-xs-90 {
  margin-top: 90px !important
}

.ms-xs-90 {
  margin-left: 90px !important
}

.me-xs-90 {
  margin-right: 90px !important
}

.p-xs-90 {
  padding: 90px !important
}

.px-xs-90 {
  padding-left: 90px !important;
  padding-right: 90px !important
}

.py-xs-90 {
  padding-top: 90px !important
}

.pb-xs-90,
.py-xs-90 {
  padding-bottom: 90px !important
}

.pt-xs-90 {
  padding-top: 90px !important
}

.ps-xs-90 {
  padding-left: 90px !important
}

.pe-xs-90 {
  padding-right: 90px !important
}

.m-xs-95 {
  margin: 95px !important
}

.mx-xs-95 {
  margin-left: 95px !important;
  margin-right: 95px !important
}

.my-xs-95 {
  margin-top: 95px !important
}

.mb-xs-95,
.my-xs-95 {
  margin-bottom: 95px !important
}

.mt-xs-95 {
  margin-top: 95px !important
}

.ms-xs-95 {
  margin-left: 95px !important
}

.me-xs-95 {
  margin-right: 95px !important
}

.p-xs-95 {
  padding: 95px !important
}

.px-xs-95 {
  padding-left: 95px !important;
  padding-right: 95px !important
}

.py-xs-95 {
  padding-top: 95px !important
}

.pb-xs-95,
.py-xs-95 {
  padding-bottom: 95px !important
}

.pt-xs-95 {
  padding-top: 95px !important
}

.ps-xs-95 {
  padding-left: 95px !important
}

.pe-xs-95 {
  padding-right: 95px !important
}

.m-xs-100 {
  margin: 100px !important
}

.mx-xs-100 {
  margin-left: 100px !important;
  margin-right: 100px !important
}

.my-xs-100 {
  margin-top: 100px !important
}

.mb-xs-100,
.my-xs-100 {
  margin-bottom: 100px !important
}

.mt-xs-100 {
  margin-top: 100px !important
}

.ms-xs-100 {
  margin-left: 100px !important
}

.me-xs-100 {
  margin-right: 100px !important
}

.p-xs-100 {
  padding: 100px !important
}

.px-xs-100 {
  padding-left: 100px !important;
  padding-right: 100px !important
}

.py-xs-100 {
  padding-top: 100px !important
}

.pb-xs-100,
.py-xs-100 {
  padding-bottom: 100px !important
}

.pt-xs-100 {
  padding-top: 100px !important
}

.ps-xs-100 {
  padding-left: 100px !important
}

.pe-xs-100 {
  padding-right: 100px !important
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mb-sm-0 {
    margin-bottom: 0 !important
  }

  .ms-sm-0 {
    margin-left: 0 !important
  }

  .me-sm-0 {
    margin-right: 0 !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pb-sm-0 {
    padding-bottom: 0 !important
  }

  .ps-sm-0 {
    padding-left: 0 !important
  }

  .pe-sm-0 {
    padding-right: 0 !important
  }

  .m-sm-5 {
    margin: 5px !important
  }

  .mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important
  }

  .my-sm-5 {
    margin-top: 5px !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important
  }

  .mt-sm-5 {
    margin-top: 5px !important
  }

  .ms-sm-5 {
    margin-left: 5px !important
  }

  .me-sm-5 {
    margin-right: 5px !important
  }

  .p-sm-5 {
    padding: 5px !important
  }

  .px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important
  }

  .py-sm-5 {
    padding-top: 5px !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important
  }

  .pt-sm-5 {
    padding-top: 5px !important
  }

  .ps-sm-5 {
    padding-left: 5px !important
  }

  .pe-sm-5 {
    padding-right: 5px !important
  }

  .m-sm-6 {
    margin: 6px !important
  }

  .mx-sm-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .my-sm-6 {
    margin-top: 6px !important
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6px !important
  }

  .mt-sm-6 {
    margin-top: 6px !important
  }

  .ms-sm-6 {
    margin-left: 6px !important
  }

  .me-sm-6 {
    margin-right: 6px !important
  }

  .p-sm-6 {
    padding: 6px !important
  }

  .px-sm-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .py-sm-6 {
    padding-top: 6px !important
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6px !important
  }

  .pt-sm-6 {
    padding-top: 6px !important
  }

  .ps-sm-6 {
    padding-left: 6px !important
  }

  .pe-sm-6 {
    padding-right: 6px !important
  }

  .m-sm-7 {
    margin: 7px !important
  }

  .mx-sm-7 {
    margin-left: 7px !important;
    margin-right: 7px !important
  }

  .my-sm-7 {
    margin-top: 7px !important
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7px !important
  }

  .mt-sm-7 {
    margin-top: 7px !important
  }

  .ms-sm-7 {
    margin-left: 7px !important
  }

  .me-sm-7 {
    margin-right: 7px !important
  }

  .p-sm-7 {
    padding: 7px !important
  }

  .px-sm-7 {
    padding-left: 7px !important;
    padding-right: 7px !important
  }

  .py-sm-7 {
    padding-top: 7px !important
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7px !important
  }

  .pt-sm-7 {
    padding-top: 7px !important
  }

  .ps-sm-7 {
    padding-left: 7px !important
  }

  .pe-sm-7 {
    padding-right: 7px !important
  }

  .m-sm-8 {
    margin: 8px !important
  }

  .mx-sm-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .my-sm-8 {
    margin-top: 8px !important
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8px !important
  }

  .mt-sm-8 {
    margin-top: 8px !important
  }

  .ms-sm-8 {
    margin-left: 8px !important
  }

  .me-sm-8 {
    margin-right: 8px !important
  }

  .p-sm-8 {
    padding: 8px !important
  }

  .px-sm-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .py-sm-8 {
    padding-top: 8px !important
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8px !important
  }

  .pt-sm-8 {
    padding-top: 8px !important
  }

  .ps-sm-8 {
    padding-left: 8px !important
  }

  .pe-sm-8 {
    padding-right: 8px !important
  }

  .m-sm-9 {
    margin: 9px !important
  }

  .mx-sm-9 {
    margin-left: 9px !important;
    margin-right: 9px !important
  }

  .my-sm-9 {
    margin-top: 9px !important
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9px !important
  }

  .mt-sm-9 {
    margin-top: 9px !important
  }

  .ms-sm-9 {
    margin-left: 9px !important
  }

  .me-sm-9 {
    margin-right: 9px !important
  }

  .p-sm-9 {
    padding: 9px !important
  }

  .px-sm-9 {
    padding-left: 9px !important;
    padding-right: 9px !important
  }

  .py-sm-9 {
    padding-top: 9px !important
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9px !important
  }

  .pt-sm-9 {
    padding-top: 9px !important
  }

  .ps-sm-9 {
    padding-left: 9px !important
  }

  .pe-sm-9 {
    padding-right: 9px !important
  }

  .m-sm-10 {
    margin: 10px !important
  }

  .mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important
  }

  .my-sm-10 {
    margin-top: 10px !important
  }

  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important
  }

  .mt-sm-10 {
    margin-top: 10px !important
  }

  .ms-sm-10 {
    margin-left: 10px !important
  }

  .me-sm-10 {
    margin-right: 10px !important
  }

  .p-sm-10 {
    padding: 10px !important
  }

  .px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important
  }

  .py-sm-10 {
    padding-top: 10px !important
  }

  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important
  }

  .pt-sm-10 {
    padding-top: 10px !important
  }

  .ps-sm-10 {
    padding-left: 10px !important
  }

  .pe-sm-10 {
    padding-right: 10px !important
  }

  .m-sm-11 {
    margin: 11px !important
  }

  .mx-sm-11 {
    margin-left: 11px !important;
    margin-right: 11px !important
  }

  .my-sm-11 {
    margin-top: 11px !important
  }

  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 11px !important
  }

  .mt-sm-11 {
    margin-top: 11px !important
  }

  .ms-sm-11 {
    margin-left: 11px !important
  }

  .me-sm-11 {
    margin-right: 11px !important
  }

  .p-sm-11 {
    padding: 11px !important
  }

  .px-sm-11 {
    padding-left: 11px !important;
    padding-right: 11px !important
  }

  .py-sm-11 {
    padding-top: 11px !important
  }

  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 11px !important
  }

  .pt-sm-11 {
    padding-top: 11px !important
  }

  .ps-sm-11 {
    padding-left: 11px !important
  }

  .pe-sm-11 {
    padding-right: 11px !important
  }

  .m-sm-12 {
    margin: 12px !important
  }

  .mx-sm-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .my-sm-12 {
    margin-top: 12px !important
  }

  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 12px !important
  }

  .mt-sm-12 {
    margin-top: 12px !important
  }

  .ms-sm-12 {
    margin-left: 12px !important
  }

  .me-sm-12 {
    margin-right: 12px !important
  }

  .p-sm-12 {
    padding: 12px !important
  }

  .px-sm-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .py-sm-12 {
    padding-top: 12px !important
  }

  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 12px !important
  }

  .pt-sm-12 {
    padding-top: 12px !important
  }

  .ps-sm-12 {
    padding-left: 12px !important
  }

  .pe-sm-12 {
    padding-right: 12px !important
  }

  .m-sm-13 {
    margin: 13px !important
  }

  .mx-sm-13 {
    margin-left: 13px !important;
    margin-right: 13px !important
  }

  .my-sm-13 {
    margin-top: 13px !important
  }

  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 13px !important
  }

  .mt-sm-13 {
    margin-top: 13px !important
  }

  .ms-sm-13 {
    margin-left: 13px !important
  }

  .me-sm-13 {
    margin-right: 13px !important
  }

  .p-sm-13 {
    padding: 13px !important
  }

  .px-sm-13 {
    padding-left: 13px !important;
    padding-right: 13px !important
  }

  .py-sm-13 {
    padding-top: 13px !important
  }

  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 13px !important
  }

  .pt-sm-13 {
    padding-top: 13px !important
  }

  .ps-sm-13 {
    padding-left: 13px !important
  }

  .pe-sm-13 {
    padding-right: 13px !important
  }

  .m-sm-14 {
    margin: 14px !important
  }

  .mx-sm-14 {
    margin-left: 14px !important;
    margin-right: 14px !important
  }

  .my-sm-14 {
    margin-top: 14px !important
  }

  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 14px !important
  }

  .mt-sm-14 {
    margin-top: 14px !important
  }

  .ms-sm-14 {
    margin-left: 14px !important
  }

  .me-sm-14 {
    margin-right: 14px !important
  }

  .p-sm-14 {
    padding: 14px !important
  }

  .px-sm-14 {
    padding-left: 14px !important;
    padding-right: 14px !important
  }

  .py-sm-14 {
    padding-top: 14px !important
  }

  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 14px !important
  }

  .pt-sm-14 {
    padding-top: 14px !important
  }

  .ps-sm-14 {
    padding-left: 14px !important
  }

  .pe-sm-14 {
    padding-right: 14px !important
  }

  .m-sm-15 {
    margin: 15px !important
  }

  .mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important
  }

  .my-sm-15 {
    margin-top: 15px !important
  }

  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important
  }

  .mt-sm-15 {
    margin-top: 15px !important
  }

  .ms-sm-15 {
    margin-left: 15px !important
  }

  .me-sm-15 {
    margin-right: 15px !important
  }

  .p-sm-15 {
    padding: 15px !important
  }

  .px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .py-sm-15 {
    padding-top: 15px !important
  }

  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important
  }

  .pt-sm-15 {
    padding-top: 15px !important
  }

  .ps-sm-15 {
    padding-left: 15px !important
  }

  .pe-sm-15 {
    padding-right: 15px !important
  }

  .m-sm-16 {
    margin: 16px !important
  }

  .mx-sm-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .my-sm-16 {
    margin-top: 16px !important
  }

  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 16px !important
  }

  .mt-sm-16 {
    margin-top: 16px !important
  }

  .ms-sm-16 {
    margin-left: 16px !important
  }

  .me-sm-16 {
    margin-right: 16px !important
  }

  .p-sm-16 {
    padding: 16px !important
  }

  .px-sm-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .py-sm-16 {
    padding-top: 16px !important
  }

  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 16px !important
  }

  .pt-sm-16 {
    padding-top: 16px !important
  }

  .ps-sm-16 {
    padding-left: 16px !important
  }

  .pe-sm-16 {
    padding-right: 16px !important
  }

  .m-sm-17 {
    margin: 17px !important
  }

  .mx-sm-17 {
    margin-left: 17px !important;
    margin-right: 17px !important
  }

  .my-sm-17 {
    margin-top: 17px !important
  }

  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 17px !important
  }

  .mt-sm-17 {
    margin-top: 17px !important
  }

  .ms-sm-17 {
    margin-left: 17px !important
  }

  .me-sm-17 {
    margin-right: 17px !important
  }

  .p-sm-17 {
    padding: 17px !important
  }

  .px-sm-17 {
    padding-left: 17px !important;
    padding-right: 17px !important
  }

  .py-sm-17 {
    padding-top: 17px !important
  }

  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 17px !important
  }

  .pt-sm-17 {
    padding-top: 17px !important
  }

  .ps-sm-17 {
    padding-left: 17px !important
  }

  .pe-sm-17 {
    padding-right: 17px !important
  }

  .m-sm-18 {
    margin: 18px !important
  }

  .mx-sm-18 {
    margin-left: 18px !important;
    margin-right: 18px !important
  }

  .my-sm-18 {
    margin-top: 18px !important
  }

  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 18px !important
  }

  .mt-sm-18 {
    margin-top: 18px !important
  }

  .ms-sm-18 {
    margin-left: 18px !important
  }

  .me-sm-18 {
    margin-right: 18px !important
  }

  .p-sm-18 {
    padding: 18px !important
  }

  .px-sm-18 {
    padding-left: 18px !important;
    padding-right: 18px !important
  }

  .py-sm-18 {
    padding-top: 18px !important
  }

  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 18px !important
  }

  .pt-sm-18 {
    padding-top: 18px !important
  }

  .ps-sm-18 {
    padding-left: 18px !important
  }

  .pe-sm-18 {
    padding-right: 18px !important
  }

  .m-sm-19 {
    margin: 19px !important
  }

  .mx-sm-19 {
    margin-left: 19px !important;
    margin-right: 19px !important
  }

  .my-sm-19 {
    margin-top: 19px !important
  }

  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 19px !important
  }

  .mt-sm-19 {
    margin-top: 19px !important
  }

  .ms-sm-19 {
    margin-left: 19px !important
  }

  .me-sm-19 {
    margin-right: 19px !important
  }

  .p-sm-19 {
    padding: 19px !important
  }

  .px-sm-19 {
    padding-left: 19px !important;
    padding-right: 19px !important
  }

  .py-sm-19 {
    padding-top: 19px !important
  }

  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 19px !important
  }

  .pt-sm-19 {
    padding-top: 19px !important
  }

  .ps-sm-19 {
    padding-left: 19px !important
  }

  .pe-sm-19 {
    padding-right: 19px !important
  }

  .m-sm-20 {
    margin: 20px !important
  }

  .mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .my-sm-20 {
    margin-top: 20px !important
  }

  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important
  }

  .mt-sm-20 {
    margin-top: 20px !important
  }

  .ms-sm-20 {
    margin-left: 20px !important
  }

  .me-sm-20 {
    margin-right: 20px !important
  }

  .p-sm-20 {
    padding: 20px !important
  }

  .px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .py-sm-20 {
    padding-top: 20px !important
  }

  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important
  }

  .pt-sm-20 {
    padding-top: 20px !important
  }

  .ps-sm-20 {
    padding-left: 20px !important
  }

  .pe-sm-20 {
    padding-right: 20px !important
  }

  .m-sm-25 {
    margin: 25px !important
  }

  .mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important
  }

  .my-sm-25 {
    margin-top: 25px !important
  }

  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important
  }

  .mt-sm-25 {
    margin-top: 25px !important
  }

  .ms-sm-25 {
    margin-left: 25px !important
  }

  .me-sm-25 {
    margin-right: 25px !important
  }

  .p-sm-25 {
    padding: 25px !important
  }

  .px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important
  }

  .py-sm-25 {
    padding-top: 25px !important
  }

  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important
  }

  .pt-sm-25 {
    padding-top: 25px !important
  }

  .ps-sm-25 {
    padding-left: 25px !important
  }

  .pe-sm-25 {
    padding-right: 25px !important
  }

  .m-sm-30 {
    margin: 30px !important
  }

  .mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important
  }

  .my-sm-30 {
    margin-top: 30px !important
  }

  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important
  }

  .mt-sm-30 {
    margin-top: 30px !important
  }

  .ms-sm-30 {
    margin-left: 30px !important
  }

  .me-sm-30 {
    margin-right: 30px !important
  }

  .p-sm-30 {
    padding: 30px !important
  }

  .px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important
  }

  .py-sm-30 {
    padding-top: 30px !important
  }

  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important
  }

  .pt-sm-30 {
    padding-top: 30px !important
  }

  .ps-sm-30 {
    padding-left: 30px !important
  }

  .pe-sm-30 {
    padding-right: 30px !important
  }

  .m-sm-35 {
    margin: 35px !important
  }

  .mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important
  }

  .my-sm-35 {
    margin-top: 35px !important
  }

  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important
  }

  .mt-sm-35 {
    margin-top: 35px !important
  }

  .ms-sm-35 {
    margin-left: 35px !important
  }

  .me-sm-35 {
    margin-right: 35px !important
  }

  .p-sm-35 {
    padding: 35px !important
  }

  .px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important
  }

  .py-sm-35 {
    padding-top: 35px !important
  }

  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important
  }

  .pt-sm-35 {
    padding-top: 35px !important
  }

  .ps-sm-35 {
    padding-left: 35px !important
  }

  .pe-sm-35 {
    padding-right: 35px !important
  }

  .m-sm-40 {
    margin: 40px !important
  }

  .mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .my-sm-40 {
    margin-top: 40px !important
  }

  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important
  }

  .mt-sm-40 {
    margin-top: 40px !important
  }

  .ms-sm-40 {
    margin-left: 40px !important
  }

  .me-sm-40 {
    margin-right: 40px !important
  }

  .p-sm-40 {
    padding: 40px !important
  }

  .px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .py-sm-40 {
    padding-top: 40px !important
  }

  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important
  }

  .pt-sm-40 {
    padding-top: 40px !important
  }

  .ps-sm-40 {
    padding-left: 40px !important
  }

  .pe-sm-40 {
    padding-right: 40px !important
  }

  .m-sm-45 {
    margin: 45px !important
  }

  .mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important
  }

  .my-sm-45 {
    margin-top: 45px !important
  }

  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important
  }

  .mt-sm-45 {
    margin-top: 45px !important
  }

  .ms-sm-45 {
    margin-left: 45px !important
  }

  .me-sm-45 {
    margin-right: 45px !important
  }

  .p-sm-45 {
    padding: 45px !important
  }

  .px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important
  }

  .py-sm-45 {
    padding-top: 45px !important
  }

  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important
  }

  .pt-sm-45 {
    padding-top: 45px !important
  }

  .ps-sm-45 {
    padding-left: 45px !important
  }

  .pe-sm-45 {
    padding-right: 45px !important
  }

  .m-sm-50 {
    margin: 50px !important
  }

  .mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important
  }

  .my-sm-50 {
    margin-top: 50px !important
  }

  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important
  }

  .mt-sm-50 {
    margin-top: 50px !important
  }

  .ms-sm-50 {
    margin-left: 50px !important
  }

  .me-sm-50 {
    margin-right: 50px !important
  }

  .p-sm-50 {
    padding: 50px !important
  }

  .px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  .py-sm-50 {
    padding-top: 50px !important
  }

  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important
  }

  .pt-sm-50 {
    padding-top: 50px !important
  }

  .ps-sm-50 {
    padding-left: 50px !important
  }

  .pe-sm-50 {
    padding-right: 50px !important
  }

  .m-sm-55 {
    margin: 55px !important
  }

  .mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important
  }

  .my-sm-55 {
    margin-top: 55px !important
  }

  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important
  }

  .mt-sm-55 {
    margin-top: 55px !important
  }

  .ms-sm-55 {
    margin-left: 55px !important
  }

  .me-sm-55 {
    margin-right: 55px !important
  }

  .p-sm-55 {
    padding: 55px !important
  }

  .px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important
  }

  .py-sm-55 {
    padding-top: 55px !important
  }

  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important
  }

  .pt-sm-55 {
    padding-top: 55px !important
  }

  .ps-sm-55 {
    padding-left: 55px !important
  }

  .pe-sm-55 {
    padding-right: 55px !important
  }

  .m-sm-60 {
    margin: 60px !important
  }

  .mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .my-sm-60 {
    margin-top: 60px !important
  }

  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important
  }

  .mt-sm-60 {
    margin-top: 60px !important
  }

  .ms-sm-60 {
    margin-left: 60px !important
  }

  .me-sm-60 {
    margin-right: 60px !important
  }

  .p-sm-60 {
    padding: 60px !important
  }

  .px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .py-sm-60 {
    padding-top: 60px !important
  }

  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important
  }

  .pt-sm-60 {
    padding-top: 60px !important
  }

  .ps-sm-60 {
    padding-left: 60px !important
  }

  .pe-sm-60 {
    padding-right: 60px !important
  }

  .m-sm-65 {
    margin: 65px !important
  }

  .mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important
  }

  .my-sm-65 {
    margin-top: 65px !important
  }

  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important
  }

  .mt-sm-65 {
    margin-top: 65px !important
  }

  .ms-sm-65 {
    margin-left: 65px !important
  }

  .me-sm-65 {
    margin-right: 65px !important
  }

  .p-sm-65 {
    padding: 65px !important
  }

  .px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important
  }

  .py-sm-65 {
    padding-top: 65px !important
  }

  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important
  }

  .pt-sm-65 {
    padding-top: 65px !important
  }

  .ps-sm-65 {
    padding-left: 65px !important
  }

  .pe-sm-65 {
    padding-right: 65px !important
  }

  .m-sm-70 {
    margin: 70px !important
  }

  .mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important
  }

  .my-sm-70 {
    margin-top: 70px !important
  }

  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 70px !important
  }

  .mt-sm-70 {
    margin-top: 70px !important
  }

  .ms-sm-70 {
    margin-left: 70px !important
  }

  .me-sm-70 {
    margin-right: 70px !important
  }

  .p-sm-70 {
    padding: 70px !important
  }

  .px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important
  }

  .py-sm-70 {
    padding-top: 70px !important
  }

  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 70px !important
  }

  .pt-sm-70 {
    padding-top: 70px !important
  }

  .ps-sm-70 {
    padding-left: 70px !important
  }

  .pe-sm-70 {
    padding-right: 70px !important
  }

  .m-sm-75 {
    margin: 75px !important
  }

  .mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important
  }

  .my-sm-75 {
    margin-top: 75px !important
  }

  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75px !important
  }

  .mt-sm-75 {
    margin-top: 75px !important
  }

  .ms-sm-75 {
    margin-left: 75px !important
  }

  .me-sm-75 {
    margin-right: 75px !important
  }

  .p-sm-75 {
    padding: 75px !important
  }

  .px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important
  }

  .py-sm-75 {
    padding-top: 75px !important
  }

  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75px !important
  }

  .pt-sm-75 {
    padding-top: 75px !important
  }

  .ps-sm-75 {
    padding-left: 75px !important
  }

  .pe-sm-75 {
    padding-right: 75px !important
  }

  .m-sm-80 {
    margin: 80px !important
  }

  .mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .my-sm-80 {
    margin-top: 80px !important
  }

  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important
  }

  .mt-sm-80 {
    margin-top: 80px !important
  }

  .ms-sm-80 {
    margin-left: 80px !important
  }

  .me-sm-80 {
    margin-right: 80px !important
  }

  .p-sm-80 {
    padding: 80px !important
  }

  .px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .py-sm-80 {
    padding-top: 80px !important
  }

  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important
  }

  .pt-sm-80 {
    padding-top: 80px !important
  }

  .ps-sm-80 {
    padding-left: 80px !important
  }

  .pe-sm-80 {
    padding-right: 80px !important
  }

  .m-sm-85 {
    margin: 85px !important
  }

  .mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important
  }

  .my-sm-85 {
    margin-top: 85px !important
  }

  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important
  }

  .mt-sm-85 {
    margin-top: 85px !important
  }

  .ms-sm-85 {
    margin-left: 85px !important
  }

  .me-sm-85 {
    margin-right: 85px !important
  }

  .p-sm-85 {
    padding: 85px !important
  }

  .px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important
  }

  .py-sm-85 {
    padding-top: 85px !important
  }

  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important
  }

  .pt-sm-85 {
    padding-top: 85px !important
  }

  .ps-sm-85 {
    padding-left: 85px !important
  }

  .pe-sm-85 {
    padding-right: 85px !important
  }

  .m-sm-90 {
    margin: 90px !important
  }

  .mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important
  }

  .my-sm-90 {
    margin-top: 90px !important
  }

  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important
  }

  .mt-sm-90 {
    margin-top: 90px !important
  }

  .ms-sm-90 {
    margin-left: 90px !important
  }

  .me-sm-90 {
    margin-right: 90px !important
  }

  .p-sm-90 {
    padding: 90px !important
  }

  .px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important
  }

  .py-sm-90 {
    padding-top: 90px !important
  }

  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important
  }

  .pt-sm-90 {
    padding-top: 90px !important
  }

  .ps-sm-90 {
    padding-left: 90px !important
  }

  .pe-sm-90 {
    padding-right: 90px !important
  }

  .m-sm-95 {
    margin: 95px !important
  }

  .mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important
  }

  .my-sm-95 {
    margin-top: 95px !important
  }

  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important
  }

  .mt-sm-95 {
    margin-top: 95px !important
  }

  .ms-sm-95 {
    margin-left: 95px !important
  }

  .me-sm-95 {
    margin-right: 95px !important
  }

  .p-sm-95 {
    padding: 95px !important
  }

  .px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important
  }

  .py-sm-95 {
    padding-top: 95px !important
  }

  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important
  }

  .pt-sm-95 {
    padding-top: 95px !important
  }

  .ps-sm-95 {
    padding-left: 95px !important
  }

  .pe-sm-95 {
    padding-right: 95px !important
  }

  .m-sm-100 {
    margin: 100px !important
  }

  .mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important
  }

  .my-sm-100 {
    margin-top: 100px !important
  }

  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important
  }

  .mt-sm-100 {
    margin-top: 100px !important
  }

  .ms-sm-100 {
    margin-left: 100px !important
  }

  .me-sm-100 {
    margin-right: 100px !important
  }

  .p-sm-100 {
    padding: 100px !important
  }

  .px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important
  }

  .py-sm-100 {
    padding-top: 100px !important
  }

  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important
  }

  .pt-sm-100 {
    padding-top: 100px !important
  }

  .ps-sm-100 {
    padding-left: 100px !important
  }

  .pe-sm-100 {
    padding-right: 100px !important
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .my-md-0 {
    margin-bottom: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mb-md-0 {
    margin-bottom: 0 !important
  }

  .ms-md-0 {
    margin-left: 0 !important
  }

  .me-md-0 {
    margin-right: 0 !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pb-md-0 {
    padding-bottom: 0 !important
  }

  .ps-md-0 {
    padding-left: 0 !important
  }

  .pe-md-0 {
    padding-right: 0 !important
  }

  .m-md-5 {
    margin: 5px !important
  }

  .mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important
  }

  .my-md-5 {
    margin-top: 5px !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important
  }

  .mt-md-5 {
    margin-top: 5px !important
  }

  .ms-md-5 {
    margin-left: 5px !important
  }

  .me-md-5 {
    margin-right: 5px !important
  }

  .p-md-5 {
    padding: 5px !important
  }

  .px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important
  }

  .py-md-5 {
    padding-top: 5px !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important
  }

  .pt-md-5 {
    padding-top: 5px !important
  }

  .ps-md-5 {
    padding-left: 5px !important
  }

  .pe-md-5 {
    padding-right: 5px !important
  }

  .m-md-6 {
    margin: 6px !important
  }

  .mx-md-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .my-md-6 {
    margin-top: 6px !important
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6px !important
  }

  .mt-md-6 {
    margin-top: 6px !important
  }

  .ms-md-6 {
    margin-left: 6px !important
  }

  .me-md-6 {
    margin-right: 6px !important
  }

  .p-md-6 {
    padding: 6px !important
  }

  .px-md-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .py-md-6 {
    padding-top: 6px !important
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6px !important
  }

  .pt-md-6 {
    padding-top: 6px !important
  }

  .ps-md-6 {
    padding-left: 6px !important
  }

  .pe-md-6 {
    padding-right: 6px !important
  }

  .m-md-7 {
    margin: 7px !important
  }

  .mx-md-7 {
    margin-left: 7px !important;
    margin-right: 7px !important
  }

  .my-md-7 {
    margin-top: 7px !important
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7px !important
  }

  .mt-md-7 {
    margin-top: 7px !important
  }

  .ms-md-7 {
    margin-left: 7px !important
  }

  .me-md-7 {
    margin-right: 7px !important
  }

  .p-md-7 {
    padding: 7px !important
  }

  .px-md-7 {
    padding-left: 7px !important;
    padding-right: 7px !important
  }

  .py-md-7 {
    padding-top: 7px !important
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7px !important
  }

  .pt-md-7 {
    padding-top: 7px !important
  }

  .ps-md-7 {
    padding-left: 7px !important
  }

  .pe-md-7 {
    padding-right: 7px !important
  }

  .m-md-8 {
    margin: 8px !important
  }

  .mx-md-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .my-md-8 {
    margin-top: 8px !important
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8px !important
  }

  .mt-md-8 {
    margin-top: 8px !important
  }

  .ms-md-8 {
    margin-left: 8px !important
  }

  .me-md-8 {
    margin-right: 8px !important
  }

  .p-md-8 {
    padding: 8px !important
  }

  .px-md-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .py-md-8 {
    padding-top: 8px !important
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8px !important
  }

  .pt-md-8 {
    padding-top: 8px !important
  }

  .ps-md-8 {
    padding-left: 8px !important
  }

  .pe-md-8 {
    padding-right: 8px !important
  }

  .m-md-9 {
    margin: 9px !important
  }

  .mx-md-9 {
    margin-left: 9px !important;
    margin-right: 9px !important
  }

  .my-md-9 {
    margin-top: 9px !important
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9px !important
  }

  .mt-md-9 {
    margin-top: 9px !important
  }

  .ms-md-9 {
    margin-left: 9px !important
  }

  .me-md-9 {
    margin-right: 9px !important
  }

  .p-md-9 {
    padding: 9px !important
  }

  .px-md-9 {
    padding-left: 9px !important;
    padding-right: 9px !important
  }

  .py-md-9 {
    padding-top: 9px !important
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9px !important
  }

  .pt-md-9 {
    padding-top: 9px !important
  }

  .ps-md-9 {
    padding-left: 9px !important
  }

  .pe-md-9 {
    padding-right: 9px !important
  }

  .m-md-10 {
    margin: 10px !important
  }

  .mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important
  }

  .my-md-10 {
    margin-top: 10px !important
  }

  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important
  }

  .mt-md-10 {
    margin-top: 10px !important
  }

  .ms-md-10 {
    margin-left: 10px !important
  }

  .me-md-10 {
    margin-right: 10px !important
  }

  .p-md-10 {
    padding: 10px !important
  }

  .px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important
  }

  .py-md-10 {
    padding-top: 10px !important
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important
  }

  .pt-md-10 {
    padding-top: 10px !important
  }

  .ps-md-10 {
    padding-left: 10px !important
  }

  .pe-md-10 {
    padding-right: 10px !important
  }

  .m-md-11 {
    margin: 11px !important
  }

  .mx-md-11 {
    margin-left: 11px !important;
    margin-right: 11px !important
  }

  .my-md-11 {
    margin-top: 11px !important
  }

  .mb-md-11,
  .my-md-11 {
    margin-bottom: 11px !important
  }

  .mt-md-11 {
    margin-top: 11px !important
  }

  .ms-md-11 {
    margin-left: 11px !important
  }

  .me-md-11 {
    margin-right: 11px !important
  }

  .p-md-11 {
    padding: 11px !important
  }

  .px-md-11 {
    padding-left: 11px !important;
    padding-right: 11px !important
  }

  .py-md-11 {
    padding-top: 11px !important
  }

  .pb-md-11,
  .py-md-11 {
    padding-bottom: 11px !important
  }

  .pt-md-11 {
    padding-top: 11px !important
  }

  .ps-md-11 {
    padding-left: 11px !important
  }

  .pe-md-11 {
    padding-right: 11px !important
  }

  .m-md-12 {
    margin: 12px !important
  }

  .mx-md-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .my-md-12 {
    margin-top: 12px !important
  }

  .mb-md-12,
  .my-md-12 {
    margin-bottom: 12px !important
  }

  .mt-md-12 {
    margin-top: 12px !important
  }

  .ms-md-12 {
    margin-left: 12px !important
  }

  .me-md-12 {
    margin-right: 12px !important
  }

  .p-md-12 {
    padding: 12px !important
  }

  .px-md-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .py-md-12 {
    padding-top: 12px !important
  }

  .pb-md-12,
  .py-md-12 {
    padding-bottom: 12px !important
  }

  .pt-md-12 {
    padding-top: 12px !important
  }

  .ps-md-12 {
    padding-left: 12px !important
  }

  .pe-md-12 {
    padding-right: 12px !important
  }

  .m-md-13 {
    margin: 13px !important
  }

  .mx-md-13 {
    margin-left: 13px !important;
    margin-right: 13px !important
  }

  .my-md-13 {
    margin-top: 13px !important
  }

  .mb-md-13,
  .my-md-13 {
    margin-bottom: 13px !important
  }

  .mt-md-13 {
    margin-top: 13px !important
  }

  .ms-md-13 {
    margin-left: 13px !important
  }

  .me-md-13 {
    margin-right: 13px !important
  }

  .p-md-13 {
    padding: 13px !important
  }

  .px-md-13 {
    padding-left: 13px !important;
    padding-right: 13px !important
  }

  .py-md-13 {
    padding-top: 13px !important
  }

  .pb-md-13,
  .py-md-13 {
    padding-bottom: 13px !important
  }

  .pt-md-13 {
    padding-top: 13px !important
  }

  .ps-md-13 {
    padding-left: 13px !important
  }

  .pe-md-13 {
    padding-right: 13px !important
  }

  .m-md-14 {
    margin: 14px !important
  }

  .mx-md-14 {
    margin-left: 14px !important;
    margin-right: 14px !important
  }

  .my-md-14 {
    margin-top: 14px !important
  }

  .mb-md-14,
  .my-md-14 {
    margin-bottom: 14px !important
  }

  .mt-md-14 {
    margin-top: 14px !important
  }

  .ms-md-14 {
    margin-left: 14px !important
  }

  .me-md-14 {
    margin-right: 14px !important
  }

  .p-md-14 {
    padding: 14px !important
  }

  .px-md-14 {
    padding-left: 14px !important;
    padding-right: 14px !important
  }

  .py-md-14 {
    padding-top: 14px !important
  }

  .pb-md-14,
  .py-md-14 {
    padding-bottom: 14px !important
  }

  .pt-md-14 {
    padding-top: 14px !important
  }

  .ps-md-14 {
    padding-left: 14px !important
  }

  .pe-md-14 {
    padding-right: 14px !important
  }

  .m-md-15 {
    margin: 15px !important
  }

  .mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important
  }

  .my-md-15 {
    margin-top: 15px !important
  }

  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important
  }

  .mt-md-15 {
    margin-top: 15px !important
  }

  .ms-md-15 {
    margin-left: 15px !important
  }

  .me-md-15 {
    margin-right: 15px !important
  }

  .p-md-15 {
    padding: 15px !important
  }

  .px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .py-md-15 {
    padding-top: 15px !important
  }

  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important
  }

  .pt-md-15 {
    padding-top: 15px !important
  }

  .ps-md-15 {
    padding-left: 15px !important
  }

  .pe-md-15 {
    padding-right: 15px !important
  }

  .m-md-16 {
    margin: 16px !important
  }

  .mx-md-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .my-md-16 {
    margin-top: 16px !important
  }

  .mb-md-16,
  .my-md-16 {
    margin-bottom: 16px !important
  }

  .mt-md-16 {
    margin-top: 16px !important
  }

  .ms-md-16 {
    margin-left: 16px !important
  }

  .me-md-16 {
    margin-right: 16px !important
  }

  .p-md-16 {
    padding: 16px !important
  }

  .px-md-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .py-md-16 {
    padding-top: 16px !important
  }

  .pb-md-16,
  .py-md-16 {
    padding-bottom: 16px !important
  }

  .pt-md-16 {
    padding-top: 16px !important
  }

  .ps-md-16 {
    padding-left: 16px !important
  }

  .pe-md-16 {
    padding-right: 16px !important
  }

  .m-md-17 {
    margin: 17px !important
  }

  .mx-md-17 {
    margin-left: 17px !important;
    margin-right: 17px !important
  }

  .my-md-17 {
    margin-top: 17px !important
  }

  .mb-md-17,
  .my-md-17 {
    margin-bottom: 17px !important
  }

  .mt-md-17 {
    margin-top: 17px !important
  }

  .ms-md-17 {
    margin-left: 17px !important
  }

  .me-md-17 {
    margin-right: 17px !important
  }

  .p-md-17 {
    padding: 17px !important
  }

  .px-md-17 {
    padding-left: 17px !important;
    padding-right: 17px !important
  }

  .py-md-17 {
    padding-top: 17px !important
  }

  .pb-md-17,
  .py-md-17 {
    padding-bottom: 17px !important
  }

  .pt-md-17 {
    padding-top: 17px !important
  }

  .ps-md-17 {
    padding-left: 17px !important
  }

  .pe-md-17 {
    padding-right: 17px !important
  }

  .m-md-18 {
    margin: 18px !important
  }

  .mx-md-18 {
    margin-left: 18px !important;
    margin-right: 18px !important
  }

  .my-md-18 {
    margin-top: 18px !important
  }

  .mb-md-18,
  .my-md-18 {
    margin-bottom: 18px !important
  }

  .mt-md-18 {
    margin-top: 18px !important
  }

  .ms-md-18 {
    margin-left: 18px !important
  }

  .me-md-18 {
    margin-right: 18px !important
  }

  .p-md-18 {
    padding: 18px !important
  }

  .px-md-18 {
    padding-left: 18px !important;
    padding-right: 18px !important
  }

  .py-md-18 {
    padding-top: 18px !important
  }

  .pb-md-18,
  .py-md-18 {
    padding-bottom: 18px !important
  }

  .pt-md-18 {
    padding-top: 18px !important
  }

  .ps-md-18 {
    padding-left: 18px !important
  }

  .pe-md-18 {
    padding-right: 18px !important
  }

  .m-md-19 {
    margin: 19px !important
  }

  .mx-md-19 {
    margin-left: 19px !important;
    margin-right: 19px !important
  }

  .my-md-19 {
    margin-top: 19px !important
  }

  .mb-md-19,
  .my-md-19 {
    margin-bottom: 19px !important
  }

  .mt-md-19 {
    margin-top: 19px !important
  }

  .ms-md-19 {
    margin-left: 19px !important
  }

  .me-md-19 {
    margin-right: 19px !important
  }

  .p-md-19 {
    padding: 19px !important
  }

  .px-md-19 {
    padding-left: 19px !important;
    padding-right: 19px !important
  }

  .py-md-19 {
    padding-top: 19px !important
  }

  .pb-md-19,
  .py-md-19 {
    padding-bottom: 19px !important
  }

  .pt-md-19 {
    padding-top: 19px !important
  }

  .ps-md-19 {
    padding-left: 19px !important
  }

  .pe-md-19 {
    padding-right: 19px !important
  }

  .m-md-20 {
    margin: 20px !important
  }

  .mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .my-md-20 {
    margin-top: 20px !important
  }

  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important
  }

  .mt-md-20 {
    margin-top: 20px !important
  }

  .ms-md-20 {
    margin-left: 20px !important
  }

  .me-md-20 {
    margin-right: 20px !important
  }

  .p-md-20 {
    padding: 20px !important
  }

  .px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .py-md-20 {
    padding-top: 20px !important
  }

  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important
  }

  .pt-md-20 {
    padding-top: 20px !important
  }

  .ps-md-20 {
    padding-left: 20px !important
  }

  .pe-md-20 {
    padding-right: 20px !important
  }

  .m-md-25 {
    margin: 25px !important
  }

  .mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important
  }

  .my-md-25 {
    margin-top: 25px !important
  }

  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important
  }

  .mt-md-25 {
    margin-top: 25px !important
  }

  .ms-md-25 {
    margin-left: 25px !important
  }

  .me-md-25 {
    margin-right: 25px !important
  }

  .p-md-25 {
    padding: 25px !important
  }

  .px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important
  }

  .py-md-25 {
    padding-top: 25px !important
  }

  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important
  }

  .pt-md-25 {
    padding-top: 25px !important
  }

  .ps-md-25 {
    padding-left: 25px !important
  }

  .pe-md-25 {
    padding-right: 25px !important
  }

  .m-md-30 {
    margin: 30px !important
  }

  .mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important
  }

  .my-md-30 {
    margin-top: 30px !important
  }

  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important
  }

  .mt-md-30 {
    margin-top: 30px !important
  }

  .ms-md-30 {
    margin-left: 30px !important
  }

  .me-md-30 {
    margin-right: 30px !important
  }

  .p-md-30 {
    padding: 30px !important
  }

  .px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important
  }

  .py-md-30 {
    padding-top: 30px !important
  }

  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important
  }

  .pt-md-30 {
    padding-top: 30px !important
  }

  .ps-md-30 {
    padding-left: 30px !important
  }

  .pe-md-30 {
    padding-right: 30px !important
  }

  .m-md-35 {
    margin: 35px !important
  }

  .mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important
  }

  .my-md-35 {
    margin-top: 35px !important
  }

  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important
  }

  .mt-md-35 {
    margin-top: 35px !important
  }

  .ms-md-35 {
    margin-left: 35px !important
  }

  .me-md-35 {
    margin-right: 35px !important
  }

  .p-md-35 {
    padding: 35px !important
  }

  .px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important
  }

  .py-md-35 {
    padding-top: 35px !important
  }

  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important
  }

  .pt-md-35 {
    padding-top: 35px !important
  }

  .ps-md-35 {
    padding-left: 35px !important
  }

  .pe-md-35 {
    padding-right: 35px !important
  }

  .m-md-40 {
    margin: 40px !important
  }

  .mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .my-md-40 {
    margin-top: 40px !important
  }

  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important
  }

  .mt-md-40 {
    margin-top: 40px !important
  }

  .ms-md-40 {
    margin-left: 40px !important
  }

  .me-md-40 {
    margin-right: 40px !important
  }

  .p-md-40 {
    padding: 40px !important
  }

  .px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .py-md-40 {
    padding-top: 40px !important
  }

  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important
  }

  .pt-md-40 {
    padding-top: 40px !important
  }

  .ps-md-40 {
    padding-left: 40px !important
  }

  .pe-md-40 {
    padding-right: 40px !important
  }

  .m-md-45 {
    margin: 45px !important
  }

  .mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important
  }

  .my-md-45 {
    margin-top: 45px !important
  }

  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important
  }

  .mt-md-45 {
    margin-top: 45px !important
  }

  .ms-md-45 {
    margin-left: 45px !important
  }

  .me-md-45 {
    margin-right: 45px !important
  }

  .p-md-45 {
    padding: 45px !important
  }

  .px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important
  }

  .py-md-45 {
    padding-top: 45px !important
  }

  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important
  }

  .pt-md-45 {
    padding-top: 45px !important
  }

  .ps-md-45 {
    padding-left: 45px !important
  }

  .pe-md-45 {
    padding-right: 45px !important
  }

  .m-md-50 {
    margin: 50px !important
  }

  .mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important
  }

  .my-md-50 {
    margin-top: 50px !important
  }

  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important
  }

  .mt-md-50 {
    margin-top: 50px !important
  }

  .ms-md-50 {
    margin-left: 50px !important
  }

  .me-md-50 {
    margin-right: 50px !important
  }

  .p-md-50 {
    padding: 50px !important
  }

  .px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  .py-md-50 {
    padding-top: 50px !important
  }

  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important
  }

  .pt-md-50 {
    padding-top: 50px !important
  }

  .ps-md-50 {
    padding-left: 50px !important
  }

  .pe-md-50 {
    padding-right: 50px !important
  }

  .m-md-55 {
    margin: 55px !important
  }

  .mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important
  }

  .my-md-55 {
    margin-top: 55px !important
  }

  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important
  }

  .mt-md-55 {
    margin-top: 55px !important
  }

  .ms-md-55 {
    margin-left: 55px !important
  }

  .me-md-55 {
    margin-right: 55px !important
  }

  .p-md-55 {
    padding: 55px !important
  }

  .px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important
  }

  .py-md-55 {
    padding-top: 55px !important
  }

  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important
  }

  .pt-md-55 {
    padding-top: 55px !important
  }

  .ps-md-55 {
    padding-left: 55px !important
  }

  .pe-md-55 {
    padding-right: 55px !important
  }

  .m-md-60 {
    margin: 60px !important
  }

  .mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .my-md-60 {
    margin-top: 60px !important
  }

  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important
  }

  .mt-md-60 {
    margin-top: 60px !important
  }

  .ms-md-60 {
    margin-left: 60px !important
  }

  .me-md-60 {
    margin-right: 60px !important
  }

  .p-md-60 {
    padding: 60px !important
  }

  .px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .py-md-60 {
    padding-top: 60px !important
  }

  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important
  }

  .pt-md-60 {
    padding-top: 60px !important
  }

  .ps-md-60 {
    padding-left: 60px !important
  }

  .pe-md-60 {
    padding-right: 60px !important
  }

  .m-md-65 {
    margin: 65px !important
  }

  .mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important
  }

  .my-md-65 {
    margin-top: 65px !important
  }

  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important
  }

  .mt-md-65 {
    margin-top: 65px !important
  }

  .ms-md-65 {
    margin-left: 65px !important
  }

  .me-md-65 {
    margin-right: 65px !important
  }

  .p-md-65 {
    padding: 65px !important
  }

  .px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important
  }

  .py-md-65 {
    padding-top: 65px !important
  }

  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important
  }

  .pt-md-65 {
    padding-top: 65px !important
  }

  .ps-md-65 {
    padding-left: 65px !important
  }

  .pe-md-65 {
    padding-right: 65px !important
  }

  .m-md-70 {
    margin: 70px !important
  }

  .mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important
  }

  .my-md-70 {
    margin-top: 70px !important
  }

  .mb-md-70,
  .my-md-70 {
    margin-bottom: 70px !important
  }

  .mt-md-70 {
    margin-top: 70px !important
  }

  .ms-md-70 {
    margin-left: 70px !important
  }

  .me-md-70 {
    margin-right: 70px !important
  }

  .p-md-70 {
    padding: 70px !important
  }

  .px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important
  }

  .py-md-70 {
    padding-top: 70px !important
  }

  .pb-md-70,
  .py-md-70 {
    padding-bottom: 70px !important
  }

  .pt-md-70 {
    padding-top: 70px !important
  }

  .ps-md-70 {
    padding-left: 70px !important
  }

  .pe-md-70 {
    padding-right: 70px !important
  }

  .m-md-75 {
    margin: 75px !important
  }

  .mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important
  }

  .my-md-75 {
    margin-top: 75px !important
  }

  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75px !important
  }

  .mt-md-75 {
    margin-top: 75px !important
  }

  .ms-md-75 {
    margin-left: 75px !important
  }

  .me-md-75 {
    margin-right: 75px !important
  }

  .p-md-75 {
    padding: 75px !important
  }

  .px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important
  }

  .py-md-75 {
    padding-top: 75px !important
  }

  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75px !important
  }

  .pt-md-75 {
    padding-top: 75px !important
  }

  .ps-md-75 {
    padding-left: 75px !important
  }

  .pe-md-75 {
    padding-right: 75px !important
  }

  .m-md-80 {
    margin: 80px !important
  }

  .mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .my-md-80 {
    margin-top: 80px !important
  }

  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important
  }

  .mt-md-80 {
    margin-top: 80px !important
  }

  .ms-md-80 {
    margin-left: 80px !important
  }

  .me-md-80 {
    margin-right: 80px !important
  }

  .p-md-80 {
    padding: 80px !important
  }

  .px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .py-md-80 {
    padding-top: 80px !important
  }

  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important
  }

  .pt-md-80 {
    padding-top: 80px !important
  }

  .ps-md-80 {
    padding-left: 80px !important
  }

  .pe-md-80 {
    padding-right: 80px !important
  }

  .m-md-85 {
    margin: 85px !important
  }

  .mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important
  }

  .my-md-85 {
    margin-top: 85px !important
  }

  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important
  }

  .mt-md-85 {
    margin-top: 85px !important
  }

  .ms-md-85 {
    margin-left: 85px !important
  }

  .me-md-85 {
    margin-right: 85px !important
  }

  .p-md-85 {
    padding: 85px !important
  }

  .px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important
  }

  .py-md-85 {
    padding-top: 85px !important
  }

  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important
  }

  .pt-md-85 {
    padding-top: 85px !important
  }

  .ps-md-85 {
    padding-left: 85px !important
  }

  .pe-md-85 {
    padding-right: 85px !important
  }

  .m-md-90 {
    margin: 90px !important
  }

  .mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important
  }

  .my-md-90 {
    margin-top: 90px !important
  }

  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important
  }

  .mt-md-90 {
    margin-top: 90px !important
  }

  .ms-md-90 {
    margin-left: 90px !important
  }

  .me-md-90 {
    margin-right: 90px !important
  }

  .p-md-90 {
    padding: 90px !important
  }

  .px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important
  }

  .py-md-90 {
    padding-top: 90px !important
  }

  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important
  }

  .pt-md-90 {
    padding-top: 90px !important
  }

  .ps-md-90 {
    padding-left: 90px !important
  }

  .pe-md-90 {
    padding-right: 90px !important
  }

  .m-md-95 {
    margin: 95px !important
  }

  .mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important
  }

  .my-md-95 {
    margin-top: 95px !important
  }

  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important
  }

  .mt-md-95 {
    margin-top: 95px !important
  }

  .ms-md-95 {
    margin-left: 95px !important
  }

  .me-md-95 {
    margin-right: 95px !important
  }

  .p-md-95 {
    padding: 95px !important
  }

  .px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important
  }

  .py-md-95 {
    padding-top: 95px !important
  }

  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important
  }

  .pt-md-95 {
    padding-top: 95px !important
  }

  .ps-md-95 {
    padding-left: 95px !important
  }

  .pe-md-95 {
    padding-right: 95px !important
  }

  .m-md-100 {
    margin: 100px !important
  }

  .mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important
  }

  .my-md-100 {
    margin-top: 100px !important
  }

  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important
  }

  .mt-md-100 {
    margin-top: 100px !important
  }

  .ms-md-100 {
    margin-left: 100px !important
  }

  .me-md-100 {
    margin-right: 100px !important
  }

  .p-md-100 {
    padding: 100px !important
  }

  .px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important
  }

  .py-md-100 {
    padding-top: 100px !important
  }

  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important
  }

  .pt-md-100 {
    padding-top: 100px !important
  }

  .ps-md-100 {
    padding-left: 100px !important
  }

  .pe-md-100 {
    padding-right: 100px !important
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mb-lg-0 {
    margin-bottom: 0 !important
  }

  .ms-lg-0 {
    margin-left: 0 !important
  }

  .me-lg-0 {
    margin-right: 0 !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pb-lg-0 {
    padding-bottom: 0 !important
  }

  .ps-lg-0 {
    padding-left: 0 !important
  }

  .pe-lg-0 {
    padding-right: 0 !important
  }

  .m-lg-5 {
    margin: 5px !important
  }

  .mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important
  }

  .my-lg-5 {
    margin-top: 5px !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important
  }

  .mt-lg-5 {
    margin-top: 5px !important
  }

  .ms-lg-5 {
    margin-left: 5px !important
  }

  .me-lg-5 {
    margin-right: 5px !important
  }

  .p-lg-5 {
    padding: 5px !important
  }

  .px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important
  }

  .py-lg-5 {
    padding-top: 5px !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important
  }

  .pt-lg-5 {
    padding-top: 5px !important
  }

  .ps-lg-5 {
    padding-left: 5px !important
  }

  .pe-lg-5 {
    padding-right: 5px !important
  }

  .m-lg-6 {
    margin: 6px !important
  }

  .mx-lg-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .my-lg-6 {
    margin-top: 6px !important
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6px !important
  }

  .mt-lg-6 {
    margin-top: 6px !important
  }

  .ms-lg-6 {
    margin-left: 6px !important
  }

  .me-lg-6 {
    margin-right: 6px !important
  }

  .p-lg-6 {
    padding: 6px !important
  }

  .px-lg-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .py-lg-6 {
    padding-top: 6px !important
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6px !important
  }

  .pt-lg-6 {
    padding-top: 6px !important
  }

  .ps-lg-6 {
    padding-left: 6px !important
  }

  .pe-lg-6 {
    padding-right: 6px !important
  }

  .m-lg-7 {
    margin: 7px !important
  }

  .mx-lg-7 {
    margin-left: 7px !important;
    margin-right: 7px !important
  }

  .my-lg-7 {
    margin-top: 7px !important
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7px !important
  }

  .mt-lg-7 {
    margin-top: 7px !important
  }

  .ms-lg-7 {
    margin-left: 7px !important
  }

  .me-lg-7 {
    margin-right: 7px !important
  }

  .p-lg-7 {
    padding: 7px !important
  }

  .px-lg-7 {
    padding-left: 7px !important;
    padding-right: 7px !important
  }

  .py-lg-7 {
    padding-top: 7px !important
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7px !important
  }

  .pt-lg-7 {
    padding-top: 7px !important
  }

  .ps-lg-7 {
    padding-left: 7px !important
  }

  .pe-lg-7 {
    padding-right: 7px !important
  }

  .m-lg-8 {
    margin: 8px !important
  }

  .mx-lg-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .my-lg-8 {
    margin-top: 8px !important
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8px !important
  }

  .mt-lg-8 {
    margin-top: 8px !important
  }

  .ms-lg-8 {
    margin-left: 8px !important
  }

  .me-lg-8 {
    margin-right: 8px !important
  }

  .p-lg-8 {
    padding: 8px !important
  }

  .px-lg-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .py-lg-8 {
    padding-top: 8px !important
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8px !important
  }

  .pt-lg-8 {
    padding-top: 8px !important
  }

  .ps-lg-8 {
    padding-left: 8px !important
  }

  .pe-lg-8 {
    padding-right: 8px !important
  }

  .m-lg-9 {
    margin: 9px !important
  }

  .mx-lg-9 {
    margin-left: 9px !important;
    margin-right: 9px !important
  }

  .my-lg-9 {
    margin-top: 9px !important
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9px !important
  }

  .mt-lg-9 {
    margin-top: 9px !important
  }

  .ms-lg-9 {
    margin-left: 9px !important
  }

  .me-lg-9 {
    margin-right: 9px !important
  }

  .p-lg-9 {
    padding: 9px !important
  }

  .px-lg-9 {
    padding-left: 9px !important;
    padding-right: 9px !important
  }

  .py-lg-9 {
    padding-top: 9px !important
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9px !important
  }

  .pt-lg-9 {
    padding-top: 9px !important
  }

  .ps-lg-9 {
    padding-left: 9px !important
  }

  .pe-lg-9 {
    padding-right: 9px !important
  }

  .m-lg-10 {
    margin: 10px !important
  }

  .mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important
  }

  .my-lg-10 {
    margin-top: 10px !important
  }

  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important
  }

  .mt-lg-10 {
    margin-top: 10px !important
  }

  .ms-lg-10 {
    margin-left: 10px !important
  }

  .me-lg-10 {
    margin-right: 10px !important
  }

  .p-lg-10 {
    padding: 10px !important
  }

  .px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important
  }

  .py-lg-10 {
    padding-top: 10px !important
  }

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important
  }

  .pt-lg-10 {
    padding-top: 10px !important
  }

  .ps-lg-10 {
    padding-left: 10px !important
  }

  .pe-lg-10 {
    padding-right: 10px !important
  }

  .m-lg-11 {
    margin: 11px !important
  }

  .mx-lg-11 {
    margin-left: 11px !important;
    margin-right: 11px !important
  }

  .my-lg-11 {
    margin-top: 11px !important
  }

  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 11px !important
  }

  .mt-lg-11 {
    margin-top: 11px !important
  }

  .ms-lg-11 {
    margin-left: 11px !important
  }

  .me-lg-11 {
    margin-right: 11px !important
  }

  .p-lg-11 {
    padding: 11px !important
  }

  .px-lg-11 {
    padding-left: 11px !important;
    padding-right: 11px !important
  }

  .py-lg-11 {
    padding-top: 11px !important
  }

  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 11px !important
  }

  .pt-lg-11 {
    padding-top: 11px !important
  }

  .ps-lg-11 {
    padding-left: 11px !important
  }

  .pe-lg-11 {
    padding-right: 11px !important
  }

  .m-lg-12 {
    margin: 12px !important
  }

  .mx-lg-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .my-lg-12 {
    margin-top: 12px !important
  }

  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 12px !important
  }

  .mt-lg-12 {
    margin-top: 12px !important
  }

  .ms-lg-12 {
    margin-left: 12px !important
  }

  .me-lg-12 {
    margin-right: 12px !important
  }

  .p-lg-12 {
    padding: 12px !important
  }

  .px-lg-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .py-lg-12 {
    padding-top: 12px !important
  }

  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 12px !important
  }

  .pt-lg-12 {
    padding-top: 12px !important
  }

  .ps-lg-12 {
    padding-left: 12px !important
  }

  .pe-lg-12 {
    padding-right: 12px !important
  }

  .m-lg-13 {
    margin: 13px !important
  }

  .mx-lg-13 {
    margin-left: 13px !important;
    margin-right: 13px !important
  }

  .my-lg-13 {
    margin-top: 13px !important
  }

  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 13px !important
  }

  .mt-lg-13 {
    margin-top: 13px !important
  }

  .ms-lg-13 {
    margin-left: 13px !important
  }

  .me-lg-13 {
    margin-right: 13px !important
  }

  .p-lg-13 {
    padding: 13px !important
  }

  .px-lg-13 {
    padding-left: 13px !important;
    padding-right: 13px !important
  }

  .py-lg-13 {
    padding-top: 13px !important
  }

  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 13px !important
  }

  .pt-lg-13 {
    padding-top: 13px !important
  }

  .ps-lg-13 {
    padding-left: 13px !important
  }

  .pe-lg-13 {
    padding-right: 13px !important
  }

  .m-lg-14 {
    margin: 14px !important
  }

  .mx-lg-14 {
    margin-left: 14px !important;
    margin-right: 14px !important
  }

  .my-lg-14 {
    margin-top: 14px !important
  }

  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 14px !important
  }

  .mt-lg-14 {
    margin-top: 14px !important
  }

  .ms-lg-14 {
    margin-left: 14px !important
  }

  .me-lg-14 {
    margin-right: 14px !important
  }

  .p-lg-14 {
    padding: 14px !important
  }

  .px-lg-14 {
    padding-left: 14px !important;
    padding-right: 14px !important
  }

  .py-lg-14 {
    padding-top: 14px !important
  }

  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 14px !important
  }

  .pt-lg-14 {
    padding-top: 14px !important
  }

  .ps-lg-14 {
    padding-left: 14px !important
  }

  .pe-lg-14 {
    padding-right: 14px !important
  }

  .m-lg-15 {
    margin: 15px !important
  }

  .mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important
  }

  .my-lg-15 {
    margin-top: 15px !important
  }

  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important
  }

  .mt-lg-15 {
    margin-top: 15px !important
  }

  .ms-lg-15 {
    margin-left: 15px !important
  }

  .me-lg-15 {
    margin-right: 15px !important
  }

  .p-lg-15 {
    padding: 15px !important
  }

  .px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .py-lg-15 {
    padding-top: 15px !important
  }

  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important
  }

  .pt-lg-15 {
    padding-top: 15px !important
  }

  .ps-lg-15 {
    padding-left: 15px !important
  }

  .pe-lg-15 {
    padding-right: 15px !important
  }

  .m-lg-16 {
    margin: 16px !important
  }

  .mx-lg-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .my-lg-16 {
    margin-top: 16px !important
  }

  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 16px !important
  }

  .mt-lg-16 {
    margin-top: 16px !important
  }

  .ms-lg-16 {
    margin-left: 16px !important
  }

  .me-lg-16 {
    margin-right: 16px !important
  }

  .p-lg-16 {
    padding: 16px !important
  }

  .px-lg-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .py-lg-16 {
    padding-top: 16px !important
  }

  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 16px !important
  }

  .pt-lg-16 {
    padding-top: 16px !important
  }

  .ps-lg-16 {
    padding-left: 16px !important
  }

  .pe-lg-16 {
    padding-right: 16px !important
  }

  .m-lg-17 {
    margin: 17px !important
  }

  .mx-lg-17 {
    margin-left: 17px !important;
    margin-right: 17px !important
  }

  .my-lg-17 {
    margin-top: 17px !important
  }

  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 17px !important
  }

  .mt-lg-17 {
    margin-top: 17px !important
  }

  .ms-lg-17 {
    margin-left: 17px !important
  }

  .me-lg-17 {
    margin-right: 17px !important
  }

  .p-lg-17 {
    padding: 17px !important
  }

  .px-lg-17 {
    padding-left: 17px !important;
    padding-right: 17px !important
  }

  .py-lg-17 {
    padding-top: 17px !important
  }

  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 17px !important
  }

  .pt-lg-17 {
    padding-top: 17px !important
  }

  .ps-lg-17 {
    padding-left: 17px !important
  }

  .pe-lg-17 {
    padding-right: 17px !important
  }

  .m-lg-18 {
    margin: 18px !important
  }

  .mx-lg-18 {
    margin-left: 18px !important;
    margin-right: 18px !important
  }

  .my-lg-18 {
    margin-top: 18px !important
  }

  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 18px !important
  }

  .mt-lg-18 {
    margin-top: 18px !important
  }

  .ms-lg-18 {
    margin-left: 18px !important
  }

  .me-lg-18 {
    margin-right: 18px !important
  }

  .p-lg-18 {
    padding: 18px !important
  }

  .px-lg-18 {
    padding-left: 18px !important;
    padding-right: 18px !important
  }

  .py-lg-18 {
    padding-top: 18px !important
  }

  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 18px !important
  }

  .pt-lg-18 {
    padding-top: 18px !important
  }

  .ps-lg-18 {
    padding-left: 18px !important
  }

  .pe-lg-18 {
    padding-right: 18px !important
  }

  .m-lg-19 {
    margin: 19px !important
  }

  .mx-lg-19 {
    margin-left: 19px !important;
    margin-right: 19px !important
  }

  .my-lg-19 {
    margin-top: 19px !important
  }

  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 19px !important
  }

  .mt-lg-19 {
    margin-top: 19px !important
  }

  .ms-lg-19 {
    margin-left: 19px !important
  }

  .me-lg-19 {
    margin-right: 19px !important
  }

  .p-lg-19 {
    padding: 19px !important
  }

  .px-lg-19 {
    padding-left: 19px !important;
    padding-right: 19px !important
  }

  .py-lg-19 {
    padding-top: 19px !important
  }

  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 19px !important
  }

  .pt-lg-19 {
    padding-top: 19px !important
  }

  .ps-lg-19 {
    padding-left: 19px !important
  }

  .pe-lg-19 {
    padding-right: 19px !important
  }

  .m-lg-20 {
    margin: 20px !important
  }

  .mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .my-lg-20 {
    margin-top: 20px !important
  }

  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important
  }

  .mt-lg-20 {
    margin-top: 20px !important
  }

  .ms-lg-20 {
    margin-left: 20px !important
  }

  .me-lg-20 {
    margin-right: 20px !important
  }

  .p-lg-20 {
    padding: 20px !important
  }

  .px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .py-lg-20 {
    padding-top: 20px !important
  }

  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important
  }

  .pt-lg-20 {
    padding-top: 20px !important
  }

  .ps-lg-20 {
    padding-left: 20px !important
  }

  .pe-lg-20 {
    padding-right: 20px !important
  }

  .m-lg-25 {
    margin: 25px !important
  }

  .mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important
  }

  .my-lg-25 {
    margin-top: 25px !important
  }

  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important
  }

  .mt-lg-25 {
    margin-top: 25px !important
  }

  .ms-lg-25 {
    margin-left: 25px !important
  }

  .me-lg-25 {
    margin-right: 25px !important
  }

  .p-lg-25 {
    padding: 25px !important
  }

  .px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important
  }

  .py-lg-25 {
    padding-top: 25px !important
  }

  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important
  }

  .pt-lg-25 {
    padding-top: 25px !important
  }

  .ps-lg-25 {
    padding-left: 25px !important
  }

  .pe-lg-25 {
    padding-right: 25px !important
  }

  .m-lg-30 {
    margin: 30px !important
  }

  .mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important
  }

  .my-lg-30 {
    margin-top: 30px !important
  }

  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important
  }

  .mt-lg-30 {
    margin-top: 30px !important
  }

  .ms-lg-30 {
    margin-left: 30px !important
  }

  .me-lg-30 {
    margin-right: 30px !important
  }

  .p-lg-30 {
    padding: 30px !important
  }

  .px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important
  }

  .py-lg-30 {
    padding-top: 30px !important
  }

  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important
  }

  .pt-lg-30 {
    padding-top: 30px !important
  }

  .ps-lg-30 {
    padding-left: 30px !important
  }

  .pe-lg-30 {
    padding-right: 30px !important
  }

  .m-lg-35 {
    margin: 35px !important
  }

  .mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important
  }

  .my-lg-35 {
    margin-top: 35px !important
  }

  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important
  }

  .mt-lg-35 {
    margin-top: 35px !important
  }

  .ms-lg-35 {
    margin-left: 35px !important
  }

  .me-lg-35 {
    margin-right: 35px !important
  }

  .p-lg-35 {
    padding: 35px !important
  }

  .px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important
  }

  .py-lg-35 {
    padding-top: 35px !important
  }

  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important
  }

  .pt-lg-35 {
    padding-top: 35px !important
  }

  .ps-lg-35 {
    padding-left: 35px !important
  }

  .pe-lg-35 {
    padding-right: 35px !important
  }

  .m-lg-40 {
    margin: 40px !important
  }

  .mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .my-lg-40 {
    margin-top: 40px !important
  }

  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important
  }

  .mt-lg-40 {
    margin-top: 40px !important
  }

  .ms-lg-40 {
    margin-left: 40px !important
  }

  .me-lg-40 {
    margin-right: 40px !important
  }

  .p-lg-40 {
    padding: 40px !important
  }

  .px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .py-lg-40 {
    padding-top: 40px !important
  }

  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important
  }

  .pt-lg-40 {
    padding-top: 40px !important
  }

  .ps-lg-40 {
    padding-left: 40px !important
  }

  .pe-lg-40 {
    padding-right: 40px !important
  }

  .m-lg-45 {
    margin: 45px !important
  }

  .mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important
  }

  .my-lg-45 {
    margin-top: 45px !important
  }

  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important
  }

  .mt-lg-45 {
    margin-top: 45px !important
  }

  .ms-lg-45 {
    margin-left: 45px !important
  }

  .me-lg-45 {
    margin-right: 45px !important
  }

  .p-lg-45 {
    padding: 45px !important
  }

  .px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important
  }

  .py-lg-45 {
    padding-top: 45px !important
  }

  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important
  }

  .pt-lg-45 {
    padding-top: 45px !important
  }

  .ps-lg-45 {
    padding-left: 45px !important
  }

  .pe-lg-45 {
    padding-right: 45px !important
  }

  .m-lg-50 {
    margin: 50px !important
  }

  .mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important
  }

  .my-lg-50 {
    margin-top: 50px !important
  }

  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important
  }

  .mt-lg-50 {
    margin-top: 50px !important
  }

  .ms-lg-50 {
    margin-left: 50px !important
  }

  .me-lg-50 {
    margin-right: 50px !important
  }

  .p-lg-50 {
    padding: 50px !important
  }

  .px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  .py-lg-50 {
    padding-top: 50px !important
  }

  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important
  }

  .pt-lg-50 {
    padding-top: 50px !important
  }

  .ps-lg-50 {
    padding-left: 50px !important
  }

  .pe-lg-50 {
    padding-right: 50px !important
  }

  .m-lg-55 {
    margin: 55px !important
  }

  .mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important
  }

  .my-lg-55 {
    margin-top: 55px !important
  }

  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important
  }

  .mt-lg-55 {
    margin-top: 55px !important
  }

  .ms-lg-55 {
    margin-left: 55px !important
  }

  .me-lg-55 {
    margin-right: 55px !important
  }

  .p-lg-55 {
    padding: 55px !important
  }

  .px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important
  }

  .py-lg-55 {
    padding-top: 55px !important
  }

  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important
  }

  .pt-lg-55 {
    padding-top: 55px !important
  }

  .ps-lg-55 {
    padding-left: 55px !important
  }

  .pe-lg-55 {
    padding-right: 55px !important
  }

  .m-lg-60 {
    margin: 60px !important
  }

  .mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .my-lg-60 {
    margin-top: 60px !important
  }

  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important
  }

  .mt-lg-60 {
    margin-top: 60px !important
  }

  .ms-lg-60 {
    margin-left: 60px !important
  }

  .me-lg-60 {
    margin-right: 60px !important
  }

  .p-lg-60 {
    padding: 60px !important
  }

  .px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .py-lg-60 {
    padding-top: 60px !important
  }

  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important
  }

  .pt-lg-60 {
    padding-top: 60px !important
  }

  .ps-lg-60 {
    padding-left: 60px !important
  }

  .pe-lg-60 {
    padding-right: 60px !important
  }

  .m-lg-65 {
    margin: 65px !important
  }

  .mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important
  }

  .my-lg-65 {
    margin-top: 65px !important
  }

  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important
  }

  .mt-lg-65 {
    margin-top: 65px !important
  }

  .ms-lg-65 {
    margin-left: 65px !important
  }

  .me-lg-65 {
    margin-right: 65px !important
  }

  .p-lg-65 {
    padding: 65px !important
  }

  .px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important
  }

  .py-lg-65 {
    padding-top: 65px !important
  }

  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important
  }

  .pt-lg-65 {
    padding-top: 65px !important
  }

  .ps-lg-65 {
    padding-left: 65px !important
  }

  .pe-lg-65 {
    padding-right: 65px !important
  }

  .m-lg-70 {
    margin: 70px !important
  }

  .mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important
  }

  .my-lg-70 {
    margin-top: 70px !important
  }

  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 70px !important
  }

  .mt-lg-70 {
    margin-top: 70px !important
  }

  .ms-lg-70 {
    margin-left: 70px !important
  }

  .me-lg-70 {
    margin-right: 70px !important
  }

  .p-lg-70 {
    padding: 70px !important
  }

  .px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important
  }

  .py-lg-70 {
    padding-top: 70px !important
  }

  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 70px !important
  }

  .pt-lg-70 {
    padding-top: 70px !important
  }

  .ps-lg-70 {
    padding-left: 70px !important
  }

  .pe-lg-70 {
    padding-right: 70px !important
  }

  .m-lg-75 {
    margin: 75px !important
  }

  .mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important
  }

  .my-lg-75 {
    margin-top: 75px !important
  }

  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75px !important
  }

  .mt-lg-75 {
    margin-top: 75px !important
  }

  .ms-lg-75 {
    margin-left: 75px !important
  }

  .me-lg-75 {
    margin-right: 75px !important
  }

  .p-lg-75 {
    padding: 75px !important
  }

  .px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important
  }

  .py-lg-75 {
    padding-top: 75px !important
  }

  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75px !important
  }

  .pt-lg-75 {
    padding-top: 75px !important
  }

  .ps-lg-75 {
    padding-left: 75px !important
  }

  .pe-lg-75 {
    padding-right: 75px !important
  }

  .m-lg-80 {
    margin: 80px !important
  }

  .mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .my-lg-80 {
    margin-top: 80px !important
  }

  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important
  }

  .mt-lg-80 {
    margin-top: 80px !important
  }

  .ms-lg-80 {
    margin-left: 80px !important
  }

  .me-lg-80 {
    margin-right: 80px !important
  }

  .p-lg-80 {
    padding: 80px !important
  }

  .px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .py-lg-80 {
    padding-top: 80px !important
  }

  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important
  }

  .pt-lg-80 {
    padding-top: 80px !important
  }

  .ps-lg-80 {
    padding-left: 80px !important
  }

  .pe-lg-80 {
    padding-right: 80px !important
  }

  .m-lg-85 {
    margin: 85px !important
  }

  .mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important
  }

  .my-lg-85 {
    margin-top: 85px !important
  }

  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important
  }

  .mt-lg-85 {
    margin-top: 85px !important
  }

  .ms-lg-85 {
    margin-left: 85px !important
  }

  .me-lg-85 {
    margin-right: 85px !important
  }

  .p-lg-85 {
    padding: 85px !important
  }

  .px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important
  }

  .py-lg-85 {
    padding-top: 85px !important
  }

  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important
  }

  .pt-lg-85 {
    padding-top: 85px !important
  }

  .ps-lg-85 {
    padding-left: 85px !important
  }

  .pe-lg-85 {
    padding-right: 85px !important
  }

  .m-lg-90 {
    margin: 90px !important
  }

  .mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important
  }

  .my-lg-90 {
    margin-top: 90px !important
  }

  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important
  }

  .mt-lg-90 {
    margin-top: 90px !important
  }

  .ms-lg-90 {
    margin-left: 90px !important
  }

  .me-lg-90 {
    margin-right: 90px !important
  }

  .p-lg-90 {
    padding: 90px !important
  }

  .px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important
  }

  .py-lg-90 {
    padding-top: 90px !important
  }

  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important
  }

  .pt-lg-90 {
    padding-top: 90px !important
  }

  .ps-lg-90 {
    padding-left: 90px !important
  }

  .pe-lg-90 {
    padding-right: 90px !important
  }

  .m-lg-95 {
    margin: 95px !important
  }

  .mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important
  }

  .my-lg-95 {
    margin-top: 95px !important
  }

  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important
  }

  .mt-lg-95 {
    margin-top: 95px !important
  }

  .ms-lg-95 {
    margin-left: 95px !important
  }

  .me-lg-95 {
    margin-right: 95px !important
  }

  .p-lg-95 {
    padding: 95px !important
  }

  .px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important
  }

  .py-lg-95 {
    padding-top: 95px !important
  }

  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important
  }

  .pt-lg-95 {
    padding-top: 95px !important
  }

  .ps-lg-95 {
    padding-left: 95px !important
  }

  .pe-lg-95 {
    padding-right: 95px !important
  }

  .m-lg-100 {
    margin: 100px !important
  }

  .mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important
  }

  .my-lg-100 {
    margin-top: 100px !important
  }

  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important
  }

  .mt-lg-100 {
    margin-top: 100px !important
  }

  .ms-lg-100 {
    margin-left: 100px !important
  }

  .me-lg-100 {
    margin-right: 100px !important
  }

  .p-lg-100 {
    padding: 100px !important
  }

  .px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important
  }

  .py-lg-100 {
    padding-top: 100px !important
  }

  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important
  }

  .pt-lg-100 {
    padding-top: 100px !important
  }

  .ps-lg-100 {
    padding-left: 100px !important
  }

  .pe-lg-100 {
    padding-right: 100px !important
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mb-xl-0 {
    margin-bottom: 0 !important
  }

  .ms-xl-0 {
    margin-left: 0 !important
  }

  .me-xl-0 {
    margin-right: 0 !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pb-xl-0 {
    padding-bottom: 0 !important
  }

  .ps-xl-0 {
    padding-left: 0 !important
  }

  .pe-xl-0 {
    padding-right: 0 !important
  }

  .m-xl-5 {
    margin: 5px !important
  }

  .mx-xl-5 {
    margin-left: 5px !important;
    margin-right: 5px !important
  }

  .my-xl-5 {
    margin-top: 5px !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important
  }

  .mt-xl-5 {
    margin-top: 5px !important
  }

  .ms-xl-5 {
    margin-left: 5px !important
  }

  .me-xl-5 {
    margin-right: 5px !important
  }

  .p-xl-5 {
    padding: 5px !important
  }

  .px-xl-5 {
    padding-left: 5px !important;
    padding-right: 5px !important
  }

  .py-xl-5 {
    padding-top: 5px !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important
  }

  .pt-xl-5 {
    padding-top: 5px !important
  }

  .ps-xl-5 {
    padding-left: 5px !important
  }

  .pe-xl-5 {
    padding-right: 5px !important
  }

  .m-xl-6 {
    margin: 6px !important
  }

  .mx-xl-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .my-xl-6 {
    margin-top: 6px !important
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6px !important
  }

  .mt-xl-6 {
    margin-top: 6px !important
  }

  .ms-xl-6 {
    margin-left: 6px !important
  }

  .me-xl-6 {
    margin-right: 6px !important
  }

  .p-xl-6 {
    padding: 6px !important
  }

  .px-xl-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .py-xl-6 {
    padding-top: 6px !important
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6px !important
  }

  .pt-xl-6 {
    padding-top: 6px !important
  }

  .ps-xl-6 {
    padding-left: 6px !important
  }

  .pe-xl-6 {
    padding-right: 6px !important
  }

  .m-xl-7 {
    margin: 7px !important
  }

  .mx-xl-7 {
    margin-left: 7px !important;
    margin-right: 7px !important
  }

  .my-xl-7 {
    margin-top: 7px !important
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7px !important
  }

  .mt-xl-7 {
    margin-top: 7px !important
  }

  .ms-xl-7 {
    margin-left: 7px !important
  }

  .me-xl-7 {
    margin-right: 7px !important
  }

  .p-xl-7 {
    padding: 7px !important
  }

  .px-xl-7 {
    padding-left: 7px !important;
    padding-right: 7px !important
  }

  .py-xl-7 {
    padding-top: 7px !important
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7px !important
  }

  .pt-xl-7 {
    padding-top: 7px !important
  }

  .ps-xl-7 {
    padding-left: 7px !important
  }

  .pe-xl-7 {
    padding-right: 7px !important
  }

  .m-xl-8 {
    margin: 8px !important
  }

  .mx-xl-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .my-xl-8 {
    margin-top: 8px !important
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8px !important
  }

  .mt-xl-8 {
    margin-top: 8px !important
  }

  .ms-xl-8 {
    margin-left: 8px !important
  }

  .me-xl-8 {
    margin-right: 8px !important
  }

  .p-xl-8 {
    padding: 8px !important
  }

  .px-xl-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .py-xl-8 {
    padding-top: 8px !important
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8px !important
  }

  .pt-xl-8 {
    padding-top: 8px !important
  }

  .ps-xl-8 {
    padding-left: 8px !important
  }

  .pe-xl-8 {
    padding-right: 8px !important
  }

  .m-xl-9 {
    margin: 9px !important
  }

  .mx-xl-9 {
    margin-left: 9px !important;
    margin-right: 9px !important
  }

  .my-xl-9 {
    margin-top: 9px !important
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9px !important
  }

  .mt-xl-9 {
    margin-top: 9px !important
  }

  .ms-xl-9 {
    margin-left: 9px !important
  }

  .me-xl-9 {
    margin-right: 9px !important
  }

  .p-xl-9 {
    padding: 9px !important
  }

  .px-xl-9 {
    padding-left: 9px !important;
    padding-right: 9px !important
  }

  .py-xl-9 {
    padding-top: 9px !important
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9px !important
  }

  .pt-xl-9 {
    padding-top: 9px !important
  }

  .ps-xl-9 {
    padding-left: 9px !important
  }

  .pe-xl-9 {
    padding-right: 9px !important
  }

  .m-xl-10 {
    margin: 10px !important
  }

  .mx-xl-10 {
    margin-left: 10px !important;
    margin-right: 10px !important
  }

  .my-xl-10 {
    margin-top: 10px !important
  }

  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important
  }

  .mt-xl-10 {
    margin-top: 10px !important
  }

  .ms-xl-10 {
    margin-left: 10px !important
  }

  .me-xl-10 {
    margin-right: 10px !important
  }

  .p-xl-10 {
    padding: 10px !important
  }

  .px-xl-10 {
    padding-left: 10px !important;
    padding-right: 10px !important
  }

  .py-xl-10 {
    padding-top: 10px !important
  }

  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important
  }

  .pt-xl-10 {
    padding-top: 10px !important
  }

  .ps-xl-10 {
    padding-left: 10px !important
  }

  .pe-xl-10 {
    padding-right: 10px !important
  }

  .m-xl-11 {
    margin: 11px !important
  }

  .mx-xl-11 {
    margin-left: 11px !important;
    margin-right: 11px !important
  }

  .my-xl-11 {
    margin-top: 11px !important
  }

  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 11px !important
  }

  .mt-xl-11 {
    margin-top: 11px !important
  }

  .ms-xl-11 {
    margin-left: 11px !important
  }

  .me-xl-11 {
    margin-right: 11px !important
  }

  .p-xl-11 {
    padding: 11px !important
  }

  .px-xl-11 {
    padding-left: 11px !important;
    padding-right: 11px !important
  }

  .py-xl-11 {
    padding-top: 11px !important
  }

  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 11px !important
  }

  .pt-xl-11 {
    padding-top: 11px !important
  }

  .ps-xl-11 {
    padding-left: 11px !important
  }

  .pe-xl-11 {
    padding-right: 11px !important
  }

  .m-xl-12 {
    margin: 12px !important
  }

  .mx-xl-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .my-xl-12 {
    margin-top: 12px !important
  }

  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 12px !important
  }

  .mt-xl-12 {
    margin-top: 12px !important
  }

  .ms-xl-12 {
    margin-left: 12px !important
  }

  .me-xl-12 {
    margin-right: 12px !important
  }

  .p-xl-12 {
    padding: 12px !important
  }

  .px-xl-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .py-xl-12 {
    padding-top: 12px !important
  }

  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 12px !important
  }

  .pt-xl-12 {
    padding-top: 12px !important
  }

  .ps-xl-12 {
    padding-left: 12px !important
  }

  .pe-xl-12 {
    padding-right: 12px !important
  }

  .m-xl-13 {
    margin: 13px !important
  }

  .mx-xl-13 {
    margin-left: 13px !important;
    margin-right: 13px !important
  }

  .my-xl-13 {
    margin-top: 13px !important
  }

  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 13px !important
  }

  .mt-xl-13 {
    margin-top: 13px !important
  }

  .ms-xl-13 {
    margin-left: 13px !important
  }

  .me-xl-13 {
    margin-right: 13px !important
  }

  .p-xl-13 {
    padding: 13px !important
  }

  .px-xl-13 {
    padding-left: 13px !important;
    padding-right: 13px !important
  }

  .py-xl-13 {
    padding-top: 13px !important
  }

  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 13px !important
  }

  .pt-xl-13 {
    padding-top: 13px !important
  }

  .ps-xl-13 {
    padding-left: 13px !important
  }

  .pe-xl-13 {
    padding-right: 13px !important
  }

  .m-xl-14 {
    margin: 14px !important
  }

  .mx-xl-14 {
    margin-left: 14px !important;
    margin-right: 14px !important
  }

  .my-xl-14 {
    margin-top: 14px !important
  }

  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 14px !important
  }

  .mt-xl-14 {
    margin-top: 14px !important
  }

  .ms-xl-14 {
    margin-left: 14px !important
  }

  .me-xl-14 {
    margin-right: 14px !important
  }

  .p-xl-14 {
    padding: 14px !important
  }

  .px-xl-14 {
    padding-left: 14px !important;
    padding-right: 14px !important
  }

  .py-xl-14 {
    padding-top: 14px !important
  }

  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 14px !important
  }

  .pt-xl-14 {
    padding-top: 14px !important
  }

  .ps-xl-14 {
    padding-left: 14px !important
  }

  .pe-xl-14 {
    padding-right: 14px !important
  }

  .m-xl-15 {
    margin: 15px !important
  }

  .mx-xl-15 {
    margin-left: 15px !important;
    margin-right: 15px !important
  }

  .my-xl-15 {
    margin-top: 15px !important
  }

  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important
  }

  .mt-xl-15 {
    margin-top: 15px !important
  }

  .ms-xl-15 {
    margin-left: 15px !important
  }

  .me-xl-15 {
    margin-right: 15px !important
  }

  .p-xl-15 {
    padding: 15px !important
  }

  .px-xl-15 {
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .py-xl-15 {
    padding-top: 15px !important
  }

  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important
  }

  .pt-xl-15 {
    padding-top: 15px !important
  }

  .ps-xl-15 {
    padding-left: 15px !important
  }

  .pe-xl-15 {
    padding-right: 15px !important
  }

  .m-xl-16 {
    margin: 16px !important
  }

  .mx-xl-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .my-xl-16 {
    margin-top: 16px !important
  }

  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 16px !important
  }

  .mt-xl-16 {
    margin-top: 16px !important
  }

  .ms-xl-16 {
    margin-left: 16px !important
  }

  .me-xl-16 {
    margin-right: 16px !important
  }

  .p-xl-16 {
    padding: 16px !important
  }

  .px-xl-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .py-xl-16 {
    padding-top: 16px !important
  }

  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 16px !important
  }

  .pt-xl-16 {
    padding-top: 16px !important
  }

  .ps-xl-16 {
    padding-left: 16px !important
  }

  .pe-xl-16 {
    padding-right: 16px !important
  }

  .m-xl-17 {
    margin: 17px !important
  }

  .mx-xl-17 {
    margin-left: 17px !important;
    margin-right: 17px !important
  }

  .my-xl-17 {
    margin-top: 17px !important
  }

  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 17px !important
  }

  .mt-xl-17 {
    margin-top: 17px !important
  }

  .ms-xl-17 {
    margin-left: 17px !important
  }

  .me-xl-17 {
    margin-right: 17px !important
  }

  .p-xl-17 {
    padding: 17px !important
  }

  .px-xl-17 {
    padding-left: 17px !important;
    padding-right: 17px !important
  }

  .py-xl-17 {
    padding-top: 17px !important
  }

  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 17px !important
  }

  .pt-xl-17 {
    padding-top: 17px !important
  }

  .ps-xl-17 {
    padding-left: 17px !important
  }

  .pe-xl-17 {
    padding-right: 17px !important
  }

  .m-xl-18 {
    margin: 18px !important
  }

  .mx-xl-18 {
    margin-left: 18px !important;
    margin-right: 18px !important
  }

  .my-xl-18 {
    margin-top: 18px !important
  }

  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 18px !important
  }

  .mt-xl-18 {
    margin-top: 18px !important
  }

  .ms-xl-18 {
    margin-left: 18px !important
  }

  .me-xl-18 {
    margin-right: 18px !important
  }

  .p-xl-18 {
    padding: 18px !important
  }

  .px-xl-18 {
    padding-left: 18px !important;
    padding-right: 18px !important
  }

  .py-xl-18 {
    padding-top: 18px !important
  }

  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 18px !important
  }

  .pt-xl-18 {
    padding-top: 18px !important
  }

  .ps-xl-18 {
    padding-left: 18px !important
  }

  .pe-xl-18 {
    padding-right: 18px !important
  }

  .m-xl-19 {
    margin: 19px !important
  }

  .mx-xl-19 {
    margin-left: 19px !important;
    margin-right: 19px !important
  }

  .my-xl-19 {
    margin-top: 19px !important
  }

  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 19px !important
  }

  .mt-xl-19 {
    margin-top: 19px !important
  }

  .ms-xl-19 {
    margin-left: 19px !important
  }

  .me-xl-19 {
    margin-right: 19px !important
  }

  .p-xl-19 {
    padding: 19px !important
  }

  .px-xl-19 {
    padding-left: 19px !important;
    padding-right: 19px !important
  }

  .py-xl-19 {
    padding-top: 19px !important
  }

  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 19px !important
  }

  .pt-xl-19 {
    padding-top: 19px !important
  }

  .ps-xl-19 {
    padding-left: 19px !important
  }

  .pe-xl-19 {
    padding-right: 19px !important
  }

  .m-xl-20 {
    margin: 20px !important
  }

  .mx-xl-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .my-xl-20 {
    margin-top: 20px !important
  }

  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important
  }

  .mt-xl-20 {
    margin-top: 20px !important
  }

  .ms-xl-20 {
    margin-left: 20px !important
  }

  .me-xl-20 {
    margin-right: 20px !important
  }

  .p-xl-20 {
    padding: 20px !important
  }

  .px-xl-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .py-xl-20 {
    padding-top: 20px !important
  }

  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important
  }

  .pt-xl-20 {
    padding-top: 20px !important
  }

  .ps-xl-20 {
    padding-left: 20px !important
  }

  .pe-xl-20 {
    padding-right: 20px !important
  }

  .m-xl-25 {
    margin: 25px !important
  }

  .mx-xl-25 {
    margin-left: 25px !important;
    margin-right: 25px !important
  }

  .my-xl-25 {
    margin-top: 25px !important
  }

  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important
  }

  .mt-xl-25 {
    margin-top: 25px !important
  }

  .ms-xl-25 {
    margin-left: 25px !important
  }

  .me-xl-25 {
    margin-right: 25px !important
  }

  .p-xl-25 {
    padding: 25px !important
  }

  .px-xl-25 {
    padding-left: 25px !important;
    padding-right: 25px !important
  }

  .py-xl-25 {
    padding-top: 25px !important
  }

  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important
  }

  .pt-xl-25 {
    padding-top: 25px !important
  }

  .ps-xl-25 {
    padding-left: 25px !important
  }

  .pe-xl-25 {
    padding-right: 25px !important
  }

  .m-xl-30 {
    margin: 30px !important
  }

  .mx-xl-30 {
    margin-left: 30px !important;
    margin-right: 30px !important
  }

  .my-xl-30 {
    margin-top: 30px !important
  }

  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important
  }

  .mt-xl-30 {
    margin-top: 30px !important
  }

  .ms-xl-30 {
    margin-left: 30px !important
  }

  .me-xl-30 {
    margin-right: 30px !important
  }

  .p-xl-30 {
    padding: 30px !important
  }

  .px-xl-30 {
    padding-left: 30px !important;
    padding-right: 30px !important
  }

  .py-xl-30 {
    padding-top: 30px !important
  }

  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important
  }

  .pt-xl-30 {
    padding-top: 30px !important
  }

  .ps-xl-30 {
    padding-left: 30px !important
  }

  .pe-xl-30 {
    padding-right: 30px !important
  }

  .m-xl-35 {
    margin: 35px !important
  }

  .mx-xl-35 {
    margin-left: 35px !important;
    margin-right: 35px !important
  }

  .my-xl-35 {
    margin-top: 35px !important
  }

  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important
  }

  .mt-xl-35 {
    margin-top: 35px !important
  }

  .ms-xl-35 {
    margin-left: 35px !important
  }

  .me-xl-35 {
    margin-right: 35px !important
  }

  .p-xl-35 {
    padding: 35px !important
  }

  .px-xl-35 {
    padding-left: 35px !important;
    padding-right: 35px !important
  }

  .py-xl-35 {
    padding-top: 35px !important
  }

  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important
  }

  .pt-xl-35 {
    padding-top: 35px !important
  }

  .ps-xl-35 {
    padding-left: 35px !important
  }

  .pe-xl-35 {
    padding-right: 35px !important
  }

  .m-xl-40 {
    margin: 40px !important
  }

  .mx-xl-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .my-xl-40 {
    margin-top: 40px !important
  }

  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important
  }

  .mt-xl-40 {
    margin-top: 40px !important
  }

  .ms-xl-40 {
    margin-left: 40px !important
  }

  .me-xl-40 {
    margin-right: 40px !important
  }

  .p-xl-40 {
    padding: 40px !important
  }

  .px-xl-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .py-xl-40 {
    padding-top: 40px !important
  }

  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important
  }

  .pt-xl-40 {
    padding-top: 40px !important
  }

  .ps-xl-40 {
    padding-left: 40px !important
  }

  .pe-xl-40 {
    padding-right: 40px !important
  }

  .m-xl-45 {
    margin: 45px !important
  }

  .mx-xl-45 {
    margin-left: 45px !important;
    margin-right: 45px !important
  }

  .my-xl-45 {
    margin-top: 45px !important
  }

  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important
  }

  .mt-xl-45 {
    margin-top: 45px !important
  }

  .ms-xl-45 {
    margin-left: 45px !important
  }

  .me-xl-45 {
    margin-right: 45px !important
  }

  .p-xl-45 {
    padding: 45px !important
  }

  .px-xl-45 {
    padding-left: 45px !important;
    padding-right: 45px !important
  }

  .py-xl-45 {
    padding-top: 45px !important
  }

  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important
  }

  .pt-xl-45 {
    padding-top: 45px !important
  }

  .ps-xl-45 {
    padding-left: 45px !important
  }

  .pe-xl-45 {
    padding-right: 45px !important
  }

  .m-xl-50 {
    margin: 50px !important
  }

  .mx-xl-50 {
    margin-left: 50px !important;
    margin-right: 50px !important
  }

  .my-xl-50 {
    margin-top: 50px !important
  }

  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important
  }

  .mt-xl-50 {
    margin-top: 50px !important
  }

  .ms-xl-50 {
    margin-left: 50px !important
  }

  .me-xl-50 {
    margin-right: 50px !important
  }

  .p-xl-50 {
    padding: 50px !important
  }

  .px-xl-50 {
    padding-left: 50px !important;
    padding-right: 50px !important
  }

  .py-xl-50 {
    padding-top: 50px !important
  }

  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important
  }

  .pt-xl-50 {
    padding-top: 50px !important
  }

  .ps-xl-50 {
    padding-left: 50px !important
  }

  .pe-xl-50 {
    padding-right: 50px !important
  }

  .m-xl-55 {
    margin: 55px !important
  }

  .mx-xl-55 {
    margin-left: 55px !important;
    margin-right: 55px !important
  }

  .my-xl-55 {
    margin-top: 55px !important
  }

  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important
  }

  .mt-xl-55 {
    margin-top: 55px !important
  }

  .ms-xl-55 {
    margin-left: 55px !important
  }

  .me-xl-55 {
    margin-right: 55px !important
  }

  .p-xl-55 {
    padding: 55px !important
  }

  .px-xl-55 {
    padding-left: 55px !important;
    padding-right: 55px !important
  }

  .py-xl-55 {
    padding-top: 55px !important
  }

  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important
  }

  .pt-xl-55 {
    padding-top: 55px !important
  }

  .ps-xl-55 {
    padding-left: 55px !important
  }

  .pe-xl-55 {
    padding-right: 55px !important
  }

  .m-xl-60 {
    margin: 60px !important
  }

  .mx-xl-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .my-xl-60 {
    margin-top: 60px !important
  }

  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important
  }

  .mt-xl-60 {
    margin-top: 60px !important
  }

  .ms-xl-60 {
    margin-left: 60px !important
  }

  .me-xl-60 {
    margin-right: 60px !important
  }

  .p-xl-60 {
    padding: 60px !important
  }

  .px-xl-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .py-xl-60 {
    padding-top: 60px !important
  }

  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important
  }

  .pt-xl-60 {
    padding-top: 60px !important
  }

  .ps-xl-60 {
    padding-left: 60px !important
  }

  .pe-xl-60 {
    padding-right: 60px !important
  }

  .m-xl-65 {
    margin: 65px !important
  }

  .mx-xl-65 {
    margin-left: 65px !important;
    margin-right: 65px !important
  }

  .my-xl-65 {
    margin-top: 65px !important
  }

  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important
  }

  .mt-xl-65 {
    margin-top: 65px !important
  }

  .ms-xl-65 {
    margin-left: 65px !important
  }

  .me-xl-65 {
    margin-right: 65px !important
  }

  .p-xl-65 {
    padding: 65px !important
  }

  .px-xl-65 {
    padding-left: 65px !important;
    padding-right: 65px !important
  }

  .py-xl-65 {
    padding-top: 65px !important
  }

  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important
  }

  .pt-xl-65 {
    padding-top: 65px !important
  }

  .ps-xl-65 {
    padding-left: 65px !important
  }

  .pe-xl-65 {
    padding-right: 65px !important
  }

  .m-xl-70 {
    margin: 70px !important
  }

  .mx-xl-70 {
    margin-left: 70px !important;
    margin-right: 70px !important
  }

  .my-xl-70 {
    margin-top: 70px !important
  }

  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 70px !important
  }

  .mt-xl-70 {
    margin-top: 70px !important
  }

  .ms-xl-70 {
    margin-left: 70px !important
  }

  .me-xl-70 {
    margin-right: 70px !important
  }

  .p-xl-70 {
    padding: 70px !important
  }

  .px-xl-70 {
    padding-left: 70px !important;
    padding-right: 70px !important
  }

  .py-xl-70 {
    padding-top: 70px !important
  }

  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 70px !important
  }

  .pt-xl-70 {
    padding-top: 70px !important
  }

  .ps-xl-70 {
    padding-left: 70px !important
  }

  .pe-xl-70 {
    padding-right: 70px !important
  }

  .m-xl-75 {
    margin: 75px !important
  }

  .mx-xl-75 {
    margin-left: 75px !important;
    margin-right: 75px !important
  }

  .my-xl-75 {
    margin-top: 75px !important
  }

  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75px !important
  }

  .mt-xl-75 {
    margin-top: 75px !important
  }

  .ms-xl-75 {
    margin-left: 75px !important
  }

  .me-xl-75 {
    margin-right: 75px !important
  }

  .p-xl-75 {
    padding: 75px !important
  }

  .px-xl-75 {
    padding-left: 75px !important;
    padding-right: 75px !important
  }

  .py-xl-75 {
    padding-top: 75px !important
  }

  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75px !important
  }

  .pt-xl-75 {
    padding-top: 75px !important
  }

  .ps-xl-75 {
    padding-left: 75px !important
  }

  .pe-xl-75 {
    padding-right: 75px !important
  }

  .m-xl-80 {
    margin: 80px !important
  }

  .mx-xl-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .my-xl-80 {
    margin-top: 80px !important
  }

  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important
  }

  .mt-xl-80 {
    margin-top: 80px !important
  }

  .ms-xl-80 {
    margin-left: 80px !important
  }

  .me-xl-80 {
    margin-right: 80px !important
  }

  .p-xl-80 {
    padding: 80px !important
  }

  .px-xl-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .py-xl-80 {
    padding-top: 80px !important
  }

  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important
  }

  .pt-xl-80 {
    padding-top: 80px !important
  }

  .ps-xl-80 {
    padding-left: 80px !important
  }

  .pe-xl-80 {
    padding-right: 80px !important
  }

  .m-xl-85 {
    margin: 85px !important
  }

  .mx-xl-85 {
    margin-left: 85px !important;
    margin-right: 85px !important
  }

  .my-xl-85 {
    margin-top: 85px !important
  }

  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important
  }

  .mt-xl-85 {
    margin-top: 85px !important
  }

  .ms-xl-85 {
    margin-left: 85px !important
  }

  .me-xl-85 {
    margin-right: 85px !important
  }

  .p-xl-85 {
    padding: 85px !important
  }

  .px-xl-85 {
    padding-left: 85px !important;
    padding-right: 85px !important
  }

  .py-xl-85 {
    padding-top: 85px !important
  }

  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important
  }

  .pt-xl-85 {
    padding-top: 85px !important
  }

  .ps-xl-85 {
    padding-left: 85px !important
  }

  .pe-xl-85 {
    padding-right: 85px !important
  }

  .m-xl-90 {
    margin: 90px !important
  }

  .mx-xl-90 {
    margin-left: 90px !important;
    margin-right: 90px !important
  }

  .my-xl-90 {
    margin-top: 90px !important
  }

  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important
  }

  .mt-xl-90 {
    margin-top: 90px !important
  }

  .ms-xl-90 {
    margin-left: 90px !important
  }

  .me-xl-90 {
    margin-right: 90px !important
  }

  .p-xl-90 {
    padding: 90px !important
  }

  .px-xl-90 {
    padding-left: 90px !important;
    padding-right: 90px !important
  }

  .py-xl-90 {
    padding-top: 90px !important
  }

  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important
  }

  .pt-xl-90 {
    padding-top: 90px !important
  }

  .ps-xl-90 {
    padding-left: 90px !important
  }

  .pe-xl-90 {
    padding-right: 90px !important
  }

  .m-xl-95 {
    margin: 95px !important
  }

  .mx-xl-95 {
    margin-left: 95px !important;
    margin-right: 95px !important
  }

  .my-xl-95 {
    margin-top: 95px !important
  }

  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important
  }

  .mt-xl-95 {
    margin-top: 95px !important
  }

  .ms-xl-95 {
    margin-left: 95px !important
  }

  .me-xl-95 {
    margin-right: 95px !important
  }

  .p-xl-95 {
    padding: 95px !important
  }

  .px-xl-95 {
    padding-left: 95px !important;
    padding-right: 95px !important
  }

  .py-xl-95 {
    padding-top: 95px !important
  }

  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important
  }

  .pt-xl-95 {
    padding-top: 95px !important
  }

  .ps-xl-95 {
    padding-left: 95px !important
  }

  .pe-xl-95 {
    padding-right: 95px !important
  }

  .m-xl-100 {
    margin: 100px !important
  }

  .mx-xl-100 {
    margin-left: 100px !important;
    margin-right: 100px !important
  }

  .my-xl-100 {
    margin-top: 100px !important
  }

  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important
  }

  .mt-xl-100 {
    margin-top: 100px !important
  }

  .ms-xl-100 {
    margin-left: 100px !important
  }

  .me-xl-100 {
    margin-right: 100px !important
  }

  .p-xl-100 {
    padding: 100px !important
  }

  .px-xl-100 {
    padding-left: 100px !important;
    padding-right: 100px !important
  }

  .py-xl-100 {
    padding-top: 100px !important
  }

  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important
  }

  .pt-xl-100 {
    padding-top: 100px !important
  }

  .ps-xl-100 {
    padding-left: 100px !important
  }

  .pe-xl-100 {
    padding-right: 100px !important
  }
}

body,
html {
  height: auto
}

body {
  overflow: visible;
  overflow-x: hidden;
}

body::selection {
  background: #000;
  color: #fff
}

body::-moz-selection {
  background: #000;
  color: #fff
}

.swiper-slide:not(:first-child) {
  display: block
}

html.overflow-menu,
html.overflow-menu body {
  overflow: hidden
}

.bg {
  background: 50%/cover no-repeat;
  height: 100%;
  width: 100%
}

.bg,
.bg:after {
  left: 0;
  position: absolute;
  top: 0
}

.bg:after {
  bottom: 0;
  content: "";
  right: 0
}

.bg-white {
  background-color: #fff
}

.schedule-header,
.schedule-row {
  align-items: center;
  display: flex;
  padding: 12px 20px;
  position: relative
}

.schedule-header>:first-child,
.schedule-row>:first-child {
  display: inline-block;
  flex: 0 0 35%;
  max-width: 35%
}

.schedule-header {
  background-color: #f5f5f5;
  border-radius: 3px
}

.schedule-header .text-sm {
  color: #323232;
  font-weight: 500
}

.schedule-row {
  border-bottom: 1px solid #e9e9e9
}

.schedule-row:before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 12px;
  margin-right: 10px;
  margin-top: -2px;
  position: relative;
  vertical-align: middle;
  width: 12px
}

.schedule-row .text:not(.light) {
  color: #323232
}

.schedule-row .text-medium {
  margin-right: 10px
}

.schedule-row .btn {
  margin-left: auto
}

.schedule-row.still-have:before {
  background-color: #d5ecbd
}

.schedule-row.have-a-lot:before {
  background-color: #ffe4b8
}

.schedule-row.no-have:before {
  background-color: #e9e9e9
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px
}

.gallery .img {
  border-radius: 5px;
  margin-right: 15px;
  width: calc(20% - 15px)
}

.pagination {
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  padding: 15px 0
}

.pagination .btn-read-more:not(.left-arrow) {
  margin-left: auto
}

.pagination .btn-read-more.left-arrow {
  margin-right: auto
}

.pagination .text {
  display: inline-block;
  margin-right: 22px;
  position: relative;
  vertical-align: middle
}

.pagination .text:last-child {
  margin-right: 0
}

.pagination a.text:after,
.pagination span.text:after {
  wil-change: transform;
  background-color: #2ab0cd;
  bottom: -16px;
  content: "";
  height: 2px;
  left: -8px;
  position: absolute;
  right: -8px;
  transform: scaleX(0)
}

.pagination a.text.active,
.pagination span.text.active {
  color: #323232;
  font-weight: 500
}

.pagination a.text.active:after,
.pagination span.text.active:after {
  transform: none
}

.map-route-wrap {
  border-radius: 10px;
  overflow: hidden;
  padding-top: 55.027%;
  position: relative
}

.map-route-wrap form {
  display: flex;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 66.66%
}

.map-route-wrap form label {
  margin-bottom: 0;
  margin-right: 16px;
  width: calc(50% - 8px)
}


.map-route-wrap form label:first-child input {
  padding-right: 40px
}

.map-route-wrap form label:last-child {
  margin-right: 0
}



.map-route-wrap form label:after {
  background: 50%/contain no-repeat;
  content: "";
  display: block;
  height: 20px;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  right: 14px;
  top: 14px;
  width: 20px
}

.attention {
  color: #323232;
  position: relative
}

.attention:after {
  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
  width: 16px
}

.icon {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  height: 40px;
  width: 40px
}

.share {
  display: inline-block;
  margin-left: 5%;
  position: relative;
  vertical-align: middle;
  z-index: 71
}

.share.dropdown {
  padding: 0
}

.share .icon,
.share>span {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.share>span {
  color: #323232;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 15px
}



.share .dropdown-btn {
  border: none;
  border-radius: 50%;
  height: auto;
  margin: 0;
  top: 0;
  transform: none;
  width: auto
}







.lightbox-wrap .zoom img {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.lightbox-wrap .swiper-slide .zoom {
  border-radius: 3px;
  padding-top: 100%;
  width: 100%
}

.lightbox-wrap.gallery .zoom {
  padding-top: calc(20% - 15px)
}

.padding-top-none {
  padding-top: 0 !important
}

@media (min-width: 1200px) {
  .banner .btn-close:hover {
    border-color: #1c1f26
  }

  .pagination a.text:hover:after {
    transform: none
  }

  .share .dropdown-btn:hover+.icon {
    border-color: #2ab0cd
  }
}

@media (max-width: 1500px) {
  .banner .main-banner .banner-align {
    padding: 150px 0 180px
  }

  .banner-align.size-2 {
    height: 460px
  }

  .section-top-170 {
    margin-top: 140px
  }
}

@media (max-width: 1366px) {
  .share {
    margin-left: 3%
  }
}

@media (max-width: 1199px) {
  .border-right-pad:after {
    background-color: #e9e9e9;
    bottom: 15px;
    content: "";
    position: absolute;
    right: 0;
    top: -145px;
    width: 1px
  }

  .tour-content .h1:not(.autoheight) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    min-height: 78px;
    overflow: hidden;
    padding-right: 42%;
    text-overflow: ellipsis
  }

  .tour-content .right-sidebar {
    margin-top: -145px;
    padding-left: 15px
  }
}

@media (max-width: 991px) {
  .banner .opacity.top-to-bottom {
    display: none
  }

  .banner.main-banner .banner-align {
    padding-top: 100px;
    vertical-align: middle
  }

  .banner.banner-detail .banner-align {
    padding-bottom: 30px
  }

  .section-top-170 {
    margin-top: 120px
  }

  .banner-align .size-2 {
    height: 360px
  }

  .banner-info .title br {
    display: none
  }
}

@media (max-width: 767px) {
  .banner.main-banner .banner-align {
    padding: 60px 0
  }

  .banner.main-banner .banner-info>* {
    margin-bottom: 40px
  }

  .home-page .banner.main-banner .banner-align {
    height: 400px;
    padding: 90px 0 30px
  }

  .home-page .section-top-170 {
    margin-top: 40px
  }

  .section-top-170 {
    margin-top: 90px
  }

  .banner-align.size-2 {
    height: 240px
  }

  .banner-align.banner-detail {
    margin-bottom: 30px
  }

  .banner-align.banner-detail .banner-align {
    padding-bottom: 20px
  }

  .pagination {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 15px 0
  }

  .pagination .btn-read-more {
    margin-bottom: -80px
  }

  .pagination .btn-read-more:not(.left-arrow) {
    margin-left: auto;
    margin-right: 0;
    order: 3
  }

  .pagination .btn-read-more.left-arrow {
    margin-left: 0;
    margin-right: auto;
    order: 2
  }

  .pagination .text {
    display: inline-block;
    margin-right: 22px;
    position: relative;
    vertical-align: middle
  }

  .pagination .text:last-child {
    margin-right: 0
  }

  .pagination>div {
    order: 1;
    text-align: center;
    width: 100%
  }

  .pagination a.text:after,
  .pagination span.text:after {
    wil-change: transform;
    background-color: #2ab0cd;
    bottom: -16px;
    content: "";
    height: 2px;
    left: -8px;
    position: absolute;
    right: -8px;
    transform: scaleX(0)
  }

  .pagination a.text.active,
  .pagination span.text.active {
    color: #323232;
    font-weight: 500
  }

  .pagination a.text.active:after,
  .pagination span.text.active:after {
    transform: none
  }

  .map-route-wrap {
    border-radius: 0;
    margin: 0 -15px;
    padding-top: 350px
  }

  .map-route-wrap form {
    flex-wrap: wrap;
    left: 15px;
    right: 15px;
    top: 15px;
    width: auto
  }

  .map-route-wrap form label {
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
    width: 100%
  }

  .map-route-wrap form label:last-child {
    margin-bottom: 0
  }

  .tour-content .h1 {
    display: block
  }

  .tour-content .h1:not(.autoheight) {
    min-height: auto;
    padding-right: 0
  }

  .tour-content .right-sidebar {
    margin-top: 0;
    padding-left: 0
  }

  .tour-content .thumb-price {
    margin-top: 10px
  }
}

.ui-helper-hidden {
  display: none
}

.ui-helper-hidden-accessible {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.ui-helper-reset {
  border: 0;
  font-size: 100%;
  line-height: 1.3;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none
}

.ui-helper-clearfix:after,
.ui-helper-clearfix:before {
  border-collapse: collapse;
  content: "";
  display: table
}

.ui-helper-clearfix:after {
  clear: both
}

.ui-helper-zfix {
  filter: Alpha(Opacity=0);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.ui-front {
  z-index: 101
}

.ui-front.no-shadow:before {
  box-shadow: none
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none
}

.ui-icon {
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: -.25em;
  overflow: hidden;
  position: relative;
  text-indent: -99999px;
  vertical-align: middle
}

.ui-widget-icon-block {
  display: block;
  left: 50%;
  margin-left: -8px
}

.ui-widget-overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%
}

.ui-autocomplete {
  cursor: default;
  left: 0;
  position: absolute;
  top: 0
}

.ui-menu {
  box-sizing: border-box;
  display: block;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0
}

.ui-menu::-webkit-scrollbar {
  height: 4px;
  width: 4px
}

.ui-menu::-webkit-scrollbar-track {
  background-color: transparent
}

.ui-menu::-webkit-scrollbar-thumb {
  background-color: #7ebd3e
}

.ui-menu:after,
.ui-menu:before {
  border-radius: 0 0 10px 10px;
  content: "";
  left: -5px;
  position: absolute;
  right: -5px;
  z-index: -1
}

.ui-menu:before {
  background-color: #fff;
  bottom: -5px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
  top: 0
}

.ui-menu:after {
  border: 5px solid #fff;
  border-top: none;
  height: 20px;
  top: -15px
}

.ui-menu .ui-menu {
  position: absolute
}

.ui-menu .ui-menu-item {
  box-sizing: border-box;
  cursor: pointer;
  line-height: 20px;
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  margin: 0;
  max-width: 100%;
  padding-bottom: 12px;
  width: 100%
}

.ui-menu .ui-menu-item:first-child {
  padding-top: 12px
}

.ui-menu .ui-menu-item-wrapper {
  box-sizing: border-box;
  line-height: 20px;
  max-width: 100%;
  padding: 3px .4em;
  position: relative
}

.ui-menu .ui-menu-item-wrapper img,
.ui-menu .ui-menu-item-wrapper span {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle
}

.ui-menu .ui-menu-item-wrapper img {
  border-radius: 4px;
  overflow: hidden
}

.ui-menu .ui-menu-item-wrapper span {
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px
}

.ui-menu .ui-menu-item-all {
  color: #7ebd3e;
  cursor: pointer;
  padding: 12px 0;
  position: relative;
  text-align: center
}

.ui-menu .ui-menu-item-all:before {
  background-color: #e9e9e9;
  content: "";
  height: 1px;
  left: -5px;
  position: absolute;
  right: -5px;
  top: 0
}

.ui-menu .ui-menu-divider {
  border-width: 1px 0 0;
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 5px 0
}

.ui-menu .ui-icon {
  bottom: 0;
  left: .2em;
  margin: auto 0;
  position: absolute;
  top: 0
}

.ui-menu .ui-menu-icon {
  left: auto;
  right: 0
}

.ui-menu-icons {
  position: relative
}

.ui-menu-icons .ui-menu-item-wrapper {
  padding-left: 2em
}

.ui-slider {
  margin: 10px 7px 14px;
  position: relative;
  text-align: left
}

.ui-slider .ui-slider-handle {
  background-color: #fff;
  border: 4px solid #2ab0cd;
  border-radius: 50%;
  cursor: grab;
  height: 16px;
  position: absolute;
  touch-action: none;
  width: 16px;
  z-index: 2
}

.ui-slider .ui-slider-handle:before {
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0)
}

.ui-slider .ui-slider-handle:active {
  cursor: grabbing
}

.ui-slider .ui-slider-range {
  background-color: #2ab0cd;
  background-position: 0 0;
  border: 0;
  display: block;
  font-size: .7em;
  position: absolute;
  z-index: 1
}

.ui-slider.ui-slider-horizontal {
  height: 2px
}

.ui-slider.ui-slider-horizontal .ui-slider-handle {
  margin-left: -8px;
  top: -7px
}

.ui-slider.ui-slider-horizontal .ui-slider-range {
  height: 100%;
  top: 0
}

.ui-slider.ui-slider-horizontal .ui-slider-range-min {
  left: 0
}

.ui-slider.ui-slider-horizontal .ui-slider-range-max {
  right: 0
}

.ui-slider.ui-slider-vertical {
  height: 100px;
  width: .8em
}

.ui-slider.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-bottom: -.6em;
  margin-left: 0
}

.ui-slider.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%
}

.ui-slider.ui-slider-vertical .ui-slider-range-min {
  bottom: 0
}

.ui-slider.ui-slider-vertical .ui-slider-range-max {
  top: 0
}

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit
}

@media (min-width: 1200px) {
  .ui-menu-item-wrapper:hover span {
    color: #7ebd3e
  }

  .ui-menu-item-all:hover {
    color: #2ab0cd
  }

  .ui-slider .ui-slider-handle:hover:before {
    transform: scale(4)
  }
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-size: 14px;
  line-height: 24px
}

input,
input::placeholder,
label i,
textarea,
textarea::placeholder {
  font-size: 14px;
  line-height: 24px
}

input,
label,
textarea {
  border-radius: 5px
}

input,
textarea {
  color: #323232
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #626262
}

input::placeholder,
label i,
textarea::placeholder {
  color: #626262
}

input::-ms-clear,
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration,
select::-ms-expand {
  display: none;
  height: 0;
  width: 0
}

input,
select,
textarea {
  -webkit-appearance: none;
  width: 100%
}

.datepicker-input,
.timepicker-input,
label,
select {
  margin-bottom: 20px
}

label {
  background: #fff;
  display: block;
  position: relative
}

label:after,
label:before {
  background-color: #cf2020;
  display: none;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: scale(0);
  z-index: 3
}

label:before {
  bottom: calc(100% - 13px);
  content: "";
  height: 10px;
  right: 18px;
  transform: rotate(45deg);
  width: 10px
}

label:after {
  border-radius: 3px;
  bottom: calc(100% - 8px);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 12px;
  left: calc(100% - 23px);
  line-height: 22px;
  padding: 4px 10px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 70
}

label i {
  left: 10px;
  padding: 5px;
  position: absolute;
  top: 8px;
  transform-origin: top left;
  will-change: transform
}

label i:before {
  background-color: #fff;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(50% - 4px);
  z-index: -1
}

label:not(.active) i {
  cursor: text
}

label.active i,
label.filled i {
  transform: translate3d(2px, -20px, 0) scale(.7)
}


label.invalid:after,
label.invalid:before {
  display: block
}

label.invalid input,
label.invalid textarea,
select.invalid,
select.invalid .SumoSelect {
  border-color: #cf2020
}

label .smile {

  bottom: 12px;
  content: "";
  height: 18px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  width: 18px
}

label .tooltip-wrap.light {
  margin-top: -8px;
  position: absolute;
  right: 15px;
  top: 50%
}

input,
textarea {
  border: 1px solid #e9e9e9;
  display: block;
  padding: 12px 15px 10px
}

input[type=number] {
  -moz-appearance: textfield
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}

input[type=file],
input[type=submit] {
  border: none;
  cursor: pointer;
  font-size: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

input {
  height: 48px;
  z-index: 2
}

textarea {
  height: 108px;
  line-height: 25px;
  overflow: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  resize: none
}

.datepicker-input,
.timepicker-input {
  padding-left: 30px;
  position: relative
}

.datepicker-input:before,
.timepicker-input:before {
  background: 50%/contain no-repeat;

  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 18px
}

.tour-content .datepicker-input:after {
  border-bottom: 1px solid #626262;
  border-right: 1px solid #626262;
  content: "";
  height: 6px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: rotate(45deg);
  width: 6px
}

.datepicker-input span,
.datepicker-input:after,
.datepicker-input:before {
  z-index: 3
}



.datepicker-input.active {
  z-index: 4
}

.datepicker-input.active:after {
  border-color: #2ab0cd;
  transform: rotate(225deg) translate3d(-4px, -2px, 0)
}

.datepicker-input.active .datepicker-toggle {
  opacity: 1;
  transform: none;
  visibility: visible
}

.datepicker-placeholder span {
  color: #626262;
  display: block;
  font-size: 14px;
  line-height: 25px
}

.datepicker-toggle {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
  left: -4px;
  opacity: 0;
  padding: 52px 4px 4px;
  position: absolute;
  right: -4px;
  top: -4px;
  transform: translate3d(0, 15px, 0);
  visibility: hidden;
  will-change: transform;
  z-index: 2
}

.double-datepicker .datepicker-input {
  padding-left: 0
}

.double-datepicker .datepicker-input:before {
  left: 15px;
  z-index: 4
}

.double-datepicker .datepicker-input.date-departure {
  margin-bottom: 0
}



.double-datepicker .datepicker-input.date-departure .datepicker-placeholder {
  border-bottom-color: transparent;
  border-radius: 5px 5px 0 0
}

.double-datepicker .datepicker-input.date-departure.active {
  z-index: 5
}

.double-datepicker .datepicker-input.date-departure.active .datepicker-placeholder {
  border-bottom-color: #e9e9e9
}


.double-datepicker .datepicker-input.date-arrival .datepicker-placeholder {
  border-radius: 0 0 5px 5px
}

.double-datepicker .datepicker-input.active {
  z-index: 71
}

.double-datepicker .datepicker-placeholder {
  padding-left: 45px
}

.range {
  margin: 20px 0
}

.range label {
  color: #323232;
  display: block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .02em;
  line-height: 24px;
  margin-bottom: 5px;
  width: 100%
}

.range input {
  border: none;
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  width: 0;
  z-index: -1
}

.range .text {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.range .text span {
  color: #626262;
  margin-bottom: 0
}

.form.row,
form.row {
  margin: 0 -10px
}

.form.row>div,
form.row>div {
  padding: 0 10px
}

.download,
.download:before {
  display: inline-block;
  position: relative
}

.download:before {

  content: "";
  height: 26px;
  margin-right: 5px;
  vertical-align: middle;
  width: 26px
}

.download .text-md {
  color: #323232;
  line-height: 20px
}

.add-file,
.add-file:before {
  display: inline-block;
  position: relative
}

.add-file:before {

  content: "";
  height: 18px;
  left: 0;
  margin-right: 5px;
  opacity: 1;
  right: auto;
  transform: none;
  vertical-align: middle;
  width: 18px
}

.add-file span {
  color: #323232
}

.add-file input {
  bottom: 0;
  left: 0;
  opacity: 0;
  padding: 0;
  right: 0;
  top: 0
}

.number-input {
  align-items: center;
  display: flex
}

.number-input .number-input-btns,
.number-input .text-sm,
.number-input button,
.number-input input {
  display: inline-block;
  vertical-align: middle
}

.number-input button,
.number-input input {
  margin-top: -4px
}

.number-input .text-sm {
  color: #323232;
  margin-right: 15px
}

.number-input .number-input-btns {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  height: 48px;
  margin-bottom: 0;
  padding: 8px 5px 6px;
  z-index: 2
}

.number-input input {
  border: none;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  padding: 0;
  text-align: center;
  width: 28px
}

.number-input button {
  background: none;
  border: none;
  cursor: pointer;
  height: 36px;
  position: relative;
  transition: transform .1s ease-in-out;
  width: 36px
}

.number-input button:after,
.number-input button:before {
  background-color: #626262;
  content: "";
  height: 2px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 10px
}

.number-input button.increment:after {
  transform: rotate(90deg)
}

.number-input button:active {
  transform: scale(.94)
}

.number-input .suffix {
  margin-left: 10px
}

.checkbox,
.radio {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding-left: 25px;
  position: relative
}

.checkbox input,
.radio input {
  height: 0;
  opacity: 0;
  padding: 0;
  width: 0
}

.checkbox input:checked+span,
.radio input:checked+span {
  color: #323232
}

.checkbox input:checked+span:before,
.radio input:checked+span:before {
  background-color: #2ab0cd;
  border-color: #2ab0cd
}

.checkbox input:checked+span:after,
.radio input:checked+span:after {
  opacity: 1
}

.checkbox span,
.radio span {
  color: #626262;
  position: relative
}

.checkbox span:after,
.checkbox span:before,
.radio span:after,
.radio span:before {
  content: "";
  position: absolute
}

.checkbox span:before,
.radio span:before {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  height: 14px;
  left: -25px;
  width: 14px
}

.checkbox span:after,
.radio span:after {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  height: 5px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px
}

.checkbox span {
  font-size: 16px;
  line-height: 20px
}

.checkbox span:before {
  top: 3px
}

.checkbox span:after {
  left: -23px;
  top: 6px
}

.checkbox.small span,
.radio span {
  font-size: 14px
}

.radio span {
  line-height: 24px
}

.radio span:after,
.radio span:before {
  border-radius: 50%
}

.radio span:before {
  top: 2px
}

.radio span:after {
  background-color: #fff;
  border: none;
  height: 6px;
  left: -21px;
  top: 6px;
  width: 6px
}

.img-input {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: inline-block;
  height: 48px;
  margin-bottom: 20px;
  position: relative;
  vertical-align: middle;
  width: 48px
}

.img-input:after {

  bottom: 0;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0
}

.img-input input {
  height: 100%;
  width: 100%
}

.img-input .tooltip,
.img-input .tooltip:before {
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08)
}

.img-input .tooltip {
  bottom: calc(100% + 15px)
}

.img-input .tooltip .text-medium {
  color: #323232;
  display: inline-block;
  margin-bottom: 5px
}

.img-input .tooltip .text ul li {
  margin-bottom: 5px;
  padding-left: 10px
}

.img-input .tooltip .text ul li:before {
  background-color: #626262
}

.loaded-img {
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 40px
}

.loaded-img img {
  max-width: 100%
}

.checkbox-accordion .hidden-textarea {
  display: none
}

.datepicker-input.invalid:hover:after,
.datepicker-input.invalid:hover:before,
label.invalid:hover:after,
label.invalid:hover:before {
  opacity: 1
}

.datepicker-input.invalid:hover:before,
label.invalid:hover:before {
  transform: rotate(45deg) scale(1)
}

.datepicker-input.invalid:hover:after,
label.invalid:hover:after {
  transform: translateX(-50%) scale(1)
}

@media (min-width: 1200px) {
  .datepicker-placeholder:hover {
    border-color: #2ab0cd
  }

  .double-datepicker .date-departure .datepicker-placeholder:hover,
  .double-datepicker .date-departure.active .datepicker-placeholder:hover {
    border-bottom-color: #2ab0cd
  }

  .download:hover .text-md {
    color: #2ab0cd
  }

  .number-input button:hover:after,
  .number-input button:hover:before {
    background-color: #2ab0cd
  }

  .checkbox:hover input:checked+span,
  .checkbox:hover span,
  .radio:hover input:checked+span,
  .radio:hover span {
    color: #2ab0cd
  }

  .add-file:hover span {
    color: #7ebd3e
  }
}

@media (max-width: 767px) {
  .checkbox.small span {
    font-size: 12px;
    line-height: 12px
  }

  .checkbox.small span:before {
    top: 0
  }

  .checkbox.small span:after {
    top: 3px
  }

  .datepicker-input,
  .timepicker-input,
  label,
  select {
    margin-bottom: 10px
  }

  .datepicker-input:after {
    right: 10px
  }

  .datepicker-placeholder {
    font-size: 12px
  }

  textarea {
    height: 82px
  }
}

.swiper-container {
  list-style: none;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1
}

.swiper-container-no-flexbox .swiper-slide {
  float: left
}

.swiper-container-vertical>.swiper-wrapper {
  flex-direction: column
}

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translateZ(0)
}

.swiper-container-multirow>.swiper-wrapper {
  flex-wrap: wrap
}

.swiper-container-free-mode>.swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out
}

.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%
}

.swiper-slide-invisible-blank {
  visibility: hidden
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height
}

.swiper-container-3d {
  perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, .5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, .5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), transparent)
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
  touch-action: pan-y
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
  touch-action: pan-x
}

.swiper-button-next,
.swiper-button-prev {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 27px 44px;
  cursor: pointer;
  height: 44px;
  margin-top: -22px;
  width: 27px;
  z-index: 10
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: .35;
  pointer-events: none
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: 10px;
  right: auto
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: auto;
  right: 10px
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E")
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E")
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E")
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E")
}

.swiper-button-lock {
  display: none
}

.swiper-pagination {
  position: absolute;
  transform: translateZ(0);
  transition: opacity .3s;
  z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33)
}

.swiper-pagination-bullet {
  background: #000;
  border-radius: 100%;
  display: inline-block;
  opacity: .2
}

button.swiper-pagination-bullet {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer
}

.swiper-pagination-bullet-active {
  background: #007aff;
  opacity: 1
}

.swiper-container-vertical>.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  display: block;
  margin: 6px 0
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: transform .2s, top .2s
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, left .2s
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, right .2s
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, .25);
  position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transform-origin: left top;
  width: 100%
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: 4px;
  left: 0;
  top: 0;
  width: 100%
}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
  height: 100%;
  left: 0;
  top: 0;
  width: 4px
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: hsla(0, 0%, 100%, .25)
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, .25)
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000
}

.swiper-scrollbar {
  background: rgba(0, 0, 0, .1);
  border-radius: 10px;
  position: relative
}

.swiper-container-horizontal>.swiper-scrollbar {
  bottom: 3px;
  height: 5px;
  left: 1%;
  position: absolute;
  width: 98%;
  z-index: 50
}

.swiper-container-vertical>.swiper-scrollbar {
  height: 98%;
  position: absolute;
  right: 3px;
  top: 1%;
  width: 5px;
  z-index: 50
}

.swiper-scrollbar-drag {
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%
}

.swiper-scrollbar-cursor-drag {
  cursor: move
}

.swiper-scrollbar-lock {
  display: none
}

.swiper-zoom-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.swiper-slide-zoomed {
  cursor: move
}

.swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s steps(12) infinite;
  height: 42px;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
  position: absolute;
  top: 50%;
  transform-origin: 50%;
  width: 42px;
  z-index: 10
}

.swiper-lazy-preloader:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: 100%;
  width: 100%
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%23fff' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E")
}

@keyframes swiper-preloader-spin {
  to {
    transform: rotate(1turn)
  }
}

.swiper-container .swiper-notification {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1000
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube {
  overflow: visible
}

.swiper-container-cube .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  pointer-events: none;
  transform-origin: 0 0;
  visibility: hidden;
  width: 100%;
  z-index: 1
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0
}

.swiper-container-cube .swiper-cube-shadow {
  background: #000;
  bottom: 0;
  filter: blur(50px);
  height: 100%;
  left: 0;
  opacity: .6;
  position: absolute;
  width: 100%;
  z-index: 0
}

.swiper-container-flip {
  overflow: visible
}

.swiper-container-flip .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none;
  z-index: 1
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px
}

.swiper-entry.no-swipe .swiper-button-next,
.swiper-entry.no-swipe .swiper-button-prev,
.swiper-entry.no-swipe .swiper-pagination,
.swiper-entry.no-swipe .swiper-slide-duplicate {
  display: none
}

.swiper-entry.no-swipe .swiper-wrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  transform: none !important
}

.swiper-entry.no-swipe .swiper-container .swiper-wrapper,
.swiper-entry.no-swipe .swiper-container-vertical .swiper-wrapper {
  cursor: auto
}

.swiper-entry:not(.no-swipe) .swiper-container {
  cursor: grab
}

.swiper-entry:not(.no-swipe) .swiper-container:active {
  cursor: grabbing
}

.swiper-control-wrapper,
.swiper-entry {
  position: relative
}

.swiper-entry.hide-arrow .swiper-button-next,
.swiper-entry.hide-arrow .swiper-button-prev {
  display: none
}

.swiper-button-next,
.swiper-button-prev {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  height: 40px;
  left: 0;
  margin-top: -20px;
  outline: none;
  position: absolute;
  top: 50%;
  width: 40px;
  z-index: 2
}

.swiper-button-next:before,
.swiper-button-prev:before {
  background: #7ebd3e;
  border-radius: 50%;
  content: "";
  height: 40px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  transform: scale(0);
  visibility: hidden;
  width: 40px;
  z-index: 0
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: none
}

.swiper-button-next i,
.swiper-button-prev i {
  display: inline-block;
  height: 12px;
  left: 50%;
  margin-left: -3px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 6px
}

.swiper-button-next i:before,
.swiper-button-prev i:before {
  border-bottom: 2px solid #1a1a1a;
  border-left: 2px solid #1a1a1a;
  border-radius: 2px;
  content: "";
  height: 8px;
  left: 1px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg);
  width: 8px
}

.swiper-button-next.bottom,
.swiper-button-prev.bottom {
  bottom: 45px;
  top: auto
}

.swiper-button-next.inner,
.swiper-button-prev.inner {
  bottom: 48px;
  margin: 0;
  top: auto;
  z-index: 3
}

.swiper-button-next.inner.bottom-2,
.swiper-button-prev.inner.bottom-2 {
  bottom: 4px
}

.swiper-button-next {
  left: auto;
  right: 0
}

.swiper-button-next i:before {
  left: auto;
  right: 1px;
  transform: rotate(-135deg)
}

.swiper-button-prev {
  left: 15px
}

.swiper-button-prev.outside {
  left: -80px;
  right: auto
}

.swiper-button-prev.inner {
  left: 0 !important;
  right: auto
}

.swiper-button-next {
  right: 15px
}

.swiper-button-next.outside {
  right: -80px
}

.swiper-button-next.inner {
  left: auto;
  right: 0 !important
}

.swiper-pagination {
  line-height: 0;
  text-align: center;
  z-index: 2
}

.swiper-pagination.light .swiper-pagination-bullet:after {
  background-color: #fff;
  opacity: .4
}

.swiper-pagination.light .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  background-color: #fff;
  opacity: 1
}

.swiper-pagination.relative {
  bottom: auto;
  left: auto;
  padding: 30px 0;
  position: relative;
  right: auto;
  text-align: center;
  top: auto
}

.swiper-pagination-bullet {
  background: none;
  border-radius: 50%;
  height: 8px;
  margin-right: 22px;
  opacity: 1;
  position: relative;
  width: 8px
}

.swiper-pagination-bullet:last-child {
  margin-right: 0
}

.swiper-pagination-bullet:after,
.swiper-pagination-bullet:before {
  border-radius: 50%;
  content: "";
  position: absolute
}

.swiper-pagination-bullet:before {
  bottom: -6px;
  left: -6px;
  right: -6px;
  top: -6px
}

.swiper-pagination-bullet:after {
  background-color: #929292;
  bottom: 1px;
  left: 1px;
  opacity: .4;
  right: 1px;
  top: 1px;
  will-change: transform
}

.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  background-color: #2ab0cd;
  opacity: 1;
  transform: scale(1.334)
}

.swiper-slide .img-border {
  margin-bottom: 10px
}

.swiper-slide .bordered-box.vacancy {
  box-sizing: border-box;
  height: 227px;
  padding: 20px 20px 36px
}

.swiper-slide .bordered-box.vacancy .btn {
  bottom: 20px;
  left: 21px;
  position: absolute
}

.banner-carousel {
  border-radius: 10px;
  overflow: hidden
}

.banner-carousel .swiper-button-next,
.banner-carousel .swiper-button-prev {
  background-color: rgba(0, 0, 0, .4);
  border: none;
  height: 30px;
  margin: 0;
  top: 20px;
  width: 35px
}

.banner-carousel .swiper-button-next:before,
.banner-carousel .swiper-button-prev:before {
  display: none
}

.banner-carousel .swiper-button-next:after,
.banner-carousel .swiper-button-prev:after {
  background-color: hsla(0, 0%, 100%, .2);
  bottom: 5px;
  content: "";
  position: absolute;
  top: 5px;
  width: 1px
}

.banner-carousel .swiper-button-next i:before,
.banner-carousel .swiper-button-prev i:before {
  border-color: #fff
}

.banner-carousel .swiper-button-next.swiper-button-disabled,
.banner-carousel .swiper-button-prev.swiper-button-disabled {
  opacity: 1
}

.banner-carousel .swiper-button-next.swiper-button-disabled i,
.banner-carousel .swiper-button-prev.swiper-button-disabled i {
  opacity: .67
}

.banner-carousel .swiper-button-prev {
  border-radius: 50% 0 0 50%;
  left: auto;
  right: 55px
}

.banner-carousel .swiper-button-prev:after {
  right: 0
}

.banner-carousel .swiper-button-next {
  border-radius: 0 50% 50% 0;
  right: 20px
}

.banner-carousel .swiper-button-next:after {
  left: 0
}

.banner-carousel .swiper-slide {
  overflow: hidden;
  padding-top: 54.5%;
  position: relative
}

.banner-carousel .swiper-slide img {
  display: block;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform
}

.banner-carousel .swiper-slide .label {
  font-size: 12px;
  font-weight: 500;
  left: 0;
  position: absolute;
  top: 20px
}

.banner-carousel .swiper-slide .full-size {
  align-items: flex-end;
  background: linear-gradient(1turn, #000 11.73%, transparent 50%);
  display: flex;
  padding: 55px 60px
}

.banner-carousel .swiper-slide .full-size>div,
.banner-carousel .swiper-slide .full-size>span {
  opacity: 0;
  transition: opacity .3s ease
}

.banner-carousel .swiper-slide .full-size>div:first-child {
  padding-right: 14%;
  width: 78%
}

.banner-carousel .swiper-slide .full-size>div:first-child .h1,
.banner-carousel .swiper-slide .full-size>div:first-child .text-md {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis
}

.banner-carousel .swiper-slide .full-size>div:first-child .h1 {
  -webkit-line-clamp: 2;
  position: relative
}

.banner-carousel .swiper-slide .full-size>div:first-child .h1 a:after {
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  content: "";
  display: inline-block;
  height: 10px;
  margin-left: 10px;
  position: relative;
  transform: rotate(45deg);
  vertical-align: middle;
  width: 10px
}

.banner-carousel .swiper-slide .full-size>div:first-child .text-md {
  -webkit-line-clamp: 3;
  font-size: 14px;
  line-height: 22px
}

.banner-carousel .swiper-slide .full-size>div:first-child .btn {
  vertical-align: middle
}

.banner-carousel .swiper-slide .full-size>div:last-child {
  padding-left: 20px;
  padding-top: 8px;
  position: relative;
  width: 22%
}

.banner-carousel .swiper-slide .full-size>div:last-child:before {
  background-color: hsla(0, 0%, 100%, .4);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 1px
}

.banner-carousel .swiper-slide .full-size>div:last-child .h1 {
  color: #ffb947;
  font-size: 40px;
  font-weight: 700;
  line-height: 42px
}

.banner-carousel .swiper-slide .full-size>div:last-child .h1 .text {
  color: #fff;
  vertical-align: top
}

.banner-carousel .swiper-slide .full-size>div:last-child .text-sm {
  display: block
}

.banner-carousel .swiper-slide .btn-more {
  margin-left: 15px
}

.banner-carousel .swiper-slide.swiper-slide-active .full-size>div,
.banner-carousel .swiper-slide.swiper-slide-active .full-size>span {
  opacity: 1;
  transition: opacity .5s ease .3s
}

.banner-carousel .swiper-pagination {
  right: 100px;
  top: 31px
}

.partners-slider .swiper-button-prev {
  left: 0
}

.partners-slider .swiper-button-next {
  right: 0
}

.partner-item {
  display: table-cell;
  height: 70px;
  padding: 30px 15px;
  text-align: center;
  vertical-align: middle;
  width: 500px
}

.partner-item img {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: middle
}

.partner-item+.text {
  margin-top: -15px
}

.thumbs-carousel .swiper-container,
.thumbs-carousel .swiper-pagination {
  z-index: auto
}

.thumbs-carousel .swiper-container {
  position: relative
}

.thumbs-carousel .swiper-wrapper {
  padding-bottom: 95px
}

.thumbs-carousel .thumb>* {
  box-sizing: border-box
}

.thumbs-carousel .swiper-button-next,
.thumbs-carousel .swiper-button-prev {
  z-index: 1
}

.thumbs-carousel .swiper-button-prev {
  left: -90px
}

.thumbs-carousel .swiper-button-prev.bottom {
  left: 0
}

.thumbs-carousel .swiper-button-next {
  right: -90px
}

.thumbs-carousel .swiper-button-next.bottom {
  right: 0
}

.thumbs-carousel .swiper-pagination {
  bottom: 60px;
  left: 0;
  right: 0
}

.swiper-button-lock+.swiper-container {
  cursor: auto !important
}

.default-slider {
  margin: 0 -15px
}

.slider-transport .swiper-slide .img {
  padding-top: 68%
}

.slider-transport .swiper-slide .img img {
  height: 83%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0
}

@media (min-width: 1200px) {

  .swiper-button-next:hover:before,
  .swiper-button-prev:hover:before {
    opacity: 1;
    transform: scale(1);
    visibility: visible
  }

  .swiper-button-next:hover i:before,
  .swiper-button-prev:hover i:before {
    border-color: #fff
  }

  .swiper-pagination-bullet:hover:after {
    transform: scale(1.334)
  }

  .swiper-pagination.light .swiper-pagination-bullet:hover {
    background-color: #fff;
    border-color: #fff
  }

  .banner-carousel .swiper-button-next:hover i:before,
  .banner-carousel .swiper-button-prev:hover i:before {
    border-color: #7ebd3e
  }

  .banner-carousel .swiper-slide .full-size>div:first-child .h1 a:hover {
    color: #7ebd3e
  }

  .banner-carousel .swiper-slide .full-size>div:first-child .h1 a:hover:after {
    border-color: #7ebd3e
  }

  .banner-carousel .swiper-button-prev {
    border-radius: 50% 0 0 50%;
    left: auto;
    right: 55px
  }

  .banner-carousel .swiper-button-next {
    border-radius: 0 50% 50% 0;
    right: 20px
  }
}

@media (max-width: 1399px) {
  .banner-carousel .swiper-slide .full-size {
    padding: 25px 20px
  }

  .banner-carousel .swiper-slide .full-size>div:first-child {
    padding-right: 10%
  }
}

@media (min-width: 1200px) {
  .partners-slider .swiper-pagination {
    display: none
  }
}

@media (max-width: 1199px) {

  .banner-carousel .swiper-button-next,
  .banner-carousel .swiper-button-prev,
  .banner-carousel .swiper-slide .label {
    top: 16px
  }

  .banner-carousel .swiper-slide .full-size>div:first-child {
    padding-right: 2%;
    width: 82%
  }

  .banner-carousel .swiper-slide .full-size>div:last-child {
    width: 18%
  }

  .banner-carousel .swiper-slide .full-size>div:last-child .h1 {
    font-size: 38px;
    line-height: 40px
  }

  .banner-carousel .swiper-pagination {
    top: 26px
  }

  .partner-item+.text {
    margin-top: 0
  }

  .default-slider,
  .thumbs-carousel {
    text-align: center
  }

  .default-slider .swiper-wrapper,
  .thumbs-carousel .swiper-wrapper {
    text-align: left
  }

  .accordion-inner .swiper-button-prev.bottom-sm+.swiper-pagination,
  .default-slider .swiper-button-next.bottom-sm,
  .default-slider .swiper-button-prev.bottom-sm,
  .default-slider .swiper-button-prev.bottom-sm+.swiper-pagination,
  .swiper-button-next.bottom-sm,
  .swiper-button-prev.bottom-sm,
  .thumbs-carousel .swiper-button-prev.bottom-sm+.swiper-pagination {
    bottom: auto !important;
    display: inline-block !important;
    left: auto !important;
    margin: 0 !important;
    position: relative !important;
    right: auto !important;
    top: auto !important;
    vertical-align: middle !important
  }

  .default-slider .swiper-button-next.bottom-sm,
  .default-slider .swiper-button-prev.bottom-sm,
  .swiper-button-next.bottom-sm,
  .swiper-button-prev.bottom-sm {
    margin-top: 22px !important
  }

  .accordion-inner .swiper-button-prev.bottom-sm+.swiper-pagination,
  .default-slider .swiper-button-prev.bottom-sm+.swiper-pagination,
  .thumbs-carousel .swiper-button-prev.bottom-sm+.swiper-pagination {
    max-width: calc(100% - 110px) !important;
    padding-top: 22px
  }

  .default-slider .swiper-button-prev.bottom-sm+.swiper-pagination.relative {
    padding-bottom: 0
  }

  .default-slider .swiper-button-prev.bottom-sm,
  .swiper-button-prev.bottom-sm {
    margin-right: 15px !important
  }

  .default-slider .swiper-button-next.bottom-sm,
  .swiper-button-next.bottom-sm {
    margin-left: 15px !important
  }
}

@media (min-width: 992px) {
  .partners-slider .swiper-container {
    margin: 0 8.6%
  }
}

@media (max-width: 991px) {

  .swiper-button-next,
  .swiper-button-prev {
    border: none;
    height: 30px;
    width: 30px
  }

  .partners-slider {
    text-align: center
  }

  .partners-slider .swiper-button-next,
  .partners-slider .swiper-button-prev,
  .partners-slider .swiper-pagination {
    display: inline-block;
    position: relative;
    vertical-align: middle
  }

  .partners-slider .swiper-button-next,
  .partners-slider .swiper-button-prev {
    bottom: auto;
    margin: 0;
    top: auto
  }

  .partners-slider .swiper-button-prev {
    left: auto;
    margin-right: 10px
  }

  .partners-slider .swiper-button-next {
    margin-left: 10px;
    right: auto
  }

  .partner-item {
    padding: 15px
  }

  .swiper-pagination.relative {
    padding: 15px 0
  }

  .slider-transport .swiper-slide .img img {
    height: 87%
  }
}

@media (max-width: 767px) {
  .banner-tabs .banner-carousel .swiper-slide .full-size {
    background: linear-gradient(1turn, rgba(0, 0, 0, .8), transparent 34.02%)
  }

  .banner-carousel {
    border-radius: 0;
    margin: 0 -15px
  }

  .banner-carousel .swiper-slide .label {
    font-size: 10px
  }

  .banner-carousel .swiper-slide .full-size {
    background: linear-gradient(1turn, rgba(0, 0, 0, .8) 14.06%, transparent 93.75%);
    justify-content: space-between;
    padding: 15px
  }

  .banner-carousel .swiper-slide .full-size>div:first-child,
  .banner-carousel .swiper-slide .full-size>div:last-child {
    padding: 0
  }

  .banner-carousel .swiper-slide .full-size>div:first-child {
    width: 78%
  }

  .banner-carousel .swiper-slide .full-size>div:last-child {
    text-align: right;
    width: 22%
  }

  .banner-carousel .swiper-slide .full-size>div:last-child .h1 {
    display: block;
    font-size: 24px;
    line-height: 24px;
    margin-left: 0;
    position: relative;
    white-space: nowrap
  }

  .banner-carousel .swiper-slide .full-size>div:last-child .h1>span.text {
    font-size: 12px;
    position: relative;
    top: -6px;
    vertical-align: middle
  }

  .banner-carousel .swiper-slide .full-size .h1 {
    font-size: 14px
  }

  .banner-carousel .swiper-entry>.swiper-pagination {
    left: auto;
    text-align: right;
    width: calc(50% - 30px)
  }

  .banner-carousel .swiper-slide .full-size .h1+div,
  .banner-carousel .swiper-slide .full-size>div:first-child .h1 a:after,
  .banner-carousel .swiper-slide .full-size>div:first-child .h1:after,
  .banner-carousel .swiper-slide .full-size>div:first-child .text-md,
  .banner-carousel .swiper-slide .full-size>div:last-child .text-sm,
  .banner-carousel .swiper-slide .full-size>div:last-child:before {
    display: none
  }

  .banner-carousel-controls {
    font-size: 0;
    position: absolute;
    right: 15px;
    top: 15px
  }

  .banner-carousel-controls .swiper-button-next,
  .banner-carousel-controls .swiper-button-prev,
  .banner-carousel-controls .swiper-pagination {
    bottom: auto;
    display: inline-block;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
    vertical-align: middle
  }

  .banner-carousel-controls .swiper-button-next:after,
  .banner-carousel-controls .swiper-button-prev:after {
    display: none
  }

  .banner-carousel-controls .swiper-pagination {
    background-color: rgba(0, 0, 0, .4);
    height: 30px;
    padding: 11px 10px
  }

  .slider-transport .swiper-slide .img img {
    height: 77%
  }

  .default-slider .swiper-slide .img-border {
    border-radius: 0
  }
}

.left-sidebar-inner>.btn,
.right-sidebar-inner>.btn {
  align-items: center;
  display: flex;
  line-height: 18px;
  margin-bottom: 15px;
  min-height: 48px;
  width: 100%
}

.left-sidebar-inner>.btn:not(.btn-block),
.right-sidebar-inner>.btn:not(.btn-block) {
  text-align: left
}

.left-sidebar-inner>.btn.btn-block,
.right-sidebar-inner>.btn.btn-block {
  justify-content: center
}

.left-sidebar-inner .bordered-box,
.right-sidebar-inner .bordered-box {
  margin-bottom: 20px
}

.left-sidebar-inner .review-title .h4,
.right-sidebar-inner .review-title .h4 {
  margin-bottom: 10px;
  width: 100%
}

.left-sidebar-inner .review-title .stars,
.right-sidebar-inner .review-title .stars {
  padding-top: 0
}

.left-sidebar-inner {
  font-size: 0;
  position: relative
}

.left-sidebar-inner .sidebar-item .bottom-part .btn-block:last-child {
  margin-top: 20px
}

.left-sidebar-inner .sidebar-item .review {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 20px
}

.right-sidebar-inner .thumb-info {
  z-index: auto
}

.right-sidebar-inner .sidebar-item .btn.type-1,
.right-sidebar-inner .sidebar-item .btn.type-2 {
  padding: 12px 20px
}

.right-sidebar-inner .sidebar-item.vacancy-form .btn {
  margin-top: 0
}

.right-sidebar-inner .sidebar-item.reviews {
  padding: 0
}

.right-sidebar-inner .sidebar-item.reviews .review {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 20px
}

.right-sidebar-inner .sidebar-item.reviews .review:last-of-type {
  border-bottom: none;
  padding-bottom: 0
}

.right-sidebar-inner .sidebar-item .datepicker-placeholder {
  padding-left: 15px
}

.add-to-like {
  margin-left: 5%
}

.add-to-like,
.add-to-like .icon {
  display: inline-block;
  vertical-align: middle
}

.add-to-like .icon {
  cursor: pointer;
  line-height: 40px;
  margin-right: 15px;
  position: relative;
  text-align: center
}

.add-to-like .icon svg {
  fill: #2ab0cd;
  height: 16px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 16px
}

.add-to-like .text {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.add-to-like .text>span {
  color: #626262;
  transition: opacity .44s ease;
  white-space: nowrap
}

.add-to-like .text>span:first-child {
  position: relative
}

.add-to-like .text>span:last-child {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1
}

.add-to-like.active .icon {
  background-color: #2ab0cd;
  border-color: #2ab0cd
}

.add-to-like.active .icon svg {
  fill: #fff
}

.add-to-like.active .text>span:first-child {
  opacity: 0;
  z-index: -1
}

.add-to-like.active .text>span:last-child {
  opacity: 1;
  z-index: 1
}

.sidebar-item {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative
}

.sidebar-item:last-child {
  margin-bottom: 0
}

.sidebar-item .bg:after {
  background: rgba(0, 0, 0, .3)
}

.sidebar-item .bottom-part,
.sidebar-item .top-part {
  padding: 17px 20px;
  position: relative
}

.sidebar-item .top-part {
  border-radius: 5px 5px 0 0;
  overflow: hidden
}

.sidebar-item .top-part.b-border {
  border-bottom: 1px solid #e9e9e9;
  margin: 0 20px;
  padding: 20px 0
}

.sidebar-item .SumoSelect>.CaptionCont,
.sidebar-item .datepicker-placeholder {
  height: 40px
}

.sidebar-item .datepicker-placeholder {
  padding: 8px 15px 6px 45px
}

.sidebar-item .SumoSelect {
  margin-bottom: 15px
}

.sidebar-item .SumoSelect>.CaptionCont {
  padding: 8px 15px 6px
}

.sidebar-item .news-links {
  position: relative
}

.sidebar-item .news-links>* {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 16px;
  padding-bottom: 16px
}

.sidebar-item .news-links>:last-child {
  border-bottom: none;
  margin-bottom: 24px;
  padding-bottom: 0
}

.sidebar-item .news-links .news-item a {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px
}

.sidebar-item .news-links .news-item .news-date {
  color: #626262;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 4px;
  position: relative
}

.sidebar-item .subscribe-block {
  margin-top: 5px
}

.sidebar-item .subscribe-block>* {
  margin-bottom: 15px
}

.sidebar-item .subscribe-block>:last-child {
  margin-bottom: 0
}

.sidebar-item form .btn {
  vertical-align: middle;
  width: 50%
}

.sidebar-item .review {
  border-bottom: none;
  margin-bottom: 5px;
  padding-bottom: 0
}

.sidebar-item.tour-selection .top-part .title {
  letter-spacing: .1em;
  position: relative;
  text-transform: uppercase
}

.sidebar-item.notice .top-part {
  background: #ffb947;
  margin: -1px -1px 0
}

.sidebar-item.notice .bottom-part>img {
  display: block;
  margin-top: 20px;
  width: 100%
}

.sidebar-item.no-border {
  border: none
}

.sidebar-item.vacancy-form .h3 {
  display: block;
  padding-left: 36px;
  position: relative
}

.sidebar-item.vacancy-form .h3:before {

  content: "";
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 26px
}

.sidebar-item.tour-voting-form .h3:before {

  content: "";
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 26px
}

.sidebar-item.vacancy-form .btn {
  width: 100%
}

.gift-certificate {
  padding: 30px 15px 25px;
  position: relative;
  text-align: center;
  z-index: 1
}

.gift-certificate .gift-icon {
  display: inline-block;
  padding: 0 30px;
  position: relative
}

.gift-certificate .gift-icon:after,
.gift-certificate .gift-icon:before {
  background: #fff;
  content: "";
  height: 2px;
  left: 0;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  width: 20px
}

.gift-certificate .gift-icon:after {
  left: auto;
  right: 0
}

.gift-certificate .gift-icon img {
  display: inline-block;
  height: auto;
  width: 28px
}

.gift-certificate .title {
  margin-top: 9px;
  position: relative
}

.right-sidebar {
  padding: 0 0 0 18px
}

.right-sidebar .thumb-price {
  align-items: center;
  border-top: none;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding-top: 0
}

.right-sidebar .thumb-price .text span {
  font-size: 30px;
  line-height: 40px
}

.right-sidebar .thumb-price .discount {
  margin-left: 30px
}

.right-sidebar .stars>a {
  pointer-events: auto
}

.right-sidebar .sidebar-item {
  padding: 20px
}

.right-sidebar .sidebar-item .btn {
  margin-top: 15px
}

.right-sidebar .sidebar-item .spacer-xs {
  height: 12px
}

.right-sidebar .sidebar-item.notice,
.right-sidebar .sidebar-item.testimonials {
  padding: 0
}

.right-sidebar .sidebar-item.notice .title {
  font-weight: 500
}

.right-sidebar .sidebar-item.notice .bottom-part {
  padding: 20px 40% 20px 20px
}

.right-sidebar .sidebar-item.testimonials .review-header {
  margin-bottom: 10px
}

.right-sidebar .sidebar-item.testimonials .review-title {
  flex-wrap: wrap
}

.right-sidebar .sidebar-item.testimonials .review-title .h4 {
  margin-bottom: 10px;
  width: 100%
}

.right-sidebar .sidebar-item.testimonials.vote .btn.btn-block {
  width: 100%
}

.right-sidebar .sidebar-item .bottom-part {
  position: relative
}

.right-sidebar .sidebar-item .bottom-part .text-md {
  color: #323232
}

.right-sidebar .sidebar-item .bottom-part>img {
  bottom: 0;
  position: absolute;
  right: 0;
  width: 50%
}

.right-sidebar .single-datepicker {
  margin-bottom: 15px
}

@media (min-width: 1200px) {
  .sidebar-item .news-links .news-item a:hover {
    color: #7ebd3e
  }

  .gift-certificate:hover .bg {
    transform: scale(1.14)
  }

  .right-sidebar a.text:hover {
    color: #7ebd3e
  }

  .add-to-like .icon:hover {
    border-color: #2ab0cd
  }
}

@media (max-width: 1399px) {
  .right-sidebar-inner .add-to-like {
    margin-left: 3%
  }
}

@media (min-width: 1200px) {

  .left-sidebar .only-mobile,
  .right-sidebar .only-mobile {
    display: none !important
  }

  #tour-selection-dropdown .btn-close {
    /* display: none */
  }

  .sidebar-item .btn {
    width: 100%
  }
}

@media (max-width: 1199px) {

  .left-sidebar .only-desktop,
  .right-sidebar .only-desktop {
    display: none !important
  }

  .left-sidebar .only-mobile,
  .right-sidebar .only-mobile {
    display: block !important
  }

  .right-sidebar {
    padding-left: 0
  }

  .sidebar-item.notice .bottom-part>img {
    margin: 20px auto 0;
    max-width: 240px
  }

  .sidebar-item .subscribe-block>.btn {
    margin-bottom: 0;
    margin-right: 20px;
    width: calc(50% - 10px)
  }

  .sidebar-item .subscribe-block>.btn:last-child {
    margin-right: 0
  }

  #tour-selection-dropdown {
    /* background-color: #fff;
    left: calc(50% - 239px);
    opacity: 0;
    position: fixed;
    top: calc(50% + 42px);
    transform: translate3d(0, -50%, 0) scale(1.2);
    visibility: hidden;
    width: 478px;
    will-change: transform, opacity, visibility;
    z-index: 101 */
  }

  #tour-selection-dropdown .btn-close {
    
    
  }

  #tour-selection-dropdown .bottom-part {
    
    
  }

  #tour-selection-dropdown .bottom-part::-webkit-scrollbar {
    height: 4px;
    width: 4px
  }

  #tour-selection-dropdown .bottom-part::-webkit-scrollbar-track {
    background-color: transparent
  }

  #tour-selection-dropdown .bottom-part::-webkit-scrollbar-thumb {
    background-color: #2ab0cd;
    border-radius: 8px
  }

  #tour-selection-dropdown .bottom-part form {
    width: 100%
  }

  #tour-selection-dropdown.active {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
    visibility: visible
  }
}

@media (max-width: 1199px) and (min-width:768px) {

  .right-sidebar,
  .right-sidebar .right-sidebar-inner.only-mobile .thumb-price {
    padding-top: 0
  }
}

@media (min-width: 768px) {
  .right-sidebar-inner.only-mobile .sidebar-item .thumb-price {
    display: none
  }
}

@media (max-width: 767px) {
  .right-sidebar {
    padding: 5px 0 0
  }

  .right-sidebar-inner .add-to-like .text {
    display: none
  }

  .right-sidebar-inner.only-mobile {
    display: flex;
    flex-wrap: wrap
  }

  .right-sidebar-inner.only-mobile>* {
    width: 100%
  }

  .right-sidebar-inner.only-mobile>.thumb-price {
    display: none
  }

  .right-sidebar-inner.only-mobile>div {
    order: 1
  }

  .right-sidebar-inner.only-mobile .sidebar-item {
    border: none;
    padding: 0
  }

  .right-sidebar-inner>.btn {
    min-height: 36px;
    padding: 7px 20px 5px
  }

  .sidebar-item .subscribe-block>.btn {
    margin-bottom: 15px;
    margin-right: 0;
    width: 100%
  }

  .sidebar-item .subscribe-block>.btn:last-child {
    margin-bottom: 0
  }

  .sidebar-item .bottom-part>.btn {
    width: 100%
  }

  #tour-selection-dropdown {
    left: 15px;
    top: calc(50% + 30px);
    transform: translate3d(0, -50%, 0) scale(1.2);
    width: calc(100vw - 30px)
  }

  #tour-selection-dropdown .btn-close {
    margin-top: -12px;
    right: 10px;
    top: 50%
  }

  #tour-selection-dropdown .top-part {
    text-align: center
  }

  #tour-selection-dropdown .top-part img {
    display: none
  }

  #tour-selection-dropdown .bottom-part {
    max-height: calc(100vh - 160px)
  }

  #tour-selection-dropdown.active {
    transform: translate3d(0, -50%, 0)
  }

  .sidebar-item.testimonials.vote .top-part.b-border {
    margin: 0
  }
}

.review {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 20px
}

.review .text a {
  white-space: normal
}

.review:last-of-type {
  border-bottom: none;
  margin-bottom: 0
}

.review.clear-bottom {
  border-bottom: 0;
  margin-bottom: 10px;
  padding-bottom: 0
}

.review.clear-bottom+.load-more-wrapp,
.review.clear-bottom+.load-more-wrapp .review {
  padding-bottom: 10px
}

.review.testimonial {
  align-items: center
}

.review.testimonial .h4 {
  display: block;
  margin-bottom: 5px;
  width: 100%
}

.review.testimonial .review-img .h4 {
  display: flex;
  margin-bottom: 0
}

.review.testimonial .text-sm:nth-child(3) {
  margin-right: auto
}

.review.testimonial .review-title .text:not(.text-sm) {
  color: #7ebd3e;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
  position: relative
}

.review.testimonial+.load-more-wrapp {
  border-bottom: 1px solid #e9e9e9;
  padding: 0 20px 30px
}

.review.testimonial.clear-bottom {
  margin-bottom: 0;
  padding-bottom: 0
}

.review.testimonial.clear-bottom+.load-more-wrapp .review {
  padding-bottom: 0
}

.review.testimonial .swiper-entry {
  margin-right: -10px
}

.review-header {
  display: flex;
  margin-bottom: 20px
}

.review-img {
  background-color: #e9e9e9;
  border-radius: 5px;
  height: 48px;
  overflow: hidden;
  position: relative;
  width: 48px
}

.review-img img {
  display: block
}

.review-img .h4 {
  align-items: center;
  display: flex;
  justify-content: center
}

.review-title {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  width: calc(100% - 48px)
}

.review-title .h4 {
  display: block;
  font-weight: 500
}

.review-title .h4 .text-md {
  color: #626262;
  font-weight: 400
}

.review-title .stars,
.review-title .text-sm {
  vertical-align: middle
}

.review-title .text-sm {
  display: inline-block;
  margin-right: 10px
}

.review-title .text-sm:last-child {
  margin-right: 0
}

.review-title .stars {
  margin-left: auto;
  padding-top: 4px
}

.new-review {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0
}

.new-review label {
  margin-left: auto;
  width: calc(100% - 63px)
}

.new-review label textarea {
  height: 60px
}

.new-review .text-right {
  width: 100%
}

.new-review .text-right .btn {
  margin-right: 30px;
  vertical-align: middle
}

.new-review .text-right .btn:last-child {
  margin-right: 0
}

@media (min-width: 1200px) {
  .review.testimonial .review-title .text:not(.text-sm):hover {
    color: #626262
  }
}

@media (max-width: 1199px) {
  .review.testimonial .swiper-entry {
    margin-right: 0
  }
}

.tab-nav,
.tabs-wrap {
  position: relative
}

.tab-toggle {
  border-bottom: 1px solid #e9e9e9;
  display: block;
  text-align: center
}

.tab-caption {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 40px;
  position: relative;
  vertical-align: middle
}

.tab-caption:last-child {
  margin-right: 0
}

.tab-caption:before {
  background-color: #2ab0cd;
  bottom: -10px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleX(0)
}

.tab-caption span,
.tab-caption svg {
  display: inline-block;
  vertical-align: middle
}

.tab-caption svg {
  margin-right: 10px;
  margin-top: -4px;
  width: 16px
}

.tab-caption path {
  fill: #626262
}

.tab-caption rect {
  stroke: #626262
}

.tab-caption span {
  color: #626262;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 32px;
  text-transform: uppercase
}

.tab-caption.active:before {
  transform: none
}

.tab-caption.active path {
  fill: #2ab0cd
}

.tab-caption.active rect {
  stroke: #2ab0cd
}

.tab-caption.active span {
  color: #2ab0cd
}

.tab,
.tab-bottom-part,
.tab-top-part {
  transition: opacity .3s ease-out
}

.tab-bottom-part:not(.active),
.tab-top-part:not(.active),
.tab:not(.active) {
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.tab-bottom-part.active,
.tab-top-part.active,
.tab.active {
  position: relative
}

@media (min-width: 1200px) {
  .tab-caption:hover:before {
    transform: none
  }

  .tab-caption:hover path {
    fill: #2ab0cd
  }

  .tab-caption:hover rect {
    stroke: #2ab0cd
  }

  .tab-caption:hover span {
    color: #2ab0cd
  }
}

@media (min-width: 768px) {
  .tab-title {
    display: none
  }
}

@media (max-width: 767px) {
  .tab-caption {
    margin-right: 30px
  }

  .tab-caption:last-child {
    margin-right: 0
  }

  .tab-caption span {
    font-size: 12px;
    line-height: 24px
  }
}

.thumb-wrap {
  font-size: 0;
  position: relative
}

.thumb-wrap .img.img-border.img-caption:not(.style-2),
.thumb-wrap+.only-pad-mobile .swiper-slide .img.img-border.img-caption:not(.style-2) {
  padding-bottom: 20px
}

.thumb {
  margin-bottom: 20px;
  position: relative;
  transition: all .3s ease-in-out;
  z-index: 1
}

.thumb .like {
  line-height: 1
}

.thumb:before {
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0
}

.thumb .thumb-img {
  border-radius: 3px 3px 0 0;
  padding-top: 67.54%;
  position: relative
}

.thumb .thumb-img img {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.thumb .thumb-content {
  padding: 15px 20px 20px
}

.thumb .full-size {
  z-index: 3
}

.thumb-img {
  overflow: hidden;
  position: relative;
  z-index: 1
}

.thumb-img img {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%
}

.label {
  background-color: #7ebd3e;
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  left: 0;
  letter-spacing: .05em;
  line-height: 26px;
  padding: 0 15px 0 10px;
  position: absolute;
  text-transform: uppercase;
  top: 20px;
  z-index: 2
}

.label.hit-sales {
  background: #ffb947
}

.item,
.thumb .thumb-content,
.thumb-desc {
  border: 1px solid #e9e9e9
}

.thumb-content,
.thumb-desc {
  background: #fff;
  border-radius: 0 0 3px 3px;
  border-top: none
}

.thumb-content {
  position: relative
}

.thumb-content>* {
  margin-bottom: 10px
}

.thumb-content>:last-child {
  margin-bottom: 0
}

.thumb-content .title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.thumb-content .datepicker-input:before {
  top: 11px
}

.thumb-content .datepicker-placeholder {
  height: 40px;
  padding: 8px 15px 6px
}

.thumb-content .tooltip-wrap,
.thumb-info .tooltip-wrap {
  margin-left: 10px;
  margin-top: -3px;
  z-index: 999
}

.thumb-content .SumoSelect.open {
  z-index: 72
}

.thumb-content>.text {
  margin: 1.5rem 0
}

.stars .text {
  margin-left: 10px
}

.stars a.text {
  pointer-events: auto
}

.stars.stars-selected {
  cursor: auto;
  pointer-events: none
}

.stars:not(.rating-picker) {
  display: inline-block;
  min-width: 68px
}

.stars.rating-picker {
  height: 40px;
  margin-bottom: 20px;
  padding: 8px 0;
  pointer-events: none
}

.stars.rating-picker .select-icon {
  height: 22px;
  pointer-events: auto;
  width: 22px
}



.stars.active .select-icon.active+.select-icon,
.stars.active .select-icon.active+.select-icon+.select-icon,
.stars.active .select-icon.active+.select-icon+.select-icon+.select-icon,
.stars.active .select-icon.active+.select-icon+.select-icon+.select-icon+.select-icon {}

.stars.vertical-margin {
  margin: 10px 0
}

.select-icon {
  background: 50%/cover no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  margin-right: 2px;
  position: relative;
  width: 12px
}

.select-icon:last-child {
  margin-right: 0
}





.thumb-info {
  font-size: 0;
  padding: 5px 0;
  position: relative;
  z-index: 71
}

.thumb-info>span,
.thumb-info>span:after,
.thumb-info>span:before {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.thumb-info>span {
  width: 50%
}

.thumb-info>span:after,
.thumb-info>span:before {
  background: 50%/contain no-repeat;
  height: 16px;
  top: -2px;
  width: 16px
}

.thumb-info>span:before {
  content: "";
  margin-right: 10px
}

.thumb-info>span.thumb-info-time {
  border-right: 1px solid #e9e9e9;
  padding-right: 20px
}



.thumb-info>span.thumb-info-time:last-child {
  border-right: none
}

.thumb-info>span.thumb-info-people {
  padding-left: 20px
}





.thumb-price {
  align-items: center;
  border-top: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-top: 15px
}

.thumb-price i,
.thumb-price>span {
  display: inline-block
}

.thumb-price>.text {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px
}

.thumb-price>.text>* {
  margin-bottom: 0
}

.thumb-price>.text>b {
  margin-right: 6px
}

.thumb-price>.text span {
  color: #ffb947;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 10px
}

.thumb-price>.text i {
  color: #626262;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-left: 5px;
  vertical-align: top
}

.discount {
  background-color: #f5f5f5;
  color: #323232;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: -4px;
  padding: 4px 12px 5px;
  position: relative
}

.discount.margin-top {
  margin-top: 5px
}

.thumb-desc {
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  left: 0;
  opacity: 0;
  padding: 5px 20px 20px;
  top: calc(100% - 2px);
  transform: translate3d(0, -25px, 0);
  transform-origin: top center;
  visibility: hidden;
  will-change: transform;
  z-index: 1
}

.like,
.thumb-desc {
  position: absolute;
  right: 0
}

.like {
  background-color: rgba(0, 0, 0, .6);
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
  cursor: pointer;
  height: 26px;
  padding: 8px 5px 8px 8px;
  text-align: center;
  top: 20px;
  width: 38px;
  z-index: 3
}

.like svg {
  width: 14px
}

.like path {
  stroke: #fff;
  fill: transparent
}

.like.active {
  background-color: #2ab0cd
}

.like.active path {
  fill: #fff
}

.item {
  align-items: center;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  position: relative
}

.item:last-child {
  margin-bottom: 0
}

.item .thumb-img {
  border-radius: 3px;
  width: 31%
}

.item>div:nth-child(2) {
  padding: 0 20px;
  width: 36%
}

.item .thumb-content:last-of-type {
  border-left: 1px solid #e9e9e9;
  padding-left: 20px;
  width: 33%
}

.item .thumb-content .title {
  font-weight: 700
}

.item .thumb-content>.text p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.item.post .thumb-img {
  align-self: flex-start
}

.item.post .thumb-content {
  width: 69%
}

.item.post .thumb-content>.text-sm {
  display: block;
  margin-bottom: 15px
}

.item.post .thumb-content>.text:last-child {
  margin-bottom: 0
}

.item.post .thumb-content>.text p {
  -webkit-line-clamp: 3
}

@media (min-width: 1200px) {

  .item .title a:hover,
  .thumb .title a:hover {
    color: #7ebd3e
  }

  .thumb:hover {
    z-index: 2
  }

  .thumb:hover .thumb-desc,
  .thumb:hover:before {
    opacity: 1
  }

  .thumb:hover:before {
    height: calc(100% + 82px)
  }

  .thumb:hover .thumb-content {
    border-radius: 0
  }

  .thumb:hover .thumb-desc {
    transform: none;
    visibility: visible
  }

  .thumb-img:hover img {
    transform: scale(1.1)
  }

  .like:hover path {
    fill: #fff
  }

  .rating-picker .select-icon:hover,
  .rating-picker.active .select-icon:hover,
  .rating-picker.active:hover .select-icon,
  .rating-picker:hover .select-icon {}

  .rating-picker .select-icon:hover+.select-icon,
  .rating-picker .select-icon:hover+.select-icon+.select-icon,
  .rating-picker .select-icon:hover+.select-icon+.select-icon+.select-icon,
  .rating-picker .select-icon:hover+.select-icon+.select-icon+.select-icon+.select-icon,
  .rating-picker.active .select-icon:hover+.select-icon,
  .rating-picker.active .select-icon:hover+.select-icon+.select-icon,
  .rating-picker.active .select-icon:hover+.select-icon+.select-icon+.select-icon,
  .rating-picker.active .select-icon:hover+.select-icon+.select-icon+.select-icon+.select-icon {}

  .rating-picker .select-icon.active+.select-icon+.select-icon+.select-icon+.select-icon:hover,
  .rating-picker .select-icon.active+.select-icon+.select-icon+.select-icon:hover,
  .rating-picker .select-icon.active+.select-icon+.select-icon:hover,
  .rating-picker .select-icon.active+.select-icon:hover,
  .rating-picker.active .select-icon.active+.select-icon+.select-icon+.select-icon+.select-icon:hover,
  .rating-picker.active .select-icon.active+.select-icon+.select-icon+.select-icon:hover,
  .rating-picker.active .select-icon.active+.select-icon+.select-icon:hover,
  .rating-picker.active .select-icon.active+.select-icon:hover {}
}

@media (max-width: 1199px) {
  .thumb-wrap.only-desktop {
    display: none
  }

  .item .thumb-img {
    width: 49%
  }

  .item .thumb-content>.text-sm {
    margin: 5px 0
  }

  .item .thumb-content .text>* {
    margin-bottom: 15px
  }
}

@media (max-width: 991px) {
  .item {
    align-items: stretch
  }
}

@media (max-width: 768px) {
  .thumb-wrap {
    margin: 0 -10px
  }

  .thumb-wrap>div {
    padding: 0 10px
  }

  .thumb-wrap>div .img.img-caption {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: calc(100% - 20px)
  }
}

.thumb-wrap>div .img.img-caption {
  min-height: calc(100% - 20px)
}

@media (max-width: 767px) {
  .thumb .thumb-content .h3 {
    font-size: 14px;
    line-height: 20px
  }

  .item {
    margin-bottom: 15px
  }

  .item:not(.post) {
    border: none;
    flex-wrap: wrap;
    padding: 0 0 0 calc(34.69% + 20px)
  }

  .item:not(.post)>div:nth-child(2) {
    padding: 0;
    width: 100%
  }

  .item:not(.post)>div:nth-child(2)>.text {
    display: none
  }

  .item:not(.post) .thumb-content {
    padding: 0
  }

  .item:not(.post) .thumb-content .h3 {
    margin-bottom: 15px;
    min-height: 46px
  }

  .item:not(.post) .thumb-content:last-of-type {
    border-left: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 42px;
    padding-left: 0;
    width: 100%
  }

  .item:not(.post) .thumb-content:last-of-type .datepicker-input,
  .item:not(.post) .thumb-content:last-of-type .thumb-price {
    width: 100%
  }

  .item:not(.post) .thumb-content:last-of-type .SumoSelect,
  .item:not(.post) .thumb-content:last-of-type select {
    margin-bottom: 15px
  }

  .item:not(.post) .thumb-content:last-of-type .datepicker-input {
    order: 2
  }

  .item:not(.post) .thumb-content:last-of-type .thumb-info {
    margin-top: -36px;
    order: 1;
    text-align: right;
    width: 50%
  }

  .item:not(.post) .thumb-content:last-of-type .thumb-info>span {
    width: auto
  }

  .item:not(.post) .thumb-content:last-of-type .thumb-info>span.thumb-info-time {
    border-left: 1px solid #e9e9e9;
    border-right: none;
    padding-left: 20px;
    padding-right: 10px
  }

  .item:not(.post) .thumb-content:last-of-type .thumb-info>span.thumb-info-people {
    padding-right: 20px
  }

  .item:not(.post) .thumb-content:last-of-type .thumb-price {
    border-top: none;
    order: 3;
    padding-top: 0
  }

  .item:not(.post) .thumb-content:last-of-type .btn {
    bottom: 0;
    left: 0;
    order: 4;
    position: absolute;
    right: 0
  }

  .item .thumb-img img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
  }

  .item.post {
    flex-wrap: wrap;
    padding: 0
  }

  .item.post .thumb-img {
    align-self: flex-start;
    border-radius: 3px 3px 0 0;
    bottom: auto;
    height: auto;
    left: auto;
    padding-top: 38%;
    position: relative;
    right: auto;
    top: auto;
    width: 100%
  }

  .item.post .thumb-content {
    justify-content: flex-start;
    padding: 16px;
    width: 100%
  }

  .item.post .thumb-content .h3 {
    margin-bottom: 0;
    min-height: auto
  }

  .item.post .thumb-content>.text {
    margin: 10px 0
  }

  .item.post .thumb-content>.text:last-child {
    margin-bottom: 0
  }

  .item.post .thumb-content>.text>* {
    margin-bottom: 10px
  }

  .item.post .thumb-content>.text>:last-child {
    margin-bottom: 0
  }
}

@media (max-width: 575px) {
  .thumb-wrap>div:first-child .thumb {
    border-top: 1px solid #e9e9e9;
    padding-top: 15px
  }

  .thumb-wrap>div:first-child .thumb .thumb-img {
    top: 65px
  }

  .thumb .thumb-content .datepicker-input:before {
    display: none
  }

  .thumb {
    align-items: flex-start;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 15px;
    padding-left: 46.065%
  }

  .thumb .thumb-img {
    border-radius: 0;
    bottom: 15px;
    left: 0;
    padding-top: 0;
    position: absolute;
    top: 50px;
    width: 46.065%
  }

  .thumb .thumb-img img {
    left: 50%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto
  }

  .thumb .thumb-content {
    border: none;
    padding: 50px 0 0 10px;
    width: 100%
  }

  .thumb .thumb-content .title {
    -webkit-line-clamp: 2;
    height: 37px;
    left: -86.065%;
    margin-left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 132%
  }

  .thumb .thumb-content .stars {
    position: absolute;
    right: 0;
    text-align: right;
    top: 1px;
    width: 85px
  }

  .thumb .thumb-content .stars .text {
    display: block;
    font-size: 10px;
    line-height: 10px
  }

  .thumb .thumb-content .datepicker-placeholder {
    height: 36px;
    padding: 6px 10px
  }

  .thumb .thumb-content .tooltip-wrap {
    z-index: 72
  }

  .thumb .thumb-content>.text {
    margin: 1rem 0
  }

  .thumb .datepicker-input {
    padding-left: 0
  }

  .thumb .thumb-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0
  }

  .thumb .thumb-info .text {
    font-size: 12px;
    width: auto
  }

  .thumb .thumb-info .text.thumb-info-time {
    border: none;
    padding-right: 0
  }

  .thumb .thumb-info .text.thumb-info-people {
    border-left: 1px solid #e9e9e9;
    padding-left: 20px
  }

  .thumb .thumb-price {
    border: none;
    margin-bottom: 10px;
    min-height: 27px;
    padding-top: 0
  }

  .thumb .thumb-price>.text {
    font-size: 0;
    line-height: 0
  }

  .thumb .thumb-price>.text span {
    font-size: 18px;
    line-height: 20px;
    margin-left: 0
  }

  .thumb .thumb-price>.text i {
    font-size: 10px
  }

  .discount {
    font-size: 12px
  }

  .like,
  .thumb .thumb-img .label {
    top: 8px
  }

  .like {
    border-radius: 0 13px 13px 0;
    bottom: 22px;
    height: 26px;
    left: 0;
    padding: 8px 5px 8px 8px;
    right: auto;
    top: auto;
    width: 38px
  }

  .like svg {
    width: 14px
  }

  .thumb-content .SumoSelect {
    margin-bottom: 0
  }

  .thumb-content .thumb-info,
  .thumb-content .thumb-price {
    margin-bottom: 5px
  }
}

.fc-icon,
body .fc {
  font-size: 1em
}

.fc td:first-child,
.fc th:first-child {
  border-left: none
}

.fc td:last-child,
.fc th:last-child {
  border-right: none
}

.fc td {
  text-align: center
}

.fc-button-group,
.fc-icon {
  display: inline-block
}

.fc-bg,
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0
}

.fc-icon,
.fc-unselectable {
  -webkit-touch-callout: none;
  -khtml-user-select: none
}

.fc th,
.fc-basic-view td.fc-week-number,
.fc-icon,
.fc-toolbar {
  text-align: center
}

.fc {
  direction: ltr;
  text-align: left
}

.fc button {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  vertical-align: middle
}

.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0
}

.fc button .fc-icon {
  border-right: 1px solid #626262;
  border-top: 1px solid #626262;
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 8px
}

.fc button .fc-icon.fc-icon-left-single-arrow {
  transform: rotate(-135deg)
}

.fc button .fc-icon.fc-icon-right-single-arrow {
  transform: rotate(45deg)
}

.fc .fc-button-group>* {
  float: left;
  margin: 0 0 0 -1px
}

.fc .fc-button-group>:first-child {
  margin-left: 0
}

.fc .fc-row {
  border-style: solid;
  border-width: 0
}

.fc .fc-toolbar>*>* {
  float: left
}

.fc .fc-axis {
  display: none;
  padding: 0 4px;
  vertical-align: middle;
  white-space: nowrap
}

.fc-rtl {
  text-align: right
}

.fc th.fc-day-header>span,
.fc-day-number {
  color: #626262;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  text-transform: uppercase
}

.fc-day-number {
  border-radius: 50%;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0;
  width: 22px
}

.fc-agendaDay-view th.fc-day-header:after {
  border-top: 1px solid #e9e9e9;
  color: #323232;
  content: attr(data-date);
  display: block;
  font-size: 10px;
  line-height: 24px;
  padding: 10px 0;
  text-align: center
}

.fc-agendaDay-view th.fc-day-header .fc-day-header.fc-widget-header {
  border-bottom: none;
  border-left: none;
  border-right: none
}

.fc-view.fc-agendaDay-view.fc-agenda-view .fc-widget-content {
  border-top: none
}

.fc th.fc-day-header {
  position: relative;
  vertical-align: middle
}

.fc th.fc-day-header>span {
  font-weight: 500;
  line-height: 34px;
  padding: 5px 0
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #e9e9e9
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #eee
}

.fc-unthemed .fc-popover {
  background-color: #fff;
  border-style: solid;
  border-width: 1px
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
  font-size: .9em;
  margin-top: 2px
}

.fc-unthemed td.fc-today .fc-day-number {
  background-color: #ffb947;
  color: #fff;
  font-weight: 500
}

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3
}

.fc-highlight {
  background: #bce8f1;
  opacity: .3
}

.fc-bgevent {
  background: #8fdf82;
  opacity: .3
}

.fc-nonbusiness {
  background: #d7d7d7
}

.ui-widget .fc-disabled-day {
  background-image: none
}

.fc-icon {
  font-family: Courier New, Courier, monospace;
  height: 1em;
  line-height: 1em;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.fc-icon:after {
  position: relative
}

.fc-icon-left-double-arrow:after,
.fc-icon-right-double-arrow:after {
  font-size: 160%;
  top: -7%
}

.fc-icon-left-double-arrow:after {
  content: "Ã‚Â«"
}

.fc-icon-right-double-arrow:after {
  content: "Ã‚Â»"
}

.fc-icon-down-triangle:after,
.fc-icon-left-triangle:after,
.fc-icon-right-triangle:after {
  font-size: 125%
}

.fc-icon-left-triangle:after,
.fc-icon-right-triangle:after {
  top: 3%
}

.fc-icon-left-triangle:after {
  content: "Ã¢â€”â€ž"
}

.fc-icon-right-triangle:after {
  content: "Ã¢â€“Âº"
}

.fc-icon-down-triangle:after {
  content: "Ã¢â€“Â¼";
  top: 2%
}

.fc-icon-x:after {
  content: "Ãƒâ€”";
  font-size: 200%;
  top: 6%
}

.fc-state-default {
  background-color: #f5f5f5;
  background-image: linear-gradient(180deg, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid rgba(0, 0, 0, .1);
  border-bottom-color: rgba(0, 0, 0, .25);
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .2), 0 1px 2px rgba(0, 0, 0, .05);
  color: #333;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, .75)
}

.fc-state-default.fc-corner-left {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px
}

.fc-state-default.fc-corner-right {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px
}

.fc-state-default.fc-next-button,
.fc-state-default.fc-prev-button {
  height: 18px;
  width: 18px
}

.fc-state-default.fc-next-button {
  margin-left: 0
}

.fc-state-default.fc-today-button {
  color: #323232;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 24px;
  margin-left: 50px;
  opacity: 1;
  padding-left: 28px;
  position: relative;
  text-transform: uppercase
}

.fc-state-default.fc-today-button:before {

  content: "";
  height: 18px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 18px
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down,
.fc-state-hover {
  background-color: #e6e6e6;
  color: #333
}

.fc-state-hover {
  background-position: 0 -15px;
  color: #333;
  text-decoration: none;
  transition: background-position .1s linear
}

.fc-state-active,
.fc-state-down {
  background-color: #ccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05)
}

.fc-state-disabled {
  background-image: none;
  box-shadow: none;
  cursor: default;
  opacity: .65
}

.fc-event.fc-draggable,
.fc-event[href],
.fc-popover .fc-header .fc-close,
a[data-goto] {
  cursor: pointer
}

.fc-popover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
  position: absolute
}

.fc-popover .fc-header {
  padding: 2px 4px
}

.fc-popover .fc-header .fc-title {
  margin: 0 2px
}

.fc-popover>.ui-widget-header+.ui-widget-content {
  border-top: 0
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left
}

.fc-ltr .fc-popover .fc-header .fc-close,
.fc-rtl .fc-popover .fc-header .fc-title {
  float: right
}

.fc-divider {
  border-style: solid;
  border-width: 1px
}

hr.fc-divider {
  border-width: 1px 0;
  display: none;
  height: 0;
  margin: 0;
  padding: 0 0 2px
}

.fc-bg table,
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%
}

.fc-clear {
  clear: both
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-helper-skeleton,
.fc-highlight-skeleton {
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  font-size: 1em;
  width: 100%
}

.fc td,
.fc th {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top
}

.fc-agendaDay-view .fc-head-container,
.fc-agendaDay-view .fc-head-container.fc-widget-header {
  border-bottom: none
}

.fc-row {
  position: relative
}

.fc-row:first-child table {
  border-top: 0 hidden transparent
}

.fc-row table {
  border-bottom: 0 hidden transparent;
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent
}

.fc-row .fc-bg {
  z-index: 1
}

.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2
}

.fc-row .fc-highlight-skeleton {
  z-index: 3
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: 0 0;
  border-color: transparent;
  border-bottom: 0
}

.fc-row .fc-content-skeleton {
  padding-bottom: 2px;
  position: relative;
  z-index: 4
}

.fc-row .fc-helper-skeleton {
  z-index: 5
}

.fc-scroller {
  -webkit-overflow-scrolling: touch
}

.fc-scroller>.fc-day-grid,
.fc-scroller>.fc-time-grid {
  position: relative;
  width: 100%
}

.fc-row.fc-rigid,
.fc-time-grid-event {
  overflow: hidden
}

.fc-event,
.ui-widget .fc-event {
  text-decoration: none
}

.fc-event {
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 5px 15px;
  position: relative;
  text-align: left;
  z-index: 1
}

.fc-event:after,
.fc-event:before {
  border-radius: 3px;
  content: "";
  position: absolute;
  z-index: -1
}

.fc-event:before {
  border: 2px solid;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.fc-event .fc-bg {
  background: #fff;
  opacity: .25;
  z-index: 1
}

.fc-event .fc-resizer {
  display: none;
  position: absolute;
  z-index: 4
}

.fc-event .fc-content:after,
.fc-event:after,
.fc-event:before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.fc-event.fc-not-start:before {
  border-left: none;
  left: 10px
}

.fc-event.fc-not-start:after {
  bottom: -2px;
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  left: 0;
  position: absolute;
  top: -2px;
  width: 11px
}

.fc-event.fc-not-end:before {
  border-right: none;
  right: 10px
}

.fc-event.fc-not-end .fc-content:after {
  bottom: -2px;
  -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  content: "";
  position: absolute;
  right: 0;
  top: -2px;
  width: 10px
}

.fc-event.fc-selected {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  z-index: 9999 !important
}

.fc-event.fc-selected:before {
  bottom: 3px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 3
}

.fc-event.fc-selected .fc-resizer:before {
  content: "";
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
  z-index: 9999
}

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, .3)
}

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block
}

.fc-event.have-a-lot .fc-content:after,
.fc-event.have-a-lot:after,
.fc-event.have-a-lot:before {
  background-color: #d5ecbd;
  border-color: #d5ecbd
}

.fc-event.still-have .fc-content:after,
.fc-event.still-have:after,
.fc-event.still-have:before {
  background-color: #ffe4b8;
  border-color: #ffe4b8
}

.fc-event.no-have .fc-content:after,
.fc-event.no-have:after,
.fc-event.no-have:before {
  background-color: #e9e9e9;
  border-color: #e9e9e9
}

.fc-event.event-discount .fc-title {
  position: relative
}

.fc-event.event-discount .fc-title:after {

  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  margin-top: -2px;
  position: relative;
  vertical-align: middle;
  width: 16px
}

.fc-event.fc-not-end {
  border-radius: 3px 0 0 3px;
  border-right: none
}

.fc-event.fc-not-start {
  border-left: none;
  border-radius: 0 3px 3px 0
}

.fc-title {
  color: #323232;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed
}

.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-left-radius: 0;
  margin-left: 0
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  margin-right: 0
}

.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px
}

.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px
}

.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  bottom: -1px;
  top: -1px;
  width: 7px
}

.fc-h-event.fc-selected .fc-resizer {
  background: #fff;
  border: 1px solid;
  border-color: inherit;
  border-radius: 4px;
  height: 6px;
  margin-top: -4px;
  top: 50%;
  width: 6px
}

.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px
}

.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px
}

.fc-day-grid-event.fc-selected:after {
  background: #000;
  bottom: -1px;
  content: "";
  left: -1px;
  opacity: .25;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1
}

.fc-day-grid-event .fc-content {
  overflow: hidden
}

.fc-day-grid-event .fc-time {
  font-weight: 700
}

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px
}

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px
}

a.fc-more {
  cursor: pointer;
  font-size: .85em;
  margin: 1px 3px;
  text-decoration: none
}

.fc-day-grid .fc-row {
  z-index: 1
}

.fc-more-popover {
  width: 220px;
  z-index: 2
}

.fc-more-popover .fc-event-container {
  padding: 10px
}

.fc-event-container {
  padding: 0 10px !important
}

.fc-now-indicator {
  border: 0 solid red;
  position: absolute
}

.fc-unselectable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.fc-toolbar {
  align-items: center;
  display: flex
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 30px
}

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em
}

.fc-toolbar .fc-left {
  align-items: center;
  display: flex;
  float: left;
  width: 41%
}

.fc-toolbar .fc-right {
  float: right;
  margin-left: auto
}

.fc-toolbar .fc-center,
.fc-toolbar h2 {
  display: inline-block
}

.fc-toolbar h2 {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 10px;
  text-transform: capitalize;
  vertical-align: middle
}

.fc-toolbar button {
  background: none;
  border: none;
  box-shadow: none;
  position: relative
}

.fc-toolbar button:focus {
  z-index: 5
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2
}

.fc-toolbar .fc-state-down {
  z-index: 3
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4
}

.fc-right {
  border-radius: 5px;
  cursor: pointer;
  max-height: 40px;
  position: relative;
  width: 115px
}

.fc-right:after {
  border-bottom: 1px solid #626262;
  border-right: 1px solid #626262;
  content: "";
  height: 6px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: rotate(45deg);
  width: 6px;
  z-index: 3
}

.fc-right>button {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  color: #323232;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 24px;
  text-align: left
}

.fc-right>button:not(.fc-state-active) {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
  display: block;
  left: -4px;
  opacity: 0;
  padding: 56px 19px 10px;
  position: absolute;
  right: -4px;
  top: -4px;
  transform: translate3d(0, 15px, 0);
  visibility: hidden;
  width: calc(100% + 8px);
  will-change: transform;
  z-index: 2
}

.fc-right>button.fc-state-active {
  border-radius: 5px;
  padding: 8px 30px 6px 15px;
  position: relative;
  width: 100%;
  z-index: 3
}

.fc-right.active:after {
  border-color: #2ab0cd;
  transform: rotate(225deg) translate3d(-4px, -2px, 0)
}

.fc-right.active button:not(.fc-state-active) {
  opacity: 1;
  transform: none;
  visibility: visible
}

.fc-view-container *,
.fc-view-container :after,
.fc-view-container :before {
  box-sizing: content-box
}

.fc-view-container {
  margin-bottom: 30px
}

.fc-view-container+.fc-toolbar {
  margin-bottom: 0
}

.fc-view,
.fc-view>table {
  position: relative;
  z-index: 1
}

.fc-basic-view .fc-body .fc-row {
  min-height: 4em
}

.fc-basic-view th.fc-day-number,
.fc-basic-view th.fc-week-number {
  padding: 0 2px
}

.fc-basic-view .fc-day-top .fc-week-number {
  background-color: #f2f2f2;
  color: grey;
  min-width: 1.5em;
  text-align: center
}

.fc-basic-view td.fc-week-number>* {
  display: inline-block;
  min-width: 1.25em
}

.fc-day-top.fc-other-month {
  opacity: .3
}

.fc .fc-day-top {
  padding: 10px 0
}

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left
}

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  border-radius: 0 0 3px;
  float: left
}

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  border-radius: 0 0 0 3px;
  float: right
}

.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em
}

.fc-ltr .fc-axis {
  text-align: right
}

.fc-rtl .fc-axis {
  text-align: left
}

.ui-widget td.fc-axis {
  font-weight: 400
}

.fc-time-grid,
.fc-time-grid-container {
  position: relative;
  z-index: 1
}

.fc-time-grid {
  min-height: 100%
}

.fc-time-grid table {
  border: 0 hidden transparent
}

.fc-time-grid>.fc-bg {
  z-index: 1
}

.fc-time-grid .fc-slats,
.fc-time-grid>hr {
  position: relative;
  z-index: 2
}

.fc-time-grid .fc-content-col {
  position: relative
}

.fc-time-grid .fc-content-skeleton {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3
}

.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1
}

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2
}

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3
}

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4
}

.fc-time-grid .fc-now-indicator-line {
  z-index: 5
}

.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6
}

.fc-time-grid .fc-slats {
  display: none
}

.fc-time-grid .fc-slats td {
  border-bottom: 0;
  height: 1.5em
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted
}

.fc-time-grid .fc-slats .ui-widget-content {
  background: 0 0
}

.fc-time-grid .fc-highlight {
  left: 0;
  position: absolute;
  right: 0
}

.fc-time-grid .fc-bgevent,
.fc-time-grid .fc-event {
  position: absolute;
  z-index: 1
}

.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0
}

.fc-ltr .fc-time-grid .fc-event-container {
  margin: 0 2.5% 0 2px
}

.fc-rtl .fc-time-grid .fc-event-container {
  margin: 0 2px 0 2.5%
}

.fc-v-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px
}

.fc-v-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px
}

.fc-time-grid-event.fc-selected {
  overflow: visible
}

.fc-time-grid-event.fc-selected .fc-bg {
  display: none
}

.fc-time-grid-event.fc-selected .fc-resizer {
  background: #fff;
  border: 1px solid;
  border-color: inherit;
  border-radius: 5px;
  bottom: -5px;
  height: 8px;
  left: 50%;
  margin-left: -5px;
  width: 8px
}

.fc-time-grid-event .fc-content {
  overflow: hidden
}

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap
}

.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start)
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: "Ã‚Â -Ã‚Â "
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  padding: 0
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  bottom: 0;
  cursor: s-resize;
  font-family: monospace;
  font-size: 11px;
  height: 8px;
  left: 0;
  line-height: 8px;
  overflow: hidden;
  right: 0;
  text-align: center
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "="
}

.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0
}

.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-width: 5px 0 5px 6px;
  left: 0
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-width: 5px 6px 5px 0;
  right: 0
}

.fc-rtl .fc-list-view {
  direction: rtl
}

.fc-event-dot {
  border-radius: 5px;
  display: inline-block;
  height: 10px;
  width: 10px
}

.fc-list-view {
  border-style: solid;
  border-width: 1px
}

.fc .fc-list-table {
  table-layout: auto
}

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px
}

.fc-list-table tr:first-child td {
  border-top-width: 0
}

.fc-list-heading {
  border-bottom-width: 1px
}

.fc-list-heading td {
  font-weight: 700
}

.fc-ltr .fc-list-heading-main {
  float: left
}

.fc-ltr .fc-list-heading-alt,
.fc-rtl .fc-list-heading-main {
  float: right
}

.fc-rtl .fc-list-heading-alt {
  float: left
}

.fc-list-item.fc-has-url {
  cursor: pointer
}

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px
}

.fc-ltr .fc-list-item-marker {
  padding-right: 0
}

.fc-rtl .fc-list-item-marker {
  padding-left: 0
}

.fc-list-item-title a {
  color: inherit;
  text-decoration: none
}

.fc-list-empty-wrap1 {
  display: table;
  height: 100%;
  width: 100%
}

.fc-list-empty-wrap2 {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.fc-list-empty {
  display: table-cell;
  text-align: center;
  vertical-align: middle
}

.fc-unthemed .fc-list-empty {
  background-color: #eee
}

#calendar {
  position: relative
}

.calendar-footer-center,
.calendar-header-center {
  align-items: center;
  display: flex;
  left: 41%;
  position: absolute;
  right: 130px
}

.calendar-footer-center span,
.calendar-header-center span {
  color: #323232
}

.calendar-footer-center .text-sm,
.calendar-header-center .text-sm {
  margin-right: 30px;
  position: relative
}

.calendar-footer-center .text-sm:last-child,
.calendar-header-center .text-sm:last-child {
  margin-right: 0
}

.calendar-footer-center .text-sm:before,
.calendar-header-center .text-sm:before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  margin-top: -2px;
  position: relative;
  vertical-align: middle;
  width: 12px
}

.calendar-footer-center .text-sm:first-child:before,
.calendar-header-center .text-sm:first-child:before {
  background-color: #d5ecbd
}

.calendar-footer-center .text-sm:nth-child(2):before,
.calendar-header-center .text-sm:nth-child(2):before {
  background-color: #ffe4b8
}

.calendar-footer-center .text-sm:nth-child(3):before,
.calendar-header-center .text-sm:nth-child(3):before {
  background-color: #e9e9e9
}

.calendar-footer-center .text,
.calendar-header-center .text {
  font-weight: 500;
  margin-left: auto
}

.calendar-header-center {
  top: 8px
}

.calendar-footer-center {
  bottom: 8px
}

.tooltip {
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  font-size: 12px;
  left: 0;
  line-height: 22px;
  opacity: 0;
  padding: 5px 10px;
  pointer-events: none;
  transform-origin: left bottom;
  white-space: nowrap
}

.tooltip,
.tooltip:before {
  background-color: #323232;
  position: absolute
}

.tooltip:before {
  bottom: -5px;
  content: "";
  height: 10px;
  left: calc(50% - 5px);
  transform: rotate(45deg);
  width: 10px
}

.tooltip-wrap .tooltip {
  left: 50%;
  transform: scale(0) translateX(-50%)
}

.tooltip-wrap.black,
.tooltip-wrap.light,
.tooltip-wrap.red {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  position: relative;
  vertical-align: middle;
  width: 16px
}

.tooltip-wrap.black .text span,
.tooltip-wrap.red .text span {
  color: #fff
}

.tooltip-wrap.black .tooltip,
.tooltip-wrap.light .tooltip,
.tooltip-wrap.red .tooltip {
  bottom: calc(100% + 6px)
}

.tooltip-wrap.black.active .tooltip,
.tooltip-wrap.light.active .tooltip,
.tooltip-wrap.red.active .tooltip {
  opacity: 1;
  transform: scale(1) translateX(-50%)
}





.tooltip-wrap.light .tooltip,
.tooltip-wrap.light .tooltip:before {
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08)
}

.tooltip-wrap.light .tooltip {
  color: #626262
}

.tooltip-wrap.fc-event .tooltip {
  left: 0;
  transform: scale(0)
}

.fc-content-skeleton tbody .fc-axis+.fc-event-container .tooltip {
  left: 0;
  right: auto
}

.fc-content-skeleton tbody td:last-child .tooltip {
  left: auto;
  right: 0
}

@media (min-width: 1200px) {
  .tooltip-wrap:not(.red):not(.black):not(.light):hover .tooltip {
    opacity: 1;
    transform: scale(1) translateX(-50%)
  }

  .tooltip-wrap.fc-event:hover .tooltip {
    opacity: 1;
    transform: none
  }

  .fc-event.have-a-lot.hover .fc-content:after,
  .fc-event.have-a-lot.hover:after,
  .fc-event.have-a-lot.hover:before,
  .fc-event.have-a-lot:hover .fc-content:after,
  .fc-event.have-a-lot:hover:after,
  .fc-event.have-a-lot:hover:before,
  .fc-event.no-have.hover .fc-content:after,
  .fc-event.no-have.hover:after,
  .fc-event.no-have.hover:before,
  .fc-event.no-have:hover .fc-content:after,
  .fc-event.no-have:hover:after,
  .fc-event.no-have:hover:before,
  .fc-event.still-have.hover .fc-content:after,
  .fc-event.still-have.hover:after,
  .fc-event.still-have.hover:before,
  .fc-event.still-have:hover .fc-content:after,
  .fc-event.still-have:hover:after,
  .fc-event.still-have:hover:before {
    opacity: .61
  }

  .fc-toolbar button:hover .fc-icon {
    border-color: #2ab0cd
  }

  .fc-right button:not(.fc-state-active):hover {
    color: #2ab0cd
  }

  .fc-right button.fc-state-active:hover {
    border-color: #2ab0cd
  }

  .fc-today-button:hover {
    color: #2ab0cd
  }

  .fc-day-number:hover {
    color: #7ebd3e
  }
}

@media (max-width: 1399px) {
  .fc-event {
    padding: 5px 10px
  }
}

@media (max-width: 1199px) {
  .calendar-popup .fc-view-container+.fc-header-toolbar {
    margin-bottom: 0
  }

  .tab .fc {
    margin-top: 60px
  }

  .fc {
    margin-top: 10px
  }

  .fc-toolbar {
    position: relative;
    z-index: 72
  }

  .fc-toolbar h2 {
    font-size: 14px;
    line-height: 24px
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px
  }

  .fc-toolbar.fc-header-toolbar .fc-right {
    left: 65px;
    margin: 0;
    position: absolute;
    top: -58px
  }

  .fc-view-container {
    margin-bottom: 15px
  }

  .fc-view-container+.fc-toolbar .fc-right {
    display: none
  }

  .calendar-footer-center,
  .calendar-header-center {
    left: auto;
    right: 0
  }

  .calendar-footer-center span.text-sm,
  .calendar-header-center span.text-sm {
    margin-left: 30px;
    margin-right: 0
  }

  .calendar-footer-center span.text-sm:first-child,
  .calendar-header-center span.text-sm:first-child {
    margin-left: 0
  }

  .calendar-header-center {
    top: 0
  }

  .calendar-header-center .text {
    position: absolute;
    right: 0;
    top: -48px;
    width: calc(100vw - 40px);
    z-index: 71
  }

  .calendar-header-center .text:before {
    background-color: #fff;
    bottom: -16px;
    content: "";
    left: -3000px;
    position: absolute;
    right: -3000px;
    top: -16px;
    z-index: -1
  }

  .calendar-footer-center .text {
    display: none
  }
}

@media (min-width: 768px) {
  .fc table {
    table-layout: fixed
  }
}

@media (max-width: 767px) {
  .calendar-header-center span.text-sm {
    bottom: -34px;
    font-size: 12px;
    line-height: 24px
  }

  .calendar-footer-center {
    display: none
  }

  .fc-toolbar .fc-left {
    width: 100%
  }

  .fc-state-default.fc-today-button {
    margin-left: auto
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 45px
  }

  .fc td,
  .fc th {
    min-width: 140px
  }

  .fc-event-container {
    padding: 0 !important
  }

  .fc-view>table {
    border-collapse: collapse;
    display: block;
    max-width: 100%;
    overflow: auto;
    position: relative;
    width: auto
  }

  .fc-view>table::-webkit-scrollbar {
    height: 4px;
    width: 4px
  }

  .fc-view>table::-webkit-scrollbar-track {
    background-color: transparent
  }

  .fc-view>table::-webkit-scrollbar-thumb {
    background-color: #2ab0cd;
    border-radius: 8px
  }

  .fc-view-container+.fc-toolbar {
    margin-bottom: 0
  }

  .fc-agendaDay-view table,
  .fc-agendaDay-view tbody,
  .fc-agendaDay-view td,
  .fc-agendaDay-view th,
  .fc-agendaDay-view thead,
  .fc-agendaDay-view tr {
    display: block;
    max-width: 100%;
    min-width: 100%;
    width: 100%
  }

  .fc-agendaDay-view table {
    padding: 0 1%
  }

  .fc-agendaDay-view .fc-content,
  .fc-agendaDay-view .fc-divider,
  .fc-agendaDay-view .fc-list-heading td,
  .fc-agendaDay-view .fc-list-view,
  .fc-agendaDay-view .fc-popover,
  .fc-agendaDay-view .fc-row,
  .fc-agendaDay-view tbody,
  .fc-agendaDay-view td,
  .fc-agendaDay-view th,
  .fc-agendaDay-view thead {
    border: none !important
  }

  .fc-agendaDay-view .fc-unthemed thead {
    border-color-left: transparent !important
  }
}

.accordion,
.accordion-item,
.acoordion-all-expand,
.expand-all-button {
  position: relative;
}

.accordion-all-expand>.accordion {
  margin: 15px 0
}

.expand-all-button {
  /* text-align:right; */
}

.expand-all-button>* {
  margin-right: 40px
}

.expand-all-button>:last-child {
  margin-right: 0
}

.accordion {
  z-index: 1
}

.accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #e9e9e9

}

.accordion .accordion-item:first-child {
  border-top: 1px solid #e9e9e9
}

.accordion.type-1>.accordion-item>.accordion-title,
.accordion.type-4>.accordion-item>.accordion-title,
.accordion.type-5>.accordion-item>.accordion-title {
  font-size: 20px
}

.accordion.type-1>.accordion-item>.accordion-title span,
.accordion.type-4>.accordion-item>.accordion-title span,
.accordion.type-5>.accordion-item>.accordion-title span {
  background: none;
  border-radius: 0;
  display: inline-block;
  height: 26px;
  left: 0;
  line-height: 26px;
  position: absolute;
  top: 22px;
  width: 26px
}

.accordion.type-1>.accordion-item>.accordion-title span img,
.accordion.type-4>.accordion-item>.accordion-title span img,
.accordion.type-5>.accordion-item>.accordion-title span img {
  display: block;
  height: 100%;
  max-width: 100%;
  width: 100%
}

.accordion.type-1>.accordion-item>.accordion-title i,
.accordion.type-4>.accordion-item>.accordion-title i,
.accordion.type-5>.accordion-item>.accordion-title i {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  height: 32px;
  top: calc(50% - 16px);
  width: 32px
}

.accordion.type-1>.accordion-item>.accordion-title i:before,
.accordion.type-4>.accordion-item>.accordion-title i:before,
.accordion.type-5>.accordion-item>.accordion-title i:before {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-bottom: 2px solid #2ab0cd;
  border-left: 2px solid #2ab0cd;
  content: "";
  height: 8px;
  position: absolute;
  right: 11px;
  top: 11px;
  transform: rotate(-45deg);
  width: 8px;
  will-change: transform
}

.accordion.type-1>.accordion-item>.accordion-inner,
.accordion.type-4>.accordion-item>.accordion-inner,
.accordion.type-5>.accordion-item>.accordion-inner {
  padding: 0 20px
}

.accordion.type-1>.accordion-item.active>.accordion-title i:before,
.accordion.type-4>.accordion-item.active>.accordion-title i:before,
.accordion.type-5>.accordion-item.active>.accordion-title i:before {
  border-color: #626262;
  transform: rotate(-225deg)
}

.accordion.type-1>.accordion-item.active>.accordion-inner,
.accordion.type-4>.accordion-item.active>.accordion-inner,
.accordion.type-5>.accordion-item.active>.accordion-inner {
  padding: 13px 20px 35px
}

.accordion.type-1>.accordion-item>.accordion-title {
  padding: 21px 60px 22px 40px
}

.accordion.type-1>.accordion-item>.accordion-title i {
  right: 20px
}

.accordion.type-2>.accordion-item>.accordion-title,
.accordion.type-3>.accordion-item>.accordion-title {
  padding: 13px 40px 13px 0
}

.accordion.type-2>.accordion-item>.accordion-title i,
.accordion.type-3>.accordion-item>.accordion-title i {
  height: 8px;
  right: 15px;
  top: calc(50% - 4px);
  width: 8px
}

.accordion.type-2>.accordion-item>.accordion-title i:after,
.accordion.type-2>.accordion-item>.accordion-title i:before,
.accordion.type-3>.accordion-item>.accordion-title i:after,
.accordion.type-3>.accordion-item>.accordion-title i:before {
  background-color: #2ab0cd;
  content: "";
  position: absolute
}

.accordion.type-2>.accordion-item>.accordion-title i:before,
.accordion.type-3>.accordion-item>.accordion-title i:before {
  height: 2px;
  left: 0;
  right: 0;
  top: 3px
}

.accordion.type-2>.accordion-item>.accordion-title i:after,
.accordion.type-3>.accordion-item>.accordion-title i:after {
  bottom: 0;
  right: 3px;
  top: 0;
  width: 2px;
  will-change: transform
}

.accordion.type-2>.accordion-item>.accordion-inner,
.accordion.type-3>.accordion-item>.accordion-inner {
  padding: 0
}

.accordion.type-2>.accordion-item>.accordion-inner .swiper-entry,
.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry {
  padding: 0 60px
}

.accordion.type-2>.accordion-item>.accordion-inner .swiper-entry .swiper-button-prev,
.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry .swiper-button-prev {
  left: 0
}

.accordion.type-2>.accordion-item>.accordion-inner .swiper-entry .swiper-button-next,
.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry .swiper-button-next {
  right: 0
}

.accordion.type-2>.accordion-item>.accordion-inner .swiper-entry .swiper-slide,
.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry .swiper-slide {
  overflow: hidden
}

.accordion.type-2>.accordion-item.active>.accordion-title i:after,
.accordion.type-2>.accordion-item.active>.accordion-title i:before,
.accordion.type-3>.accordion-item.active>.accordion-title i:after,
.accordion.type-3>.accordion-item.active>.accordion-title i:before {
  background-color: #626262
}

.accordion.type-2>.accordion-item.active>.accordion-title i:after,
.accordion.type-3>.accordion-item.active>.accordion-title i:after {
  transform: scaleY(0)
}

.accordion.type-2>.accordion-item>.accordion-title {
  font-size: 16px
}

.accordion.type-2>.accordion-item.active>.accordion-inner {
  padding: 0 0 35px
}

.accordion.type-2>.accordion-item:last-child {
  border-bottom: none
}

.accordion.type-2>.accordion-item:last-child .accordion-inner {
  padding-bottom: 0
}

.accordion.type-2 .accordion-inner .swiper-button-next,
.accordion.type-2 .accordion-inner .swiper-button-prev {
  bottom: 0
}

.accordion.type-3>.accordion-item {
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0 20px
}

.accordion.type-3>.accordion-item:last-child {
  margin-bottom: 0
}

.accordion.type-3>.accordion-item>.accordion-title {
  color: #000;
  font-size: 14px;
  padding-right: 0
}

.accordion.type-3>.accordion-item>.accordion-title span {
  background-color: #7ebd3e;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  left: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 22px
}

.accordion.type-3>.accordion-item>.accordion-title i {
  right: -5px
}

.accordion.type-3>.accordion-item>.accordion-inner {
  padding: 0
}

.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry .swiper-button-next,
.accordion.type-3>.accordion-item>.accordion-inner .swiper-entry .swiper-button-prev {
  background-color: #f5f5f5
}

.accordion.type-3>.accordion-item.active>.accordion-title i:after,
.accordion.type-3>.accordion-item.active>.accordion-title i:before {
  background-color: #626262
}

.accordion.type-3>.accordion-item.active>.accordion-title i:after {
  transform: scaleY(0)
}

.accordion.type-3>.accordion-item.active>.accordion-inner {
  padding: 0 0 20px
}

.accordion.type-4>.accordion-item>.accordion-title,
.accordion.type-5>.accordion-item>.accordion-title {
  padding: 21px 40px 22px 0
}

.accordion.type-4>.accordion-item>.accordion-title i,
.accordion.type-5>.accordion-item>.accordion-title i {
  right: 0
}

.accordion.type-5 .accordion-title b {
  color: #2ab0cd;
  font-size: 18px;
  line-height: 13px;
  margin-right: 10px
}

.accordion-title {
  color: #323232;
  cursor: pointer;
  font-weight: 500;
  line-height: 28px;
  position: relative
}

.accordion-title i {
  position: absolute
}

.accordion-inner {
  display: none
}

.accordion-inner .calendar-header-center {
  bottom: auto;
  left: auto;
  margin-bottom: 30px;
  position: relative;
  right: auto;
  top: auto;
  width: 100%
}

.accordion-inner>form {
  padding: 0 20px
}

.accordion-inner>form:not(.calc-form) {
  margin-top: 10px
}

.accordion-inner .review {
  border-bottom: none
}

.accordion-inner .review-title {
  align-items: center
}

.accordion-inner .review-title .h4 {
  display: block;
  margin-bottom: 5px;
  width: 100%
}

.accordion-inner .review-title .text-sm:nth-child(3) {
  margin-right: auto
}

.accordion-inner .review-title .text:not(.text-sm) {
  color: #7ebd3e;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
  position: relative
}

.accordion-inner .more-info {
  display: none;
  padding-top: 10px
}

.accordion-inner>.row>div>span.text-md {
  color: #323232;
  display: inline-block;
  margin-bottom: 10px;
  position: relative
}

.accordion-inner .contact {
  padding-left: 0
}

.accordion-inner .contact:last-child {
  margin-bottom: 0
}

.accordion-inner .contact .img {
  display: inline-block;
  left: auto;
  margin-right: 10px;
  position: relative;
  top: auto;
  vertical-align: middle
}

.accordion-inner .thumb-wrap {
  margin: -13px -30px -10px
}

.accordion-inner .guide-name {
  height: auto
}

.accordion-inner .tickets {
  margin-left: 20px
}

.accordion-inner .tickets:first-child {
  padding-top: 0
}

.accordion-inner .tickets:last-child {
  border-bottom: none;
  padding-bottom: 0
}

.accordion-inner .tickets p {
  margin-bottom: 0
}

.accordion-inner .calc-header label.checkbox {
  background-color: transparent
}

.accordion-inner .schedule-header>span:first-child {
  margin-right: 20px
}

@media (min-width: 1200px) {

  .accordion.type-1 .accordion-title:hover,
  .accordion.type-4 .accordion-title:hover,
  .accordion.type-5 .accordion-title:hover {
    color: #2ab0cd
  }

  .accordion-inner .review-title .text:not(.text-sm):hover {
    color: #626262
  }
}

@media (max-width: 1199px) and (min-width:768px) {
  .accordion-inner .review .swiper-entry {
    padding: 0 60px
  }

  .accordion-inner .review .swiper-entry .swiper-button-next.bottom-sm,
  .accordion-inner .review .swiper-entry .swiper-button-prev.bottom-sm {
    margin-top: -20px !important;
    position: absolute !important;
    top: 50% !important
  }

  .accordion-inner .review .swiper-entry .swiper-button-prev.bottom-sm {
    left: 0 !important
  }

  .accordion-inner .review .swiper-entry .swiper-button-next.bottom-sm {
    right: 0 !important
  }

  .accordion-inner .review .swiper-entry .swiper-pagination {
    display: none !important
  }
}

@media (max-width: 1199px) {
  .accordion-inner .swiper-entry {
    text-align: center
  }

  .accordion-inner .swiper-entry .swiper-wrapper {
    text-align: left
  }
}

@media (max-width: 767px) {
  .expand-all-button {
    text-align: center
  }

  .accordion.type-1>.accordion-item.active>.accordion-inner,
  .accordion.type-4>.accordion-item.active>.accordion-inner,
  .accordion.type-5>.accordion-item.active>.accordion-inner {
    padding: 13px 0 35px
  }

  .accordion.type-3>.accordion-item {
    padding: 0 15px
  }

  .accordion.type-3>.accordion-item>.accordion-title {
    padding: 8px 40px 8px 0
  }

  .accordion .swiper-entry {
    padding: 0 0 10px !important
  }

  .accordion-inner .swiper-button-next,
  .accordion-inner .swiper-button-prev {
    bottom: 20px;
    margin: 0;
    top: auto;
    z-index: 3
  }

  .accordion-inner .swiper-button-prev {
    left: 0
  }

  .accordion-inner .swiper-button-next {
    right: 0
  }

  .accordion-inner .swiper-pagination {
    padding: 22px 0 0;
    position: relative
  }

  .accordion-inner .calendar-header-center {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: -13px 0 15px;
    width: 100%
  }

  .accordion-inner .calendar-header-center span.text-sm {
    bottom: auto
  }

  .accordion-inner .calendar-footer-center span.text-sm,
  .accordion-inner .calendar-header-center span.text-sm {
    margin-left: 15px
  }

  .accordion-inner .calendar-footer-center span.text-sm:first-child,
  .accordion-inner .calendar-header-center span.text-sm:first-child {
    margin-left: 0
  }

  .accordion-inner .schedule-header,
  .accordion-inner .schedule-row {
    margin: 0 -15px;
    padding: 12px 15px
  }

  .accordion-inner .schedule-row>div>span {
    display: block;
    white-space: nowrap
  }

  .accordion-inner .schedule-row>div>span.text {
    margin-right: 0
  }

  .accordion-inner .schedule-row>div>span.discount {
    background-color: transparent;
    padding: 0
  }

  .accordion-inner .schedule-row .btn {
    padding: 8px 12px
  }

  .accordion-inner .calc-form {
    margin: 0 0 -20px;
    padding: 0
  }

  .accordion-inner .calc,
  .accordion-inner .calc-rows-wrap {
    border: none;
    padding: 0
  }

  .accordion-inner .calc-header {
    margin: 0 -20px
  }

  .accordion-inner .calc-row {
    flex-wrap: wrap;
    padding-bottom: 15px
  }

  .accordion-inner .calc-row .text-md {
    margin-left: 15px;
    text-align: left;
    width: auto
  }

  .accordion-inner .calc-footer {
    padding: 12px 0;
    text-align: left
  }

  .accordion-inner .calc-rows-wrap .checkbox {
    display: block;
    margin-bottom: 10px
  }

  .accordion-inner .review-title .stars {
    margin-left: -63px;
    margin-top: 10px
  }

  .accordion-inner .review-title .stars+.text {
    margin-top: -26px
  }

  .accordion-inner .btn[data-rel=testimonial-popup],
  .accordion-inner .calc-form .datepicker-input,
  .accordion-inner .calc-form .single-datepicker,
  .accordion-inner .calc-rows-wrap .checkbox,
  .accordion-inner .review-title .stars {
    width: 100%
  }

  .accordion-inner .btn[data-rel=testimonial-popup] {
    display: block
  }
}

.social {
  font-size: 0
}

.social a,
.social span {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.social span {
  margin-right: 20px
}

.social span.text {
  margin-right: 40px
}

.social span:not(.text) {
  color: #323232;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px
}

.social a:not(.download),
.social a:not(.download):before {
  border-radius: 50%
}

.social a:not(.download) {
  height: 40px;
  margin-right: 10px;
  width: 40px
}

.social a:not(.download):last-child {
  margin-right: 0
}

.social a:not(.download):before {
  background-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform
}

.social a:not(.download) img,
.social a:not(.download) svg {
  left: 50%;
  max-height: 20px;
  max-width: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

.social.style-1 a:not(.download):before {
  border: 1px solid #e9e9e9
}

.social.style-1 .download {
  margin-left: 40px
}

.social.style-1.dark a:not(.download):before {
  background-color: #f7f7f7;
  border: none
}

.social.style-2 a:not(.download) {
  height: 46px;
  margin-right: 5px;
  width: 46px
}

.social.style-2 a:not(.download):last-child {
  margin-right: 0
}

.social.style-2 a:not(.download):before {
  opacity: 0;
  transform: scale(0)
}

.social.style-2 a:not(.download) svg {
  max-height: 38px;
  max-width: 38px;
  transform: scale(1.6) translate(-30%, -30%);
  transform-origin: center
}

.social.vertical-margin {
  margin: 15px 0
}

.social .share {
  margin-left: 0
}

.social.post-social .download {
  margin-left: 50px
}

@media (min-width: 1200px) {
  .social.style-1 a:not(.download):hover:before {
    opacity: 0;
    transform: scale(0)
  }

  .social.style-1 a:not(.download):hover circle,
  .social.style-1 a:not(.download):hover line,
  .social.style-1 a:not(.download):hover path,
  .social.style-1 a:not(.download):hover polygon,
  .social.style-1 a:not(.download):hover square {
    fill: #2ab0cd
  }

  .social.style-2 a:not(.download):hover:before {
    opacity: 1;
    transform: none
  }

  .social.style-2 a:not(.download):hover circle,
  .social.style-2 a:not(.download):hover line,
  .social.style-2 a:not(.download):hover path,
  .social.style-2 a:not(.download):hover polygon,
  .social.style-2 a:not(.download):hover square {
    fill: #2ab0cd
  }
}

@media (max-width: 767px) {
  .social.post-social .download {
    display: block;
    margin-left: 0;
    margin-top: 15px
  }
}

.calc-form>.text-sm {
  color: #323232;
  font-weight: 500;
  margin-bottom: 10px
}

.calc-form .single-datepicker+.text {
  margin-bottom: 15px
}

.calc-form .datepicker-input {
  margin-bottom: 10px;
  width: 36%
}

.calc {
  border: 1px solid #e9e9e9;
  border-radius: 5px
}

.calc-footer,
.calc-header {
  padding: 12px 20px
}

.calc-header {
  background-color: #f5f5f5
}

.calc-footer {
  border-top: 1px solid #e9e9e9;
  text-align: right
}

.calc-footer .text-sm {
  color: #323232
}

.calc-footer .text-sm span {
  color: #ffb947;
  font-size: 20px;
  font-weight: 700;
  margin-left: 15px
}

.calc-footer .text-sm sup {
  font-size: 14px;
  position: relative;
  top: -5px
}

.calc-rows-wrap {
  padding: 20px
}

.calc-row {
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  padding: 10px 0
}

.calc-row:last-child {
  border-bottom: none
}

.calc-row .checkbox {
  margin-right: auto
}

.calc-row .number-input .number-input-btns {
  position: relative
}

.calc-row .number-input .number-input-btns:after {
  content: "0";
  font-size: 14px;
  left: 50%;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%)
}

.calc-row .text-md {
  text-align: right;
  width: 120px
}

.calc-row:not(.checked) .number-input {
  pointer-events: none
}

.calc-row:not(.checked) .number-input input {
  color: transparent
}

.calc-row.checked .number-input .number-input-btns:after {
  opacity: 0
}

@media (min-width: 1200px) {
  .calc-header .checkbox:hover span {
    color: #2ab0cd
  }
}

.order-page .h1 span {
  color: #626262;
  font-weight: 400
}

.order-page .tab-toggle {
  border: none;
  font-size: 0
}

.order-page .tab-caption {
  color: #626262;
  font-size: 14px;
  height: 50px;
  line-height: 20px;
  margin: 0;
  padding: 12px 26px;
  text-align: left;
  width: 33.3333333333%
}

.order-page .tab-caption:before {
  background-color: #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
  bottom: 0;
  height: auto;
  left: -18px;
  right: 18px;
  top: 0;
  transform: none;
  z-index: -1
}

.order-page .tab-caption:after {
  background-color: #f7f7f7;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  content: "";
  height: 35px;
  position: absolute;
  right: 0;
  top: 7.6px;
  transform: rotate(45deg);
  width: 35px
}

.order-page .tab-caption:first-child {
  border-radius: 5px 0 0 5px;
  padding-left: 16px
}

.order-page .tab-caption:first-child:before {
  border-left: 1px solid #e9e9e9;
  border-radius: 5px 0 0 5px;
  left: 0
}

.order-page .tab-caption:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 16px
}

.order-page .tab-caption:last-child:before {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #e9e9e9;
  right: 0
}

.order-page .tab-caption:last-child:after {
  display: none
}

.order-page .tab-caption span {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #626262;
  font-size: 12px;
  height: 24px;
  line-height: 22px;
  margin-right: 15px;
  text-align: center;
  width: 24px
}

.order-page .tab-caption.active {
  color: #fff
}

.order-page .tab-caption.active:after,
.order-page .tab-caption.active:before {
  background-color: #2ab0cd;
  border-color: #2ab0cd
}

.order-page .tab-caption.active span {
  background-color: #fff;
  border-color: #fff;
  color: #2ab0cd
}

.order-page .tab-caption.active+.tab-caption+.tab-caption:after,
.order-page .tab-caption.active+.tab-caption+.tab-caption:before,
.order-page .tab-caption.active+.tab-caption:after,
.order-page .tab-caption.active+.tab-caption:before {
  background-color: transparent;
  border-color: #e9e9e9
}

.order-page .tab-caption.active+.tab-caption span,
.order-page .tab-caption.active+.tab-caption+.tab-caption span {
  border-color: #e9e9e9
}

.order-page .radio {
  margin-bottom: 20px;
  margin-right: 30px
}

.order-page .radio:last-child {
  margin-bottom: 0;
  margin-right: 0
}

.order-page .text-sm.title,
.order-page .text.title {
  display: block;
  margin-bottom: 10px
}

.order-page .text-sm.title.inline,
.order-page .text.title.inline {
  display: inline-block;
  margin-right: 15px
}

.order-page .number-input {
  display: inline-block;
  margin-right: 50px
}

.order-page .number-input:last-child {
  margin-right: 0
}

.order-page .number-input>.text-sm.title,
.order-page .number-input>.text.title {
  margin-bottom: 0
}

.order-page .thumb-price {
  border-top: none;
  padding-top: 0
}

.order-page .thumb-price .text span {
  font-size: 30px;
  line-height: 40px;
  margin-left: 0
}

.order-page .thumb-price .text sup {
  font-size: 14px;
  line-height: 18px;
  position: relative;
  top: -12px
}

.order-page .tab-top-part>div.text-right .btn,
.order-page .tab-top-part>div.text-right .checkbox,
.order-page .tab-top-part>div.text-right .text {
  vertical-align: middle
}

.order-page .tab-top-part>div.text-right .btn {
  margin-left: 30px
}

.order-page .bordered-box {
  border-radius: 10px
}

.order-page .bordered-box>div {
  align-items: center;
  display: flex;
  justify-content: space-between
}

.order-page .bordered-box .thumb-price {
  display: inline-block
}

.order-page .bordered-box .thumb-price .text span {
  font-size: 20px;
  line-height: 30px
}

.order-page .bordered-box .thumb-price .text sup {
  top: -7px
}

.order-page .bordered-box .row {
  margin: 0
}

.order-page .bordered-box hr {
  margin: 12px 0
}

.order-page .tooltip-wrap .tooltip {
  display: inline;
  padding: 10px;
  text-align: center
}

.datepicker-input input {
  border-color: transparent;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  padding-left: 45px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3
}

.datepicker-input.active input {
  opacity: 1;
  pointer-events: auto
}

.single-datepicker .datepicker-input .datepicker-placeholder input {
  padding: 12px 15px 10px
}

.participant {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  padding-right: 40px;
  position: relative
}

.participant:last-child {
  margin-bottom: 0
}

.participant .h4 {
  min-width: 90px;
  white-space: nowrap
}

.participant .h4 span {
  background-color: #ffb947;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 22px
}

.participant .form {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-left: 40px
}

.participant .form>.text {
  display: block;
  margin-top: 5px;
  white-space: nowrap;
  width: 100%
}

.participant .datepicker-input,
.participant label {
  margin-bottom: 0
}

.participant .single-datepicker,
.participant label {
  width: calc(25% - 15px)
}

.participant label {
  margin-right: 20px
}

.participant .datepicker-input {
  padding-left: 0
}

.participant .datepicker-input:before {
  left: 15px;
  z-index: 4
}

.participant .datepicker-input input {
  border-color: transparent;
  left: 0;
  opacity: 0;
  padding-left: 45px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3
}

.participant .datepicker-input.active input,
.participant .datepicker-input.filled input {
  opacity: 1;
  pointer-events: auto
}

.participant .datepicker-input.active {
  z-index: 5
}

.participant .datepicker-placeholder {
  padding-left: 45px
}

.participant .btn-delete {
  top: 16px
}

.participant.bottom-text {
  padding-bottom: 22px
}

.participant.bottom-text .form>.text {
  margin-bottom: -22px
}

.participant.phone-number .form>.text {
  display: inline-block;
  white-space: normal;
  width: 36%
}

.participant.filled .h4 span {
  margin-right: 40px;
  position: relative
}

.participant.filled .h4 span:after {

  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  right: -30px;
  top: 50%;
  width: 16px
}

.participant.filled .h4+.form {
  padding-left: 0
}

.apartment-option {
  margin-bottom: 15px
}

.apartment-option.active .text {
  color: #323232
}

.radio-accordion {
  display: block;
  margin-bottom: 20px
}

.radio-accordion .radio {
  margin-bottom: 0
}

.radio-accordion-toggle {
  display: none;
  padding-top: 5px
}

.checkbox-wrap .checkbox {
  margin-bottom: 15px
}

.checkbox-wrap .checkbox:last-child {
  margin-bottom: 0
}

.radio-datepickers {
  position: relative
}

.radio-additional-tab,
.radio-additional-tab-inner,
.radio-datepicker,
.radio-datepicker-inner {
  transition: opacity .3s ease-out
}

.radio-additional-tab-inner:not(.active),
.radio-additional-tab:not(.active),
.radio-datepicker-inner:not(.active),
.radio-datepicker:not(.active) {
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2
}

.radio-additional-tab-inner.active,
.radio-additional-tab.active,
.radio-datepicker-inner.active,
.radio-datepicker.active {
  position: relative
}

.radio-additional-tab-inner.radio-datepicker:after,
.radio-additional-tab.radio-datepicker:after,
.radio-datepicker-inner.radio-datepicker:after,
.radio-datepicker.radio-datepicker:after {
  display: none
}

@media (min-width: 1200px) {
  .order-page .tab-caption:not(.active):hover {
    color: #2ab0cd
  }

  .order-page .tab-caption:not(.active):hover span {
    background-color: #2ab0cd;
    border-color: #2ab0cd;
    color: #fff
  }
}

@media (max-width: 1199px) {
  .apartment-option {
    align-items: center;
    display: flex
  }

  .apartment-option .number-input {
    margin-right: 15px
  }

  .apartment-option .number-input-btns {
    width: 119px
  }
}

@media (max-width: 991px) {
  .order-page .col-12.only-mobile {
    display: block !important;
    margin-bottom: 15px
  }

  .participant {
    border-top: 1px solid #e9e9e9;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-right: 0
  }

  .participant:last-child {
    border-bottom: 1px solid #e9e9e9
  }

  .participant .h4 {
    margin-bottom: 0;
    min-width: 100%;
    padding: 15px 0
  }

  .participant .h4:after {
    border-bottom: 1px solid #2ab0cd;
    border-right: 1px solid #2ab0cd;
    content: "";
    display: inline-block;
    height: 6px;
    margin-left: 15px;
    margin-top: -5px;
    position: absolute;
    position: relative;
    top: 50%;
    top: auto;
    transform: rotate(45deg);
    vertical-align: middle;
    width: 6px
  }

  .participant .h4.active:after {
    border-color: #323232;
    transform: rotate(225deg) translate3d(-4px, -2px, 0)
  }

  .participant .h4 i {

    display: none;
    height: 16px;
    margin-left: 15px;
    vertical-align: middle;
    width: 16px
  }

  .participant .h4 i.active {
    display: inline-block
  }

  .participant .form {
    display: block;
    padding-bottom: 25px;
    padding-left: 0
  }

  .participant .form>.text {
    float: left
  }

  participant .form {
    display: block !important
  }

  .participant .datepicker-input,
  .participant label {
    margin-bottom: 0
  }

  .participant .single-datepicker,
  .participant label {
    float: left;
    margin-bottom: 16px;
    width: calc(50% - 8px)
  }

  .participant .single-datepicker:nth-child(odd),
  .participant label:nth-child(odd) {
    margin-right: 16px
  }

  .participant .single-datepicker:last-child,
  .participant label:last-child {
    margin-bottom: 0
  }

  .participant label {
    margin-right: 0
  }

  .participant .btn-delete {
    top: 18px
  }

  .participant.bottom-text {
    padding-bottom: 0
  }

  .participant.bottom-text .form>.text {
    margin-bottom: 0
  }
}

@media (min-width: 768px) {
  .order-page .text b {
    white-space: nowrap
  }
}

@media (max-width: 767px) {
  .order-page .tab-toggle {
    text-align: left;
    white-space: nowrap
  }

  .order-page .tab-caption {
    flex: 0 0 33.3333333333%;
    height: 40px;
    max-width: 33.3333333333%;
    padding: 8px 30px 8px 15px;
    width: auto
  }

  .order-page .tab-caption:not(.active) {
    font-size: 0;
    padding: 8px 10px
  }

  .order-page .tab-caption.active {
    flex: 0 0 calc(100% - 108px);
    max-width: calc(100% - 108px);
    white-space: nowrap;
    width: calc(100% - 108px)
  }

  .order-page .tab-caption.active:after {
    z-index: -1
  }

  .order-page .tab-caption:after {
    height: 28px;
    right: 4px;
    top: 5.6px;
    width: 28px
  }

  .order-page .tab-caption span {
    margin-right: 10px;
    position: relative;
    z-index: 1
  }

  .order-page .datepicker-placeholder {
    white-space: nowrap
  }

  .order-page .number-input {
    margin-right: 20px
  }

  .order-page .number-input .text-sm.title.inline {
    display: block;
    margin-bottom: 5px
  }

  .order-page .number-input:last-child {
    margin-right: 0
  }

  .order-page .tab-top-part>div.text-right .btn {
    margin-left: 0
  }

  .order-page .checkbox.small+.text-sm {
    font-size: 12px;
    line-height: 12px
  }

  .order-page .col-pidar-1 {
    flex: 0 0 calc(100% - 150px);
    max-width: calc(100% - 150px)
  }

  .order-page .col-pidar-1 .radio-datepickers .radio-datepicker.datepicker-input {
    max-width: 100%
  }

  .order-page .col-pidar-2 {
    flex: 0 0 150px;
    max-width: 150px
  }

  .radio-datepickers .radio-datepicker.datepicker-input {
    max-width: calc(50vw - 30px)
  }

  .participant .h4 i {

    display: none;
    height: 16px;
    margin-left: 15px;
    vertical-align: middle;
    width: 16px
  }

  .participant .h4 i.active {
    display: inline-block
  }

  .participant .datepicker-input,
  .participant label {
    margin-bottom: 10px
  }

  .participant .single-datepicker,
  .participant label {
    width: 100%
  }

  .participant .single-datepicker:nth-child(odd),
  .participant label:nth-child(odd) {
    margin-right: 0
  }

  .apartment-option {
    align-items: flex-start;
    display: flex
  }
}

.registration-page .h1 {
  margin-bottom: 10px
}

.registration-page .h1 span {
  color: #2ab0cd
}

.registration-page .h1+.text {
  color: #323232
}

.registration-page .tab-toggle {
  border: none;
  font-size: 0
}

.registration-page .tab-caption {
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  color: #626262;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 -2px 0 0;
  padding: 8px 30px;
  text-align: left
}

.registration-page .tab-caption:before {
  display: none
}

.registration-page .tab-caption:first-child {
  border-radius: 5px 0 0 5px
}

.registration-page .tab-caption:last-child {
  border-radius: 0 5px 5px 0;
  margin-right: 0
}

.registration-page .tab-caption span {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #626262;
  font-size: 12px;
  height: 24px;
  line-height: 22px;
  margin-right: 15px;
  text-align: center;
  width: 24px
}

.registration-page .tab-caption.active {
  border-color: #2ab0cd;
  color: #2ab0cd;
  z-index: 1
}

.or {
  position: relative;
  text-align: center
}

.or:before {
  background-color: #e9e9e9;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%
}

.or span {
  background-color: #fff;
  display: inline-block;
  padding: 0 10px;
  position: relative
}

@media (min-width: 1200px) {
  .registration-page .tab-caption:hover {
    color: #2ab0cd
  }
}

.certificate-order-page .tab-toggle {
  border-bottom: none
}

.certificate-order-page .tab-caption {
  margin-right: 10px
}

.certificate-order-page .tab-caption:last-child {
  margin-right: 0
}

.certificate-order-page .tab-caption:last-child:after {
  opacity: 0
}

.certificate-order-page .tab-caption:before {
  background-color: transparent;
  bottom: 0;
  bottom: auto;
  color: #323232;
  content: attr(data-text);
  font-size: inherit;
  font-weight: 500;
  height: auto;
  left: 35px;
  line-height: inherit;
  opacity: 0;
  right: auto;
  top: 1px;
  transform: none
}

.certificate-order-page .tab-caption:after {
  background-color: #e9e9e9;
  content: "";
  display: inline-block;
  height: 1px;
  margin-left: 10px;
  position: relative;
  vertical-align: middle;
  width: 110px
}

.certificate-order-page .tab-caption span {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  font-weight: 700;
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
  text-align: center;
  width: 25px
}

.certificate-order-page .tab-caption.active {
  color: transparent
}

.certificate-order-page .tab-caption.active:before {
  opacity: 1
}

.certificate-order-page .tab-caption.active span {
  background-color: #2ab0cd;
  border-color: #2ab0cd;
  color: #fff
}

.certificate-order-page .tab-top-part {
  align-items: center
}

.certificate-order-page .radio {
  margin-bottom: 10px;
  margin-right: 30px
}

.certificate-order-page .radio:last-of-type {
  margin-right: 0
}

@media (min-width: 1200px) {
  .certificate-order-page .tab-caption:hover span {
    background-color: #2ab0cd;
    border-color: #2ab0cd;
    color: #fff
  }
}

@media (max-width: 991px) {
  .certificate-order-page .tab-caption:after {
    width: 50px
  }
}

@media (max-width: 575px) {
  .certificate-order-page .tab-nav {
    margin-right: -10px
  }

  .certificate-order-page .tab-caption {
    font-size: 12px;
    white-space: nowrap
  }

  .certificate-order-page .tab-caption:last-child {
    margin-right: 10px
  }

  .certificate-order-page .tab-caption>span {
    margin-right: 3px
  }

  .certificate-order-page .tab-caption:before {
    left: 28px
  }

  .certificate-order-page .tab-caption:after {
    display: none
  }
}

#content-block.cabinet-page {
  padding-top: 50px
}

.cabinet-page {
  overflow: visible
}

.cabinet-page .tab-toggle {
  border-bottom: none;
  padding-top: 10px
}

.cabinet-page .tab {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 60px
}

.cabinet-page .tab .h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px
}

.cabinet-page .tab .h2+.text {
  margin-top: 10px
}

.cabinet-page .tab .h4 {
  display: block;
  margin-bottom: 14px
}

.cabinet-page .tab hr {
  margin: 35px -60px
}

.cabinet-page .tab .btn-read-more {
  font-size: 12px
}

.cabinet-page .tab .btn-read-more:after {
  display: none
}

.cabinet-page .tab .align-items-center {
  align-items: center
}

.cabinet-page .tab .img-input-wrap {
  align-items: center;
  display: flex
}

.cabinet-page .tab .img-input-wrap .img,
.cabinet-page .tab .img-input-wrap .loaded-img {
  border-radius: 10px;
  height: 80px;
  width: 80px
}

.cabinet-page .tab .img-input-wrap .img img,
.cabinet-page .tab .img-input-wrap .loaded-img img {
  min-height: 100%;
  min-width: 100%
}

.cabinet-page .tab .img-input-wrap .img {
  float: left;
  overflow: hidden
}

.cabinet-page .tab .img-input-wrap .loaded-img {
  margin-bottom: 0;
  margin-left: 20px
}

.cabinet-page .tab .img-input-wrap .img-input {
  height: auto;
  width: auto
}

.cabinet-page .tab .img-input-wrap .btn {
  margin-bottom: 0;
  margin-left: 20px
}

.cabinet-page .accordion.type-1 .accordion-title {
  align-items: center;
  display: flex;
  padding-left: 0
}

.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center,
.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center .text-sm,
.cabinet-page .accordion.type-1 .accordion-title .h4,
.cabinet-page .accordion.type-1 .accordion-title .text {
  display: inline-block;
  height: auto;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
  vertical-align: middle;
  width: auto
}

.cabinet-page .accordion.type-1 .accordion-title .text {
  margin-right: 40px
}

.cabinet-page .accordion.type-1 .accordion-title .h4 {
  margin-bottom: 0
}

.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center {
  margin-left: auto;
  margin-right: 70px;
  min-width: 120px
}

.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center.waiting .text-sm:before {
  background-color: #ffe4b8
}

.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center.paid .text-sm:before {
  background-color: #d5ecbd
}

.cabinet-page .accordion.type-1 .accordion-title .calendar-header-center.canceled .text-sm:before {
  background-color: #e9e9e9
}

.cabinet-page .accordion.type-1 .accordion-title .download {
  margin-right: 20px
}

.cabinet-page .accordion.type-1 .more-info {
  padding-left: 0
}

.cabinet-page .accordion.type-1 .more-info .text-sm {
  line-height: 22px
}

.history {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 0 20px 10px
}

.history .row {
  padding: 10px 0;
  position: relative
}

.history .row:after {
  background-color: #e9e9e9;
  bottom: 0;
  content: "";
  height: 1px;
  left: 15px;
  position: absolute;
  right: 15px
}

.history .row:last-child:after {
  display: none
}

.history .row>div:last-child .text {
  color: #323232
}

.history .download {
  margin-bottom: 10px
}

.history .download:last-child {
  margin-bottom: 0
}

.history .btn {
  margin-top: 15px
}

.delete-account {
  color: #929292;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px
}

.additional-block {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 10px 20px 30px
}

.additional-block>div {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px
}

.additional-block>div:last-child {
  margin-bottom: 0
}

.additional-block>div>.text {
  padding-right: 20px
}

.additional-block>div .morder-info {
  margin-bottom: 10px
}

.add-note {
  border-bottom: none !important;
  flex-wrap: wrap
}

.add-note-btns,
.add-note-input,
.add-note-toggle {
  display: block;
  width: 100%
}

.add-note-toggle {
  display: none
}

.add-note-btns {
  font-size: 0
}

.add-note-btns>span {
  display: inline-block;
  vertical-align: middle
}

.add-note-btn,
.add-note-btn-cancel,
.cancel-tour-btn {
  color: #323232;
  cursor: pointer;
  text-transform: uppercase
}

.add-note-btn-cancel {
  margin-left: 20px
}

@media (min-width: 1200px) {
  .cabinet-page .accordion.type-1 .accordion-title:hover .h4 {
    color: #2ab0cd
  }

  .add-note-btn .text-bold:hover,
  .delete-account:hover {
    color: #7ebd3e
  }

  .cabinet-page {
    background-color: #fafafa
  }

  .cabinet-page .tab-nav {
    position: sticky
  }

  .cabinet-page .tab-caption {
    display: block;
    margin-bottom: 20px;
    margin-right: 0;
    text-align: left
  }

  .cabinet-page .tab-caption:last-child {
    margin-bottom: 0
  }

  .cabinet-page .tab-caption:before {
    display: none
  }

  .cabinet-page .tab-caption svg {
    max-height: 20px;
    max-width: 20px
  }

  .cabinet-page .tab-caption a {
    display: block;
    width: 100%
  }

  .cabinet-page .tab-caption.active {
    color: #2ab0cd
  }
}

@media (max-width: 1199px) {
  .cabinet-page {
    overflow: hidden
  }

  .cabinet-page .tab-nav,
  .faq-tab-nav {
    align-items: center;
    display: flex;
    overflow: auto;
    white-space: nowrap
  }

  .cabinet-page .tab-nav::-webkit-scrollbar,
  .faq-tab-nav::-webkit-scrollbar {
    height: 2px;
    width: 2px
  }

  .cabinet-page .tab-nav::-webkit-scrollbar-track,
  .faq-tab-nav::-webkit-scrollbar-track {
    background-color: transparent
  }

  .cabinet-page .tab-nav::-webkit-scrollbar-thumb,
  .faq-tab-nav::-webkit-scrollbar-thumb {
    background-color: #929292
  }

  .cabinet-page .tab-nav+.spacer-xs,
  .faq-tab-nav+.spacer-xs {
    display: none
  }

  .cabinet-page .tab-nav .delete-account,
  .faq-tab-nav .delete-account {
    color: #626262;
    margin-bottom: 10px;
    margin-left: 40px
  }

  .cabinet-page .tab-nav .tab-toggle,
  .faq-tab-nav .tab-toggle {
    display: block !important
  }

  .add-note-btn .text-bold {
    color: #323232;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 24px;
    text-transform: uppercase
  }

  .cabinet-page .tab-caption a {
    pointer-events: none
  }
}

@media (max-width: 991px) {
  .cabinet-page .tab {
    padding: 30px 0
  }

  .cabinet-page .accordion.type-1>.accordion-item.active>.accordion-inner,
  .cabinet-page .accordion.type-1>.accordion-item:not(.active)>.accordion-inner {
    padding: 0 0 30px
  }

  .cabinet-page .accordion.type-1>.accordion-item.active>.accordion-inner .history,
  .cabinet-page .accordion.type-1>.accordion-item:not(.active)>.accordion-inner .history {
    padding: 0 20px
  }

  .cabinet-page .accordion.type-1 .accordion-title .calendar-header-center {
    margin-left: auto;
    margin-right: 15px
  }

  .faq-tab-nav {
    margin-bottom: 30px
  }
}

@media (max-width: 767px) {
  .cabinet-page .delete-account {
    margin-left: 20px
  }

  .cabinet-page .accordion.type-1 .accordion-title {
    padding-bottom: 15px;
    padding-right: 35px;
    padding-top: 60px
  }

  .cabinet-page .accordion.type-1 .accordion-title .calendar-header-center,
  .cabinet-page .accordion.type-1 .accordion-title .download,
  .cabinet-page .accordion.type-1 .accordion-title>.text {
    position: absolute;
    top: 0
  }

  .cabinet-page .accordion.type-1 .accordion-title>.text {
    left: 0;
    margin-right: 0;
    top: 20px
  }

  .cabinet-page .accordion.type-1 .accordion-title .calendar-header-center {
    left: 85px;
    top: 16px
  }

  .cabinet-page .accordion.type-1 .accordion-title .calendar-header-center>.text-sm {
    bottom: auto
  }

  .cabinet-page .accordion.type-1 .accordion-title .download {
    margin-right: 0;
    right: 50px;
    top: 15px
  }

  .cabinet-page .accordion.type-1 .accordion-title>i {
    right: 0;
    top: 16px
  }

  .cabinet-page .accordion.type-1 .accordion-item.active .accordion-inner .additional-block,
  .cabinet-page .accordion.type-1 .accordion-item.active .accordion-inner .history,
  .cabinet-page .accordion.type-1 .accordion-item:not(.active) .accordion-inner .additional-block,
  .cabinet-page .accordion.type-1 .accordion-item:not(.active) .accordion-inner .history {
    background-color: transparent;
    padding: 0
  }
}

footer {
  background-color: #fafafa;
  position: relative
}

footer .text,
footer span .text-md {
  line-height: 20px
}

footer span.text-md {
  color: #323232;
  display: block;
  font-weight: 500
}

footer li {
  margin-bottom: 8px
}

footer li:last-child {
  margin-bottom: 0
}

footer .btn-more:not(.text-uppercase) {
  font-size: 16px;
  letter-spacing: .1px;
  line-height: 26px;
  text-transform: none
}

footer .accordion-item {
  margin-bottom: 20px
}

footer .accordion-inner {
  padding-top: 12px
}

footer .accordion-inner .social {
  margin-bottom: 12px
}

footer .accordion-inner .contact {
  padding-left: 30px
}

footer .accordion-inner .contact .img {
  display: flex;
  left: 0;
  position: absolute;
  top: 1px
}

footer .accordion-inner>.btn,
footer .accordion-inner>.img {
  vertical-align: middle
}

footer .accordion-inner>.img {
  border-radius: 50%;
  display: inline-block;
  height: 48px;
  margin: 0 15px -2px 0;
  overflow: hidden;
  width: 48px
}

footer .accordion-inner>.img img {
  width: 100%
}

footer .accordion-inner .spacer-sm {
  height: 30px
}

footer .accordion-inner .spacer-xs {
  height: 15px
}

.footer-bottom {
  background-color: #e9e9e9;
  padding: 15px 0
}

.footer-bottom .row {
  align-items: center
}

.footer-bottom ul {
  text-align: center
}

.footer-bottom li {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 45px;
  vertical-align: middle
}

.footer-bottom li:last-child {
  margin-right: 0
}

.contacts-block {
  margin-bottom: 30px
}

.contacts-block:last-child {
  margin-bottom: 0
}

.contacts-block>.text-md {
  color: #323232;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 12px
}

.contacts-block form label {
  margin: 0 0 10px
}

.contacts-block form .btn-block {
  margin-top: 20px
}

@media (min-width: 1200px) {
  footer a.text:hover {
    color: #7ebd3e
  }
}

@media (min-width: 1600px) {
  footer .container-fluid {
    padding: 0 8%
  }
}

@media (min-width: 1200px) {
  footer .accordion-item:last-child {
    margin-bottom: 0
  }

  footer .accordion-title {
    pointer-events: none
  }

  footer .accordion-title a {
    pointer-events: auto
  }

  footer .accordion-inner {
    display: block !important
  }
}

@media (max-width: 1199px) {
  footer .container {
    padding: 0 40px
  }

  footer .accordion-item {
    margin-bottom: 20px
  }

  footer .accordion-title:after {
    border-bottom: 1px solid #323232;
    border-right: 1px solid #323232;
    content: "";
    height: 6px;
    margin-top: -5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(45deg);
    width: 6px
  }

  footer .accordion-item.active .accordion-title:after {
    border-color: #323232;
    transform: rotate(225deg) translate3d(-4px, -2px, 0)
  }

  .footer-top .row>div .accordion-title .btn-more,
  .footer-top .row>div>.btn-more {
    color: #323232;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px
  }

  .footer-top .row>div .accordion-title .btn-more:after,
  .footer-top .row>div>.btn-more:after {
    display: none
  }

  .footer-top .row>div>.btn-more {
    margin-bottom: 20px
  }

  .footer-top .row>div:last-child>:last-child {
    margin-bottom: 0
  }

  .footer-top li {
    margin-bottom: 15px
  }

  .footer-bottom,
  .footer-bottom .text-right {
    text-align: center
  }

  .footer-bottom {
    padding: 30px 0
  }

  .footer-bottom .row>div:first-child {
    order: 3
  }

  .footer-bottom .row>div:nth-child(2) {
    order: 2
  }

  .footer-bottom .row>div:last-child {
    order: 1
  }

  .footer-bottom .social,
  .footer-bottom .social span,
  .footer-bottom ul {
    margin-bottom: 20px
  }

  .footer-bottom .social span {
    margin-right: 0;
    width: 100%
  }

  .footer-bottom li {
    margin-right: 20px;
    position: relative
  }

  .footer-bottom li:after {
    bottom: 0;
    content: "|";
    position: absolute;
    right: -14px;
    top: 0
  }

  .footer-bottom li:last-child:after {
    display: none
  }
}

@media (max-width: 767px) {
  footer .container {
    padding: 0 35px
  }
}

.datepicker--cells {
  display: flex;
  flex-wrap: wrap
}

.datepicker--cell {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1
}

.datepicker--cell.-focus- {
  background: #f0f0f0
}

.datepicker--cell.-current- {
  color: #4eb5e6
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a
}

.datepicker--cell.-current-.-in-range- {
  color: #4eb5e6
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, .2)
}

.datepicker--cell.-disabled- {
  color: #aeaeae;
  cursor: default
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae
}

.datepicker--cell.-selected- {
  border: none
}

.datepicker--cell.-selected-.-current- {
  color: #fff
}

.datepicker--cell.-selected-.-focus- {
  background: #45bced
}

.datepicker--cell:empty {
  cursor: default
}

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap
}

.datepicker--day-name {
  align-items: center;
  color: #ffb947;
  display: flex;
  flex: 1;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase
}

.datepicker--cell-day {
  width: 14.28571%
}

.datepicker--cells-months {
  height: 170px
}

.datepicker--cell-month {
  height: 25%;
  width: 33.33%
}

.datepicker--cells-years,
.datepicker--years {
  height: 170px
}

.datepicker--cell-year {
  height: 33.33%;
  width: 25%
}

.datepickers-container {
  left: 0;
  position: absolute;
  top: 0
}

@media print {
  .datepickers-container {
    display: none
  }
}

.datepicker {
  box-sizing: content-box;
  left: -100000px;
  opacity: 0;
  position: absolute;
  transition: opacity .3s ease, transform .3s ease, left .3s ease;
  z-index: 98
}

.datepicker.-from-top- {
  transform: translateY(-8px)
}

.datepicker.-from-right- {
  transform: translateX(8px)
}

.datepicker.-from-bottom- {
  transform: translateY(8px)
}

.datepicker.-from-left- {
  transform: translateX(-8px)
}

.datepicker.active {
  opacity: 1;
  transform: translate(0);
  transition: opacity .3s ease, transform .3s ease
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  left: auto;
  opacity: 1;
  position: static;
  right: auto;
  transform: none
}

.datepicker-inline .datepicker--pointer {
  display: none
}

.datepicker--content {
  box-sizing: content-box
}

.-only-timepicker- .datepicker--content {
  display: none
}

.datepicker--pointer {
  background: #fff;
  border-right: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: -1
}

.-top-center- .datepicker--pointer,
.-top-left- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  transform: rotate(135deg)
}

.-right-bottom- .datepicker--pointer,
.-right-center- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  right: calc(100% - 4px);
  transform: rotate(225deg)
}

.-bottom-center- .datepicker--pointer,
.-bottom-left- .datepicker--pointer,
.-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  transform: rotate(315deg)
}

.-left-bottom- .datepicker--pointer,
.-left-center- .datepicker--pointer,
.-left-top- .datepicker--pointer {
  left: calc(100% - 4px);
  transform: rotate(45deg)
}

.-bottom-left- .datepicker--pointer,
.-top-left- .datepicker--pointer {
  left: 10px
}

.-bottom-right- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  right: 10px
}

.-bottom-center- .datepicker--pointer,
.-top-center- .datepicker--pointer {
  left: calc(50% - 5px)
}

.-left-top- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  top: 10px
}

.-left-bottom- .datepicker--pointer,
.-right-bottom- .datepicker--pointer {
  bottom: 10px
}

.-left-center- .datepicker--pointer,
.-right-center- .datepicker--pointer {
  top: calc(50% - 5px)
}

.datepicker--body {
  display: none
}

.datepicker--body.active {
  display: block
}

.datepicker--nav {
  border-bottom: 1px solid #efefef;
  display: flex;
  min-height: 32px;
  z-index: 1
}

.-only-timepicker- .datepicker--nav {
  display: none
}

.datepicker--nav-action,
.datepicker--nav-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 1
}

.datepicker--nav-action {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.datepicker--nav-action.-disabled- {
  visibility: hidden
}

.datepicker--nav-action svg {
  height: 32px;
  width: 32px
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px
}

.datepicker--nav-title {
  color: #323232;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 15px
}

.datepicker--nav-title.-disabled- {
  background: none;
  cursor: default
}

.datepicker--buttons {
  border-top: 1px solid #efefef;
  display: flex;
  padding: 4px
}

.datepicker--button {
  align-items: center;
  border-radius: 4px;
  color: #4eb5e6;
  cursor: pointer;
  display: inline-flex;
  flex: 1;
  height: 32px;
  justify-content: center
}

.datepicker--time {
  align-items: center;
  border-top: 1px solid #efefef;
  display: flex;
  padding: 4px;
  position: relative
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  flex: 0 1 138px;
  max-width: 138px
}

.-only-timepicker- .datepicker--time {
  border-top: none
}

.datepicker--time-sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px
}

.datepicker--time-label {
  display: none;
  font-size: 12px
}

.datepicker--time-current {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  margin: 0 0 0 10px;
  text-align: center
}

.datepicker--time-current-colon {
  line-height: 1;
  margin: 0 2px 3px
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-size: 19px;
  line-height: 1;
  position: relative;
  z-index: 1
}

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  background: #f0f0f0;
  border-radius: 4px;
  bottom: -2px;
  content: "";
  left: -2px;
  opacity: 0;
  position: absolute;
  right: -2px;
  top: -3px;
  z-index: -1
}

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1
}

.datepicker--time-current-ampm {
  align-self: flex-end;
  color: #9c9c9c;
  font-size: 11px;
  margin-bottom: 1px;
  margin-left: 6px;
  text-transform: uppercase
}

.datepicker--time-row {
  align-items: center;
  background: linear-gradient(90deg, #dedede, #dedede) left 50%/100% 1px no-repeat;
  display: flex;
  font-size: 11px;
  height: 17px
}

.datepicker--time-row:first-child {
  margin-bottom: 4px
}

.datepicker--time-row input[type=range] {
  -webkit-appearance: none;
  background: none;
  cursor: pointer;
  flex: 1;
  height: 100%;
  margin: 0;
  padding: 0
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none
}

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none
}

.datepicker--time-row input[type=range]:focus {
  outline: none
}

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef
}

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef
}

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  height: 12px;
  -webkit-transition: background .2s;
  transition: background .2s;
  width: 12px
}

.datepicker--time-row input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  height: 12px;
  -moz-transition: background .2s;
  transition: background .2s;
  width: 12px
}

.datepicker--time-row input[type=range]::-ms-thumb {
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  height: 12px;
  -ms-transition: background .2s;
  transition: background .2s;
  width: 12px
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: -6px
}

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  height: 1px
}

.datepicker--time-row input[type=range]::-moz-range-track {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  height: 1px
}

.datepicker--time-row input[type=range]::-ms-track {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  height: 1px
}

.datepicker--time-row input[type=range]::-ms-fill-lower,
.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent
}

.datepicker--time-row span {
  padding: 0 12px
}

.datepicker--time-icon {
  border: 1px solid;
  border-radius: 50%;
  color: #9c9c9c;
  font-size: 16px;
  height: 1em;
  margin: 0 5px -1px 0;
  position: relative;
  width: 1em
}

.datepicker--time-icon:after,
.datepicker--time-icon:before {
  background: currentColor;
  content: "";
  position: absolute
}

.datepicker--time-icon:after {
  height: .4em;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  transform: translateY(-100%);
  width: 1px
}

.datepicker--time-icon:before {
  height: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  width: .4em
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-,
.-disabled-.-focus-.datepicker--cell-year.-other-decade-,
.datepicker--cell-day.-other-month-,
.datepicker--cell-year.-other-decade- {
  color: #dedede
}

.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade- {
  background: #a2ddf6;
  color: #fff
}

.-selected-.-focus-.datepicker--cell-day.-other-month-,
.-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4
}

.-in-range-.datepicker--cell-day.-other-month-,
.-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, .1);
  color: #ccc
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-,
.-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, .2)
}

.datepicker--cell-day.-other-month-:empty,
.datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none
}

.datepicker {
  width: 100%
}

.datepicker--pointer {
  display: none
}

.datepicker-wrap {
  padding-right: 10%
}

.datepicker--nav {
  align-items: center
}

.datepicker--nav-title {
  display: inline-block;
  margin: 0 auto
}

.datepicker--content {
  padding-top: 10px;
  position: relative;
  z-index: 1
}

.datepicker--nav-action path {
  stroke: #323232
}

.datepicker--days-names {
  position: relative
}

.datepicker--cell:not(.datepicker--cell-month),
.datepicker--day-name {
  color: #626262;
  flex: 0 0 14.2857142857%;
  line-height: 36px;
  max-width: 14.2857142857%;
  text-align: center;
  text-transform: uppercase
}

.datepicker--cell:not(.datepicker--cell-month):before,
.datepicker--day-name:before {
  border-radius: 50%;
  content: "";
  height: 32px;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 32px;
  z-index: -1
}

.datepicker--cell {
  font-size: 12px;
  line-height: 31px
}

.datepicker--cell.-other-month- {
  color: #e9e9e9
}

.datepicker--cell.-current- {
  color: inherit
}

.datepicker--cell.-current-:before {
  background-color: #e9e9e9
}

.datepicker--cell.-in-range-,
.datepicker--cell.-selected- {
  background-color: transparent !important
}

.datepicker--cell.-selected- {
  color: #fff
}

.datepicker--cell.-selected-:before {
  background-color: #ffb947
}

.datepicker--cell.-in-range- {
  color: #626262
}

.datepicker--cell.-in-range-:before {
  background-color: #ffd289 !important
}

.datepicker--cell-month,
.datepicker--day-name {
  font-size: 10px;
  font-weight: 500;
  line-height: 24px
}

.datepicker-block nav {
  margin: 0
}

.datepicker-block .datepicker--cell,
.datepicker-block .datepicker--day-name,
.datepicker-block .datepicker--nav {
  height: 42px
}

.datepicker-block .datepicker--cell.-selected-,
.datepicker-block .datepicker--cell.-selected-:first-child,
.datepicker-block .datepicker--cell.-selected-:last-child {
  background: #ffb947;
  color: #fff
}

.datepicker-block .datepicker--cell.-in-range- {
  background-color: #626262;
  color: #626262
}

.datepicker-block .flex {
  align-items: center
}

.datepicker-block .flex .button-done {
  margin-left: 15px;
  white-space: nowrap
}

@media (min-width: 1200px) {
  .datepicker--nav-action:hover {
    background-color: transparent
  }

  .datepicker--nav-action:hover path {
    stroke: #2ab0cd
  }

  .datepicker--nav-title:hover {
    color: #2ab0cd
  }

  .datepicker--cell.-selected-,
  .datepicker--cell:not(.-selected-) {
    background-color: transparent !important
  }

  .datepicker--cell:not(.-selected-):hover:before {
    background-color: #fff3e0
  }

  .datepicker--cell.-selected-:hover:before {
    background-color: #ffb947 !important
  }

  .datepicker--cell.-focus- {
    background-color: #ffb947
  }
}

@media (max-width: 1799px) {
  .datepicker-wrap {
    padding-right: 6%
  }
}

@media (max-width: 1599px) {
  .datepicker-wrap {
    padding-right: 15px
  }

  .datepicker-block,
  .datepicker-block+div+div {
    margin-right: -7%
  }

  .datepicker-block .select2-container--default .select2-selection--single .select2-selection__rendered {
    height: 52px;
    padding: 14px 40px 14px 20px
  }

  .datepicker-block .select2-container--default .select2-results>.select2-results__options {
    padding: 14px 20px
  }

  .datepicker-block .select2-results__option {
    padding: 6px 0
  }

  .datepicker-block .select2-container--default .select2-selection--single .select2-selection__arrow b {
    right: 20px
  }
}

@media (max-width: 1399px) {

  .datepicker-block,
  .datepicker-block+div+div {
    margin-right: -10%
  }

  .datepicker-block .datepicker--cell,
  .datepicker-block .datepicker--day-name,
  .datepicker-block .datepicker--nav {
    height: 28px
  }

  .datepicker-block .select2-container--default .select2-selection--single .select2-selection__rendered {
    height: 48px;
    padding: 12px 35px 12px 15px
  }

  .datepicker-block .select2-container--default .select2-results>.select2-results__options {
    padding: 12px 15px
  }

  .datepicker-block .select2-container--default .select2-selection--single .select2-selection__arrow b {
    right: 15px
  }
}

@media (max-width: 1199px) {

  .datepicker-block,
  .datepicker-block+div+div {
    margin-right: -15%
  }
}

@media (max-width: 991px) {

  .datepicker-block,
  .datepicker-block+div+div {
    margin-right: 0
  }
}

@media (max-width: 767px) {
  .datepicker-block .flex {
    flex-direction: column
  }

  .datepicker-block .flex .button-done {
    margin-left: 0;
    margin-top: 15px;
    width: 100%
  }
}

.touch-screen .CaptionCont.SelectBox {
  pointer-events: none
}

.SumoSelect {
  margin-bottom: 20px;
  outline: none;
  position: relative;
  z-index: 1
}

.SumoSelect p {
  margin: 0
}

.SumoSelect .hidden {
  display: none
}

.SumoSelect .search-txt {
  display: none;
  outline: none
}

.SumoSelect .no-match {
  display: none;
  padding: 6px
}

.SumoSelect label {
  margin-bottom: 0
}

.SumoSelect .select-all>label,
.SumoSelect>.CaptionCont,
.SumoSelect>.optWrapper>.options li.opt label {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.SumoSelect>.CaptionCont {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
  height: 48px;
  padding: 12px 15px 10px;
  position: relative;
  z-index: 3
}

.SumoSelect>.CaptionCont:after {
  border-bottom: 1px solid #626262;
  border-right: 1px solid #626262;
  content: "";
  height: 6px;
  margin-top: -5px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: rotate(45deg);
  width: 6px
}

.SumoSelect>.CaptionCont>span {
  color: #626262;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 22px;
  overflow: hidden;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap
}

.SumoSelect>.CaptionCont>span.placeholder {
  font-style: normal
}

.SumoSelect>.CaptionCont>label {
  display: none
}

.SumoSelect>.optWrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .08);
  box-sizing: border-box;
  display: block;
  left: -4px;
  opacity: 0;
  padding: 52px 4px 4px;
  position: absolute;
  right: -4px;
  top: -4px;
  transform: translate3d(0, 15px, 0);
  visibility: hidden;
  will-change: transform
}

.SumoSelect>.optWrapper ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0
}

.SumoSelect>.optWrapper>.options,
.datepicker-options {
  max-height: 210px;
  overflow: auto
}

.filter-result-bar .SumoSelect>.optWrapper>.options {
  max-height: unset;
  overflow: auto;
  width: -moz-fit-content;
  width: fit-content
}

.SumoSelect>.optWrapper>.options::-webkit-scrollbar {
  height: 2px;
  width: 2px
}

.SumoSelect>.optWrapper>.options::-webkit-scrollbar-track {
  background-color: transparent
}

.SumoSelect>.optWrapper>.options::-webkit-scrollbar-thumb {
  background-color: #2ab0cd
}

.SumoSelect>.optWrapper>.options li {
  cursor: pointer
}

.SumoSelect>.optWrapper>.options li span {
  display: none
}

.SumoSelect>.optWrapper>.options li label.checkbox span {
  display: unset !important
}

.SumoSelect>.optWrapper>.options li label {
  color: #626262;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.SumoSelect>.optWrapper>.options li.opt {
  padding: 6px 15px;
  position: relative
}

.SumoSelect>.optWrapper>.options li.opt:last-child {
  border-bottom: none
}

.SumoSelect>.optWrapper>.options li.opt.disabled {
  display: none
}

.SumoSelect>.optWrapper>.options li.opt.selected label {
  color: #2ab0cd
}

.SumoSelect>.optWrapper>.options li.group>label {
  cursor: default;
  font-weight: 700;
  padding: 8px 6px
}

.SumoSelect>.optWrapper>.options li.group.disabled * {
  filter: alpha(opacity=50);
  opacity: .5
}

.SumoSelect>.optWrapper>.options li.group.disabled>label {
  opacity: .5
}

.SumoSelect>.optWrapper>.options li.optGroup {
  padding-left: 5px;
  text-decoration: underline
}

.SumoSelect>.optWrapper>.options li ul li.opt {
  padding-left: 22px
}

.SumoSelect>.optWrapper.multiple>.options li.opt {
  cursor: pointer;
  padding-left: 35px
}

.SumoSelect>.optWrapper.multiple>.options li ul li.opt {
  padding-left: 50px
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 12px;
  text-align: center;
  width: 50%
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p.btnOk {
  border-right: 1px solid #dbdbdb
}

.SumoSelect>.optWrapper.multiple.isFloating {
  padding-bottom: 43px
}

.SumoSelect>.optWrapper.multiple.isFloating>.MultiControls {
  bottom: 0;
  display: block;
  margin-top: 5px;
  position: absolute;
  width: 100%
}

.SumoSelect>.optWrapper.multiple.isFloating>.options li.opt {
  padding-left: 35px
}

.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls {
  display: block
}

.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls>p {
  padding: 6px
}

.SumoSelect>.optWrapper.isFloating {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 90%;
  position: fixed;
  right: 0;
  top: 0;
  width: 90%
}

.SumoSelect>.optWrapper.isFloating>.options {
  max-height: 100%
}

.SumoSelect>.optWrapper.isFloating>.options li.opt {
  padding: 12px 6px
}

.SumoSelect>.optWrapper>.MultiControls {
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: none
}

.SumoSelect .select-all {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  height: 20px;
  padding: 8px 0 3px 35px;
  position: relative
}

.SumoSelect .select-all.partial>span i {
  background-color: #ccc
}

.SumoSelect:not(.open) {
  transition: z-index .35s ease-in-out
}

.SumoSelect.open {
  z-index: 75 !important
}

.SumoSelect.open .search-txt {
  border: none;
  box-sizing: border-box;
  display: inline-block;
  left: 0;
  margin: 0;
  padding: 5px 8px;
  position: absolute;
  top: 0;
  width: 100%
}

.SumoSelect.open>.search>label,
.SumoSelect.open>.search>span {
  visibility: hidden
}

.SumoSelect.open>.optWrapper {
  opacity: 1;
  transform: none;
  visibility: visible
}

.SumoSelect.open>.optWrapper.up {
  bottom: 100%;
  margin-bottom: 5px;
  top: auto
}

.SumoSelect.open>.CaptionCont:after {
  border-color: #2ab0cd;
  transform: rotate(225deg) translate3d(-4px, -2px, 0)
}

.SumoSelect.disabled {
  cursor: not-allowed;
  opacity: .7
}

.SumoSelect.disabled>.CaptionCont {
  border-color: #ccc
}

.sumoStopScroll {
  overflow: hidden
}

.SelectClass,
.SumoUnder {
  border: none;
  box-sizing: border-box;
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.SelectClass {
  z-index: 1
}

.SumoSelect .select-all.sel,
.SumoSelect>.optWrapper>.options li.opt.sel {
  background-color: #a1c0e4
}

.SumoSelect .select-all>span,
.SumoSelect>.optWrapper.multiple>.options li.opt span {
  bottom: 0;
  display: block;
  margin-left: -35px;
  position: absolute;
  top: 0;
  width: 30px
}

.SumoSelect .select-all>span i,
.SumoSelect>.optWrapper.multiple>.options li.opt span i {
  background-color: #fff;
  border: 1px solid #aeaeae;
  bottom: 0;
  height: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 14px
}

.SumoSelect>.optWrapper>.options li.opt.selected {
  color: #ff6b02
}

.SumoSelect .select-all.partial>span i,
.SumoSelect .select-all.selected>span i,
.SumoSelect>.optWrapper.multiple>.options li.opt.selected span i {
  background-color: #11a911;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC");
  background-position: 50%;
  background-repeat: no-repeat;
  border-color: transparent
}

.SumoSelect .select-all>label,
.SumoSelect .select-all>span i {
  cursor: pointer
}

.custom-select+.CaptionCont+.optWrapper .opt img,
.custom-select+.CaptionCont+.optWrapper .opt label {
  display: inline-block;
  vertical-align: middle
}

.custom-select+.CaptionCont+.optWrapper .opt img {
  border-radius: 5px;
  margin-right: 10px;
  max-width: 40px
}

@media (min-width: 1200px) {
  .SumoSelect>.optWrapper>.options li.opt:hover label {
    color: #2ab0cd
  }

  .CaptionCont:hover {
    border-color: #2ab0cd
  }
}

.popup-wrap {
  height: 100%;
  left: -1000%;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101
}

.popup-wrap .bg-layer {
  background: rgba(0, 0, 0, .6);
  bottom: -100px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: -100px;
  transition: all .3s ease-out
}

.popup-wrap.active {
  left: 0;
  opacity: 1
}

.popup-wrap.active .bg-layer {
  opacity: 1
}

.popup-content {
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  height: 100%;
  left: -10000px;
  opacity: 0;
  overflow: auto;
  padding: 15px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: scale(1.2);
  transition: transform .32s ease-in, opacity .32s ease-in, left 0s ease .32s;
  width: 100%
}

.popup-content:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.popup-content .layer-close {
  bottom: 0;
  height: 10000px;
  left: 0;
  position: absolute;
  right: 0
}

.popup-content .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1
}

.popup-content.active {
  left: 0;
  opacity: 1;
  position: relative;
  transform: scale(1);
  transition: transform .32s ease-in, opacity .32s ease-in, left 0s ease 0s
}

.popup-container {
  background: #fff;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 100%
}

.popup-container.size-1 {
  max-width: 846px
}

.popup-container.size-2 {
  max-width: 638px
}

.popup-container.size-3 {
  bottom: 50px;
  left: 50%;
  max-width: 1480px;
  position: fixed;
  top: 50px;
  transform: translateX(-50%);
  width: calc(100% - 100px)
}

.popup-container.size-3 .popup-align,
.popup-container.size-3 .swiper-container,
.popup-container.size-3 .swiper-entry,
.popup-container.size-3 .swiper-slide,
.popup-container.size-3 .swiper-wrapper {
  height: 100%
}

.popup-container.size-3 .swiper-slider {
  align-items: center;
  display: flex;
  padding-bottom: 50px
}

.popup-container.size-3 .popup-align {
  padding-bottom: 20px;
  padding-top: 70px
}

.popup-container.size-3 .pagination-fraction,
.popup-container.size-3 .swiper-slide .text-md,
.popup-container.size-3 .swiper-slide img {
  position: absolute
}

.popup-container.size-3 .pagination-fraction {
  color: #323232;
  font-size: 16px;
  left: 20px;
  line-height: 20px;
  top: 20px
}

.popup-container.size-3 .swiper-slide {
  transition: opacity .44s ease-in-out
}

.popup-container.size-3 .swiper-slide img {
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto
}

.popup-container.size-3 .swiper-slide .text-md {
  bottom: 0;
  color: #323232;
  left: 0
}

.popup-container.size-3 .swiper-slide:not(.swiper-slide-active) {
  opacity: 0
}

.popup-container.size-3 .swiper-button-prev {
  left: -7%
}

.popup-container.size-3 .swiper-button-next {
  right: -7%
}

.popup-container.size-3 .swiper-pagination {
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: right
}

.popup-align {
  padding: 70px 10%
}

.popup-align .btn {
  margin-bottom: 20px
}

.popup-align .btn:last-child {
  margin-bottom: 0
}

.popup-footer,
.popup-header {
  background-color: #fff;
  padding: 18px 30px;
  text-align: center
}

.popup-header {
  border-bottom: 1px solid #e9e9e9;
  border-radius: 5px 5px 0 0;
  padding-top: 25px
}

.popup-footer {
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #e9e9e9
}

.popup-footer .text {
  color: #323232
}

.calendar-popup .popup-header {
  margin-bottom: 10px
}

.calendar-popup .popup-align {
  padding: 0
}

.calendar-popup .fc {
  margin-left: 0
}

.calendar-popup .fc-view-container+.fc-header-toolbar {
  padding-bottom: 25px
}

.calendar-popup .fc-left {
  padding-left: 20px;
  width: 57%
}

.calendar-popup .fc-right {
  display: none
}

.calendar-popup .calendar-footer-center,
.calendar-popup .calendar-header-center {
  left: 57%;
  padding-right: 20px;
  width: 43%
}

.calendar-popup .calendar-footer-center .text,
.calendar-popup .calendar-header-center .text {
  display: none
}

.calendar-popup .calendar-header-center {
  top: 0
}

.calendar-popup .calendar-footer-center {
  bottom: 0;
  padding-bottom: 25px
}

.calendar-popup .fc-day-top {
  width: 14.2857142857%
}

.calendar-popup .fc-day-number {
  pointer-events: none
}

.open-popup {
  cursor: pointer
}

.open-popup-link {
  color: #7ebd3e
}

.play-button {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border: 1px solid hsla(0, 0%, 100%, .22);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 74px;
  position: relative;
  text-align: left;
  width: 74px
}

.play-button:before {
  background: #fff;
  border-radius: 50%;
  bottom: -1px;
  content: "";
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
  transition: all .2s linear;
  z-index: 0
}

.play-button svg {
  position: relative;
  z-index: 1
}

.play-button svg path {
  fill: #1c1f26;
  transition: all .2s linear
}

.play-button.color-2 svg path {
  fill: #fff
}

.play-button.color-2:before {
  transform: scale(0)
}

.play-button-with-text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  font-size: 0
}

.play-button-with-text a {
  display: inline-block
}

.play-button-with-text a>* {
  display: inline-block;
  position: relative;
  vertical-align: middle
}

.play-button-with-text .title {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 32px;
  margin-left: 20px;
  text-transform: uppercase;
  white-space: nowrap
}

.play-button-with-text .title i {
  display: inline-block;
  height: 9px;
  margin-left: 20px;
  position: relative;
  width: 25px
}

.play-button-with-text .title i:before {
  background: #fff;
  bottom: 0;
  content: "";
  height: 9px;
  left: auto;
  position: absolute;
  right: 0;
  transition: all .2s linear;
  width: 0
}

.video-popup {
  height: 100%;
  left: 0;
  left: -100%;
  letter-spacing: 0;
  position: fixed;
  top: 0;
  transition-delay: .3s;
  width: 100%;
  z-index: 1000
}

.video-popup iframe {
  height: 100%;
  position: absolute;
  width: 100%
}

.video-popup.active {
  left: 0;
  transition-delay: 0ms
}

.video-popup.active .video-popup-overlay {
  opacity: .8
}

.video-popup.active .video-popup-content {
  left: 0;
  opacity: 1;
  position: relative;
  transform: scale(1);
  transition: opacity .3s ease-out, left 0s ease-out
}

.video-popup.embed-responsive-16by9 {
  padding-bottom: 56.3%
}

.video-popup-overlay {
  background: rgba(0, 0, 0, .8);
  bottom: -100px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: -100px;
  transition: all .4s ease-out
}

.video-popup-content {
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  height: 100%;
  left: -10000px;
  opacity: 0;
  overflow: auto;
  padding: 15px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: scale(1.2);
  transition: transform .3s ease-out, opacity .3s ease-out, left .3s ease-out;
  width: 100%
}

.video-popup-content:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.video-popup-container {
  background: #454545;
  display: inline-block;
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 100%
}

.video-popup-container .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.video-popup-layer {
  bottom: 0;
  height: 10000px;
  left: 0;
  position: absolute;
  right: 0
}

.video-popup-align {
  max-height: 100vh;
  overflow: hidden
}

.have-an-account {
  margin-bottom: 30px;
  margin-top: -15px
}

.have-an-account>span.text {
  color: #323232
}

.have-an-account .img-input {
  align-items: center;
  border: 1px dashed #e9e9e9;
  display: flex;
  height: auto;
  padding: 25px 5%;
  width: auto
}

.have-an-account .img-input:after {

  background-size: 98%;
  bottom: auto;
  display: inline-block;
  height: 40px;
  left: auto;
  order: 1;
  position: relative;
  right: auto;
  top: auto;
  vertical-align: middle;
  width: 40px
}

.have-an-account .img-input .text {
  order: 2;
  padding-left: 15px;
  text-align: left;
  width: calc(100% - 40px)
}

.have-an-account .img-input .text span {
  color: #626262
}

.mic-icon {
  margin: 0 auto 15px;
  max-width: 220px
}

.voice-search-dots {
  font-size: 0;
  margin-top: 25px;
  text-align: center
}

.voice-search-dots span {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-right: 8px;
  position: relative;
  width: 8px
}

.voice-search-dots span:first-child {
  background-color: #3e77aa
}

.voice-search-dots span:nth-child(2) {
  background-color: #f84147
}

.voice-search-dots span:nth-child(3) {
  background-color: #ff5c00
}

.voice-search-dots span:last-child {
  background-color: #00a046;
  margin-right: 0
}

.voice-search-dropdown {

}

.voice-search-dropdown.active {
  opacity: 1;
  transition: opacity .44s ease, visibility 0s ease;
  visibility: visible
}

.header-search.active .search-toggle {
  opacity: 1;
  transform: none;
  transition: opacity .44s ease-in-out, transform .44s ease-in-out, visibility 0s;
  visibility: visible
}

.search-toggle {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08);
  left: -5px;
  opacity: 0;
  padding: 55px 20px 0;
  position: absolute;
  right: -5px;
  top: -5px;
  transform: translate3d(0, 15px, 0);
  transition: opacity .44s ease-in-out, transform .44s ease-in-out, visibility 0s .44s;
  visibility: hidden;
  z-index: -1
}

.search-toggle>.text {
  color: #323232;
  display: block;
  margin: 10px 0 15px
}

.search-toggle>.btn {
  margin-bottom: 20px
}

.search-toggle ul li {
  cursor: pointer;
  margin-bottom: 15px
}

.search-toggle ul li .img,
.search-toggle ul li .text {
  display: inline-block;
  vertical-align: middle
}

.search-toggle ul li .img {
  border-radius: 3px;
  margin-right: 6px;
  overflow: hidden;
  padding-top: 40px;
  width: 54px
}

.search-toggle ul li .img img {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.search-toggle-footer {
  border-top: 1px solid #e9e9e9;
  cursor: pointer;
  margin: 0 -20px;
  padding: 12px 0;
  position: relative;
  text-align: center
}

.search-toggle-footer .text {
  color: #7ebd3e
}

@media (min-width: 1200px) {
  .play-button:hover:before {
    transform: scale(0)
  }

  .play-button:hover svg path {
    fill: #fff
  }

  .play-button-with-text a:hover svg path,
  .play-button.color-2:hover svg path {
    fill: #1c1f26
  }

  .play-button-with-text:hover .play-button:before,
  .play-button.color-2:hover:before {
    transform: scale(1)
  }

  .play-button-with-text:hover .title i:before {
    left: 0;
    right: auto;
    width: 100%
  }

  .search-toggle li:hover .text {
    color: #7ebd3e
  }

  .search-toggle-footer:hover .text {
    color: #2ab0cd
  }
}

@media (max-width: 1499px) {
  .popup-container.size-3 .swiper-container {
    padding-bottom: 50px
  }

  .popup-container.size-3 .popup-align {
    padding-bottom: 0;
    padding-top: 50px
  }

  .popup-container.size-3 .pagination-fraction {
    color: #323232;
    font-size: 16px;
    left: 20px;
    line-height: 20px;
    top: 20px
  }

  .popup-container.size-3 .swiper-slide .text-md {
    bottom: -40px;
    color: #323232;
    left: 0
  }

  .popup-container.size-3 .swiper-button-prev {
    left: -7%
  }

  .popup-container.size-3 .swiper-button-next {
    right: -7%
  }

  .popup-container.size-3 .swiper-pagination {
    bottom: 20px
  }

  .popup-align {
    padding: 50px 8%
  }

  .video-popup-container {
    max-width: 1000px
  }
}

@media (min-width: 992px) {

  .popup-container.size-3 .swiper-container,
  .popup-container.size-3 .swiper-wrapper {
    height: 100% !important
  }
}

@media (max-width: 991px) {
  .popup-container.size-3 {
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    transform: none;
    width: 100%
  }

  .popup-container.size-3 .popup-align {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 15px 20px 0;
    width: 100%
  }

  .popup-container.size-3 .swiper-container,
  .popup-container.size-3 .swiper-entry {
    height: auto !important
  }

  .popup-container.size-3 .swiper-entry {
    max-height: calc(100% - 90px);
    width: 100%
  }

  .popup-container.size-3 .swiper-container {
    padding-bottom: 30px
  }

  .popup-container.size-3 .swiper-slide {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
  }

  .popup-container.size-3 .swiper-slide .text-md,
  .popup-container.size-3 .swiper-slide img {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
    transform: none
  }

  .popup-container.size-3 .swiper-slide img {
    max-height: 88%;
    max-width: 100%;
    order: 2
  }

  .popup-container.size-3 .swiper-slide .text-md {
    margin-bottom: 15px;
    order: 1
  }

  .popup-container.size-3 .popup-align,
  .popup-container.size-3 .swiper-pagination {
    text-align: center
  }

  .popup-container.size-3 .swiper-button-next,
  .popup-container.size-3 .swiper-button-prev,
  .popup-container.size-3 .swiper-pagination {
    bottom: auto;
    display: inline-block;
    left: auto;
    margin: 0;
    position: relative;
    right: auto;
    top: auto;
    vertical-align: middle
  }

  .popup-container.size-3 .swiper-button-next,
  .popup-container.size-3 .swiper-button-prev {
    margin: 0
  }

  .popup-container.size-3 .swiper-button-prev {
    margin-right: 15px
  }

  .popup-container.size-3 .swiper-button-next {
    margin-left: 15px
  }

  .popup-container.size-3 .swiper-pagination {
    text-align: center;
    width: auto
  }

  .popup-container.size-3 .pagination-fraction {
    display: none
  }

  .calendar-popup .fc-view-container+.fc-header-toolbar {
    padding-bottom: 25px
  }

  .calendar-popup .fc-left {
    padding-left: 15px;
    width: 50%
  }

  .calendar-popup .fc-right {
    display: none
  }

  .calendar-popup .calendar-footer-center,
  .calendar-popup .calendar-header-center {
    justify-content: flex-end;
    left: auto;
    padding-right: 0;
    right: 15px;
    width: calc(50% - 15px)
  }

  .calendar-popup .calendar-footer-center .text,
  .calendar-popup .calendar-header-center .text {
    display: none
  }

  .calendar-popup .calendar-header-center {
    top: 4px
  }

  .calendar-popup .calendar-footer-center {
    bottom: 4px;
    padding-bottom: 25px
  }

  .calendar-popup .fc-state-default.fc-today-button {
    margin-left: 30px
  }
}

@media (max-width: 767px) {

  .calendar-popup .fc-view .fc-row .fc-widget-header>table>tbody,
  .calendar-popup .fc-view .fc-row .fc-widget-header>table>thead {
    display: block;
    min-width: 100%;
    width: 100%
  }

  .calendar-popup .fc td,
  .calendar-popup .fc th,
  .calendar-popup .fc-day-top {
    min-width: auto;
    min-width: 140px;
    width: 140px
  }

  .calendar-popup tr {
    width: 100%
  }

  .calendar-popup .calendar-header-center,
  .calendar-popup .fc-left {
    left: 0;
    padding: 0 15px;
    right: auto;
    width: 100%
  }

  .calendar-popup .calendar-header-center {
    justify-content: flex-start
  }

  .calendar-popup .calendar-header-center span.text-sm {
    margin-left: 15px;
    white-space: nowrap
  }

  .calendar-popup .calendar-header-center span:first-child.text-sm {
    margin-left: 0
  }

  .popup-content .btn-close {
    right: 10px;
    top: 10px
  }

  .popup-align {
    padding: 30px 15px
  }

  .popup-align+.popup-footer {
    margin-top: -10px
  }

  .popup-footer {
    padding: 12px 15px
  }

  .have-an-account {
    margin-bottom: 15px;
    margin-top: -30px
  }

  .have-an-account .img-input {
    flex-wrap: wrap;
    padding: 15px
  }

  .have-an-account .img-input:after {
    display: block;
    margin: 0 auto 15px
  }

  .have-an-account .img-input .text {
    padding-left: 0;
    text-align: center;
    width: 100%
  }
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  -moz-transition: all .44s ease-in-out;
  transition: all .44s ease-in-out
}

#header-layer-close,
#menu-btn span,
#menu-btn:after,
#menu-btn:before,
#search-dropdown,
#tour-selection-dropdown,
#voice-search-btn svg,
.SumoSelect .select-all>label,
.SumoSelect>.CaptionCont,
.SumoSelect>.CaptionCont:after,
.SumoSelect>.CaptionCont>span,
.SumoSelect>.optWrapper,
.SumoSelect>.optWrapper>.options li.opt label,
.accordion-inner .review-title .text:not(.text-sm),
.accordion-title,
.accordion-title i:after,
.accordion-title i:before,
.add-file span,
.add-note-btn .text-bold,
.apartment-option .text,
.banner-carousel .swiper-slide .full-size>div:first-child .h1 a:after,
.btn path,
.btn-close span:after,
.btn-close span:before,
.btn-close:before,
.btn-delete:after,
.btn-delete:before,
.btn-fb path,
.btn-google path,
.btn-read-more,
.btn-read-more.left-arrow:before,
.btn-read-more:after,
.btn-to-top,
.btn-to-top:after,
.btn-to-top:before,
.btn.arrow-right:after,
.cabinet-page .accordion-title .h4,
.calc-row .input-number label:after,
.checkbox span,
.checkbox span:after,
.checkbox span:before,
.datepicker--cell,
.datepicker--nav-action path,
.datepicker--nav-title,
.datepicker-input,
.datepicker-input:after,
.datepicker-placeholder,
.datepicker-toggle,
.delete-account,
.download .text-md,
.dropdown-btn,

.dropdown:after,
.exchange>span,
.expand-all,
.expand-all:after,
.expand-all:before,
.fc-event .fc-content:after,
.fc-event:after,
.fc-event:before,
.fc-icon,
.fc-right button,
.fc-right:after,
.fc-today-button,
.gift-certificate .bg,
.header-search button path,
.lang>span,
.like,
.like path,
.log-in,
.number-input button:after,
.number-input button:before,
.open-popup-link,
.order-page .tab-caption:after,
.pagination .text:after,
.participant .h4:after,
.radio span,
.radio span:after,
.radio span:before,
.review.testimonial .review-title .text:not(.text-sm),
.right-sidebar .add-to-like .icon,
.right-sidebar .add-to-like .icon svg,
.search-toggle .text,
.search-toggle-footer .text,
.select-icon,
.share .icon,
.show-more,
.show-more:after,
.show-more:before,
.social a circle,
.social a line,
.social a path,
.social a polygon,
.social a square,
.social a:before,
.social-block li a:before,
.swiper-button-next i,
.swiper-button-next i:before,
.swiper-button-next:before,
.swiper-button-prev i,
.swiper-button-prev i:before,
.swiper-button-prev:before,
.swiper-pagination-bullet,
.swiper-pagination-bullet:after,
.tab-caption,
.tab-caption path,
.tab-caption rect,
.tab-caption span,
.tab-caption:before,
.tab-title:after,
.tab-toggle,
.text-md.open-popup,
.text-sm.open-popup,
.text.open-popup,
.thumb-content,
.thumb-desc,
.thumb-img img,
.thumb:before,
.ui-menu-item-all,
.ui-menu-item-wrapper span,
.zoom img,
a,
header,
input,
input::placeholder,
label i,
nav,
textarea,
textarea::placeholder {
  transition: all .44s ease-in-out
}

.swiper-slide .bordered-box.vacancy {
  padding: 20px 20px 50px
}

.swiper-slide .bordered-box.vacancy .btn {
  border-top: 1px solid #e9e9e9;
  bottom: 15px;
  padding: 15px 0 0;
  right: 21px;
  text-align: left
}

.swiper-button-prev.inner.vac {
  bottom: auto !important;
  left: 0 !important;
  top: 100px
}

.swiper-button-next.inner.vac {
  bottom: auto !important;
  right: 0 !important;
  top: 100px
}

.thumbs-carousel.vac .swiper-pagination {
  margin: 0;
  top: auto
}

.thumbs-carousel.vac {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 60px
}

.btn-book-size-d {
  font-size: 14px
}

.social.style-2 a:not(.download) {
  height: 38px;
  width: 38px
}

.social.style-2 a:not(.download) svg {
  transform: scale(1.4) translate(-30%, -30%)
}

.popup-container.size-3-new {
  max-width: 1480px
}

.popup-container.size-3-new .pagination-fraction {
  font-size: 16px;
  left: 30px;
  position: absolute;
  top: 30px
}

.popup-container.size-3-new .popup-align {
  padding: 70px 170px 40px
}

.popup-container.size-3-new .swiper-slide img {
  margin: 0 auto;
  max-height: 75vh;
  max-width: 100%
}

.popup-container.size-3-new .swiper-slide {
  padding: 0 0 36px;
  text-align: center
}

.popup-container.size-3-new .swiper-entry .swiper-button-next {
  right: -105px
}

.popup-container.size-3-new .swiper-entry .swiper-button-prev {
  left: -105px
}

.popup-container.size-3-new .swiper-slide .text-md {
  bottom: -4px;
  left: 0;
  position: absolute
}

.popup-container.size-3-new .swiper-pagination {
  bottom: 0;
  right: 0
}

.popup-container.size-3-new .btn-close {
  height: 40px;
  right: 20px;
  top: 20px;
  width: 40px
}

.popup-container.size-3-new .btn-close:before {
  height: 40px;
  width: 40px
}

.cabinet-page {
  overflow: hidden
}

.item {
  -webkit-box-align: start;
  -ms-flex-align: start
}

.item .item-cnt {
  width: 100% !important
}

.item .thumb-img {
  max-width: 280px;
  min-width: 280px
}

.item .item-cnt .thumb-content {
  width: 50%
}

.item .item-cnt .thumb-content:last-child {
  margin: 0 0 0 20px
}

.item .item-cnt .thumb-info {
  width: 100%
}



.m10 {
  margin: 0 0 10px
}

input[name=tel]::-webkit-input-placeholder {
  color: transparent
}

input[name=tel]:-moz-placeholder,
input[name=tel]::-moz-placeholder {
  color: transparent
}

input[name=tel]:-ms-input-placeholder {
  color: transparent
}

input[name=viber]::-webkit-input-placeholder {
  color: transparent
}

input[name=viber]:-moz-placeholder,
input[name=viber]::-moz-placeholder {
  color: transparent
}

input[name=viber]:-ms-input-placeholder {
  color: transparent
}

.upak-variant .radio {
  vertical-align: top
}

.upak-variant .img-ic {
  margin: 10px 0
}

.upak-variant .img-ic img {
  width: 50px
}

.det-n {
  display: none
}

@media (max-width: 1199px) {
  .thumbs-carousel.vac {
    padding: 0 0 50px
  }

  .thumbs-carousel.vac .swiper-pagination {
    bottom: 20px
  }

  .swiper-button-prev.inner.vac {
    border: 0;
    bottom: 9px !important;
    left: 0 !important;
    top: auto
  }

  .swiper-button-next.inner.vac {
    border: 0;
    bottom: 9px !important;
    right: 0 !important;
    top: auto
  }

  .thumbs-carousel.pad .swiper-wrapper {
    padding-bottom: 95px
  }

  .tab .fc {
    margin-bottom: 60px
  }

  .calendar-footer-center .text {
    bottom: -58px;
    display: block;
    position: absolute;
    right: 0;
    width: calc(100vw - 40px);
    z-index: 71
  }

  .fc-view-container+.fc-toolbar .fc-right {
    bottom: -58px;
    display: block;
    left: 65px;
    margin: 0;
    position: absolute;
    top: auto
  }

  .calendar-footer-center {
    display: block
  }

  .calendar-footer-center .text-sm {
    display: none
  }

  .tour-content .h1.h0 {
    min-height: 0
  }

  .popup-container.size-3-new .popup-align {
    padding: 70px 50px 40px
  }

  .popup-container.size-3-new .swiper-entry .swiper-button-next {
    right: -45px
  }

  .popup-container.size-3-new .swiper-entry .swiper-button-prev {
    left: -45px
  }

  .datepicker-here-2 .datepicker--nav {
    background-color: #fff;
    border-top: 0 solid #e9e9e9;
    bottom: 0;
    max-height: none;
    overflow: auto;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    width: 100%;
    will-change: transform
  }

  .tour-content.nn .h1:not(.autoheight) {
    min-height: 0
  }

  .order-page .tab-caption {
    width: 31.25%
  }
}

@media (max-width: 992px) {
  .only-desktop-9 {
    display: none !important
  }

  .cntr {
    text-align: center
  }

  .review .swiper-button-prev {
    border: 1px solid #e9e9e9;
    left: 0
  }

  .review .swiper-button-next {
    border: 1px solid #e9e9e9;
    right: 0
  }

  .review.testimonial .swiper-entry {
    padding: 0 50px
  }

  .faq-tab-nav .tab-toggle {
    width: 100%
  }

  .tab-caption:last-child {
    margin-right: 40px
  }

  #tour-selection-dropdown .bottom-part {
    font-size: 0
  }

  .item {
    border: 0;
    border-radius: 0;
    border-top: 1px solid #e9e9e9;
    margin: 0 0 20px;
    padding: 20px 0 0
  }

  .item .item-cnt .thumb-content .desc {
    display: none
  }

  .item .item-cnt {
    display: block !important;
    padding: 0 !important
  }

  .item .item-cnt .thumb-content {
    width: 100%
  }

  .item .thumb-content:last-of-type {
    border: 0;
    margin-left: 0 !important;
    padding-left: 0
  }

  .item .thumb-img {
    margin: 0 20px 0 0;
    max-width: 256px;
    min-width: 256px
  }

  .item .thumb-img img {
    height: 100%;
    max-width: none;
    width: auto
  }

  .item .item-cnt .stars {
    width: 100%
  }

  .item .item-cnt .thumb-info {
    min-width: 225px
  }

  .item .thumb-content .title {
    line-height: 26px
  }

  .item .item-cnt .thumb-info>span.thumb-info-time {
    border-left: 1px solid #e9e9e9;
    border-right: 0 solid #e9e9e9;
    margin: 0 10px 0 0;
    padding-left: 20px;
    padding-right: 0;
    width: 144px
  }

  .item .item-cnt .thumb-info>span.thumb-info-people {
    padding-left: 0
  }

  .item .item-cnt .thumb-info>span {
    width: auto
  }

  .item .item-cnt .SumoSelect>.CaptionCont {
    height: 40px;
    padding: 10px 15px 8px
  }

  .item .item-cnt .thumb-price {
    justify-content: flex-start !important
  }

  .item .item-cnt .thumb-price .discount {

    border-radius: 3px;
    margin: 0 0 0 10px;
    padding: 5px 33px 5px 13px
  }

  .item .item-cnt .thumb-price {
    border: 0;
    margin-bottom: 20px;
    padding: 0
  }

  .w-100-t {
    width: 100%
  }

  .news .item {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    margin: 0 0 20px;
    padding: 0
  }

  .news .item.post .thumb-img {
    margin: 0;
    max-width: 100%;
    min-width: 0
  }

  .news .item .thumb-img img {
    width: 100%
  }

  .news .item.post .thumb-content {
    padding: 15px
  }

  .order-page .tab-caption {
    width: auto
  }
}

@media (max-width: 767px) {
  .item.post .thumb-img {
    height: 193px;
    padding-top: 0
  }

  .calendar-popup .fc td,
  .calendar-popup .fc th,
  .calendar-popup .fc-day-top {
    min-width: 70px
  }

  .scroll-bottom,
  .scroll-top {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%
  }

  .scroll-div-1,
  .scroll-top {
    height: 5px
  }

  .scroll-div-2 {
    overflow: auto
  }

  .review .swiper-button-prev {
    border: 0 solid #e9e9e9;
    bottom: -12px;
    left: 0;
    margin: 0;
    top: auto
  }

  .review .swiper-button-next {
    border: 0 solid #e9e9e9;
    bottom: -12px;
    margin: 0;
    right: 0;
    top: auto
  }

  .review.testimonial .swiper-entry {
    padding: 0 0 25px
  }

  .review.testimonial .swiper-entry .swiper-pagination {
    bottom: 0;
    width: 100%
  }

  .popup-container.size-3-new {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
  }

  .popup-container.size-3-new .popup-align {
    padding: 0 15px
  }

  .popup-container.size-3-new .pagination-fraction {
    display: none
  }

  .popup-container.size-3-new .swiper-entry {
    left: 15px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%)
  }

  .popup-container.size-3-new .swiper-slide {
    padding: 40px 0 30px
  }

  .popup-container.size-3-new .swiper-slide .text-md {
    bottom: auto;
    text-align: center;
    top: 0;
    width: 100%
  }

  .popup-container.size-3-new .swiper-wrapper {
    align-items: center !important
  }

  .popup-container.size-3-new .swiper-pagination {
    left: 40px;
    right: 40px
  }

  .popup-container.size-3-new .swiper-entry .swiper-button-next {
    bottom: -12px;
    margin: 0;
    right: 0;
    top: auto
  }

  .popup-container.size-3-new .swiper-entry .swiper-button-prev {
    bottom: -12px;
    left: 0;
    margin: 0;
    top: auto
  }

  .popup-container.size-3-new .btn-close {
    border: 0;
    right: 0;
    top: 0
  }

  .popup-container.size-3-new .btn-close:before {
    display: none
  }

  .faqs .tab-caption:last-child {
    margin-right: 0
  }

  .faqs .tab-caption {
    margin: 0 10px 10px !important
  }

  .faqs .tab-caption.active:before {
    display: none
  }

  .history .col-6 {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto
  }

  .history .row .col-6:last-child {
    text-align: right;
    white-space: nowrap
  }

  .history .row {
    justify-content: space-between !important
  }

  .thumb .SumoSelect>.CaptionCont span {
    font-size: 12px
  }

  .thumb .SumoSelect>.CaptionCont {
    padding: 12px 8px 10px
  }

  .thumb .SumoSelect>.CaptionCont:after {
    right: 10px
  }

  .order-page .social span {
    margin-bottom: 20px;
    margin-right: 0;
    width: 100%
  }

  .order-page .participant.bottom-text .form>.text {
    white-space: normal
  }

  br.only-mobile {
    display: block
  }

  .order-page .checkbox.small span {
    font-size: 14px
  }

  .w-normal {
    white-space: normal
  }

  .btn.type-1.w-normal {
    padding: 5px 10px 3px
  }

  .btn.btn-read-more.w-normal {
    padding: 0 0 0 15px;
    text-align: left
  }

  .btn.btn-read-more.left-arrow.w-normal:before {
    left: 0;
    position: absolute;
    top: 7px
  }
}

@media (max-width: 575px) {
  .order-page .bordered-box {
    padding: 20px 0
  }

  .order-page .col-pidar-2 {
    flex: 0 0 130px;
    max-width: 130px
  }

  .order-page .col-pidar-1 {
    flex: 0 0 calc(100% - 130px);
    max-width: calc(100% - 130px)
  }

  .order-page .col-pidar-2 .number-input button {
    width: 30px
  }
}

@media (max-width: 359px) {
  header.login-header .container .row>div:first-child {
    margin-right: 30px
  }

  .log-inned-icon {
    margin-right: 15px
  }

  header.login-header .log-in {
    margin-left: 15px
  }
}

#seo-shorten-text {
  transition: height .4s ease-in-out
}

@media (max-width: 1200px) {
  .dropdown-toggle li>a {
    /* display: block */
  }

  li {
    /* margin-bottom: 20px */
  }

  nav li>a,
  nav li>span:not(.dropdown-btn) {
    display: inline-block;
    padding: 10px 0;
    width: 100%
  }

  nav li>span:not(.dropdown-btn) {
    cursor: pointer;
    font-size: 16px
  }

  .datepicker .datepicker--nav {
    position: unset;
    transform: unset;
    width: auto
  }
}

.datepicker-placeholder:after,
label:after {
  display: block !important;
  left: 100% !important;
  margin: 0 20px !important;
  transform: translateX(-100%) !important
}

.alert {
  font-size: 14px;
  margin: 10px auto;
  padding: 5px 10px 5px 26px;
  position: relative;
  width: -moz-fit-content;
  width: fit-content
}

.alert.alert-danger {
  color: #cf2020
}

.alert:before {
  bottom: 0;
  content: "";
  display: block;
  height: 16px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 16px
}

.popup-content .swiper-slide {
  display: flex !important;
  flex-direction: column !important
}

@media (max-width: 768px) {
  .popup-content .swiper-slide {
    flex-direction: column-reverse !important
  }

  .popup-content .swiper-slide .text-md {
    position: relative !important
  }
}

.wbr,
wbr {
  display: inline-block
}

@media (max-width: 768px) {

  .wbr,
  wbr {
    display: block
  }
}

.swiper-slide {
  width: 152.75px;
  margin-right: 22px;
}